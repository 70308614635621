<template>
  <div class="p-4">
    <strong>Upload local file</strong>
    <p class="text">Here you can upload your own stickers and they will be added to your clip.</p>

    <div v-if="error" class="alert-danger alert" role="alert">
      {{ error }}
    </div>

    <div class="upload">
      <div class="flex">
        <button class="btn-outline btn-primary btn-lg btn" @click="triggerFileUpload">
          <i> <upload-icon style="margin-right: 0" /> </i><span class="ml-1">Upload local file</span>
          <silver-plan-button class="ml-4" feature="upload-custom-stickers" :small="true" />
        </button>
      </div>
      <input
        @click="checkTier1"
        @change="customStickerSelected"
        style="display: none"
        ref="stickerfile"
        id="stickerfile"
        type="file"
        name="stickerfile"
        accept="image/png, image/jpeg"
      />
    </div>

    <hr class="my-4 opacity-10" />

    <custom-sticker-library />

    <progress-dialog
      ref="fileUploadDialog"
      title="Uploading sticker"
      :in-progress="isLoading"
      :progress-percentage="uploadFilePercentage"
      :success="uploadFileSuccess"
      :error-message="'An error occurred while uploading your custom Sticker'"
      :loading-message="'Preparing your custom Sticker'"
      :success-message="'Your custom Sticker has been processed and is ready to use'"
    />
  </div>
</template>

<script>
import UploadIcon from '../Icons/UploadIcon.vue'
import ProgressDialog from '../Dialog/ProgressDialog.vue'
import uploadService from '@/services/uploadService'
import customStickerService from '@/services/customStickerService'
import * as Sentry from '@sentry/browser'
import CustomStickerLibrary from './Custom/CustomStickerLibrary.vue'
import EventBus from '../../eventBus'
import customStickerEvents from '../../events/customStickerEvents'
import canFeature from '../../mixins/canFeature'
import SilverPlanButton from '../Account/Upgrade/SilverPlanButton.vue'
import { defineAsyncComponent, markRaw } from 'vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'

export default {
  components: {
    SilverPlanButton,
    UploadIcon,
    ProgressDialog,
    CustomStickerLibrary,
  },
  data() {
    return {
      isLoading: false,
      error: '',
      uploadFilePercentage: 0,
      uploadFileSuccess: true,
      maxFileSize: 5, // in mb
    }
  },
  mixins: [canFeature],
  methods: {
    triggerFileUpload() {
      this.$refs.stickerfile.click()
    },
    checkTier1(e) {
      if (!this.userCan('upload-custom-stickers')) {
        upgradeDialog.open('upload-custom-stickers')
        e.preventDefault()
      }
    },
    async customStickerSelected(event) {
      this.isLoading = true

      if (event.target.files.length === 0) {
        // no file selected, reset form
        this.showMaxSizeError = false
        this.isLoading = false
        return
      }

      // Validate size
      const file = event.target.files[0]
      if (file.size / 1024 / 1024 > this.maxFileSize) {
        this.error = `File is too big. Max file size is: ${this.maxFileSize}MB`
        this.loading = false
        return
      }

      // Upload file and add sticker
      try {
        this.uploadFilePercentage = 0
        this.$refs.fileUploadDialog.open()

        const result = await uploadService.getUploadCustomStickerSignedUrl()
        await uploadService.uploadFileS3(result.signedUrl, file, this.onFileUploadProgress)
        await customStickerService.post(result.resultUrl)
        EventBus.$emit(customStickerEvents.CUSTOM_STICKER_UPLOADED)
        this.uploadFileSuccess = true
        event.target.value = ''

        await this.selectSticker(result.resultUrl)
        this.$refs.fileUploadDialog.close()
      } catch (e) {
        this.uploadFileSuccess = false
        Sentry.captureException(e)
      }
      this.isLoading = false
    },
    onFileUploadProgress(percentage) {
      this.uploadFilePercentage = percentage
    },
    async selectSticker(imageUrl) {
      // Precache the image for StickerLayer, so the new sticker does not overflow the whole Preview before it is fully loaded
      // It still happens, but not as long without this
      const img = new Image()
      img.src = imageUrl
      await new Promise((resolve) => {
        img.onload = () => resolve()
      })

      // Create sticker as usual and emit
      const newSticker = {
        component: markRaw(defineAsyncComponent(() => import('./CustomSticker.vue'))),
        componentName: 'custom-sticker',
        imageUrl: imageUrl,
        x: -1,
        y: -1,
        scale: -1,
        key: '',
        savedSticker: false,
        visible: true,
        animationStyle: 'none',
        animationTime: undefined,
      }

      // scroll to top when a sticker is added (mostly for mobile)
      window.scrollTo(0, 0)
      EventBus.$emit('editor/stickers/added', newSticker)
      this.$emit('stickerPicked')
    },
  },
  mounted() {
    EventBus.$on(customStickerEvents.CUSTOM_STICKER_ADDED, async (url) => {
      await this.selectSticker(url)
    })
  },
  beforeUnmount() {
    EventBus.$off(customStickerEvents.CUSTOM_STICKER_ADDED)
  },
}
</script>

<style lang="scss" scoped>
p.text {
  padding-top: 4px;
  font-size: 0.8rem;
  opacity: 0.8;
}

div.upload label {
  color: #47089e;
}
</style>
