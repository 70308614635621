<template>
  <div>
    <div class="display">
      <div
        v-for="(sticker, i) in toRenderStickers"
        :key="i"
        class="sticker absolute"
        :style="{ transform: `translate(${sticker.x}px, ${sticker.y}px) scale(${sticker.scale})` }"
        style="user-select: none; line-height: 0"
        ref="stickerTarget"
      >
        <StickerAnimator
          :container-width="1080"
          :animation-time="sticker.animationTime"
          :animation-style="sticker.animationStyle"
          :sticker="sticker"
          :duration="videoDurationMs"
          :start-time="videoOffsetMs"
          :end-time="videoOffsetMs + videoDurationMs"
        >
          <component :is="sticker.component" v-bind="sticker" @stickerLoaded="stickerLoaded" />
        </StickerAnimator>
      </div>
      <caption-container
        ref="captions"
        :captions="captions"
        :captionStyleSettings="captionStyleSettings"
        :captionsWrapper="captionsWrapper"
        :currentTimeMs="currentTimeMs"
        :videoDuration="videoDurationMs"
        :offsetMs="videoOffsetMs"
      />
    </div>

    <div class="hiddenStickers">
      <!-- This is for preloading the fonts for the Puppeteer tool -->
      <div v-for="sticker in stickerLibrary" :key="sticker.key">
        <component :is="sticker.component" :html-content="'test'" :icon="sticker.icon" />
      </div>

      <!-- This is for preloading the fonts for the Puppeteer tool -->
      <div v-for="textSticker in textLibrary" :key="textSticker.key">
        <component :is="textSticker.component" :variant="textSticker.variant" :html-content="'test'" />
      </div>

      <!-- This is for preloading the fonts for the Puppeteer tool -->
      <div v-for="captionStyle in captionLibrary" :key="captionStyle.key">
        <component
          :is="getCaptionComponent(captionStyle.key)"
          :variant="captionStyle.variant"
          :html-content="'test'"
          class="always-visible"
        />
      </div>
    </div>
  </div>
</template>

<script>
import stickerLibrary from '../../components/Stickers/stickerLibrary/stickerLibrary'
import textLibrary from '../../components/Stickers/textLibrary'
import captionLibrary from '../../components/Captions/old/captionLibrary'
import CaptionContainer from '../../components/Captions/CaptionContainer.vue'
import TextSticker from '../../components/Stickers/TextSticker.vue'
import { mapState } from 'pinia'
import { useFontsStore } from '@/store/fonts'
import { markRaw, reactive, ref } from 'vue'
import { useTimeLine } from '@/components-v2/modules/VideoPlayer/useVideoManager'
import StickerAnimator from '@/components/Stickers/StickerAnimator.vue'
import { delay, uniq, findLastIndex } from 'lodash-es'
import * as Sentry from '@sentry/browser'
import { useDebugPopover, useDebugPopoverAction } from '@/components/Debug/useDebugStore'
import { getApiRendersIdDetails } from '@/apis/streamladder-api/renders/renders'

function concatObjectToString(obj) {
  let result = ''

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result += key + ': ' + obj[key] + '\n'
    }
  }

  return result
}

export default {
  components: { StickerAnimator, CaptionContainer },
  data() {
    return {
      stickerLibrary: stickerLibrary,
      textLibrary: textLibrary,
      captionLibrary: captionLibrary,
      toRenderStickers: [],
      stickerLoadedCounter: 0,
      isCaptionLoaded: false,
      captions: [],
      captionStyleSettings: undefined,
      captionsWrapper: undefined,
      currentTimeMs: 0,
      videoDurationMs: 0,
      videoOffsetMs: 0,
      lastKeyFrameIndex: null,
      keyframes: [], // {start: 0, end: 0}[]
    }
  },
  computed: {
    ...mapState(useFontsStore, ['hasFinishedLoadedFonts']),
    allStickers() {
      return this.stickerLibrary.concat(this.textLibrary)
    },
  },
  setup() {
    const timeline = useTimeLine()

    window.timeline = timeline

    const debug = reactive({
      title: 'pause',
      currentTime: 0,
      duration: 0,
      stickers: 0,
      captions: 0,
    })

    timeline.eventCallback('onUpdate', () => {
      debug.currentTime = timeline.time()
    })

    useDebugPopover('StickerRender', debug)
    useDebugPopoverAction('play', (state) => {
      if (timeline.isActive()) {
        state.title = 'play'
        debug.title = 'stopped'
        timeline.pause()
      } else {
        state.title = 'stop'
        debug.title = 'playing'
        timeline.time(0)
        timeline.play()
      }
    })
    useDebugPopoverAction('frame by frame', () => {
      debug.title = 'frame by frame'
      window.renderVideo()
    })

    useDebugPopoverAction('invalidate timeline', () => {
      timeline.invalidate()
    })
    return {
      debug,
    }
  },
  mounted() {
    document.body.className = 'transparant-bg'

    // Register functions to window so Puppeteer can call them more easily
    window.initializeRender = async (renderTaskData) => {
      await this.initializeRender(renderTaskData)
    }
    window.goToDurationMs = (duration) => {
      this.goToDurationMs(duration)
    }
    window.checkIfNeedRender = (duration) => {
      return this.checkIfNeedRender(duration)
    }
    window.renderVideo = async (duration) => {
      await this.renderVideo(duration)
    }
    const route = this.$route
    if (route.params?.renderId) {
      console.log('fetching render data')
      setTimeout(async () => {
        const renderData = await getApiRendersIdDetails(route.params.renderId)
        if (renderData.value.overlay) await this.initializeRender(renderData.value.overlay)
      }, 1000)
    }
    console.log('route', route)
  },
  methods: {
    async initializeRender(renderTaskData) {
      console.log('initializeRender', renderTaskData)
      const fontsStore = useFontsStore()
      const Stickers = renderTaskData.Stickers ?? []
      this.videoDurationMs = renderTaskData.DurationMs ?? 0
      this.videoOffsetMs = renderTaskData.OffsetMs ?? 0
      const timeline = useTimeLine()
      timeline.set({}, {}, this.videoDurationMs / 1000)

      this.debug.duration = this.videoDurationMs / 1000

      // Add extra data to stickers
      await Promise.all(
        Stickers.map(async (sticker) => {
          const stickerInLibrary = this.allStickers.find((x) => x.key === sticker.componentName) || {
            component: markRaw(TextSticker),
          }

          if (sticker.fontFamily) await fontsStore.loadFontByLabel(sticker.fontFamily)

          this.toRenderStickers.push({
            ...sticker,
            x: sticker.x * 1080,
            y: sticker.y * 1920,
            scale: sticker.scale * 1080,
            primaryColor: sticker.primaryColor,
            secondaryColor: sticker.secondaryColor,
            fontFamily: sticker.fontFamily,
            variant: sticker.variant,
            component: stickerInLibrary.component,
          })
        })
      )

      this.debug.stickers = this.toRenderStickers.length

      // Set and filter out empty/not-displayed captions
      this.captions = []
      if (renderTaskData.Captions) {
        this.captionsWrapper = renderTaskData.CaptionsWrapper
        this.captionStyleSettings = renderTaskData.captionsObject.CaptionStyleSettings

        await fontsStore.loadFontByLabel(this.captionStyleSettings?.fontFamily || '')

        this.captions = renderTaskData.Captions.map((caption) => {
          return {
            ...caption,
            start: Math.max(caption.start - this.videoOffsetMs, 0),
            end: Math.max(caption.end - this.videoOffsetMs, 0),
          }
        })

        this.debug.captions = this.captions.length

        this.captionLoaded()
      }

      await this.$nextTick()
      timeline.invalidate()
      try {
        this.generateKeyFrames()
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            captions: this.captions,
          },
        })
      }

      this.debug2 = `captions: ${this.captions.length} stickers: ${this.toRenderStickers.length}`

      // If we don't have any stickers or captions, we're ready to render
      if (this.toRenderStickers.length === 0 && this.captions.length === 0) {
        this.$nextTick(() => {
          this.checkRenderReady()
        })
      }
    },
    goToDurationMs(duration) {
      const timeline = useTimeLine()
      timeline.render(duration / 1000, false, false)
      this.currentTimeMs = duration
    },
    checkIfNeedRender(duration) {
      // if there are no keyframes, render every frame
      if (this.keyframes.length === 0) return true
      // if there is no lastKeyFrameIndex, find the last keyframe starts before the duration
      if (this.lastKeyFrameIndex === null) {
        this.lastKeyFrameIndex = findLastIndex(this.keyframes, (x) => x.start <= duration)
        return true
      }
      // check if the duration is within the current keyframe
      const currentKeyFrameValue = this.keyframes[this.lastKeyFrameIndex]
      const isWithinCurrentKeyFrame =
        currentKeyFrameValue && currentKeyFrameValue.start <= duration && currentKeyFrameValue.end >= duration
      if (isWithinCurrentKeyFrame) return true
      // get the next keyframe and check if it starts before the duration
      const nextKeyFrameValue = this.keyframes[this.lastKeyFrameIndex + 1]
      const isWithinNextKeyFrame = nextKeyFrameValue && nextKeyFrameValue.start <= duration
      if (isWithinNextKeyFrame) {
        this.lastKeyFrameIndex++
        return true
      }
      // if the duration is after the last keyframe, we don't need to render
      return false
    },
    stickerLoaded(elem) {
      this.stickerLoadedCounter++
      this.checkRenderReady()
    },
    captionLoaded(elem) {
      this.isCaptionLoaded = true
      this.checkRenderReady()
    },
    checkRenderReady() {
      // Sets a flag for Puppeteer to let it know the renderer is ready
      const captionsCompleted = !this.captions || this.captions.length === 0 || this.isCaptionLoaded
      const stickersCompleted = this.toRenderStickers.length === this.stickerLoadedCounter
      const fontsStore = useFontsStore()
      console.log(
        concatObjectToString({
          captionsCompleted,
          stickersCompleted,
          fontsCompleted: fontsStore.hasFinishedLoadedFonts,
        })
      )
      if (captionsCompleted && stickersCompleted && fontsStore.hasFinishedLoadedFonts) {
        this.$nextTick(() => {
          window.renderReady = true
        })
      }
    },
    getCaptionComponent(componentName) {
      const style = captionLibrary.find((e) => e.key === componentName)
      return style.component ?? TextSticker
    },
    // Generate a list of keyframes and animation zones so we can evaluate if we need to render a frame or use a cached one
    generateKeyFrames() {
      // Get all tweens from the gsap timeline
      const children = window.timeline.getChildren()
      // Get all the start and end times from the tweens and convert them to ms
      const timeSets = children.map((child) => {
        const { _start, _end } = child
        return { start: _start * 1000, end: _end * 1000 }
      })
      // Merge overlapping timeSets
      for (let i = 0; i < timeSets.length; i++) {
        const timeSet = timeSets[i]
        const nextTimeSet = timeSets[i + 1]
        if (!nextTimeSet) break
        if (timeSet.end >= nextTimeSet.start) {
          timeSet.end = nextTimeSet.end
          timeSets.splice(i + 1, 1)
          i--
        }
      }
      this.keyframes = timeSets
    },
    async renderVideo() {
      console.log('renderVideo', {
        videoDurationMs: this.videoDurationMs,
        videoOffsetMs: this.videoOffsetMs,
        captions: this.captions,
        toRenderStickers: this.toRenderStickers,
        keyframes: this.keyframes,
      })

      const totalFrames = Math.ceil((this.videoDurationMs / 1000) * 30)

      this.debug.totalFrames = totalFrames

      const triggers = []
      for (let i = 0; i < totalFrames; i++) {
        const duration = (i * 1000) / 30
        this.debug.currentTime = duration / 1000
        const isRenderFrame = this.checkIfNeedRender(duration)
        if (isRenderFrame) {
          triggers.push(duration)
          this.debug.renderedFrames = triggers.length
          this.goToDurationMs(duration)
          console.log('rendering frame', duration)
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve()
            }, 500)
          })
        }
      }
    },
  },
  watch: {
    // As extra check
    hasFinishedLoadedFonts(val) {
      if (val) this.checkRenderReady()
    },
  },
}
</script>

<style lang="scss" scoped>
.display {
  width: 1080px;
  height: 1920px;
  position: relative;
  overflow: hidden;
}

.sticker {
  transform-origin: top left;
}

.hiddenStickers {
  float: right;
}
</style>
<style>
.transparant-bg {
  background: none !important;
}
</style>
