<template>
  <div
    class="flex min-h-0 flex-row items-center justify-around border-t-2 border-t-gray-50 py-2 text-company-primary-500"
  >
    <AutoSplitterButton />
    <SimpleTooltip>
      <template v-slot:trigger>
        <button @click="splitSegment" class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block">
          <IconSaxScissor class="p-[1px]" />
          <span class="hidden md:inline">Split</span>
        </button>
      </template>
      <template v-slot:content>
        <span>Split clip at current time (s)</span>
      </template>
    </SimpleTooltip>
    <SimpleTooltip>
      <template v-slot:trigger>
        <button @click="pullRight" class="btn-ghost btn-sm btn block flex flex-row gap-2 rounded-lg text-black">
          <IconSaxArrowRight class="p-[1px]" />
        </button>
      </template>
      <template v-slot:content>
        <span>Trim to the left</span>
      </template>
    </SimpleTooltip>
    <SimpleTooltip>
      <template v-slot:trigger>
        <button @click="pullLeft" class="btn-ghost btn-sm btn block flex flex-row gap-2 rounded-lg text-black">
          <IconSaxArrowLeft class="p-[1px]" />
        </button>
      </template>
      <template v-slot:content>
        <span>Trim to the right</span>
      </template>
    </SimpleTooltip>
    <SimpleTooltip v-if="editorFocusStore.canDeleteFocusModel">
      <template v-slot:trigger>
        <button
          @click="editorFocusStore.deleteFocusModel"
          class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block"
        >
          <IconSaxTrash class="p-[1px]" />
        </button>
      </template>
      <template v-slot:content>
        <span>Delete selected</span>
      </template>
    </SimpleTooltip>
  </div>
</template>

<style scoped>
.btn.btn-ghost {
  background-color: transparent;
  border-color: transparent;
  padding-inline: 4px;
  gap: 0.25rem;
  display: flex;
  font-weight: 400;
}

.btn.btn-ghost.btn-info {
  background-color: #f2fcfe;
  color: #3b82f6;
}

.btn.btn-ghost.btn-success {
  background-color: #f0fdf4;
  color: #097c55;
}

.btn.btn-ghost:hover {
  background: #f3f4f6;
  border-color: transparent;
  color: black;
}

#zoomSlider {
  direction: rtl;
}

button > svg {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

/* The slider itself */
.zoom-range {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  overflow: hidden;
  width: 2rem; /* Full-width */
  height: 7px; /* Specified height */
  cursor: pointer;
  //background: #d3d3d3; /* Grey background */ border-radius: 5px; //outline: none; /* Remove outline */ //opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ //-webkit-transition: 0.2s; /* 0.2 seconds transition on hover */ //transition: opacity 0.2s;
}

/* Mouse-over effects */
.zoom-range:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.zoom-range::-webkit-slider-thumb {
  --range-shdw: black;
  appearance: none;
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  color: var(--range-shdw);
  background-color: black;
  border-radius: 100%;
  --filler-size: 100rem;
  --filler-offset: 0.2rem;
  box-shadow: calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
}

.zoom-range::-moz-range-thumb {
  top: 50%;
  color: var(--range-shdw);
  --filler-size: 100rem;
  --filler-offset: rem;
  box-shadow: calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
}

.zoom-range::-webkit-slider-runnable-track {
  background-color: #00000088;
}
</style>

<script setup lang="ts">
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorTimelineStore } from '@/store/editor/editorTimeline'
import { useDeleteCaption } from '@/Hooks/captions/useCaptionTools'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { onKeyStroke } from '@vueuse/core'
import { isInputTarget, msToTime } from '@/components/Editor/Timeline/helpers'
import { useConfirmDialog } from '@/components-v2/actions/dialog/useConfirmDialog'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxAddCircle from '@/components/Icons/iconsax/IconSaxAddCircle.vue'
import SimpleTooltip from '@/components-v2/data-display/SimpleTooltip.vue'
import TimelineZoomSlider from '@/components/Editor/Timeline/TimelineZoomSlider.vue'
import IconSaxMinusCirlce from '@/components/Icons/iconsax/IconSaxMinusCirlce.vue'
import IconCustomMaximize from '@/components/Icons/Normalized/IconCustomMaximize.vue'
import IconSaxVolumeHigh from '@/components/Icons/iconsax/IconSaxVolumeHigh.vue'
import IconSaxVolumeSlash from '@/components/Icons/iconsax/IconSaxVolumeSlash.vue'
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import IconSaxScissor from '@/components/Icons/iconsax/IconSaxScissor.vue'
import AutoSplitterButton from '@/components/Editor/RemoveParts/AutoSplitterButton.vue'

const editorMainStore = useEditorMainStore()
const editorCaptionsStore = useEditorCaptionsStore()
const editorTimelineStore = useEditorTimelineStore()
const deleteCaptions = useDeleteCaption()
const editorFocusStore = useEditorFocusStore()
const editorVideoStore = useEditorVideoStore()
const editorFeedDataStore = useEditorFeedDataStore()

const { reveal } = useConfirmDialog()

const splitSegment = () => {
  if (editorFocusStore.focus?.key) editorFocusStore.focus.key = undefined
  editorFeedDataStore.splitSegment(editorVideoStore.getExactTime())
}

const pullLeft = () => {
  const currentTimeMs = editorVideoStore.getExactTime()
  // find caption at time or right of time
  const segment = editorFeedDataStore.segments.find(
    (c) => (c.start <= currentTimeMs && c.end >= currentTimeMs) || c.start > currentTimeMs
  )
  if (segment == null) return
  const isUnder = segment.start <= currentTimeMs
  const _segment = {
    start: isUnder ? segment.start : currentTimeMs,
    end: isUnder ? currentTimeMs : segment.end,
  }
  editorFeedDataStore.updateSegment(segment.id, _segment)
}

const pullRight = () => {
  const currentTimeMs = editorVideoStore.getExactTime()
  // find caption at time or right of time
  const segment = editorFeedDataStore.segments.findLast(
    (c) => (c.start <= currentTimeMs && c.end >= currentTimeMs) || c.end < currentTimeMs
  )
  if (segment == null) return
  const isUnder = segment.end <= currentTimeMs
  const _segment = {
    start: isUnder ? segment.start : currentTimeMs,
    end: isUnder ? currentTimeMs : segment.end,
  }
  editorFeedDataStore.updateSegment(segment.id, _segment)
}

const deleteFeed = async () => {
  const confirm = await reveal({
    title: 'Delete caption',
    message: 'Are you sure you want to delete the selected caption?',
  })
  if (!confirm) return
  if (editorFocusStore.focus?.type === 'segment' && editorFocusStore.focus?.key) {
    editorFeedDataStore.removeSegment(editorFocusStore.focus.key)
  }
}
</script>
