<template>
  <div class="flex w-full flex-col items-start gap-4 lg:flex-row">
    <div class="panel w-full p-12 lg:w-2/3">
      <div class="mb-0 flex flex-col">
        <h2 class="text-3xl font-bold">Review your changes</h2>
        <p class="mb-3 mt-6 font-light">Take one last look: you can still revisit your plan.</p>

        <ol class="flex w-full flex-col gap-4">
          <Feature type="lost" v-for="(feature, i) in allFeaturesByTier.gold.slice(0, 3)" :key="i">
            {{ feature }}
          </Feature>
        </ol>
      </div>
    </div>

    <!-- dialog section -->
    <div class="panel w-full p-8 lg:w-1/3">
      <h4 class="text-2xl">Your new plan</h4>

      <div class="flex flex-col gap-2">
        <p class="mb-0 flex items-center gap-2" v-if="nextPlan">
          {{
            capitalize(
              nextPlan.productTitle
                .replace(/^streamladder ?|-/gi, ' ')
                .trim()
                .toLowerCase()
            )
          }}
          <SilverPlanButton :can-click="false" :force-show="true" />
        </p>

        <hr class="my-4" />

        <ol class="flex w-full flex-col gap-2">
          <Feature type="gained" v-for="(feature, i) in allFeaturesByTier.silver.slice(0, 3)" :key="i">
            {{ feature }}
          </Feature>
        </ol>

        <hr class="my-4" />

        <div class="flex w-full flex-row justify-between">
          <div>
            <strong class="">Your next charge:<br /></strong>
            <span class="text-sm" v-if="subscription.nextPayment?.date">
              (on {{ formatDate(subscription.nextPayment?.date) }})
            </span>
          </div>
          <div class="flex flex-col text-right">
            <span class="text-sm line-through opacity-50">{{ currentPlan.displayPrice }}</span>
            <span class="text-lg">{{ nextPlan.displayPrice }}</span>
          </div>
        </div>

        <!-- If user wants to cancel -->
        <button
          @click="downgradeSubscription"
          class="btn-primary btn h-16 w-full flex-wrap gap-px rounded px-4 py-4"
          :class="{ loading: isProcessing }"
        >
          <span class="font-bold">Continue to downgrade</span>
        </button>

        <router-link :to="{ name: 'Home' }" class="btn-outline btn-primary btn rounded" role="button">
          Go back
        </router-link>
      </div>
    </div>
    <!-- end dialog section -->

    <error-dialog
      ref="errorDialog"
      :action="
        () => {
          $router.push({ name: 'Home' })
        }
      "
      button-text="Ok, I understand"
      title="Failed to downgrade your subscription"
    >
      <p>Please contact us by opening a ticket in our Discord.</p>
      <a :href="settings.discordInviteUrl" class="btn-primary btn-lg btn gap-2" target="_blank">
        <discord-logo fill="#FFF" class="w-8" />
        Join Discord
      </a>
    </error-dialog>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { accountsAxios } from '@/services/axios'
import settings from '@/data/settings'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import plans from '@/data/plans'
import { mapState } from 'pinia/dist/pinia'
import { DEVELOPER_GOLD_PLAN, productIds, useProductsStore } from '@/store/products'
import ErrorDialog from '@/components/Dialog/ErrorDialog.vue'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import Feature from '@/components-v2/lists/Feature.vue'
import { allFeaturesByTier } from '@/data/allFeaturesByTier'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import { capitalize } from 'vue'

export default {
  components: { SilverPlanButton, Feature, ErrorDialog, DiscordLogo },
  data() {
    return {
      settings,
      plans,
      allFeaturesByTier,
      isProcessing: false,
    }
  },
  setup() {
    const userInfoStore = useUserInfoStore()
    return { userInfoStore }
  },
  computed: {
    ...mapState(useUserSubscriptionStore, ['isLoading', 'subscription', 'isPaddleSubscription']),
    currentProductId() {
      return this.subscription.subscriptionPlanId === DEVELOPER_GOLD_PLAN
        ? productIds.GOLD.YEARLY
        : this.subscription.subscriptionPlanId
    },
    nextPlan() {
      const productStore = useProductsStore()

      // yearly Gold => yearly silver
      if (Number(this.currentProductId) === productIds.GOLD.YEARLY || this.currentProductId === DEVELOPER_GOLD_PLAN)
        return productStore.products.filter((x) => x.productId === productIds.SILVER.YEARLY)[0]
      // monthly Gold => monthly silver
      else if (Number(this.currentProductId) === productIds.GOLD.MONTHLY)
        return productStore.products.filter((x) => x.productId === productIds.SILVER.MONTHLY)[0]

      return null
    },
    currentPlan() {
      const productStore = useProductsStore()
      return productStore.products.filter((x) => x.productId === parseInt(this.currentProductId))[0]
    },
  },
  methods: {
    capitalize,
    async downgradeSubscription() {
      this.isProcessing = true
      try {
        await accountsAxios.put(`api/subscription/changeplan?newPlanId=${this.nextPlan.productId}}`)
        // TODO: send tracking to Mixpanel with reason (too expensive in this case)
        this.isProcessing = false
        this.$trackEvent('Subscription Downgraded Frontend', { Reason: 'downgrade_to_silver', plan: this.plan })
      } catch (e) {
        this.isProcessing = false
        Sentry.captureException(e)
        this.$refs.errorDialog.open()
        // TODO: show a error dialog that downgrading failed and to contact support
        // also log the error in Sentry
        return
      }

      this.$router.push({ name: 'SubscriptionUpdated', query: { to: 'downgraded' } })
    },
    formatDate(dateStr) {
      // TODO: move this to a helper file
      if (dateStr == null) return ''

      const date = new Date(dateStr)
      return Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(date)
    },
  },
}
</script>
