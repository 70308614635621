<template>
  <div v-if="showTestLogin" class="w-full flex flex-col">
    <div class="relative flex py-5 items-center">
      <div class="flex-grow border-t border-gray-400"></div>
      <span class="flex-shrink mx-4 text-gray-400">or</span>
      <div class="flex-grow border-t border-gray-400"></div>
    </div>

    <h4>Login with Email</h4>
    <div class="w-full mt-4 w">
      <form class="layer-2 shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full" @submit.prevent="submit">
        <div class="mb-4">
          <label class="block text-sm font-bold mb-2" for="username">
            Username
          </label>
          <input
            id="username"
            v-model="username"
            class="layer-1 shadow appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Username"
            type="text"
          >
        </div>
        <div class="mb-6">
          <label class="mb-2 block text-sm font-bold" for="password"> Password </label>
          <input
            id="password"
            v-model="password"
            class="layer-1 shadow appearance-none rounded w-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="******************"
            type="password"
          >
        </div>
        <div class="flex items-center justify-end">
          <button
            class="btn btn-primary"
            type="submit"
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
// component used to temporarily login to the app for testing purposes (Facebook review process)
export default {
  data() {
    return {
      testLoginClicked: false,
      username: '',
      password: '',
    }
  },
  computed: {
    showTestLogin() {
      return location.hostname.includes('local.streamladder.com') || location.hostname.includes('acc.streamladder.com')
    },
  },
  methods: {
    submit() {
      if (this.username != 'fbtestlogin' || this.password != 'fbtestpassword') {
        alert('Invalid credentials')
      } else {
        location.href = 'https://acc.accounts.streamladder.com/signin?method=rW8dnV22xfz4otnQAuXp'
      }
    },
  },
}

</script>
