<template>
  <Tile feature="captions" tabindex="0" @mouseleave="stopAnimation" @mouseover="startAnimation" @resize="resize">
    <CaptionElement
      v-if="!isActive"
      key="key-34234"
      :caption="captions[0]"
      :caption-style-definition="captionSettings"
      :enable-time-line-animation="false"
      class="text !relative"
    />
    <CaptionElement
      v-for="(caption, index) in captions"
      v-else
      v-show="index === counter + 1"
      :key="caption.id"
      :caption="caption"
      :enable-time-line-animation="false"
      :caption-style-definition="captionSettings"
      class="text !relative"
    />

    <OverlayBadges :is-new="isNewCaption(captionSettings)" :tags="captionSettings.tags" />
  </Tile>
</template>

<script lang="ts" setup>
import CaptionElement from '@/components/Captions/CaptionElement.vue'
import { useInterval } from '@vueuse/core'
import Tile from '@/components/Tile.vue'
import { isNewCaption } from '@/helpers/isNewOverlayElement'
import type { CaptionStyleSettings } from '@/components/Captions/styles/captionStyles'
import OverlayBadges from '@/components/OverlayBadges.vue'
import { useCaptionSettings } from '@/components/Captions/useCaptionSettings'

const props = defineProps<{
  settings: CaptionStyleSettings
}>()

const { resize, captionSettings, captions, animationSpeed } = useCaptionSettings(props.settings)

const { counter, reset, pause, resume, isActive } = useInterval(animationSpeed, {
  controls: true,
  immediate: false,
  callback: () => {
    if (captions.length - 1 === counter.value) {
      reset()
    }
  },
})

const startAnimation = () => {
  resume()
}

const stopAnimation = () => {
  pause()
  reset()
}
</script>
