<script setup lang="ts">
import RadialProgress from '@/components/Dialog/RadialProgress.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { ref } from 'vue'

const showModal = ref(false)

const props = defineProps<{
  inProgress: boolean
  progressPercentage: number
  success: boolean
  title: string
  loadingMessage: string
  successMessage: string
  errorMessage: string
}>()

defineExpose({ open: () => (showModal.value = true), close: () => (showModal.value = false) })

const size = 'small'

const _onOverlayClick = (event: MouseEvent) => {
  if (!showModal.value) return

  if (!(event.target instanceof HTMLElement)) return

  if (event.target.classList.contains('modal')) {
    showModal.value = false
  }
}
</script>

<template>
  <Teleport to="body">
    <div v-if="showModal" :class="[$attrs.class]" class="modal modal-open" @click.stop="_onOverlayClick">
      <div
        :class="{ 'max-w-sm': size === 'small', 'max-w-4xl': size === 'large' }"
        class="layer-1 modal-box max-h-[80vh] w-11/12"
      >
        <div class="flex flex-col items-center gap-4 py-4">
          <template v-if="inProgress">
            <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
            <h2 class="text-center text-2xl font-bold">Hold tight! ✨</h2>
            <p class="text-center text-lg font-normal text-gray-600">
              {{ loadingMessage }}
            </p>
            <radial-progress :progress="progressPercentage" />
          </template>
          <template v-else-if="success">
            <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
            <h2 class="text-center text-2xl font-bold">Success! 🚀</h2>
            <p class="text-center text-lg font-normal text-gray-600">
              {{ successMessage }}
            </p>
          </template>
          <template v-else-if="!inProgress && !success">
            <LottieAnimation url="/lottie/error.json" class="w-20" />
            <h2 class="text-center text-2xl font-bold">Oops!</h2>
            <p class="text-center text-lg font-normal text-gray-600">{{ errorMessage }}</p>
          </template>
        </div>
      </div>
    </div>
  </Teleport>
</template>
