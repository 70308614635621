<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
  currentTime: number
}>()

const seekerEl = ref<HTMLElement | null>(null)
const { useMsToPx, zoomLevel, scrollToPx, containerWidth } = useTimelineStore()
const left = useMsToPx(
  computed(() => props.currentTime),
  true
)

// watch(zoomLevel, () => {
//   scrollToPx(left.value - containerWidth.value / 2)
// })
</script>

<template>
  <div
    ref="seekerEl"
    class="absolute"
    :style="{
      transform: `translateX(calc(${left}px - 50%))`,
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss"></style>
