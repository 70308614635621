<template>
  <div @click="connect">
    <slot></slot>
  </div>
</template>
<script>
import popupService from '../../../../services/popupService'
import { accountsAxios } from '@/services/axios'
import { useUserInfoStore } from '@/store/user/userInfo'
import canFeature from '@/mixins/canFeature'
import { upgradeDialog } from '@/helpers/upgradeDialog'

export default {
  mixins: [popupService, canFeature],
  props: {
    platform: { type: String, required: true, default: 'youtube' },
  },
  data() {
    return {
      authWindow: null,
      currentAccountCount: 0,
    }
  },
  methods: {
    connect() {
      // Check if we have a connection already and the user wants to connect another one
      const userInfoStore = useUserInfoStore()
      const connectionLength = userInfoStore.connections[this.platform].length

      if (connectionLength >= 1 && !this.userCan('multiple-publishing-accounts')) {
        upgradeDialog.open('multiple-publishing-accounts')
        return
      }

      // Open Connection window
      this.currentAccountCount = userInfoStore.connections[this.platform].length
      // Workaround for Safari. Open a window in a Sync function to avoid the popup blocker
      this.authWindow = window.open(undefined, 'ConnectSocial', this.getPopupFeatures(800, 800))
      window.addEventListener('message', this.receiveMessage)
      this.secondConnectStep()
    },
    async secondConnectStep() {
      const userInfoStore = useUserInfoStore()
      await userInfoStore.updateUserInfo()
      const resp = await accountsAxios.get(this.getConnectUrl(this.platform))
      this.authWindow.location = resp.data.redirectUrl
    },
    getConnectUrl(platform) {
      switch (platform) {
        case 'tiktok':
          return `api/tiktok/v2/connect?returnUrl=${window.location.origin}/connection-success`
        case 'youtube':
          return `api/youtube/connect?returnUrl=${window.location.origin}/connection-success`
        case 'instagram':
          return `api/instagram/connect?returnUrl=${window.location.origin}/connection-success`
        default:
          return ''
      }
    },
    async receiveMessage(event) {
      const { data } = event

      if (data === 'connection-success') {
        this.$emit('success', {
          platform: this.platform,
          window: this.authWindow,
          accountCount: this.currentAccountCount,
        })

        window.removeEventListener('message', this.receiveMessage)
      }
    },
  },
}
</script>
