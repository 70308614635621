<template>
  <div class="flex flex-row gap-2 px-4">
    <button
      v-for="tag in tags"
      class="btn-sm btn rounded capitalize"
      :class="{
        'bg-gray-300': tag === selectedTag,
        'bg-gray-100': tag !== selectedTag,
        'text-gray-800': tag === selectedTag,
        'text-gray-600': tag !== selectedTag,
      }"
      @click="selectedTag = tag"
      :key="tag"
    >
      {{ tag }}
    </button>
  </div>
  <div
    id="captions-container"
    class="grid gap-2 p-4"
    :class="hasThreeColumnLayout ? 'grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))]' : 'grid-cols-2'"
  >
    <div v-for="captionStyle in styles" :key="captionStyle.type" class="relative">
      <CaptionStylePreview
        show-tier-badge
        @click="emit('generate-captions', captionStyle.type)"
        :settings="captionStyle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CaptionStylePreview from '@/components/Captions/CaptionStylePreview.vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { computed, ref } from 'vue'
import { captionStyleSettingsArray } from '@/components/Captions/styles/CaptionStyleManager'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import {intersection} from "lodash-es";

const captionStore = useEditorCaptionsStore()

const hasThreeColumnLayout = useFeatureFlagVariantEnabled('three-column-layout', 'wide')

const emit = defineEmits<{
  (event: 'generate-captions', captionType: string): void
}>()

const tags = computed(() => {
  return ['all', 'popular', 'bold', 'simple', 'seasonal']
})

const selectedTag = ref('all')

const styles = computed(() => {
  if (selectedTag.value === 'all') return captionStyleSettingsArray
  if (selectedTag.value === 'seasonal') return captionStyleSettingsArray.filter((style) => intersection(style?.tags, ['christmas', 'halloween']).length > 0)
  return captionStyleSettingsArray.filter((style) => style?.tags?.includes(selectedTag.value))
})
</script>
