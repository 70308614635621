<template>
  <div class="app-bg flex min-h-screen w-full flex-col">
    <NavbarApplication />
    <div class="px-3 pb-8">
      <h2 class="mt-6 text-center align-baseline text-3xl font-bold text-white">Edit your post!</h2>
      <div
        class="container mx-auto mt-6 grid max-w-5xl grid-flow-dense gap-4 md:grid-cols-[300px,1fr] md:gap-x-11 md:gap-y-6"
      >
        <IphoneContainer>
          <video v-if="post" ref="resultVideo" :src="post?.contentUrl" class="w-full" controls />
        </IphoneContainer>

        <div class="md:row-span-3">
          <!-- skeleton -->
          <div class="relative rounded-xl bg-white p-6 md:p-10">
            <ScheduleClipSectionSkeleton v-if="showLoader" />
            <ScheduleClipSection
              v-if="showScheduler"
              :key="post?.id"
              :clip-url="post?.contentUrl ?? undefined"
              :default-values="defaultValues"
              :on-save="publishClip"
            >
              <button v-if="!isDone" class="hover:text-red btn-error btn-lg btn gap-2" @click="deletePost">
                <span>Cancel post</span>
                <TrashcanIcon class="h- w-4 fill-current" />
              </button>
              <div class="flex-grow"></div>
            </ScheduleClipSection>
            <div v-if="showSuccess" class="flex flex-col items-center gap-4 p-10 md:p-0">
              <h2 class="text-center">Thumbs up! Your post has been successfully been updated!</h2>
              <LottieAnimation loop url="/lottie/thumbs-up-birdie.json" />
              <div class="flex flex-col items-center justify-center gap-2">
                <router-link :to="{ name: dashboardRouteNames.contentPublisher }" class="btn-primary btn-lg btn gap-2">
                  Back to calendar
                  <calendar-icon />
                </router-link>
              </div>
            </div>
            <div v-if="isDone" class="flex flex-col items-center gap-4 p-10 md:p-0">
              <h2 v-if="status === 'Published'" class="text-center">Thumbs up! Your video has been posted!</h2>
              <h2 v-else class="text-center">There were some errors while posting your video.</h2>
              <publish-status-bar
                v-for="social in accounts"
                :id="social.id"
                :key="social.id"
                :social-media-post-target="social"
              />
              <div class="flex flex-col items-center justify-center gap-2">
                <router-link :to="{ name: dashboardRouteNames.contentPublisher }" class="btn-primary btn-lg btn gap-2">
                  View your schedule
                  <calendar-icon />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavbarApplication from '@/components-v2/navigation/NavbarApplication.vue'
import IphoneContainer from '@/components/Phones/IphoneContainer.vue'
import { computed, ref, onMounted } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import ScheduleClipSection from '@/components-v2/modules/Scheduler/ScheduleClipSection.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import CalendarIcon from '@/components/Icons/CalendarIcon.vue'
import PublishStatusBar from '@/components/Dialog/Scheduler/PublishStatusBar.vue'
import { useQueryClient } from '@tanstack/vue-query'
import TrashcanIcon from '@/components/Icons/TrashcanIcon.vue'
import ScheduleClipSectionSkeleton from '@/components-v2/modules/Scheduler/ScheduleClipSectionSkeleton.vue'
import { getGetApiVideosQueryKey } from '@/apis/streamladder-api/videos/videos'
import { useRouter } from 'vue-router'
import { useConfirmDialog } from '@/components-v2/actions/dialog/useConfirmDialog'
import type { TargetDto } from '@/apis/streamladder-publisher/model'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'

const userInfo = useUserInfoStore()
const store = useContentPublisherStore()

const props = defineProps<{ postId: string }>()

const post = computed(() => store.entities[props.postId])
onMounted(() => store.syncPost(props.postId))

const status = computed(() => post.value?.status?.overallStatus)
const isDone = computed(
  () => status.value === 'Published' || status.value === 'PartialFailure' || status.value === 'Failed'
)

const targets = computed(() => post.value?.targets ?? [])
const selectedAccountsIds = computed(() => {
  return targets.value.map((target: TargetDto) => target.accountId).filter(Boolean) as string[]
})

const accounts = computed(() => {
  return (
    targets.value.map((target: TargetDto) => {
      const acc = userInfo.allSocials.find((conn) => target.accountId === conn.id)
      return {
        ...target,
        accountData: acc,
      }
    }) || []
  )
})

const defaultValues = computed(() => {
  const reducedOptions: {
    title: string
    description: string
    youtube?: { publishPublic: boolean; isChildFriendly: boolean }
  } = { title: '', description: '' }

  for (const target of post.value?.targets ?? []) {
    reducedOptions.title = target.title ?? reducedOptions.title
    reducedOptions.description = target.description ?? reducedOptions.description
    if (target.youtubeOptions) {
      reducedOptions['youtube'] = {
        publishPublic: target.youtubeOptions.visibility === 'Public',
        isChildFriendly: target.youtubeOptions.youTubeChildFriendly ?? false,
      }
    }
  }

  return {
    dateTime: post.value?.scheduledAt ? new Date(post.value.scheduledAt) : undefined,
    selectedAccounts: selectedAccountsIds.value,
    ...reducedOptions,
  }
})

// Scheduler display logic
const scheduledPost = ref(false)

const showScheduler = computed(
  () =>
    userInfo.isAuthenticated &&
    userInfo.hasLoadedUserInfo &&
    !store.isPerformingBackgroundAction &&
    !isDone.value &&
    !scheduledPost.value
)

const router = useRouter()
const { reveal } = useConfirmDialog()
const deletePost = async () => {
  const isConfirmed = await reveal({
    message: 'Are you sure you want to delete this post?',
    title: 'Delete post',
  })

  if (!isConfirmed) return
  await store.removePost(props.postId)

  await router.push({ name: dashboardRouteNames.contentPublisher })
}

const queryClient = useQueryClient()
const publishClip = async (publishDate: Date | null, targets: TargetDto[]) => {
  const response = await store.reschedulePost(props.postId, {
    publishAt: publishDate?.toISOString(),
    targets: targets,
  })
  await queryClient.invalidateQueries({
    queryKey: getGetApiVideosQueryKey(),
  })
  scheduledPost.value = true

  return response
}

const showSuccess = computed(() => scheduledPost.value)

const showLoader = computed(() => !showScheduler.value && !showSuccess.value && !isDone.value)
</script>
