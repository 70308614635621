<template>
  <div class="panel mb-8">
    <div class="flex w-full rounded-lg bg-company-primary-100 p-8 text-white">
      <div class="w-4/5">
        <h2 class="text-3xl font-bold">Join the StreamLadder referral program and get paid</h2>
        <p>Get your friends to sign up to StreamLadder and earn credit worth one month’s Silver subscription for each paid user that signs up using your link. Cha-ching!</p>
        <div class="flex justify-between">
        </div>

      </div>
      <div class="relative w-2/5">
        <img class="absolute -top-32 -ml-16 xl:-ml-6" src="/images/3d/coin.png"/>
      </div>
    </div>

    <section class="mt-8 flex flex-col gap-6 rounded-lg border-2 border-gray-100 p-8">
      <div><h3 class="p-0">Share your unique URL</h3>
        <span class="text-company-primary-500 opacity-50"
        >Spread the word and get as many people as you can to sign up using your URL. The more people sign up, the more money you earn!</span
        ></div>

      <div
        class="mt-2 flex lg:w-2/3 space-x-2 rounded-xl border border-company-primary-300 bg-company-primary-light p-2">
        <input type="text" class="w-full bg-transparent" :value="referralLink"/>
        <button class="btn-primary btn" @click.stop="copy(referralLink)">
          <span v-if='!copied'>Copy</span>
          <span v-else>Copied!</span>
        </button>
      </div>
    </section>

    <section class="mt-8 flex flex-wrap gap-4">
      <div class="flex-grow rounded-lg border-2 border-gray-100 p-8">
        <div class="flex gap-4">
          <div>
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24.4399" r="24" fill="#6B6DFF" fill-opacity="0.2"/>
              <path
                d="M24.5 14.4399C21.88 14.4399 19.75 16.5699 19.75 19.1899C19.75 21.7599 21.76 23.8399 24.38 23.9299C24.46 23.9199 24.54 23.9199 24.6 23.9299C24.62 23.9299 24.63 23.9299 24.65 23.9299C24.66 23.9299 24.66 23.9299 24.67 23.9299C27.23 23.8399 29.24 21.7599 29.25 19.1899C29.25 16.5699 27.12 14.4399 24.5 14.4399Z"
                fill="#6B6DFF"
              />
              <path
                d="M29.58 26.6001C26.79 24.7401 22.24 24.7401 19.43 26.6001C18.16 27.4401 17.46 28.5901 17.46 29.8201C17.46 31.0501 18.16 32.1901 19.42 33.0301C20.82 33.9701 22.66 34.4401 24.5 34.4401C26.34 34.4401 28.18 33.9701 29.58 33.0301C30.84 32.1801 31.54 31.0401 31.54 29.8001C31.53 28.5801 30.84 27.4301 29.58 26.6001ZM26.83 29.0001L24.31 31.5201C24.19 31.6401 24.03 31.7001 23.87 31.7001C23.71 31.7001 23.55 31.6301 23.43 31.5201L22.17 30.2601C21.93 30.0201 21.93 29.6201 22.17 29.3801C22.41 29.1401 22.81 29.1401 23.05 29.3801L23.87 30.2001L25.95 28.1201C26.19 27.8801 26.59 27.8801 26.83 28.1201C27.08 28.3601 27.08 28.7601 26.83 29.0001Z"
                fill="#6B6DFF"
              />
            </svg>
          </div>
          <div>
            <span class="block text-2xl font-extrabold">{{ referrals?.totalCount }}</span>
            <span class="opacity-50">Friends referred</span>
          </div>
        </div>
      </div>

      <div class="flex-grow rounded-lg border-2 border-gray-100 p-8">
        <div class="flex gap-4">
          <div>
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24.4399" r="24" fill="#6B6DFF" fill-opacity="0.2"/>
              <path
                d="M24.75 28.3598H25.4C26.05 28.3598 26.59 27.7798 26.59 27.0798C26.59 26.2098 26.28 26.0398 25.77 25.8598L24.76 25.5098V28.3598H24.75Z"
                fill="#6B6DFF"
              />
              <path
                d="M23.97 14.3399C18.45 14.3599 13.98 18.8499 14 24.3699C14.02 29.8899 18.51 34.3599 24.03 34.3399C29.55 34.3199 34.02 29.8299 34 24.3099C33.98 18.7899 29.49 14.3299 23.97 14.3399ZM26.26 24.4399C27.04 24.7099 28.09 25.2899 28.09 27.0799C28.09 28.6199 26.88 29.8599 25.4 29.8599H24.75V30.4399C24.75 30.8499 24.41 31.1899 24 31.1899C23.59 31.1899 23.25 30.8499 23.25 30.4399V29.8599H22.89C21.25 29.8599 19.92 28.4799 19.92 26.7799C19.92 26.3699 20.26 26.0299 20.67 26.0299C21.08 26.0299 21.42 26.3699 21.42 26.7799C21.42 27.6499 22.08 28.3599 22.89 28.3599H23.25V24.9799L21.74 24.4399C20.96 24.1699 19.91 23.5899 19.91 21.7999C19.91 20.2599 21.12 19.0199 22.6 19.0199H23.25V18.4399C23.25 18.0299 23.59 17.6899 24 17.6899C24.41 17.6899 24.75 18.0299 24.75 18.4399V19.0199H25.11C26.75 19.0199 28.08 20.3999 28.08 22.0999C28.08 22.5099 27.74 22.8499 27.33 22.8499C26.92 22.8499 26.58 22.5099 26.58 22.0999C26.58 21.2299 25.92 20.5199 25.11 20.5199H24.75V23.8999L26.26 24.4399Z"
                fill="#6B6DFF"
              />
              <path
                d="M21.42 21.81C21.42 22.68 21.73 22.85 22.24 23.03L23.25 23.38V20.52H22.6C21.95 20.52 21.42 21.1 21.42 21.81Z"
                fill="#6B6DFF"
              />
            </svg>
          </div>
          <div>
            <span class="block text-2xl font-extrabold">{{ getDisplayPrice(rewardTotal, rewardCurrency) }}</span>
            <span class="opacity-50">Total referral bonus</span>
          </div>
        </div>
      </div>
    </section>

    <section
      class="relative mt-4 overflow-x-auto"
      v-if="referralsData.isSuccess && referrals?.totalCount > 0"
    >
      <table class="w-full text-left">
        <thead class="border-b">
        <tr>
          <th scope="col" class="px-6 py-3">Name</th>
          <th scope="col" class="px-6 py-3">Status</th>
          <th scope="col" class="px-6 py-3">Date joined</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(referral, i) in referrals?.items" :key="i" class="border-b bg-white font-thin">
          <td class="px-6 py-4">{{ referral.name }}</td>
          <td class="px-6 py-4">
            <span v-if="referral.state != 'subscribed'">{{ referral.state }} 🎉 </span>
            <span v-else class="badge-success badge badge-lg">{{ referral.state }} 🎉 </span>
          </td>
          <td class="px-6 py-4">{{ format(Date.parse(referral.createdAt), 'MMM dd, yyyy') }}</td>
        </tr>
        </tbody>
      </table>
    </section>
    <section class="mt-8" v-if="referralsData.isSuccess && referrals?.totalCount == 0">
      <div class="flex justify-center rounded-lg bg-twitch-100">
        <div class="flex max-w-xl align-middle">
          <LottieAnimation url="/lottie/cute-coffie-mocking.json" class="w-1/4"/>
          <p class="mb-0 w-3/4 self-center text-twitch-700 font-semibold py-4 pr-4">
            Looks like your referral game is just getting started. Let's work together to build your referral network!
          </p>
        </div>
      </div>
    </section>

    <section class="mt-8">
      <div class="flex items-center">
        <div class="w-2/3 flex flex-col gap-2">
          <span class="badge-success badge badge-lg">Tips & tricks</span>
          <h2 class="font-bold m-0">How to become a referral legend
</h2>
          <p class="font-light text-lg">
            Here’s everything you need to know about our referral program. 

          </p>
        </div>
        <div class="w-1/3">
          <LottieAnimation url="/lottie/cute-cup-stickers-like.json" class="lg:-mt-12 -mb-10"/>
        </div>
      </div>

      <accordion title="How does it work?">
        <p>
          Share your unique referral URL (find it at the top of this page) with friends, family or followers. Each time someone clicks on your referral link and then signs up for a paid subscription, you'll earn the value of a Silver subscription as credit. Your earnings will be deducted automatically as a discount from your normal subscription costs during your next billing cycle. You can track your referrals and earnings in your StreamLadder account dashboard. There's no limit to how many referrals you can make, so keep sharing your link! 
        </p>
      </accordion>

      <accordion title="Where can I share my link?">
        <p>You can share your link anywhere you want - directly with your friends, in WhatsApp groups or even in the local newspaper, if you’re old school. Here are some tips:</p>
        <p>
          <ul class="list-decimal list-outside ml-4">
            <li>
              <strong>In your TikTok Bio: </strong>Add the link to your bio and write a short message about StreamLadder and how it has helped you create videos for social media. 
            </li>
            <li>
              <strong>In your Instagram Bio: </strong>Share the link in your Instagram bio and encourage your followers to check out StreamLadder if they want to make videos like you.
            </li>
            <li>
              <strong>On your Twitch Page: </strong>Add your referral link beneath your Twitch page or in the About Me section to let people know how StreamLadder can benefit them. 
            </li>
            <li>
              <strong>On Discord: </strong>Share the URL in your favorite Discord servers or other gaming communities you're a part of.
            </li>
          </ul>
        </p>
      </accordion>

      <accordion title="How will I know when someone signs up using my referral link?">
        <p>
          You can keep track of your referrals and earnings in your StreamLadder account dashboard. Just click on your name in the top-right corner and go to the referral page.

        </p>
      </accordion>

      <accordion title="Is there a maximum of how much I can earn in the referral program?">
        <p>
          There is no limit to how many referrals you can make and how much credit you can earn. With each converted user, you'll receive a referral bonus with the value of one Silver subscription. You’ll receive this bonus in the form of credit that will be deducted from your subscription costs. 
        </p>
      </accordion>

      <accordion title="When will I receive my referral rewards?">
        <p>
          Your earnings will be automatically deducted from your subscription costs during the next billing cycle. So, it depends on your subscription type and whether you have a monthly or annual subscription for StreamLadder when you can reap the rewards/
        </p>
      </accordion>

      <accordion title="Can I get my credit paid out?">
        No, you can only use your credit as a discount to your StreamLadder subscription. 
      </accordion>
    </section>
  </div>
</template>

<script lang="ts" setup>
import {computed} from 'vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import {useUserInfoStore} from '@/store/user/userInfo'
import {format} from 'date-fns'
import {useGetApiUsersUserIdReferrals} from '@/apis/streamladder-accounts/referrals/referrals'
import {useGetApiUsersUserIdRewards} from '@/apis/streamladder-accounts/rewards/rewards'
import Accordion from '@/components-v2/modules/Accordion/Accordion.vue'
import {getDisplayPrice} from '@/store/products'
import {useClipboard} from '@vueuse/core'


const userInfo = useUserInfoStore()

const referralLink = computed(() => `https://${location.host}/ref/${userInfo.referralCode}`)
const referralsData = useGetApiUsersUserIdReferrals(userInfo.userId)
const referrals = computed(() => referralsData.data.value?.value);

const {copy, copied} = useClipboard()

const rewardsData = useGetApiUsersUserIdRewards(userInfo.userId)
const rewards = computed(() => rewardsData.data.value?.value)

const rewardCurrency = computed(() => rewards.value?.items?.[0]?.currency ?? 'USD');
const rewardTotal = computed(() => rewards.value?.items?.filter(x => x.currency == rewardCurrency.value).reduce((acc, reward) => acc + reward.amount, 0))

</script>
