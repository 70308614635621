<template>
  <base-dialog ref="baseDialog" :closeable="true" :showDismissButton="true">
    <div v-if="askUpdateExistingTemplate">
      <h2>Update current Template?</h2>
      <p>Do you want to update the existing template?<br />or create a new one</p>

      <div class="flex w-full justify-center gap-x-2 pt-2">
        <button :disabled="loading" @click="saveTemplate(true)" class="btn-primary btn">Update</button>
        <button :disabled="loading" @click="createNew()" class="btn-outline btn-primary btn">New</button>
      </div>
    </div>

    <div v-else-if="showForm" class="pt-3">
      <h2>Save template</h2>
      <div class="pb-6 text-xs">
        Save a template to easily apply it to your next clip, it will automatically load the crop areas, positions and
        stickers
      </div>
      <div class="pb-1 text-left text-xs font-semibold">Template name</div>
      <form @submit.prevent="submit" class="row">
        <div>
          <input
            ref="templateInput"
            type="text"
            class="border-grey-200 m-0 block w-full rounded border px-3 py-2 text-base text-gray-800 outline-0 transition ease-in-out focus:border-blue-500 focus:outline-none focus:outline-8 focus:ring"
            v-model="templateName"
          />
          <p class="validation-message" v-if="validationMessage">
            {{ validationMessage }}
          </p>
        </div>
        <div class="flex justify-center gap-2 pb-2 pt-8">
          <button class="btn-outline btn-primary btn" @click="close" type="button">Back</button>
          <button class="btn-primary btn" :class="{ loading: loading }" type="submit">Save template</button>
        </div>
      </form>
    </div>

    <div v-else>
      <div v-if="hasError" class="flex flex-col items-center">
        <error-icon />
        <h2>Something went wrong...</h2>
      </div>
      <div v-else class="flex flex-col items-center">
        <success-icon />
        <h2>Saved successfully</h2>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import BaseDialog from './BaseDialog.vue'
import templateService from '../../services/templateService'
import SuccessIcon from './Icons/SuccessIcon.vue'
import ErrorIcon from './Icons/ErrorIcon.vue'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useEditorMainStore } from '@/store/editor/editorMain'

export default {
  components: {
    BaseDialog,
    SuccessIcon,
    ErrorIcon,
  },
  data() {
    return {
      askUpdateExistingTemplate: null,
      templateName: '',
      showForm: true,
      loading: false,
      hasError: false,
      validationMessage: '',
    }
  },
  methods: {
    open() {
      this.$refs.baseDialog.open()
      this.focusInput()

      const editorMainStore = useEditorMainStore()
      this.askUpdateExistingTemplate = !!editorMainStore.templateId
      this.templateName = ''
      this.showForm = true
      this.hasError = false
      this.loading = false
    },
    close() {
      this.$refs.baseDialog.close()
    },
    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.templateInput) this.$refs.templateInput.focus()
      })
    },
    createNew() {
      this.askUpdateExistingTemplate = false
      this.focusInput()
    },
    async submit() {
      const templateNameTrimmed = this.templateName.trim()
      if (templateNameTrimmed.length < 4 || templateNameTrimmed.length > 24) {
        this.validationMessage = 'Template name must be between 4 and 24 characters'
        return
      } else {
        await this.saveTemplate()
      }
    },
    async saveTemplate(updateTemplate = false) {
      this.loading = true
      const editorMainStore = useEditorMainStore()
      const editorFeedDataStore = useEditorFeedDataStore()
      const croppers = editorFeedDataStore.fragmentArray.map((fragment) => {
        return {
          cropperKey: fragment.key,
          data: fragment.cropData,
        }
      })
      const feeds = editorFeedDataStore.fragmentArray.map((fragment) => {
        return {
          feedKey: fragment.key,
          data: fragment.feedData,
        }
      })

      const editorStickersStore = useEditorStickersStore()
      const stickers = editorStickersStore.selectedStickers
        .map((sticker) => patch(sticker))
        .filter((sticker) => !sticker.isTextSticker)

      const template = {
        id: updateTemplate ? editorMainStore.templateId : null,
        templateName: this.templateName.trim(),
        layoutName: this.$route.params.layout,
        croppers,
        feeds,
        stickers,
      }

      try {
        const result = await templateService.saveTemplate(template, updateTemplate)
        editorMainStore.templateId = result.id
      } catch (e) {
        this.hasError = true
        console.error(e)
      } finally {
        this.askUpdateExistingTemplate = null
        this.showForm = false
        this.loading = false
      }
    },
  },
}

const patch = (sticker) => {
  if (sticker.htmlContent) {
    return {
      ...sticker,
      text: sticker.htmlContent,
    }
  } else {
    return sticker
  }
}
</script>

<style scoped lang="scss">
.validation-message {
  color: #f00;
}

.button-group-flex {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.btn-secondary {
  color: #4d287f;
  background: #eaeaea;
}
</style>
