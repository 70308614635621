import { ref } from 'vue'
import { getCurrentCampaign } from '@/data/campaigns'

const intervals = {
  month: {
    key: 'month',
    value: 'month',
    label: 'Monthly',
  },
  year: {
    key: 'year',
    value: 'year',
    label: 'Yearly',
  },
} as const

export type SubscriptionIntervals = keyof typeof intervals

const campaign = getCurrentCampaign()

const interval = ref<SubscriptionIntervals>(campaign?.yearlyDiscountCode ? 'year' : 'month')

export const useSubscriptionIntervals = () => {
  return { interval, intervals }
}
