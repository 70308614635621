<template>
  <tw-base-dialog
    :closable="closeable"
    :close-event="events.close_event"
    :open-event="events.open_event"
    styles="max-w-2xl"
  >
    <div class="flex flex-col p-12 pt-6 text-center">
      <div v-if="!success" class="flex flex-col">
        <div v-if="connectingPlatform === 'youtube'" class="mb-8 flex w-full justify-center">
          <youtube-text-icon :style="{ height: '4em' }" class="w-2/3" fill="black" />
        </div>
        <tik-tok-text-icon v-if="connectingPlatform === 'tiktok'" class="mx-auto -mt-8 w-2/3" />
        <instagram-icon v-if="connectingPlatform === 'instagram'" class="mx-auto -mt-8 mb-4 w-2/3" />

        <h2 class="text-2xl">
          <strong>{{ getTitle }}</strong>
        </h2>

        <p class="mb-8">
          To upload your clips, you'll need to connect and authorise
          <strong>StreamLadder</strong> to your social media account.
        </p>

        <div v-if="infoMessage" class="alert alert-info mx-auto mb-10 max-w-sm shadow-lg">
          <div class="flex w-full flex-row">
            <svg fill="none" height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 18.5C12.34 18.5 12.6252 18.3848 12.8556 18.1544C13.086 17.924 13.2008 17.6392 13.2 17.3V12.47C13.2 12.13 13.0848 11.85 12.8544 11.63C12.624 11.41 12.3392 11.3 12 11.3C11.66 11.3 11.3748 11.4152 11.1444 11.6456C10.914 11.876 10.7992 12.1608 10.8 12.5V17.33C10.8 17.67 10.9152 17.95 11.1456 18.17C11.376 18.39 11.6608 18.5 12 18.5ZM12 8.9C12.34 8.9 12.6252 8.7848 12.8556 8.5544C13.086 8.324 13.2008 8.0392 13.2 7.7C13.2 7.36 13.0848 7.0748 12.8544 6.8444C12.624 6.614 12.3392 6.4992 12 6.5C11.66 6.5 11.3748 6.6152 11.1444 6.8456C10.914 7.076 10.7992 7.3608 10.8 7.7C10.8 8.04 10.9152 8.3252 11.1456 8.5556C11.376 8.786 11.6608 8.9008 12 8.9ZM12 24.5C10.34 24.5 8.78 24.1848 7.32 23.5544C5.86 22.924 4.59 22.0692 3.51 20.99C2.43 19.91 1.5752 18.64 0.9456 17.18C0.316 15.72 0.0008 14.16 0 12.5C0 10.84 0.3152 9.28 0.9456 7.82C1.576 6.36 2.4308 5.09 3.51 4.01C4.59 2.93 5.86 2.0752 7.32 1.4456C8.78 0.816 10.34 0.5008 12 0.5C13.66 0.5 15.22 0.8152 16.68 1.4456C18.14 2.076 19.41 2.9308 20.49 4.01C21.57 5.09 22.4252 6.36 23.0556 7.82C23.686 9.28 24.0008 10.84 24 12.5C24 14.16 23.6848 15.72 23.0544 17.18C22.424 18.64 21.5692 19.91 20.49 20.99C19.41 22.07 18.14 22.9252 16.68 23.5556C15.22 24.186 13.66 24.5008 12 24.5Z"
                fill="#277DFF"
              />
            </svg>
            <div class="w-full space-y-2">
              <p class="mb-0 w-full whitespace-pre-line break-all text-start text-sm sm:break-normal">
                {{ infoMessage }}
              </p>
            </div>
          </div>
        </div>

        <!-- Connection logic -->
        <div class="mx-auto flex max-w-sm flex-col items-center gap-4 space-y-4">
          <connect-button class="w-full" platform="youtube" @success="connectionSuccess">
            <youtube-button
              v-if="connectingPlatform === 'youtube' || !connectingPlatform"
              class="lg:px-22 flex w-full items-center justify-center rounded-full bg-black px-8 py-3 text-white md:px-20"
            >
              <span class="ml-2">Connect YouTube</span>
            </youtube-button>
          </connect-button>
          <connect-button class="w-full" platform="tiktok" @success="connectionSuccess">
            <button
              v-if="connectingPlatform === 'tiktok' || !connectingPlatform"
              class="flex w-full items-baseline justify-center rounded-full bg-black px-8 py-4 text-white md:px-20 lg:px-24"
            >
              <tik-tok-icon class="mr-2 w-4" />
              Connect Tiktok
            </button>
          </connect-button>
          <connect-button class="w-full" platform="instagram" @success="connectionSuccess">
            <button
              v-if="(connectingPlatform === 'instagram' || !connectingPlatform) && instagramEnabled"
              class="flex w-full items-center justify-center rounded-full bg-purple-600 px-8 py-4 text-white hover:bg-purple-800 md:px-20 lg:px-24"
            >
              <instagram-simple-icon class="mr-2 w-4" />
              Connect Instagram
            </button>
          </connect-button>
        </div>
        <!-- end connection logic -->
      </div>
      <div v-else class="flex flex-col items-center">
        <h3 class="mb-4 mt-2 py-0 text-4xl font-bold text-company-primary">Success!</h3>
        <div
          class="mb-4 flex h-24 w-24 flex-shrink-0 items-center justify-center rounded-full bg-green-100 text-green-600"
        >
          <check-mark-icon class="h-12 w-12 fill-current" />
        </div>
        <p>Successfully connected your account with StreamLadder</p>
      </div>
    </div>
  </tw-base-dialog>
</template>

<script>
import EventBus from '../../../../eventBus'
import TwBaseDialog from '../../TwBaseDialog.vue'
import toastEvents from '../../../../events/toastEvents'
import CheckMarkIcon from '../../../Icons/CheckMarkIcon.vue'
import accountEvents from '../../../../events/accountEvents'
import popupService from '../../../../services/popupService'
import ConnectButton from '../../../Account/Connections/List/ConnectButton.vue'
import YoutubeButton from '../../../Buttons/YoutubeButton.vue'
import TikTokIcon from '../../../Icons/SocialMedia/TikTokIcon.vue'
import YoutubeTextIcon from '../../../Icons/SocialMedia/YoutubeTextIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import TikTokTextIcon from '../../../Icons/SocialMedia/TikTokTextIcon.vue'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import InstagramSimpleIcon from '@/components/Icons/SocialMedia/InstagramSimpleIcon.vue'

export default {
  components: {
    InstagramSimpleIcon,
    InstagramIcon,
    TikTokTextIcon,
    YoutubeTextIcon,
    TikTokIcon,
    YoutubeButton,
    ConnectButton,
    CheckMarkIcon,
    TwBaseDialog,
  },
  mixins: [popupService],
  props: {
    platform: { type: String, required: false, default: undefined },
    closeable: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      callback: null,
      success: false,
      connectingPlatform: '',
      connectedAccountsCount: 0,
      events: {
        close_event: accountEvents.CLOSE_CONNECT_SOCIAL_MEDIA_DIALOG,
        open_event: accountEvents.OPEN_CONNECT_SOCIAL_MEDIA_DIALOG,
      },
      instagramEnabled:
        location.hostname.includes('local.streamladder.com') || location.hostname.includes('acc.streamladder.com'),
    }
  },
  computed: {
    getTitle() {
      if (!this.connectingPlatform) return 'What social media account would you like to connect?'
      if (this.connectingPlatform === 'youtube') return 'Connect a Youtube account'
      if (this.connectingPlatform === 'tiktok') return 'Connect a TikTok account'
      if (this.connectingPlatform === 'instagram') return 'Connect a Instagram account'
      return ''
    },
    infoMessage: function () {
      switch (this.connectingPlatform) {
        case 'instagram':
          return "To post from Streamladder to Instagram, you need a Instagram Business account. Upgrade your account if you haven't already. Learn how to upgrade to Instagram Business"
        default:
          return undefined
      }
    },
  },
  methods: {
    async connectionSuccess({ platform, window, accountCount }) {
      await this.sync()

      // Get the new count in the store to check if there has been an increment.
      const userInfoStore = useUserInfoStore()
      const newCount = userInfoStore.connections[platform].length

      if (newCount > accountCount) {
        window.close()
        EventBus.$emit(accountEvents.CLOSE_CONNECT_SOCIAL_MEDIA_DIALOG)
        await userInfoStore.updateUserInfo()
        if (this.callback !== null) return this.callback()
      } else {
        EventBus.$emit(toastEvents.TOAST_ERROR, {
          title: 'Something went wrong',
          description: 'Could not connect ' + platform + ' account. Maybe you already connected this account?',
        })
      }

      EventBus.$emit(this.events.close_event)
    },
    async sync() {
      const userInfoStore = useUserInfoStore()
      await userInfoStore.updateUserInfo()
      this.$emit('login-success')
    },
    initializeModal(data) {
      if (typeof data == 'object' && data.callback) {
        this.callback = data.callback
      }
      if (typeof data == 'object' && data.platform) {
        this.connectingPlatform = data.platform
      }
    },
  },
  mounted() {
    this.connectingPlatform = this.platform
    EventBus.$on(accountEvents.OPEN_CONNECT_SOCIAL_MEDIA_DIALOG, this.initializeModal)
  },
  unmounted() {
    EventBus.$off(accountEvents.OPEN_CONNECT_SOCIAL_MEDIA_DIALOG, this.initializeModal)
    window.removeEventListener('message', this.receiveMessage)
  },
}
</script>
