<template>
  <Tile v-if="destroying" class="pointer-events-none border-rose-50 bg-rose-50 text-rose-600">
    <IconLoader2 :size="48" class="recycling animate-spin" />
    Deleting...
  </Tile>
  <Tile v-else :selected="selected" class="group" feature="brandkit" @click="selectStyle(sticker)" @resize="onResize">
    <component
      :is="getComponent()"
      :id="id"
      ref="sticker"
      :editable="false"
      :font-family="getFontFamily"
      :icon="sticker.icon"
      :primary-color="getPrimaryColor"
      :secondary-color="getSecondaryColor"
      :html-content="htmlContent"
      :variant="sticker.variant"
      class="sticker"
      @stickerLoaded="scaleSticker"
    />

    <div
      v-if="displayActions"
      v-click-outside="() => (showOptions = false)"
      :class="{ 'dropdown-open !flex': showOptions }"
      class="dropdown-top dropdown absolute bottom-1 right-1 hidden group-hover:flex"
      @click.stop="showOptions = !showOptions"
    >
      <div class="rounded bg-cyan-600/5 px-2 py-0 text-cyan-400 hover:bg-cyan-600/[0.15]">
        <IconDots />
      </div>

      <div
        class="dropdown-content right-0 my-2 flex cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow"
      >
        <div
          class="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:bg-company-primary hover:bg-opacity-10"
          @click="selectStyle(sticker)"
        >
          <IconCirclePlus :size="20" />
          <span>Add to video</span>
        </div>
        <div
          class="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:bg-company-primary hover:bg-opacity-10"
          @click="edit(sticker)"
        >
          <IconEdit :size="20" />
          <span>Edit style</span>
        </div>
        <div
          class="flex items-center gap-2 whitespace-nowrap px-3 py-2 text-rose-600 hover:bg-rose-600 hover:text-white"
          @click.stop="destroy(sticker)"
        >
          <IconTrash :size="20" />
          Delete from library
        </div>
      </div>
    </div>
  </Tile>
</template>

<script>
import TextSticker from '../Stickers/TextSticker.vue'
import EventBus from '../../eventBus'
import brandkitStyleTypes from '../../enums/brandkitStyleTypes'
import brandKitEvents from '../../events/brandKitEvents'
import TrashcanIcon from '../Icons/TrashcanIcon.vue'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { markRaw } from 'vue'
import Tile from '@/components/Tile.vue'
import { IconCirclePlus, IconDots, IconEdit, IconLoader2, IconTrash } from '@tabler/icons-vue'

export default {
  components: { Tile, TrashcanIcon, IconDots, IconCirclePlus, IconEdit, IconTrash, IconLoader2 },
  props: {
    htmlContent: {
      type: String,
    },
    id: {
      type: Number,
    },
    // One of the objects in StickerLibrary.js or textLibrary.js
    sticker: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
    isTextSticker: {
      type: Boolean,
      default: false,
    },
    hasEditableText: {
      type: Boolean,
      default: false,
    },
    shouldPreviewInClip: {
      type: Boolean,
      default: false,
    },
    // Component can be used for stickers and for captions.
    styleType: {
      type: String,
      default: brandkitStyleTypes.STICKERS,
    },
    displayActions: {
      type: Boolean,
      default: false,
    },
    primaryColor: {
      type: String,
    },
    secondaryColor: {
      type: String,
    },
    fontFamily: {
      type: String,
    },
  },
  data() {
    return {
      containerSize: null,
      showOptions: false,
      destroying: false,
    }
  },
  computed: {
    getPrimaryColor() {
      return this.sticker.primaryColor || this.primaryColor
    },
    getSecondaryColor() {
      return this.sticker.secondaryColor || this.secondaryColor
    },
    getFontFamily() {
      return this.sticker.fontFamily || this.fontFamily
    },
  },
  methods: {
    selectStyle() {
      switch (this.styleType) {
        case brandkitStyleTypes.STICKERS:
          this.selectSticker(this.style)
          break
        case brandkitStyleTypes.CAPTIONS:
          this.selectCaption(this.style)
          break
      }
    },
    getComponent() {
      return this.sticker.component ?? TextSticker
    },
    onResize(size) {
      this.containerSize = size
      this.scaleSticker()
    },
    scaleSticker() {
      if (!this.$refs.sticker || !this.containerSize) {
        return
      }
      const stickerElement = this.$refs.sticker.$el
      const targetWidth = this.containerSize.width * 0.8
      if (stickerElement.clientWidth >= targetWidth) {
        const stickerScale = targetWidth / stickerElement.clientWidth
        stickerElement.style.transform = `scale(${stickerScale})`
      } else {
        stickerElement.style.transform = null
      }
    },
    selectSticker(sticker) {
      this.$emit('stickerPicked', this.sticker)

      if (!this.shouldPreviewInClip) return

      const newSticker = {
        component: markRaw(this.getComponent(sticker)),
        componentName: this.sticker.key,
        primaryColor: this.sticker.primaryColor,
        secondaryColor: this.sticker.secondaryColor,
        fontFamily: this.sticker.fontFamily,
        variant: this.sticker.variant,
        icon: this.sticker.icon,
        savedSticker: false,
        x: -1,
        y: -1,
        scale: -1,
        htmlContent: this.htmlContent,
        isTextSticker: this.isTextSticker,
        hasEditableText: this.hasEditableText,
        key: '',
        visible: true,
        animationStyle: 'none',
        animationTime: undefined,
      }

      // scroll to top when a sticker is added (mostly for mobile)
      window.scrollTo(0, 0)
      EventBus.$emit('editor/stickers/added', newSticker)

      this.$trackEvent('Editor Brandkit TextSticker Added', {
        Variant: newSticker.variant,
        Color: newSticker.color,
        Tags: this.sticker.tags,
      })
    },
    selectCaption() {
      this.$emit('captionSelected')

      if (!this.shouldPreviewInClip) return
      const editorCaptionsStore = useEditorCaptionsStore()
      editorCaptionsStore.setSelectedCaptionStyle(this.sticker)
    },
    destroy(e) {
      this.destroying = true
      const userBrandkitStore = useUserBrandkitStore()
      userBrandkitStore.destroy(this.sticker.id)
    },
    callback() {
      if (this.selected) {
        this.selectCaption()
      }
    },
    edit(style) {
      this.showActions = false

      EventBus.$emit(brandKitEvents.OPEN_BRAND_KIT_EVENT, {
        style: style,
        callback: () => this.callback && this.callback(),
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
