<template>
  <base-dialog ref="baseDialog" :closeable="false">
    <div class="flex flex-col items-center">
      <ErrorIcon />
      <h5>
        <strong>{{ title }}</strong>
      </h5>
      <slot></slot>
      <button class="mt-6 w-full rounded bg-red-600 p-2 text-white hover:bg-red-800" @click="onClickHandler">
        {{ buttonText }}
      </button>
    </div>
  </base-dialog>
</template>

<script>
import BaseDialog from './BaseDialog.vue'
import ErrorIcon from "@/components/Dialog/Icons/ErrorIcon.vue";

export default {
  components: {
    ErrorIcon,
    BaseDialog,
  },
  props: {
    title: {
      type: String,
      default: 'Error',
    },
    buttonText: {
      type: String,
      default: 'Ok, I understand',
    },
    action: {
      type: Function,
    },
  },
  methods: {
    onClickHandler() {
      if (typeof this.action != 'undefined') {
        this.action()
      }

      this.$refs.baseDialog.close()
    },
    open() {
      this.$refs.baseDialog.open()
    },
  },
}
</script>

<style lang="scss">
.error-icon {
  color: rgba($color: red, $alpha: 0.8);
  font-size: 3em;
}
</style>
