<template>
  <button @click="$emit('click')" class="button-youtube" v-bind="$attrs">
    <i><youtube-icon /></i>
    <slot></slot>
  </button>
</template>

<script>
import YoutubeIcon from '../Icons/SocialMedia/YoutubeIcon.vue'
export default {
  components: { YoutubeIcon },
}
</script>

<style scoped lang="scss">
.button-youtube {
  background-color: red;
  color: white;

  &:hover {
    background-color: darken(red, 10%);
  }

  > i {
    width: 1.2em;
  }

  line-height: 2em;
}
</style>
