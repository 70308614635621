<script setup lang="ts">
import MovableRoot from '@/modules/SLMovable/MovableRoot.vue'
import CustomLayoutsPreview from '@/modules/CustomLayouts/CustomLayoutsPreview.vue'
import CropElement from '@/modules/CustomLayouts/Crop/CropElement.vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { useScreen } from '@/Hooks/useScreen'
import { ref, computed, provide, inject, onMounted } from 'vue'
import { useEventListener, useElementSize, useRafFn } from '@vueuse/core'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { contain } from '@/modules/SLMovable/helpers/fit'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import type { CropsStore } from '@/store/entity-system/useCropsStore'

const cropsStore = inject<CropsStore>('cropsStore')!
const props = defineProps<{ id: string, enableSnapping: boolean }>()
const crops = cropsStore.idsWhereLayoutIdIs(props.id)

const isDesktop = useScreen('md')
const tab = ref<'preview' | 'crop'>('crop')

const editorFocusStore = useEditorFocusStore()
function unfocus() {
  editorFocusStore.unFocus()
}

onMounted(() => {
  setTimeout(() => {
    const lastCrop = crops.value[crops.value.length - 1]
    if (lastCrop && editorFocusStore.focus?.type !== 'crop') {
      editorFocusStore.setFocus('crop', lastCrop)
    }
  }, 0)
})

const editorVideoStore = useEditorVideoStore()
const canvas = ref<HTMLCanvasElement | null>(null)

useRafFn(() => {

  const video = editorVideoStore.videoElement
  const size = editorVideoStore.videoSize
  if (video && size && canvas.value) {

    const canvasSize = contain(size, relativeVideoSize.value!)

    canvas.value.width = canvasSize.width
    canvas.value.height = canvasSize.height

    const ctx = canvas.value.getContext('2d')
    ctx?.drawImage(video, 0, 0, canvasSize.width, canvasSize.height)
  }
})

provide('canvas', canvas)

const cropper = ref<HTMLElement | null>(null)
const { width: cropperWidth } = useElementSize(cropper)
const cropperHeight = computed(() => cropperWidth.value * (9 / 16))

const relativeVideoSize = computed(() => {
  if (editorVideoStore.videoSize) {
    return contain(editorVideoStore.videoSize, { width: cropperWidth.value, height: cropperHeight.value })
  }

  return null
})

const width = computed(() => relativeVideoSize.value?.width)
const height = computed(() => relativeVideoSize.value?.height)

useEventListener(['click', 'touch'], unfocus, { target: document.body })
</script>

<template>
  <div class="w-full" v-if="isDesktop">
    <div class="preview-grid">
      <div
        ref="cropper"
        class="relative grid place-items-center box-content preview-grid__cropper p-2 bg-zinc-900 rounded-xl"
        :style="{ maxHeight: height + 'px' }"
      >
        <div class="rounded-lg overflow-hidden w-full h-full grid place-items-center bg-zinc-900">
          <canvas ref="canvas" />
        </div>

        <MovableRoot
          :blackout="crops.length > 0"
          class="flex-0 absolute inset-auto"
          mask-class="rounded-lg overflow-hidden"
          :style="{ width: width + 'px', height: height + 'px' }"
        >
          <CropElement v-for="crop in crops" :key="crop" :id="crop" :enable-snapping="enableSnapping" @click.stop />
        </MovableRoot>
      </div>
      <div
        class="relative border-box p-2 w-full rounded-2xl border-zinc-900 bg-zinc-900 shadow-lg preview-grid__preview"
      >
        <div class="h-0 pb-[177.78%] relative">
          <CustomLayoutsPreview class="absolute inset-0 overflow-hidden rounded-xl" :id="props.id" :enable-snapping="enableSnapping" />
        </div>
      </div>
    </div>
  </div>
  <div class="w-full flex flex-col gap-2" v-else>
    <LightSwitch @click.stop :options="[ { value: 'crop', label: 'Crop' }, { value: 'preview', label: 'Preview' }, ]" v-model:value="tab" />
    <div
      v-if="tab === 'crop'"
      ref="cropper"
      class="relative grid place-items-center -m-2 box-content"
      :style="{ maxHeight: height + 'px' }"
    >
      <div class="rounded-xl overflow-hidden w-full h-full grid place-items-center bg-zinc-900">
        <canvas ref="canvas" />
      </div>

      <MovableRoot
        :blackout="crops.length > 0"
        class="flex-0 absolute inset-auto"
        :style="{ width: width + 'px', height: height + 'px' }"
      >
        <CropElement v-for="crop in crops" :key="crop" :enable-snapping="enableSnapping" :id="crop" @click.stop />
      </MovableRoot>
    </div>
    <div
      v-else-if="tab === 'preview'"
      class="relative flex h-0 w-full rounded-2xl border-[0.5rem] border-zinc-900 bg-zinc-900 pb-[177.78%] shadow-lg"
    >
      <CustomLayoutsPreview class="absolute inset-0 overflow-hidden rounded-xl" :id="props.id" :enable-snapping="enableSnapping" />
    </div>
  </div>
</template>

<style scoped lang="scss">

.preview-grid {
  display: grid;

  // CSS calculation that accounts for borders while maintaining aspect-ratio,
  // read as: [border-left] [crop] [border-right] [gap] [border-left] [preview] [border-right].
  grid-template-columns: 0.5rem 16fr 0.5rem 2rem 0.5rem calc(calc(9fr / 16) * 9) 0.5rem;
  grid-template-areas: 'crop crop crop . preview preview preview';
  padding: 0.5rem;
}

.preview-grid__cropper {
  grid-area: crop;
}

.preview-grid__preview {
  grid-area: preview;
}
</style>
