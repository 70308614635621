<script setup lang="ts">
import { useMovableElementContext } from '@/modules/SLMovable/useMovableElementContext'
import type { Directions } from '@/modules/SLMovable/@types/Movable'
import { resizeAutomatically } from '@/modules/SLMovable/helpers/resize/resizeAutomatically'
import { cover } from '@/modules/SLMovable/helpers/fit'

defineProps<{ handleClass?: string }>()

const emit = defineEmits<{
  (event: 'resizeStart', direction: Directions): void
  (event: 'resize', area: { x: number; y: number; width: number; height: number }, directions: Directions): void
  (event: 'resizeEnd'): void
}>()

const directions: Record<string, { cursor: Readonly<string>; directions: Directions; angle: Readonly<number> }> = {
  n: { cursor: 'n-resize', directions: ['n'], angle: 0 },
  ne: { cursor: 'ne-resize', directions: ['n', 'e'], angle: 45 },
  e: { cursor: 'e-resize', directions: ['e'], angle: 90 },
  se: { cursor: 'se-resize', directions: ['s', 'e'], angle: 135 },
  s: { cursor: 's-resize', directions: ['s'], angle: 180 },
  sw: { cursor: 'sw-resize', directions: ['s', 'w'], angle: 225 },
  w: { cursor: 'w-resize', directions: ['w'], angle: 270 },
  nw: { cursor: 'nw-resize', directions: ['n', 'w'], angle: 315 },
}

const { source, localArea, aspectRatio, resizingFrom, minSize, bounds, scaleX, scaleY, container } = useMovableElementContext()!

function determineTouchPoint(event: MouseEvent | TouchEvent) {

  const { pageX, pageY } = 'pageX' in event ? event : event.touches[0]

  // For some reason this is more accurate than @vueuse useElementBounding, sadly.
  const containerRect = container.value!.getBoundingClientRect()

  return {
    x: pageX - containerRect.x - window.scrollX,
    y: pageY - containerRect.y - window.scrollY,
  }
}

function determineAspectRatio(event: MouseEvent | TouchEvent) {
  if (event.shiftKey) {
    return {
      width: localArea.value.width,
      height: localArea.value.height,
    }
  } else if (aspectRatio.value !== null) {
    // If external aspect ratio is provided, convert it to a relative aspect ratio
    // e.g. 1:1 aspect ratio in a video container would become (1/16):(1/9)
    return {
      width: scaleX.value(aspectRatio.value.width),
      height: scaleY.value(aspectRatio.value.height),
    }
  } else {
    return null
  }
}

function determineMinSize(event: MouseEvent | TouchEvent) {

  const aspectRatio = determineAspectRatio(event)

  const size = {
    width: scaleX.value(minSize.value),
    height: scaleY.value(minSize.value),
  }

  if (aspectRatio) {
    return cover(aspectRatio, size)
  } else {
    return size
  }
}

function resize(event: TouchEvent | MouseEvent) {

  const touchPoint = determineTouchPoint(event)

  const relativeTouchPoint = {
    x: scaleX.value(touchPoint.x),
    y: scaleY.value(touchPoint.y),
  }

  if (!resizingFrom.value) {
    throw new Error('Resize function is called without origin has been set. Be sure to assign `resizingFrom` before '
      + 'resuming the resize loop')
  }

  const aspectRatio = determineAspectRatio(event)

  const resize = resizeAutomatically(source.value, relativeTouchPoint, resizingFrom.value, {
    aspectRatio: aspectRatio,
    centerOrigin: event.altKey,
    bounds: bounds.value,
    snap: null,
    minSize: determineMinSize(event),
  })

  if (resize) {
    localArea.value = resize
    emit('resize', localArea.value, resizingFrom.value)
  }
}

function resizeStart(directions: Directions) {
  emit('resizeStart', directions)

  window.addEventListener('mousemove', resize)
  window.addEventListener('touchmove', resize)

  window.addEventListener('mouseup', resizeEnd)
  window.addEventListener('touchend', resizeEnd)
}

function resizeEnd() {
  emit('resizeEnd')

  window.removeEventListener('mousemove', resize)
  window.removeEventListener('touchmove', resize)

  window.removeEventListener('mouseup', resizeEnd)
  window.removeEventListener('touchend', resizeEnd)
}
</script>

<template>
  <div
    v-for="direction in directions"
    :key="direction.cursor"
    :style="{ cursor: direction.cursor }"
    @mousedown="resizeStart(direction.directions)"
    @touchstart="resizeStart(direction.directions)"
    class="absolute grid place-items-center p-1.5"
    :class="{
      handleClass,
      'left-0 -translate-x-1/2': direction.directions.includes('w'),
      'right-0 translate-x-1/2': direction.directions.includes('e'),
      'top-0 -translate-y-1/2': direction.directions.includes('n'),
      'bottom-0 translate-y-1/2': direction.directions.includes('s'),
      'left-1/2 -translate-x-1/2': !direction.directions.includes('w') && !direction.directions.includes('e'),
      'top-1/2 -translate-y-1/2': !direction.directions.includes('n') && !direction.directions.includes('s'),
    }"
  >
    <slot name="direction" :direction="direction" :angle="direction.angle" />
  </div>
</template>

<style scoped lang="scss"></style>
