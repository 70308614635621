<script setup lang="ts">
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { storeToRefs } from 'pinia'
import VolumeButton from '@/components/Editor/VolumeButton.vue'

const store = useEditorVideoStore()
const { playing, duration, muted, currentTime } = storeToRefs(store)
</script>

<template>
  <div class="flex w-full items-center gap-4 rounded-xl border-2 border-zinc-200 px-6 py-3">
    <button
      v-if="!playing"
      class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-zinc-900 text-white transition-all active:scale-90"
      @click="playing = true"
    >
      <IconSaxPlay class="h-4 w-4 fill-current" />
    </button>
    <button
      v-else
      class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-zinc-900 text-white transition-all active:scale-90"
      @click="playing = false"
    >
      <IconSaxPause class="h-4 w-4 fill-current" />
    </button>

    <div class="relative flex h-4 w-full flex-col justify-center">
      <input type="range" class="opacity-0" :min="0" :max="duration" v-model="currentTime" />
      <div class="pointer-events-none absolute inset-auto h-2 w-full rounded-full bg-zinc-300" />
      <div
        class="pointer-events-none absolute inset-auto left-0 h-2 rounded-full bg-primary"
        :style="{ width: (currentTime / duration) * 100 + '%' }"
      />
    </div>

    <VolumeButton />
  </div>
</template>

<style scoped lang="scss"></style>
