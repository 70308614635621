import { helpers } from '@vuelidate/validators'

export const maxStrippedLength = (param: number) =>
  helpers.withMessage(
    (props) => `Your ${props.$property} cannot be longer than ${param} characters.`,
    helpers.withParams(
      {
        max: param,
      },
      (value: string) => value.replace(/\s/g, '').length <= param || value === ''
    )
  )

export const minStrippedLength = (param: number) =>
  helpers.withMessage(
    (props) => `Your ${props.$property} cannot be shorter than ${param} characters.`,
    helpers.withParams(
      {
        max: param,
      },
      (value: string) => value.replace(/\s/g, '').length >= param || value === ''
    )
  )

export const maxTrimmedLength = (max: number, message?: string) => {
  return helpers.withMessage(
    (props) => message ?? `Your ${props.$property} cannot be longer than ${max} characters.`,
    helpers.withParams({ max }, (value: string) => (value?.trim().length ?? 0) <= max)
  )
}

export const noIllegalCharacters = helpers.withMessage(
  (props) => `The ${props.$property} is invalid. Make sure your description does not contain: "<", ">".`,
  (value: string) => !/([<>])/s.test(value)
)
