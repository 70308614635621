<template>
  <FeatureShowcase>
    <div class="flex w-full items-center justify-center">
      <AsyncComponent :is="LoaderComponent || 'div'" class-name="w-3/5 md:w-1/3 lg:w-1/2">
        <lottie-animation url="./lottie/walking-chick.json" :loop="true" :autoPlay="true" />
      </AsyncComponent>
    </div>

    <div class="flex w-full flex-col text-center">
      <h1 class="text-2xl">
        <strong>{{ statusMsg }}</strong>
      </h1>
      <p v-if="!hasError">You’ll be ready to roll before you know!</p>
    </div>

    <div class="mx-auto flex flex-col space-y-2">
      <status-row status-text="Loading resources" :status="ffmpegStatus" />
      <template v-if="false">
        <!-- <status-row-->
        <!--   v-if="hasOverlay"-->
        <!--   :status-text="stickerStatusRowText"-->
        <!--   :status="stickerStatus"-->
        <!--   :progress-percentage="stickerPercentage"-->
        <!-- />-->
      </template>
      <status-row status-text="Rendering video" :status="renderStatus" :progress-percentage="progressPercentage" />
    </div>

    <div v-if="hasErrorWithVideo" class="self-center rounded-xl border border-red-700 bg-red-100 p-4">
      <h5>Error</h5>
      <p>We couldn't process your video.</p>
      <a href="/video-support" class="btn-primary btn">Find out why</a>
    </div>

    <div class="flex justify-center">
      <div v-if="hasError" class="">
        <a href="/" class="btn-outline btn-primary btn">Return to home</a>
      </div>
    </div>
  </FeatureShowcase>
</template>
<script>
import localForage from 'localforage'
import StatusRow from './StatusRow.vue'
import LottieAnimation from '../LottieAnimation.vue'
import plan from '../../data/plans'
import MegaphoneIcon from '../Icons/MegaphoneIcon.vue'
import * as Sentry from '@sentry/browser'
import FeatureShowcase from '@/components/Clip2TikTok/FeatureShowcase.vue'
import ConvertInBackgroundAlert from '@/components/Clip2TikTok/ConvertInBackgroundAlert.vue'
import AsyncComponent from '@/components/Campaigns/AsyncComponent.vue'
import { useCampaignComponent } from '@/data/campaigns'

export default {
  components: {
    AsyncComponent,
    ConvertInBackgroundAlert,
    FeatureShowcase,
    MegaphoneIcon,
    LottieAnimation,
    StatusRow,
  },
  head() {
    return {
      title: 'Generating video...',
      bodyAttrs: {
        class: 'bg-base-100',
      },
    }
  },
  data() {
    return {
      clipUrl: '',
      shouldRevokeClipUrl: false,
      goldPlan: plan.gold,
      statusMsg: 'Processing...',
      baseDocumentTitle: '',
      generateProgress: 0,
      stickerPercentage: 0,
      progressPercentage: 0,
      hasError: false,
      hasErrorWithVideo: false,
      ffmpegStringArray: '',
      loggingString: '',
      originalFileUrl: '',
      isTrimmed: false,
      stickerDataUrl: '',
      ffmpegStatus: 'in-endless-progress',
      smartCutStatus: 'idle',
      smartCutPercentage: 0,
      stickerStatus: 'idle',
      renderStatus: 'idle',
      ffmpeg: null,
    }
  },
  computed: {
    userAgent() {
      return navigator.userAgent
    },
    command() {
      return this.$route.query.command
    },
    clipName() {
      return this.$route.query.clipName
    },
    trimmedDurationMs() {
      return Number.parseInt(this.$route.query.trimmedDurationMs)
    },
    trimmedStartTime() {
      return this.$route.query.trimmedStartTime
    },
  },
  setup() {
    const LoaderComponent = useCampaignComponent('generate-loader')
    return { LoaderComponent }
  },
  async mounted() {
    this.generateProgress = 0
    this.baseDocumentTitle = document.title

    // Note that the message is ignored on most browsers due security concerns. But we should return a string anyway
    window.onbeforeunload = () => 'Leave site?'

    if (!this.hasCommand()) {
      this.handleError('No croptask provided', null)
      return
    }

    try {
      // Setup clipUrl
      if (this.$route.query.lf === 'true') {
        // get uri from localForage
        const fileData = await localForage.getItem('localFile')
        if (!fileData) {
          this.handleError('No clip provided', null)
          return
        }

        this.clipUrl = URL.createObjectURL(fileData)
        this.shouldRevokeClipUrl = true
      } else if (this.hasClipUrl()) {
        // public file
        this.clipUrl = this.$route.query.clipUrl
      } else {
        this.handleError('No clip or croptask provided', null)
        return
      }

      // Run FFMPEG
      this.ffmpegStringArray = JSON.parse(atob(this.command))
      await this.run(this.clipUrl, this.clipName, this.ffmpegStringArray)
    } catch (e) {
      this.handleError('Failed ☹️', e, true)
      return
    }
  },
  methods: {
    openUpgradePage() {
      window.open(window.location.origin + '/upgrade?upgrade=server-side-queueing', '_blank').focus()
    },
    hasClipUrl() {
      return !this.isEmptyOrNull(this.$route.query.clipUrl)
    },
    hasCommand() {
      return !this.isEmptyOrNull(this.$route.query.command)
    },
    isEmptyOrNull(inputString) {
      return !inputString || inputString.length === 0
    },
    async run(clipUrl, clipName, ffmpegStringArray) {
      const { createFFmpeg, fetchFile } = await import('@ffmpeg/ffmpeg')

      try {
        // setup ffmpeg
        this.ffmpeg = createFFmpeg({
          log: import.meta.env.DEV,
          progress: ({ time }) => {
            if (time) {
              this.progressPercentage = (time / (this.trimmedDurationMs / 1000)) * 100
            }
          },
          corePath: '/ffmpeg-core/0.11.0/ffmpeg-core.js',
        })
        this.ffmpeg.setLogger(({ type, message }) => {
          console.log('logger: ', type, message)
        })

        this.statusMsg = 'Transcoding...'

        await this.ffmpeg.load()
        // fetch file
        this.ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(clipUrl))
        this.ffmpegStatus = 'success'

        this.ffmpeg.FS('writeFile', 'mask_360.png', await fetchFile('/images/images/circle_1080.png'))

        this.renderStatus = 'in-progress'
        /*
          -i input.mp4 -c:v libx264 -pix_fmt yuv420p -crf 17 -preset superfast
          -filter_complex [0:v]crop=384:216:768:432[croppedfc];[croppedfc]
          scale=720:-2:flags=lanczos[top];[0:v]crop=796:972:562:54[croppedgf];[croppedgf]
          scale=720:-2:flags=lanczos[bottom];[top][bottom]vstack -c:a aac output.mp4
         */

        /*
          -i input.mp4 -c:v libx264 -pix_fmt yuv420p -crf 17 -preset superfast
          -filter_complex [0:v]crop=332:186:56:33[croppedfc];[croppedfc]
          scale=0:-2:flags=lanczos[top];[0:v]crop=796:972:562:54[croppedgf];[croppedgf]
          scale=0:-2:flags=lanczos[bottom];[top][bottom]vstack -c:a aac output.mp4
         */
        await this.ffmpeg.run(...ffmpegStringArray)
        this.renderStatus = 'success'

        const data = this.ffmpeg.FS('readFile', 'output.mp4')

        // Get and check FFMPEG result
        const resultBlob = new Blob([data.buffer], { type: 'video/mp4' })
        if (resultBlob.size === 0) {
          this.handleError('Failed ☹️', new Error('FFMPEG Failed to generate output.mp4'), true)
          return
        }
        const resultUrl = URL.createObjectURL(resultBlob)

        // Cleanup files
        this.ffmpeg.FS('unlink', 'output.mp4')

        await localForage.removeItem('localFile')
        window.onbeforeunload = null
        if (this.shouldRevokeClipUrl) {
          URL.revokeObjectURL(this.clipUrl)
        }

        await localForage.setItem('resultBlob', resultBlob)
        const route = this.$router.resolve({ name: 'Result', query: { resultUrl: resultUrl, clipName: clipName } })
        location.href = location.origin + route.href
      } catch (e) {
        this.handleError('Failed ☹️', e, true)
        return
      }
    },
    handleError(statusMessage, exception, isVideoError = false) {
      this.statusMsg = statusMessage
      this.renderStatus = 'error'
      this.hasError = true
      this.hasErrorWithVideo = isVideoError

      // remove onbeforeunload handler so people can leave the page
      window.onbeforeunload = null
      // Capture and output error. Do not rethrow, otherwise the statusMsg will be overwritten
      if (exception != null) {
        Sentry.captureException(exception)
        console.error(exception)
      }
    },
  },
  beforeUnmount() {
    document.title = this.baseDocumentTitle
    window.onbeforeunload = null
    if (this.shouldRevokeClipUrl) {
      URL.revokeObjectURL(this.clipUrl)
    }
  },
}
</script>

<style scoped lang="scss">
.progress-wrapper {
  width: 100%;
  max-width: 400px;
  display: block;
  margin: auto;
}

.progress {
  height: 50px;
}

.status-list {
  display: flex;

  .status-badge {
    width: 4em;
    height: 4em;
  }
}
</style>
