import { createInjectionState, useElementSize } from '@vueuse/core'
import { type Ref, type ComputedRef, ref, watch, nextTick } from 'vue'

export const [createMovableContext, useMovableContext] = createInjectionState(
  (container: Ref<HTMLElement | null>, config: MovableConfig) => {

    const { width, height } = useElementSize(container)

    const blackout = ref<boolean>(!!config.blackout.value)

    watch(config.blackout, (next, prev) => {
      // If blackout becomes enabled, we need to wait a tick before masks can be rendered, due to limitations of the
      // Vue Teleport API
      if (!!next && !prev) {
        nextTick(() => {
          blackout.value = Boolean(next)
        })
      } else if (!next) {
        blackout.value = Boolean(next)
      }
    })

    return {
      container: container,

      width,
      height,

      snapGridId: config.snapGridId,
      maskId: config.blackout,
      blackout: blackout,
    }
  }
)

type MovableConfig = {
  blackout: ComputedRef<string | null>
  snapGridId: string
}
