import { defineStore, acceptHMRUpdate } from 'pinia'
import { streamLadderAxios } from '@/services/axios'
import type { FontLabel } from '@/data/fonts'

interface UserBrandkitState {
  brandKitStyles: BrandKitStyle[]
  loadingBrandkit: boolean
}

export const useUserBrandkitStore = defineStore('userBrandkit', {
  state: (): UserBrandkitState => {
    return {
      brandKitStyles: [],
      loadingBrandkit: false,
    }
  },
  actions: {
    async list() {
      this.loadingBrandkit = true
      const res = await streamLadderAxios.get('api/customstyles')
      this.brandKitStyles = res.data
      this.loadingBrandkit = false
    },
    async add(style: BrandKitStyle) {
      const res = await streamLadderAxios.post('api/customstyles', style)
      this.brandKitStyles.unshift(res.data)
    },
    async edit(style: BrandKitStyle) {
      this.loadingBrandkit = true
      const res = await streamLadderAxios.put(`api/customstyles/${style.id}`, style)
      const index = this.brandKitStyles.findIndex((s) => s.id === style.id)
      this.brandKitStyles[index] = res.data
      this.loadingBrandkit = false
    },
    async destroy(id: string) {
      await streamLadderAxios.delete(`api/customstyles/${id}`)
      this.brandKitStyles = this.brandKitStyles.filter((style) => style.id !== id)
    },
  },
})

export interface BrandKitStyle {
  id: string
  primaryColor: string
  secondaryColor: string
  fontFamily: FontLabel
  variant: string
  createdDate: string
  streamLadderUserId: string
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useUserBrandkitStore, import.meta.hot))
}
