import NotFound from '@/pages/NotFoundPage.vue'
import LoginSuccess from '@/pages/auth/LoginSuccess.vue'
import Generate from '@/components/Clip2TikTok/Generate.vue'
import GenerateServerSideQueue from '@/components/Clip2TikTok/GenerateServerSide.vue'
import DownloadResult from '@/pages/DownloadResultPage.vue'
import UpgradePage from '@/pages/UpgradePage.vue'
import LayoutsPage from '@/components/LayoutSelection/LayoutsPage.vue'
import LoginAccessDenied from '@/components/LoginAccessDenied.vue'
import LocalFile from '@/components/Sources/LocalFile.vue'
import ConnectionSuccess from '@/pages/auth/ConnectionSuccess.vue'
import StickerRender from '@/pages/internal/StickerRender.vue'
import FontLineHeightCalculator from '@/pages/internal/FontLineHeightCalculator.vue'
import TwitchClip from '@/components/Sources/TwitchClip.vue'
import KickClip from '@/components/Sources/KickClip.vue'
import YouTubeClip from '@/components/Sources/YouTubeClip.vue'
import LoginPage from '@/pages/LoginPage.vue'

import CancelSubscriptionPage from '@/pages/account/cancel/CancelSubscriptionPage.vue'
import CancelReasons from '@/pages/account/cancel/CancelReasons.vue'
import OfferFeatures from '@/pages/account/cancel/OfferFeatures.vue'
import OfferDowngrade from '@/pages/account/cancel/OfferDowngrade.vue'
import OfferHelp from '@/pages/account/cancel/OfferHelp.vue'
import ReviewCancel from '@/pages/account/cancel/ReviewCancel.vue'
import ReviewDowngrade from '@/pages/account/cancel/ReviewDowngrade.vue'
import SubscriptionUpdated from '@/pages/account/cancel/SubscriptionUpdated.vue'
import CroppingPage from '@/pages/editor/CroppingPage.vue'
import EditorPage from '@/pages/editor/EditorPage.vue'
import VideoResultPage from '@/pages/editor/VideoResultPage.vue'
import VideoDetailPage from '@/pages/account/VideoDetailPage.vue'
import ScheduleVideoPage from '@/pages/scheduler/ScheduleVideoPage.vue'
import ManageScheduledVideoPage from '@/pages/scheduler/ManageScheduledVideoPage.vue'
import ReferralsLandingPage from '@/pages/ReferralsLandingPage.vue'
import TechnicalDifficultiesFeedback from '@/pages/account/cancel/TechnicalDifficultiesFeedback.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useLocalStorage } from '@vueuse/core'
import { areas } from '@/areas/router'
import ReferralsPage from '@/pages/account/referrals/ReferralsPage.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import ClipEditorPage from '@/pages/ClipEditorPage.vue'
import DashboardIndex from '@/areas/dashboard/pages/index/DashboardIndex.vue'
import EditorRoot from '@/pages/editor/EditorRoot.vue'

const routes = [
  {
    name: 'Home',
    path: '/',
    component: ClipEditorPage,
    meta: { label: 'Home' },
  },
  ...areas,
  {
    name: 'ClipEditor',
    path: '/clip-editor',
    component: ClipEditorPage,
  },
  {
    path: '/scheduler',
    redirect: '/content-publisher',
  },
  {
    path: '/content-publisher',
    meta: { label: 'Content publisher', requiresAuth: true },
  },
  {
    path: '/content-publisher/post',
    name: 'schedule-video',
    component: ScheduleVideoPage,
    meta: { label: 'Schedule Post', requiresAuth: true },
  },
  {
    path: '/content-publisher/post/:postId',
    name: 'manage-scheduled-video',
    component: ManageScheduledVideoPage,
    props: true,
    meta: { label: 'Schedule Post', requiresAuth: true },
  },
  {
    path: `/:source(twitch-clip|local-file|youtube-clip|kick-clip)/:clipId`,
    component: EditorRoot,
    children: [
      {
        path: '',
        name: `editor`,
        component: LayoutsPage,
      },
      {
        path: 'layouts/:layout',
        name: `editor/cropping`,
        component: CroppingPage,
      },
      {
        path: 'layouts/:layout/preview',
        name: `editor/main`,
        component: EditorPage,
      },
    ],
    props: (route) => ({
      clipId: route.path.split('/')[2],
      source: route.path.split('/')[1],
    }),
  },
  {
    path: '/generate',
    name: 'Generate',
    component: Generate,
    props: true,
  },
  {
    path: '/server-generate-queue',
    name: 'GenerateServerSideQueue',
    component: GenerateServerSideQueue,
  },
  {
    path: '/result',
    name: 'Result',
    component: VideoResultPage,
    props: true,
    meta: {
      step: 'export',
    },
  },
  {
    path: '/download-result/',
    name: 'DownloadResult',
    component: DownloadResult,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: UpgradePage,
  },
  {
    path: '/account',
    name: 'Account',
    children: [
      {
        path: '',
        name: 'AccountSettings',
        redirect: { name: dashboardRouteNames.account.settings },
      },
      {
        name: 'Invoicing',
        path: 'invoicing',
        redirect: { name: dashboardRouteNames.account.invoicing },
      },
      {
        name: 'Referrals',
        path: '/account/referrals',
        component: ReferralsPage,
      },
    ],
    //   meta: {
    //     requiresAuth: true,
    //   },
  },
  {
    path: '/account/subscription/cancel',
    component: CancelSubscriptionPage,
    children: [
      {
        path: '',
        name: 'CancelSubscription',
        component: CancelReasons,
      },
      {
        path: 'offer-downgrade',
        name: 'OfferDowngrade',
        component: OfferDowngrade,
      },
      {
        path: 'offer-features',
        name: 'OfferFeatures',
        component: OfferFeatures,
      },
      {
        path: 'difficulties-feedback',
        name: 'TechnicalDifficultiesFeedback',
        component: TechnicalDifficultiesFeedback,
      },
      {
        path: 'offer-help',
        name: 'OfferHelp',
        component: OfferHelp,
      },
      {
        path: 'review-cancel',
        name: 'ReviewCancel',
        component: ReviewCancel,
      },
      {
        path: 'review-downgrade',
        name: 'ReviewDowngrade',
        component: ReviewDowngrade,
      },
    ],
  },
  {
    path: '/account/subscription/updated',
    component: SubscriptionUpdated,
    name: 'SubscriptionUpdated',
  },
  {
    path: '/account/videos',
    name: 'MyVideosPage',
    redirect: { name: dashboardRouteNames.videos },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/videos/:videoId',
    name: 'VideoDetail',
    component: VideoDetailPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/account/templates',
  //   name: 'SavedTemplatesPage',
  //   component: SavedTemplatesPage,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/login-access-denied',
    name: 'LoginAccessDenied',
    component: LoginAccessDenied,
  },
  {
    path: '/sticker-render',
    name: 'StickerRender',
    component: StickerRender,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/overlay-test/:renderId',
    name: 'OverlayTest',
    component: StickerRender,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/font-line-height-calculator',
    name: 'FontLineHeightCalculator',
    component: FontLineHeightCalculator,
  },
  {
    path: '/feature-promo-content',
    name: 'FeaturePromoContent',
    component: () => import('@/pages/internal/FeaturePromoContent.vue'),
  },
  {
    path: '/video-support',
    name: 'VideoSupport',
    component: () => import('@/pages/VideoSupport.vue'),
  },
  {
    path: '/support/form',
    redirect: { name: dashboardRouteNames.support },
  },
  {
    path: '/login-success',
    name: 'LoginSuccess',
    component: LoginSuccess,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/ref/:code',
    name: 'ReferralsLandingPage',
    component: ReferralsLandingPage,
  },
  {
    path: '/connection-success',
    name: 'ConnectionSuccess',
    component: ConnectionSuccess,
    meta: {
      preventUserInfoLoad: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { el: to.hash }
    }
    return { top: 0 }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/') {
    /**
     * @type {{ expiryDate: string, token: string } | null}
     */
    const refreshToken = localStorage.getItem('refresh_token')
      ? JSON.parse(localStorage.getItem('refresh_token'))
      : null

    if (refreshToken && new Date(refreshToken.expiryDate).getTime() >= new Date().getTime()) {
      if (to.name !== dashboardRouteNames.dashboard) {
        const userInfoStore = useUserInfoStore()
        // Check if the userDetails have been loaded. If not, load them.
        if (!userInfoStore.hasLoadedUserInfo) await userInfoStore.updateUserInfo()
        if (!userInfoStore.isAuthenticated) return next()

        router.addRoute(dashboardRouteNames.root, {
          path: '',
          name: dashboardRouteNames.dashboard,
          component: DashboardIndex,
          alias: 'Home',
        })

        return next({ name: dashboardRouteNames.dashboard, query: to.query })
      }
    }
  }
  return next()
})

// affiliate code guard
router.beforeEach((to, from, next) => {
  const ref = useLocalStorage('refCode', '')
  const refCode = to.query.ref
  if (refCode && typeof refCode === 'string') {
    ref.value = refCode
  }
  return next()
})

// authentication guard
router.beforeEach(async (to, from, next) => {
  const userInfoStore = useUserInfoStore()
  // Check if the route requires auth. If it does not, continue.
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the userDetails have been loaded. If not, load them except if the route is marked to prevent it.
    if (!userInfoStore.hasLoadedUserInfo && !to.matched.some((record) => record.meta.preventUserInfoLoad))
      userInfoStore.updateUserInfo()
    return next()
  }

  // Check if the userDetails have been loaded. If not, load them.
  if (!userInfoStore.hasLoadedUserInfo) await userInfoStore.updateUserInfo()
  if (!userInfoStore.isAuthenticated) {
    // User is not authenticated, redirect to login page
    return next({ name: 'Login', query: { redirect: to.fullPath } })
  }

  // User is authenticated, continue
  return next()
})

export { router }
