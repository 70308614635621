import { useUserInfoStore } from '@/store/user/userInfo'
import { computed } from 'vue'

export const useProfileCompletion = () => {
  const userInfo = useUserInfoStore()

  const tiktokConnected = computed(() => userInfo.tikTokAccounts.length > 0)
  const youtubeConnected = computed(() => userInfo.youTubeAccounts.length > 0)
  const instagramConnected = computed(() => userInfo.instagramAccounts.length > 0)

  const percentageComplete = computed(() => {
    let count = 0
    if (tiktokConnected.value) count++
    if (youtubeConnected.value) count++
    if (instagramConnected.value) count++
    if (count === 0) return 5
    return (count / 3) * 100
  })

  const canStartSharing = computed(() => {
    return tiktokConnected.value || youtubeConnected.value || instagramConnected.value
  })

  return {
    tiktokConnected,
    youtubeConnected,
    instagramConnected,
    percentageComplete,
    canStartSharing,
  }
}
