<template>
  <div
    :id="'toast_message_' + id"
    @click="(e) => $emit('click', this.id)"
    class="flex min-w-1/2 cursor-pointer border-l-2 border-green-600 py-2 pr-8 shadow"
    :class="status"
  >
    <div class="mr-2 flex flex-col items-center justify-center px-2">
      <lottie-animation class="h-6 w-6" :url="`/lottie/${status}.json`" :auto-play="true" />
    </div>
    <div class="flex flex-col">
      <p class="mb-0 text-black">{{ title }}</p>
      <p class="mb-0 text-xs text-gray-800" v-if="subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>
<script>
import LottieAnimation from '../LottieAnimation.vue'

export default {
  components: { LottieAnimation },
  props: {
    id: { type: Number, required: true },
    status: { type: String, required: true, default: 'success' },
    title: { type: String, required: true, default: 'Success!' },
    subtitle: { type: String, required: false },
  },
}
</script>
<style scoped>
.success {
  background-color: #daefea;
  border-color: #61d49d;
}

.error {
  background-color: #f1d8de;
  border-color: #d46161;
}
</style>
