<template>
  <div class="layer-2 flex h-full flex-col gap-4 p-4 sm:p-0 md:min-h-[600px]" v-auto-animate>
    <h2>Share your clip!</h2>

    <!-- ||| Account selection |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->
    <div class="flex flex-col gap-2">
      <span class="font-bold">I'm sharing to</span>
      <div class="flex flex-row flex-wrap gap-3">
        <create-new-account-row class="" />
        <account-select
          v-for="account in accounts"
          :key="account.id"
          :account="account"
          :connection-type="account.type"
          :selected="account.selected"
          @click="toggleAccount(account.id)"
        />
      </div>
    </div>

    <!-- ||| Title  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->
    <div v-if="showYoutubeForm" class="relative flex flex-col gap-2">
      <span class="folt-bold">Title</span>
      <label class="relative">
        <span class="absolute left-3 top-2"> <youtube-icon class="h-5 w-5 text-youtube" /></span>
        <input
          v-model="v$.title.$model"
          :class="{ 'input-error': v$.title.$error }"
          class="input-primary input w-full rounded pl-11 pr-[5.5rem]"
          type="text"
        />
        <span class="absolute bottom-2 right-2 text-twitch-300">
          {{ v$.title.$model?.trim()?.length ?? 0 }} / {{ v$.title.maxLength.$params.max }}
        </span>
      </label>

      <span v-for="error of v$.title.$errors" :key="error.$uid" class="text-error">
        {{ error.$message }}
      </span>
    </div>

    <!-- ||| Description |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->
    <div class="relative flex flex-col gap-2">
      <span class="font-bold">Description</span>
      <div class="relative">
        <textarea
          v-model="v$.description.$model"
          :class="{
            'textarea-error': v$.description.$error,
          }"
          class="textarea-primary textarea h-44 w-full rounded"
          placeholder="Description"
        ></textarea>
        <span class="absolute bottom-2 right-2 text-twitch-300"
          >{{ v$.description.$model.replace(/\s/g, '').length }} / {{ v$.description.maxLength.$params.max }}</span
        >
      </div>
      <span v-for="error of v$.description.$errors" :key="error.$uid" class="text-error">
        {{ error.$message }}
      </span>
    </div>

    <!-- ||| Youtube Options |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->
    <div v-if="showYoutubeForm" class="collapse-arrow collapse w-full rounded border border-company-primary-300">
      <input type="checkbox" />
      <div class="collapse-title flex w-full cursor-pointer items-center space-x-2 px-3 py-2">
        <div class="flex w-5 items-center text-youtube-red">
          <youtube-icon class="h-5 w-5 fill-current" />
        </div>
        <p class="mb-0 flex-1 text-company-primary">For Youtube</p>
      </div>
      <div class="collapse-content flex w-full flex-1 flex-col gap-2 px-4 lg:px-8">
        <div class="flex w-full flex-row items-center justify-between gap-8">
          <div class="flex flex-col">
            <span class="label-text mb-0 font-bold text-company-primary">Child friendly?</span>
            <span class="mb-2 text-xs">
              Determine whether your content is suitable for kids according to the
              <a class="text-blue-500" href="https://support.google.com/youtube/answer/9528076?hl=en" target="_blank"
                >YouTube guidelines.
              </a>
            </span>
          </div>
          <ButtonRadioGroup
            v-model="v$.youtube.isChildFriendly.$model"
            :options="[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]"
          />
        </div>
        <hr />
        <div class="flex w-full flex-row items-center justify-between">
          <span class="label-text font-bold text-company-primary">Visibility</span>
          <ButtonRadioGroup
            v-model="v$.youtube.publishPublic.$model"
            :options="[
              { label: 'Public', value: true },
              { label: 'Private', value: false },
            ]"
          />
        </div>
      </div>
    </div>

    <!-- ||| TikTok Options |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->
    <div v-show="showTikTokForm" class="collapse-arrow collapse w-full rounded border border-company-primary-300">
      <input checked="checked" type="checkbox" />
      <div class="collapse-title flex w-full cursor-pointer items-center space-x-2 px-3 py-2 text-gray-800">
        <div class="flex w-5 items-center">
          <tik-tok-icon class="h-5 w-5 fill-current" />
        </div>
        <p class="mb-0 flex-1 text-company-primary">For TikTok</p>
      </div>

      <div class="collapse-content flex w-full flex-1 flex-col gap-2 overflow-visible px-4 lg:px-8">
        <div class="group relative flex w-full flex-col justify-between gap-2 sm:min-h-fit">
          <span class="label-text font-bold text-company-primary">Who can view this video</span>

          <select
            v-model="v$.tikTok.privacyLevel.$model"
            :disabled="availableOptions.privacyLevels.length === 1"
            class="select-bordered select w-full rounded-lg"
          >
            <option class="pb-2" disabled selected value="">Select an option</option>
            <option v-for="label of availableOptions.privacyLevels" :key="label" :value="label">
              {{ toLabel(label) }}
            </option>
          </select>
        </div>

        <template v-if="availableOptions.comments || availableOptions.duets || availableOptions.stitch">
          <hr class="my-2" />

          <span class="label-text font-bold text-company-primary">Allow users to</span>
          <div class="group relative flex flex-col gap-2 sm:min-h-fit sm:flex-row sm:text-left">
            <div class="form-control" v-if="availableOptions.comments">
              <label class="label cursor-pointer justify-start">
                <input v-model="v$.tikTok.allowComments.$model" class="checkbox mr-2" type="checkbox" />
                <span class="label-text">Comment</span>
              </label>
            </div>

            <div class="form-control" v-if="availableOptions.duets">
              <label class="label cursor-pointer justify-start">
                <input v-model="v$.tikTok.allowDuet.$model" class="checkbox mr-2" type="checkbox" />
                <span class="label-text">Duet</span>
              </label>
            </div>

            <div class="form-control" v-if="availableOptions.stitch">
              <label class="label cursor-pointer justify-start">
                <input v-model="v$.tikTok.allowStitch.$model" class="checkbox mr-2" type="checkbox" />
                <span class="label-text">Stitch</span>
              </label>
            </div>
          </div>
        </template>

        <hr class="my-2" />
        <div class="flex w-full flex-row items-center justify-between gap-8">
          <div class="flex flex-col">
            <span class="label-text font-bold text-company-primary">Disclose post content</span>
            <span class="mb-2 text-xs">Let others know this post promotes a brand, product or service.</span>
          </div>
          <input v-model="v$.tikTok.disclosePostContent" type="checkbox" class="toggle-primary toggle" />
        </div>
        <div v-if="v$.tikTok.disclosePostContent" class="">
          <div class="form-control">
            <label class="label cursor-pointer justify-start">
              <input v-model="v$.tikTok.brandOrganicToggle.$model" class="checkbox mr-2" type="checkbox" />
              <span class="label-text">Your brand</span>
            </label>
            <span class="mb-2 text-xs"> You are promoting yourself or your own business. </span>
          </div>

          <div class="form-control">
            <label class="label cursor-pointer justify-start">
              <input v-model="v$.tikTok.brandContentToggle.$model" class="checkbox mr-2" type="checkbox" />
              <span class="label-text">Branded content</span>
            </label>
            <span class="mb-2 text-xs">
              You are in a paid partnership with a brand. After posting the video, open your TikTok mobile app and link
              the campaign under the video’s “Ad settings”.
            </span>
          </div>
        </div>

        <hr class="my-2" />
        <span class="label-text font-bold text-company-primary">Publishing options</span>
        <LightSwitch
          :options="tikTokPublishingOptions"
          v-model:value="tikTokPublishingOption"
          class="text-sm"
        />
      </div>
    </div>

    <div v-if="v$.dateTime.$model" class="flex flex-col items-start">
      <div class="flex flex-col gap-2 md:flex-row">
        <button
          class="rounded border border-company-primary bg-[rgb(248,244,253)] px-2 py-1"
          @click="showTimePickerModal = true"
        >
          Scheduled to <span class="font-bold">{{ format(v$.dateTime.$model, 'PPp') }}</span>
        </button>
        <button class="btn-ghost btn gap-1 text-error" @click="v$.dateTime.$model = null">
          <span>Clear time</span>
          <CrossIcon class="h-4 fill-current" />
        </button>
      </div>
      <span v-for="error of v$.dateTime.$errors" :key="error.$uid" class="text-error">
        {{ error.$message }}
      </span>
    </div>
    <div class="flex-grow"></div>

    <!-- ||| Action Buttons |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->
    <div class="flex flex-col-reverse justify-center gap-2 sm:justify-end md:flex-row">
      <slot />
      <button v-if="!v$.dateTime.$model" class="btn-outline btn-primary btn-lg btn gap-1" @click="openTimePickerModal">
        <span>Pick time</span>
        <Clock class="h-4 fill-current" />
        <DynamicPlanButton :small="true" feature="scheduler" />
      </button>
      <!--      <button v-else @click="v$.dateTime.$model = null" class="btn-ghost btn-lg btn gap-1 text-error">-->
      <!--        <span>Clear time</span>-->
      <!--        <StopwatchAlert class="h-4 fill-current" />-->
      <!--      </button>-->
      <DateTimePickerModal
        v-model="v$.dateTime.$model"
        :show="showTimePickerModal"
        @close="showTimePickerModal = false"
      />
      <button
        :class="{
          loading: isLoading,
        }"
        class="btn-primary btn-lg btn gap-1"
        @click="submit"
      >
        <span v-if="v$.dateTime.$model">Schedule post</span>
        <span v-else>Post now</span>
        <SendIcon class="h-4 fill-current" />
        <DynamicPlanButton :small="true" feature="publish" />
      </button>
    </div>
    <toast></toast>
    <connect-social-media-dialog />
  </div>
</template>
<script lang="ts" setup>
import Clock from '@/components/Icons/Normalized/Clock.vue'
import SendIcon from '@/components/Icons/SendIcon.vue'
import AccountSelect from '@/components/Account/SelectionRow/AccountSelect.vue'
import type { SocialMediaAccount } from '@/store/user/userInfo'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, reactive, ref } from 'vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import { minValue, required, requiredIf, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import type { ConnectionType } from '@/enums/connectionTypes'
import connectionTypes, { mapConnectionTypeToSocialMedia } from '@/enums/connectionTypes'
import {
  maxStrippedLength,
  minStrippedLength,
  noIllegalCharacters,
  maxTrimmedLength,
} from '@/helpers/validators/schedulerValidators'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import ButtonRadioGroup from '@/components-v2/data-input/ButtonRadioGroup.vue'
import DateTimePickerModal from '@/components-v2/data-input/time/DateTimePickerModal.vue'
import format from 'date-fns/format'
import { useMutation } from '@tanstack/vue-query'
import EventBus from '@/eventBus'
import toastEvents from '@/events/toastEvents'
import Toast from '@/components/Toast/Toast.vue'
import CreateNewAccountRow from '@/components/Account/SelectionRow/CreateNewAccountRow.vue'
import ConnectSocialMediaDialog from '@/components/Dialog/Account/Connections/ConnectSocialMediaDialog.vue'
import { useLocalStorage } from '@/queries/localStorage/useLocalStorage'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue'
import CrossIcon from '@/components/Icons/CrossIcon.vue'
import type { LightSwitchOption } from '@/components-v2/data-input/boolean/LightSwitch.vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import type { PostDto, PrivacyLevel, TargetDto } from '@/apis/streamladder-publisher/model'
import { PrivacyLevel as privacyLevels } from '@/apis/streamladder-publisher/model'
import { useAvailableTiktokOptionsToUser } from '@/Hooks/useAvailableTiktokOptionsToUser'

type FormState = {
  title?: string
  description?: string
  dateTime?: Date
  youtube?: {
    publishPublic?: boolean
    isChildFriendly?: boolean
  }
  tikTok?: {
    allowDuet?: boolean
    allowStitch?: boolean
    allowComments?: boolean
    privacyLevel?: PrivacyLevel
    disclosePostContent: boolean
    isDraft: boolean
    brandContentToggle: boolean
    brandOrganicToggle: boolean
  }
}

const props = defineProps<{
  clipName?: string
  clipUrl?: string
  defaultValues?: FormState & { selectedAccounts?: string[] }
  onSave: (publishAt: Date | null, targets: TargetDto[]) => Promise<PostDto>
}>()

const availablePrivacyLevels = computed(() => {
  // needed to remove options not supported by this user
  // right now just remove this option until we implement the creator_info/query endpoint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { FOLLOWER_OF_CREATOR, ...other } = privacyLevels
  return other
})

// Form state
const state = reactive<FormState>({
  title: props.clipName,
  // description: ' \n Created with #StreamLadder', Leave empty until Facebook approved our app
  description: '',
  dateTime: undefined,
  youtube: {
    publishPublic: true,
    isChildFriendly: false,
  },
  tikTok: {
    allowDuet: false,
    allowStitch: false,
    allowComments: true,
    privacyLevel: privacyLevels.PUBLIC_TO_EVERYONE,
    isDraft: false,
    disclosePostContent: false,
    brandContentToggle: false,
    brandOrganicToggle: false,
  },
  ...props.defaultValues,
})

const userInfo = useUserInfoStore()

const [initialIds, setInitialIds] = useLocalStorage(
  'default_social-media',
  userInfo.allSocials.map((account) => account.id)
)

const selectedIds = ref<string[]>(props.defaultValues?.selectedAccounts || initialIds.value)

// const disablePublish = computed(() => {
//   return selectedIds.value.length === 0 || !props.clipUrl
// })

const accounts = computed(() => {
  return userInfo.allSocials.map((account) => ({
    ...account,
    selected: selectedIds.value.includes(account.id),
  }))
})

const selectedAccounts = computed(() => {
  return accounts.value.filter((account) => account.selected)
})

const toggleAccount = (id: string) => {
  if (selectedIds.value.includes(id)) {
    selectedIds.value.splice(selectedIds.value.indexOf(id), 1)
  } else {
    selectedIds.value.push(id)
  }
}

const showTimePickerModal = ref(false)
const openTimePickerModal = () => {
  if (!canGuardWithPopup('scheduler')) return
  showTimePickerModal.value = true
}

const anyAccountsSelectedOfType = computed(() => (connectionType: ConnectionType) => {
  return selectedAccounts.value.some((account) => account.type === connectionType)
})

const showYoutubeForm = computed(() => anyAccountsSelectedOfType.value(connectionTypes.YOUTUBE))
const showTikTokForm = computed(() => true || anyAccountsSelectedOfType.value(connectionTypes.TIKTOK))
// const showInstagramForm = computed(() => anyAccountsSelectedOfType.value(connectionTypes.INSTAGRAM)

const selectedTiktokAccountIds = computed(() =>
  selectedAccounts.value.filter((a) => a.type === connectionTypes.TIKTOK).map((a) => a.id)
)
const availableOptions = useAvailableTiktokOptionsToUser(selectedTiktokAccountIds)

const rules = {
  title: {
    required: requiredIf(showYoutubeForm),
    minLength: minStrippedLength(3),
    maxLength: maxTrimmedLength(
      100,
      'Whoops! Looks like your title is too long. YouTube Shorts titles must be under 100 characters.'
    ),
    noIllegalCharacters,
  },
  dateTime: { minValue: minValue(new Date()) },
  description: {
    maxLength: maxStrippedLength(2200), // 2200 for TikTok, 5000 for YouTube
    noIllegalCharacters,
  },
  youtube: {
    publishPublic: {},
    isChildFriendly: {},
  },
  tikTok: {
    allowDuet: {},
    allowStitch: {},
    allowComments: {},
    privacyLevel: {
      required,
    },
    brandContentToggle: {},
    brandOrganicToggle: {},
  },
}

const v$ = useVuelidate(rules, state)
const submit = () => {
  if (!canGuardWithPopup('publish')) return
  mutate()
}
const { isLoading, mutate } = useMutation({
  mutationFn: async () => {
    // remove title if it's not used, to prevent validation from
    if (!showYoutubeForm.value) state.title = ''

    const isFormValid = await v$.value.$validate()
    if (!isFormValid) {
      console.log(v$.value.$errors)
      throw new Error('Form is not valid')
    }

    if (selectedAccounts.value.length === 0) {
      throw new Error('Please select at least one account')
    }

    const targets = selectedAccounts.value.map(toTargetDto(state))

    // update local storage
    setInitialIds(selectedIds.value)

    const date = state.dateTime || null
    return props.onSave(date, targets)
  },
  onError: (error: Error) => {
    EventBus.$emit(toastEvents.TOAST_ERROR, {
      title: error.message,
    })
  },
})

const toTargetDto = (state: FormState) => {
  return (account: SocialMediaAccount): TargetDto => ({
    accountId: account.id,
    socialMedia: mapConnectionTypeToSocialMedia(account.type),
    title: state.title?.trim(),
    description: state.description,
    youtubeOptions:
      account.type === connectionTypes.YOUTUBE
        ? {
            visibility: state.youtube?.publishPublic ? 'Public' : 'Private',
            youTubeChildFriendly: state.youtube?.isChildFriendly ?? false,
            category: '',
          }
        : undefined,
    tikTokOptions:
      account.type === connectionTypes.TIKTOK
        ? {
            disableDuet: !state.tikTok?.allowDuet,
            disableStitch: !state.tikTok?.allowStitch,
            disableComment: !state.tikTok?.allowComments,
            privacyLevel: state.tikTok?.privacyLevel,
            videoCoverTimestampMs: 10,
            isDraft: tikTokPublishingOption.value === 'notification',
            brandContentToggle: state.tikTok?.brandContentToggle,
            brandOrganicToggle: state.tikTok?.brandOrganicToggle,
          }
        : undefined,
  })
}

const allowTikTokDirectPublish = computed(() => {
  return !!accounts.value.find(
    (account) => account.type === connectionTypes.TIKTOK && account.scopes.includes('video.publish')
  )
})

type TikTokPublishMode = 'directly' | 'notification'

const tikTokPublishingOption = ref<TikTokPublishMode>(allowTikTokDirectPublish.value ? 'directly' : 'notification')
const tikTokPublishingOptions: LightSwitchOption<TikTokPublishMode>[] = [
  {
    label: 'Auto publish',
    value: 'directly',
    tooltip: allowTikTokDirectPublish.value
      ? "Select 'Auto Publish' to directly publish your video on TikTok without any additional steps"
      : "You didn't give us permission to publish directly to TikTok. Reconnect your account to enable this option.",
    disabled: !allowTikTokDirectPublish.value,
  },
  {
    label: 'Get a Notification',
    value: 'notification',
    tooltip:
      "Choosing 'Get a Notification' will create a reminder in your TikTok app. This allows you to review and make any desired edits in the TikTok app before publishing.",
  },
]

function toLabel(enumValue: PrivacyLevel) {
  const labels = {
    PUBLIC_TO_EVERYONE: 'Everyone',
    FOLLOWER_OF_CREATOR: 'Only your followers',
    MUTUAL_FOLLOW_FRIENDS: 'Mutual follows & friends',
    SELF_ONLY: 'Only yourself',
  } as const satisfies Record<PrivacyLevel, string>
  return labels[enumValue]
}
</script>
