<template>
  <tw-base-dialog :open-event="events.open" :close-event="events.close">
    <template v-slot:header="{ close }">
      <div class="absolute right-4 top-4">
        <div class="cursor-pointer p-2 text-gray-300 hover:text-company-primary" @click="close()">
          <cross-icon class="h-6 w-6 fill-current" />
        </div>
      </div>
    </template>
    <div class="flex w-full flex-col lg:min-h-[900px] lg:flex-row">
      <div class="flex w-full flex-col px-4 py-16 lg:w-1/2 lg:p-12">
        <div class="main-content flex-1">
          <h2 class="mb-2 text-4xl font-bold text-company-primary">Custom style</h2>
          <p class="opacity-70">
            Choose your own font, color scheme and text style to create a unified look for all of your videos.
          </p>

          <stream-ladder-input type="text" v-model="preview" label="Preview" />

          <fonts-select
            v-model="selectedFont"
            dropdown-placeholder="Select your font"
            :has-search="true"
            :data="fonts"
          />

          <div class="mb-4 flex w-full flex-col">
            <span class="mb-2 text-company-primary">Text style</span>

            <div class="min-h-0 overflow-y-auto overflow-x-hidden">
              <div class="grid grid-cols-2 gap-2 pr-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
                <brand-kit-style-wrapper
                  :should-preview-in-clip="false"
                  v-for="sticker in styles"
                  :key="sticker.key"
                  ref="stickerWrappers"
                  :html-content="preview"
                  :sticker="sticker"
                  :primary-color="colors.primary"
                  :secondary-color="colors.secondary"
                  :font-family="selectedFontFamily"
                  :display-actions="false"
                  :editable="false"
                  :selected="selectedStyle.key === sticker.key"
                  :is-text-sticker="true"
                  @stickerPicked="previewSticker"
                />

                <Tile @click="joinDiscord" class="text-indigo-500">
                  <discord-logo class="h-10 w-10 fill-current" />

                  <span>Your suggestion here?</span>
                  <span class="text-xs">Leave your idea in our discord!</span>
                </Tile>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row md:space-x-8">
            <stream-ladder-color v-model="colors.primary" type="color" label="Primary color" />
            <stream-ladder-color v-model="colors.secondary" type="color" label="Secondary color" />
          </div>
        </div>

        <div class="flex flex-col justify-between space-y-2 lg:flex-row lg:space-y-0">
          <button class="btn-outline btn-primary btn-lg btn" @click="back">Back</button>
          <button class="btn-primary btn-lg btn gap-2" @click="next" :class="{ loading: isLoadingNextStep }">
            {{ onNext.label }}
            <gold-plan-button feature="brandkit" :small="true" :can-click="false" />
          </button>
        </div>
      </div>
      <div
        class="flex min-h-[300px] w-full flex-col items-center justify-center rounded-b-xl bg-company-primary-light lg:w-1/2 lg:rounded-b-none lg:rounded-r-xl"
      >
        <div class="flex flex-col">
          <p class="mb-2 text-center font-bold">Style preview</p>
          <div class="flex flex-col rounded-xl border-2 border-gray-300 p-8">
            <component
              :primary-color="this.colors.primary"
              :secondary-color="this.colors.secondary"
              :html-content="preview"
              :font-family="this.selectedFontFamily"
              :is="this.previewComponent"
              :variant="this.selectedStyle.variant"
            />
          </div>
        </div>
      </div>
    </div>
  </tw-base-dialog>
</template>
<script>
import TwBaseDialog from '../TwBaseDialog.vue'
import brandKitEvents from '../../../events/brandKitEvents'
import StreamLadderInput from '../../Form/StreamLadderInput.vue'
import StreamLadderColor from '../../Form/StreamLadderColor.vue'
import FontsSelect from '../../Brandkit/FontsSelect.vue'
import BrandKitStyleWrapper from '../../Brandkit/BrandKitStyleWrapper.vue'
import TextSticker from '../../Stickers/TextSticker.vue'
import GoldPlanButton from '../../Account/Upgrade/GoldPlanButton.vue'
import quotes from '../../../data/quotes'
import EventBus from '../../../eventBus'
import canFeature from '../../../mixins/canFeature'
import upgradeEvents from '../../../events/upgradeEvents'
import textLibrary from '../../Stickers/textLibrary'
import { mapState } from 'pinia'
import CrossIcon from '../../Icons/CrossIcon.vue'
import DiscordLogo from '../../Icons/DiscordLogo.vue'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { useFontsStore } from '@/store/fonts'
import settings from '../../../data/settings'
import Tile from '@/components/Tile.vue'

export default {
  components: {
    Tile,
    DiscordLogo,
    CrossIcon,
    GoldPlanButton,
    BrandKitStyleWrapper,
    FontsSelect,
    StreamLadderInput,
    StreamLadderColor,
    TwBaseDialog,
  },
  mixins: [canFeature],
  data() {
    return {
      mode: 'create',
      editedStyle: null,
      quotes: quotes.quotes,
      onNext: {
        label: 'Save custom style',
      },
      brandKitType: null,
      selectableFonts: [],
      selectedStyle: {},
      preview: '',
      selectedFont: null,
      colors: {
        primary: '#02D9FC',
        secondary: '#333333',
      },
      isLoadingNextStep: false,
      callback: null,
      events: {
        open: brandKitEvents.OPEN_BRAND_KIT_EVENT,
        close: brandKitEvents.CLOSE_BRAND_KIT_EVENT,
      },
    }
  },
  computed: {
    previewComponent() {
      return TextSticker
    },
    selectedFontFamily() {
      return this.selectedFont?.label ?? null
    },
    styles() {
      return textLibrary
    },
    ...mapState(useFontsStore, ['fonts']),
  },
  methods: {
    async next() {
      if (!this.userCan('brandkit')) {
        this.openUpgradeDialog('brandkit', 'gold')
        return
      }

      this.isLoadingNextStep = true

      this.mode === 'create' ? await this.store() : await this.update()

      this.callback?.()
      this.back()
      this.isLoadingNextStep = false
    },
    async store() {
      const userBrandkitStore = useUserBrandkitStore()
      await userBrandkitStore.add({
        primaryColor: this.colors.primary,
        secondaryColor: this.colors.secondary,
        fontFamily: this.selectedFont.label,
        variant: this.selectedStyle.variant,
      })
    },
    async update() {
      const userBrandkitStore = useUserBrandkitStore()
      await userBrandkitStore.edit({
        id: this.editedStyle.id,
        primaryColor: this.colors.primary,
        secondaryColor: this.colors.secondary,
        fontFamily: this.selectedFont.label,
        variant: this.selectedStyle.variant,
      })
    },
    back() {
      EventBus.$emit(this.events.close)
    },
    previewSticker(e) {
      this.selectedStyle = e
    },
    open(data) {
      const fontsStore = useFontsStore()

      this.brandKitType = data.brandKitType
      this.selectedStyle = textLibrary[0]
      this.selectedFont = fontsStore.getFontByLabel('Metropolis')

      if (typeof data === 'object') {
        if (data.onNextLabel) {
          this.onNext.label = data.onNextLabel
        }

        if (data.mode) {
          this.mode = data.mode
        }

        if (data.callback) {
          this.callback = data.callback
        }

        if (data.style) {
          this.mode = 'update'
          this.editedStyle = data.style
          this.colors.primary = data.style.primaryColor
          this.colors.secondary = data.style.secondaryColor
          this.selectedFont = fontsStore.getFontByLabel(data.style.fontFamily)
          this.selectedStyle = textLibrary.find((s) => s.variant === data.style.variant)
          this.onNext.label = 'Update your style'
        }
      }
    },
    resetState() {
      this.mode = 'create'
      this.editedStyle = null
      this.selectedFont = null
      this.colors = {
        primary: '#02D9FC',
        secondary: '#333333',
      }
      this.isLoadingNextStep = false
      this.onNext = {
        label: 'Save custom style',
      }
      this.callback = null
    },
    setLoadingNextStep() {
      this.isLoadingNextStep = false
    },
    joinDiscord() {
      window.open(settings.discordInviteUrl, '_blank')
    },
  },
  mounted() {
    this.preview = this.quotes[Math.floor(Math.random() * this.quotes.length)]
    EventBus.$on(brandKitEvents.OPEN_BRAND_KIT_EVENT, this.open)
    EventBus.$on(brandKitEvents.CLOSE_BRAND_KIT_EVENT, this.resetState)
    EventBus.$on(upgradeEvents.INTERNAL_OPEN_UPGRADE_DIALOG, this.setLoadingNextStep)
  },
  beforeUnmount() {
    EventBus.$off(brandKitEvents.OPEN_BRAND_KIT_EVENT, this.open)
    EventBus.$off(brandKitEvents.CLOSE_BRAND_KIT_EVENT, this.resetState)
    EventBus.$off(upgradeEvents.INTERNAL_OPEN_UPGRADE_DIALOG, this.setLoadingNextStep)
  },
}
</script>
<style lang="scss" scoped></style>
