<template>
  <div ref="stickerContainer" class="stickerContainer">
    <div
      v-for="sticker in selectedStickers"
      v-show="sticker.visible"
      :id="sticker.key"
      :key="sticker.key"
      :ref="sticker.key"
      :class="{
        'active z-[30]': sticker.key === focus?.key,
        'z-[29]': sticker.key !== focus?.key,
      }"
      :style="`transform: translate(${sticker.x * containerWidth}px, ${sticker.y * containerHeight}px) scale(${
        sticker.scale * containerWidth
      })`"
      class="sticker preview-layer absolute"
      data-retain-focus
      style="user-select: none"
    >
      <StickerAnimator
        :animation-style="sticker.animationStyle"
        :animation-time="sticker.animationTime"
        :container-width="containerWidth"
        :sticker="sticker"
      >
        <component
          :is="sticker.component"
          :ref="'comp-' + sticker.key"
          :color="sticker.color"
          :font-family="sticker.fontFamily"
          :html-content="sticker.htmlContent"
          :icon="sticker.icon"
          :imageUrl="sticker.imageUrl"
          :primary-color="sticker.primaryColor"
          :secondary-color="sticker.secondaryColor"
          :sticker-key="sticker.key"
          :variant="sticker.variant"
          @stickerLoaded="onStickerLoaded(sticker)"
          @updateContent="
            (e) => {
              updateStickerContent(sticker, e)
            }
          "
          @updateRect="stickerMoveables[sticker.key].updateRect()"
        />
      </StickerAnimator>
    </div>
  </div>
</template>

<script>
import Moveable from 'moveable'
import EventBus from '../../eventBus'
import stickerLibrary from '../Stickers/stickerLibrary/stickerLibrary'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { v4 as gui } from 'uuid'
import { mapState } from 'pinia'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import StickerAnimator from '@/components/Stickers/StickerAnimator.vue'
import { createDialog } from '@/components-v2/actions/dialog/createDialog'
import AnimationDialog from '@/components/Stickers/AnimationDialog.vue'
import { nextTick, ref } from 'vue'
import logging from '@/logging'
import { useResizeObserver } from '@vueuse/core'

export default {
  components: { StickerAnimator },
  stickerMoveables: {},
  props: {
    containerWidth: {
      type: Number,
      default: 0,
    },
    containerHeight: {
      type: Number,
      default: 0,
    },
    enableSnapping: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedStickers: [],
    }
  },
  mounted() {
    EventBus.$on('editor/stickers/added', this.onStickerAdded)
    EventBus.$on('editor/stickers/removed', this.onStickerRemoved)
    EventBus.$on('editor/stickers/modify-animation', this.onStickerChangeAnimation)

    const editorStickersStore = useEditorStickersStore()
    editorStickersStore.selectedStickers.forEach((sticker) => {
      if (sticker.component == undefined) {
        sticker.component = stickerLibrary.find((x) => x.key === sticker.componentName).component
      }
      sticker.savedSticker = true
      EventBus.$emit('editor/stickers/added', sticker)
    })
  },
  setup() {
    const editorStickersStore = useEditorStickersStore()
    const editorFocusStore = useEditorFocusStore()

    const { reveal } = createDialog(AnimationDialog)

    const stickerMoveables = ref({})

    const stickerContainer = ref()
    useResizeObserver(stickerContainer, () => {
      for (const key in stickerMoveables.value ?? []) {
        stickerMoveables.value[key].verticalGuidelines = [
          0,
          stickerContainer.value.clientWidth / 2,
          stickerContainer.value.clientWidth,
        ]

        stickerMoveables.value[key].horizontalGuidelines = [
          0,
          stickerContainer.value.clientHeight
        ]

        stickerMoveables.value[key].bounds = {
          top: -100,
          left: -100,
          bottom: stickerContainer.value.clientHeight + 100,
          right: stickerContainer.value.clientWidth + 100,
        }

        stickerMoveables.value[key].updateRect()
      }
    })

    return {
      editorStickersStore,
      editorFocusStore,
      reveal,
      stickerContainer,
      stickerMoveables
    }
  },
  computed: {
    ...mapState(useEditorFocusStore, ['focus']),
  },
  methods: {
    async onStickerAdded(sticker) {
      sticker.key = 'sticker-' + gui()
      if (sticker.scale == -1) {
        let defaultScale = 1
        if (sticker.isTextSticker) {
          defaultScale = 1.5
        }
        sticker.scale = defaultScale / this.stickerContainer.clientWidth
      }
      if (sticker.x == -1) {
        // new sticker, push to middle
        sticker.x = 0.25
        sticker.y = 0.3
      }
      this.selectedStickers.push(sticker)
      this.focusSticker(sticker)
      if (sticker.isTextSticker) {
        // set editMode when it's a textsticker
        this.$nextTick(() => {
          this.$refs['comp-' + sticker.key][0].setEditMode()
        })
      }

      this.focusSticker(sticker)
    },
    async onStickerChangeAnimation(sticker) {
      const stickerRef = this.editorStickersStore.selectedStickers.find((x) => x.key === sticker)
      if (stickerRef) {
        const result = await this.reveal({
          sticker: stickerRef,
        })
        if (result.isCanceled) {
          return
        }
        stickerRef.animationTime = result.data

        if (result.data == undefined) {
          logging.trackEvent('TimedSticker Removed', {
            stickerType: stickerRef.componentName,
          })
        } else {
          logging.trackEvent('TimedSticker Added', {
            animationTime: result.data,
            stickerType: stickerRef.componentName,
          })
        }
        await nextTick()

        // Update moveable
        const movable = this.stickerMoveables[sticker]
        movable.updateRect()

        await nextTick()
      }
    },
    onStickerLoaded(sticker) {
      const stickerRef = this.$refs[sticker.key][0]

      let isFullScreen = false
      let targetWidth = this.containerWidth * 0.9
      let targetHeight = this.containerHeight * 0.9

      // Calculate ratio.
      const width = stickerRef.firstChild.naturalWidth
      const height = stickerRef.firstChild.naturalHeight

      const ratio = this.getRatio(width, height)

      if (ratio === '9:16' && width > 200) {
        isFullScreen = true
        targetWidth = this.containerWidth
        targetHeight = this.containerHeight
      }

      const renderedWidth = stickerRef.getBoundingClientRect().width
      const renderedHeight = stickerRef.getBoundingClientRect().height

      if (!sticker.savedSticker && !isFullScreen) {
        // new placed sticker, check if it fits, resize accordingly
        if (renderedWidth >= targetWidth || renderedHeight >= targetHeight) {
          sticker.scale = Math.min(targetWidth / renderedWidth, targetHeight / renderedHeight) * sticker.scale
          // Put sticker in the topleft
          sticker.x = 0.05
          sticker.y = 0.05
        }
      } else if (!sticker.savedSticker && isFullScreen) {
        sticker.x = 0
        sticker.y = 0
        sticker.scale = Math.min(targetWidth / renderedWidth, targetHeight / renderedHeight) * sticker.scale
      }

      this.saveStickers()
      this.$nextTick(() => {
        this.initMoveable(sticker)
      })
    },
    onStickerRemoved(stickerKey) {
      const index = this.selectedStickers.findIndex((x) => x.key == stickerKey)
      const sticker = this.selectedStickers[index]
      sticker.visible = false

      this.stickerMoveables[stickerKey].destroy()
      delete this.stickerMoveables[stickerKey]

      this.selectedStickers.splice(index, 1)
      this.saveStickers()
      const multiIcon = this.tryParseIcons(sticker)

      if (sticker.isTextSticker) {
        this.$trackEvent('Editor TextSticker Removed', {
          Variant: sticker.variant,
          Color: sticker.color,
        })
      } else if (multiIcon) {
        this.$trackEvent('Editor MultiSticker Removed', {
          Color: sticker.color,
          Component: sticker.componentName,
          Socials: multiIcon,
        })
      } else {
        this.$trackEvent('Editor Sticker Removed', {
          Color: sticker.color,
          Component: sticker.componentName,
          Icon: sticker.icon,
        })
      }
    },
    tryParseIcons(sticker) {
      try {
        return JSON.parse(sticker.icon)
      } catch (e) {
        return undefined
      }
    },
    initMoveable(sticker) {
      const stickerTarget = this.$refs[sticker.key]

      const newMoveable = new Moveable(this.stickerContainer, {
        className: 'moveable-sticker',
        target: stickerTarget,
        // If the container is null, the position is fixed. (default: parentElement(document.body))
        container: this.stickerContainer,
        ables: [DeleteButton],
        resizable: false,
        scalable: true,
        rotatable: false,
        warpable: false,
        // Enabling pinchable lets you use events that
        // can be used in draggable, resizable, scalable, and rotateable.
        pinchable: false, // ["resizable", "scalable", "rotatable"]
        origin: false,
        keepRatio: true,
        renderDirections: ['w', 'e'],
        // Resize, Scale Events at edges.
        edge: false,
        throttleDrag: 0,
        throttleResize: 0,
        throttleScale: 0,
        throttleRotate: 0,
        snappable: this.enableSnapping,

        props: {
          deleteButton: true,
          edittextButton: sticker.isTextSticker,
          stickerKey: sticker.key,
          sticker: sticker,
          stickerComponent: this.$refs['comp-' + sticker.key][0],
        },

        snapThreshold: 5,
        snapElement: true,
        snapDirections: { center: true, left: true, right: true, top: true, bottom: true },
        verticalGuidelines: [this.stickerContainer.clientWidth / 2, this.stickerContainer.clientWidth, 0],
        horizontalGuidelines: [0, this.stickerContainer.clientHeight],

        draggable: true,
        bounds: {
          top: -100,
          left: -100,
          bottom: this.stickerContainer.clientHeight + 100,
          right: this.stickerContainer.clientWidth + 100,
        },
      })
      newMoveable.updateTarget()

      newMoveable.on('dragStart', this.focusTarget)
      newMoveable.on('drag', this.handleDragSticker)
      newMoveable.on('dragEnd', this.onDragEnd)
      newMoveable.on('scaleStart', this.focusTarget)
      newMoveable.on('scale', this.onScale)
      newMoveable.on('scaleEnd', this.onScaleEnd)

      this.stickerMoveables[sticker.key] = newMoveable
      this.setVisibility(sticker.key)
    },
    handleDragSticker({ target, transform, translate }) {
      target.style.transform = transform
    },
    onScale(e) {
      const { target, scale, drag } = e

      target.style.transform =
        `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)` + ` scale(${scale[0]})`
      target.style.setProperty('--scale', scale[0])
    },
    focusSticker(sticker) {
      if (sticker) {
        this.editorFocusStore.setFocus(sticker.isTextSticker ? FocusTypes.TEXTSTICKER : FocusTypes.STICKER, sticker.key)
      }
    },
    focusTarget({ target }) {
      this.focusSticker(this.selectedStickers.find((x) => x.key == target.id))
    },
    onDragEnd({ target, lastEvent }) {
      if (lastEvent) {
        const sticker = this.selectedStickers.find((x) => x.key == lastEvent.target.id)

        sticker.x = this.stickerMoveables[sticker.key].getRect().left / this.stickerContainer.clientWidth
        sticker.y = this.stickerMoveables[sticker.key].getRect().top / this.stickerContainer.clientHeight

        this.saveStickers()
      }
      this.focusTarget({ target })
    },
    onScaleEnd({ target, lastEvent }) {
      if (lastEvent) {
        const sticker = this.selectedStickers.find((x) => x.key == lastEvent.target.id)
        sticker.x = this.stickerMoveables[sticker.key].getRect().left / this.stickerContainer.clientWidth
        sticker.y = this.stickerMoveables[sticker.key].getRect().top / this.stickerContainer.clientHeight
        sticker.scale = lastEvent.scale[0] / this.stickerContainer.clientWidth

        this.saveStickers()
      }
      this.focusTarget({ target })
    },
    updateStickerContent(sticker, content) {
      sticker.htmlContent = content
      this.saveStickers()
    },
    saveStickers() {
      const editorStickersStore = useEditorStickersStore()
      editorStickersStore.selectedStickers = this.selectedStickers
    },
    getRatio(w, h) {
      const r = Math.round((w / h + Number.EPSILON) * 100) / 100

      if (r === 0.56) {
        return '9:16'
      }

      return 'other'
    },
    setVisibility(key) {
      const moveable = this.stickerMoveables?.[key]?.getManager()?.controlBox?.getElement()
      if (moveable) {
        if (this.focus?.key !== key) {
          moveable.classList.add('opacity-0', 'pointer-events-none')
        } else {
          moveable.classList.remove('opacity-0', 'pointer-events-none')
        }
      }
    },
  },
  beforeUnmount() {
    EventBus.$off('editor/stickers/added', this.onStickerAdded)
    EventBus.$off('editor/stickers/removed', this.onStickerRemoved)
    EventBus.$on('editor/stickers/modify-animation', this.onStickerChangeAnimation)
  },
  watch: {
    enableSnapping(newValue) {
      for (const key in this.stickerMoveables) {
        this.stickerMoveables[key].snappable = newValue
      }
    },
    focus() {
      for (const key in this.stickerMoveables) {
        this.setVisibility(key)
      }
    },
  },
}

const DeleteButton = {
  name: 'deleteButton',
  props: {
    stickerKey: String,
    animationTime: String,
  },
  events: {},
  render(moveable, React) {
    const rect = moveable.getRect()
    const sticker = moveable.props.sticker
    const timedSticker = moveable.props.sticker.animationTime !== undefined
    return React.createElement(
      'i',
      {
        key: 'moveable-delete-button',
        style: {
          top: `${rect.height - 5}px`,
          left: `${rect.width / 2}px`,
        },
        className:
          'absolute flex flex-row bg-white rounded-full shadow-md cursor-pointer overflow-hidden transform -translate-x-1/2',
      },
      [
        sticker.hasEditableText &&
          React.createElement(
            'i',
            {
              key: 'moveable-edittext-button',
              className: 'moveable-edittext-button pr-2 pl-3 border-r hover:bg-blue-200',
              onClick: () => {
                moveable.props.stickerComponent.setEditMode()
              },
            },
            ''
          ),
        React.createElement(
          'div',
          {
            key: 'moveable-delete-button-icon',
            className:
              'moveable-delete-button-icon border-r px-2 first:pl-3 hover:bg-blue-200' +
              (timedSticker ? ' active' : ' '),
            onClick: () => {
              EventBus.$emit('editor/stickers/modify-animation', moveable.props.stickerKey)
            },
          },
          ''
        ),
        React.createElement(
          'div',
          {
            key: 'moveable-delete-button-text',
            className: 'moveable-delete-button w-8 px-2 pr-3 text-black hover:bg-blue-200',
            onClick: () => {
              EventBus.$emit('editor/stickers/removed', moveable.props.stickerKey)
            },
          },
          ''
        ),
      ]
    )
  },
}
</script>

<style lang="scss" scoped>
.stickerContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.sticker {
  user-select: none;
  //z-index: 30;
  line-height: 0;
  transform-origin: top left;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
</style>

<style lang="scss">
.stickerContainer {
  //.moveable-control-box:hover {
  //  display: block !important;
  //}

  .show-stickers {
    .moveable-control.moveable-direction {
      display: block;
    }
  }

  .moveable-control.moveable-direction {
    margin-top: -10px;
  }

  .moveable-control-box.moveable-sticker .moveable-line.moveable-direction {
    --moveable-color: transparant;
  }

  .moveable-sticker {
    z-index: 30;
  }

  .moveable-sticker .moveable-control.moveable-direction {
    --moveable-color: white;
    width: 20px;
    height: 20px;
  }

  .moveable-control.moveable-direction.moveable-e {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-right: -15px;

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.37769 9.45166L5.28782 5.54152L1.37769 1.63139' stroke='%2347089E' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      margin-left: 2px;
      margin-top: 2px;
    }
  }

  .moveable-control.moveable-direction.moveable-w {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-left: -15px;

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.60864 1.63135L1.69851 5.54148L5.60864 9.45162' stroke='%2347089E' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      margin-left: -2px;
      margin-top: 2px;
    }
  }

  .moveable-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    z-index: 11;
    cursor: pointer;

    &:before {
      padding-top: 1px;
      content: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.72705 1.5484L1.90693 9.36852' stroke='currentColor' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M9.72705 9.36859L1.90693 1.54847' stroke='currentColor' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  .moveable-delete-button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    z-index: 11;
    cursor: pointer;
    color: black;

    &:before {
      padding-top: 5px;
      content: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M7.99991 3.1001C4.81324 3.1001 2.21991 5.69343 2.21991 8.8801C2.21991 12.0668 4.81324 14.6668 7.99991 14.6668C11.1866 14.6668 13.7799 12.0734 13.7799 8.88676C13.7799 5.7001 11.1866 3.1001 7.99991 3.1001ZM8.49991 8.66676C8.49991 8.9401 8.27324 9.16676 7.99991 9.16676C7.72658 9.16676 7.49991 8.9401 7.49991 8.66676V5.33343C7.49991 5.0601 7.72658 4.83343 7.99991 4.83343C8.27324 4.83343 8.49991 5.0601 8.49991 5.33343V8.66676Z" fill="currentColor"/><path d="M9.9266 2.30016H6.07326C5.80659 2.30016 5.59326 2.08683 5.59326 1.82016C5.59326 1.5535 5.80659 1.3335 6.07326 1.3335H9.9266C10.1933 1.3335 10.4066 1.54683 10.4066 1.8135C10.4066 2.08016 10.1933 2.30016 9.9266 2.30016Z" fill="currentColor"/></svg>');
    }

    &.active:before {
      content: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M7.99991 3.1001C4.81324 3.1001 2.21991 5.69343 2.21991 8.8801C2.21991 12.0668 4.81324 14.6668 7.99991 14.6668C11.1866 14.6668 13.7799 12.0734 13.7799 8.88676C13.7799 5.7001 11.1866 3.1001 7.99991 3.1001ZM8.49991 8.66676C8.49991 8.9401 8.27324 9.16676 7.99991 9.16676C7.72658 9.16676 7.49991 8.9401 7.49991 8.66676V5.33343C7.49991 5.0601 7.72658 4.83343 7.99991 4.83343C8.27324 4.83343 8.49991 5.0601 8.49991 5.33343V8.66676Z" fill="%238833ff"/><path d="M9.9266 2.30016H6.07326C5.80659 2.30016 5.59326 2.08683 5.59326 1.82016C5.59326 1.5535 5.80659 1.3335 6.07326 1.3335H9.9266C10.1933 1.3335 10.4066 1.54683 10.4066 1.8135C10.4066 2.08016 10.1933 2.30016 9.9266 2.30016Z" fill="%238833ff"/></svg>');
    }
  }

  .moveable-edittext-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    z-index: 11;
    cursor: pointer;

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z'/%3E%3C/svg%3E");
    }
  }
}
</style>
