<template>
  <button
    :title="!connectionType ? 'Disabled' : account.displayName"
    @click="emit('click')"
    :class="{
      'opacity-50': !selected,
      'opacity-100': selected,
    }"
    class="relative flex w-20 flex-col hover:opacity-100"
  >
    <img
      v-if="account.profileImageUrl"
      :src="account.profileImageUrl"
      alt="PF"
      class="h-12 w-12 select-none rounded-full"
    />
    <img v-else src="/images/svgs/default-user.svg" class="h-12 w-12" alt="default user" />
    <connection-type-icon
      width="12"
      height="12"
      size="w-6 h-6"
      position="absolute bottom-4 right-6"
      :connection-type="connectionType"
    />
    <span
      :class="{
        'scale-100 opacity-100': selected,
        'scale-50 opacity-0': !selected,
      }"
      class="sca absolute -left-2 -top-2 flex h-6 w-6 transform items-center justify-center rounded-full bg-green-400 text-white transition"
    >
      <check-mark-icon width="12" height="12" class="fill-current" />
    </span>
    <span class="mt-2 w-20 truncate whitespace-nowrap text-left text-xs">{{ account.displayName }}</span>
  </button>
</template>

<script lang="ts" setup>
import ConnectionTypeIcon from '../Connections/List/ConnectionTypeIcon.vue'
import CheckMarkIcon from '../../Icons/CheckMarkIcon.vue'
import type { ConnectionType } from '@/enums/connectionTypes'

defineProps<{
  account: {
    id: string
    displayName: string
    profileImageUrl: string
  }
  connectionType: ConnectionType
  selected: boolean
}>()

const emit = defineEmits<{
  (event: 'click'): void
}>()
</script>
