import type { SocialMediaPost } from '@/apis/swagger-api-streamladder'
import { type ComputedRef, type Ref, ref } from 'vue'
import { computed } from 'vue'
import { isFuture } from 'date-fns'
import type { CreatePostDto, PostDto, SocialMedia } from '@/apis/streamladder-publisher/model'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'

export const useSocialMediaPost = (): {
  newPost: Ref<SocialMediaPost> | Ref<undefined>
  post: (data: { data: CreatePostDto }) => Promise<PostDto>
  isScheduled: ComputedRef<boolean>
  socialsPostedTo: ComputedRef<SocialMedia[]>
  isPostedToTikTok: ComputedRef<boolean>
  isPostedToInstagram: ComputedRef<boolean>
  isPostedToYouTube: ComputedRef<boolean>
} => {
  const scheduler = useContentPublisherStore()
  const newPostId = ref<string>()
  const newPost = computed(() => {
    return newPostId.value ? scheduler.entities[newPostId.value] : undefined
  })

  async function post({ data }: { data: CreatePostDto }): Promise<PostDto> {
    const response = await scheduler.createPost(data)
    newPostId.value = response.id!
    return response
  }

  const isScheduled = computed(() => {
    const date = newPost.value?.publishedAt
    if (date == null) return false
    return isFuture(new Date(date))
  })

  const socialsPostedTo = computed(() => {
    return (newPost.value?.targets?.map((account) => account.socialMedia) ?? []) as SocialMedia[]
  })

  const isPostedToTikTok = computed(() => {
    return socialsPostedTo.value.includes('Tiktok')
  })

  const isPostedToInstagram = computed(() => {
    return socialsPostedTo.value.includes('Instagram')
  })

  const isPostedToYouTube = computed(() => {
    return socialsPostedTo.value.includes('Youtube')
  })

  return {
    newPost,
    post,
    socialsPostedTo,
    isScheduled,
    isPostedToTikTok,
    isPostedToInstagram,
    isPostedToYouTube,
  }
}
