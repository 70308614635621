<script lang="ts" setup>
import NavbarApplication from '@/components-v2/navigation/NavbarApplication.vue'
import FooterApplication from '@/components-v2/navigation/FooterApplication.vue'
import TierComparison from '@/components/Tiers/TierComparison.vue'
import Testimonials from '@/components-v2/modules/Testimonials/Testimonials.vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import unwrap from '@/helpers/unwrap'
import plans from '@/data/plans'
import type { Tier, TierLevel } from '@/data/plans'
import TierCard from '@/components/Tiers/TierCard.vue'
import { computed, ref, watch } from 'vue'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { useRoute, useRouter } from 'vue-router'
import { useElementBounding } from '@vueuse/core'
import { useDiscountCode } from '@/Hooks/useDiscountCode'
import { useUserInfoStore } from '@/store/user/userInfo'
import logging from '@/logging'
import { getCurrentCampaign, useCampaignComponent } from '@/data/campaigns'
import AsyncComponent from '@/components/Campaigns/AsyncComponent.vue'
import SocialProofSection from '@/components-v2/modules/SocialProof/SocialProofSection.vue'
import features from '@/assets/features.json'

const { interval, intervals } = useSubscriptionIntervals()

const route = useRoute()
const router = useRouter()
const { error } = usePlanUpgrade()
watch(error, () => {
  if (error.value) {
    router.push({ name: 'Upgrade', hash: '#error', query: route.query })
  }
})

const campaign = getCurrentCampaign()
const CampaignComponent = useCampaignComponent('upgrade-hero')
const DealTextComponent = useCampaignComponent('deal-text')

const discountCode = useDiscountCode()

const userInfo = useUserInfoStore()

const tableHeader = ref<HTMLDivElement>()
const isSticky = computed(() => tableHeaderTop.value === 0)

const { top: tableHeaderTop, height: tableHeaderHeight } = useElementBounding(tableHeader)

logging.trackEvent('SubscriptionPage Opened', { discountCode })

type FeaturesTable = {
  categories: {
    name: string
    features: {
      name: string
      tooltip: string | undefined
      values: Record<TierLevel, boolean | string>
      status: 'none' | 'planned' | 'active'
    }[]
  }[]
}
</script>

<template>
  <div class="bg-[#FBF9F9]">
    <AsyncComponent :is="CampaignComponent || 'div'" class="hero-section relative" class-name="hero-section relative">
      <NavbarApplication />
      <div class="relative -mb-96 px-6 pb-96 pt-14 md:px-16 lg:px-16">
        <component :is="DealTextComponent || 'div'" class="">
          <div class="mx-auto flex max-w-xl flex-col items-center gap-4 lg:max-w-2xl xl:max-w-3xl">
            <div
              class="alert alert-success mb-8 flex items-center gap-4 bg-opacity-50 text-white/95 shadow backdrop-blur"
              v-if="discountCode && userInfo.hasLoadedUserInfo && userInfo.tier === 0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M9 15l6 -6" />
                <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
                <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />
                <path
                  d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"
                />
              </svg>
              <div class="block">
                <p class="inline font-thin">Congratulations! You are eligible for a discount using code&nbsp;</p>
                <code class="inline font-bold">{{ discountCode }}</code
                >.
                <p class="inline font-thin">Your code and discount will be automatically applied at checkout.</p>
              </div>
            </div>
            <h1 class="text-center text-5xl font-bold leading-10 text-slate-50">Plans & Pricing</h1>
            <p class="text-center text-lg font-thin leading-snug text-slate-50">
              StreamLadder is free for anyone to use. However if you want to support the development, you can subscribe
              and in return you'll get some cool extra features
            </p>
          </div>
        </component>
      </div>
    </AsyncComponent>

    <div class="container isolate mx-auto my-12 flex flex-col items-center gap-12">
      <TierComparison variant="opaque" reason="upgrade-page" />
      <div class="flex items-center justify-center">
        <a class="link link-hover leading-3 text-indigo-500" href="#compare">Compare plans</a>
      </div>
      <div v-if="error" id="error" class="alert alert-error max-w-[480px]">
        <div class="flex w-full flex-row gap-4">
          <svg
            class="flex-shrink-0"
            fill="none"
            height="33"
            viewBox="0 0 32 33"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16.5" fill="#FDCECE" r="16" />
            <path
              d="M16 6.5C14.0222 6.5 12.0888 7.08649 10.4443 8.1853C8.79981 9.28412 7.51809 10.8459 6.76121 12.6732C6.00433 14.5004 5.8063 16.5111 6.19215 18.4509C6.578 20.3907 7.53041 22.1725 8.92894 23.5711C10.3275 24.9696 12.1093 25.922 14.0491 26.3078C15.9889 26.6937 17.9996 26.4957 19.8268 25.7388C21.6541 24.9819 23.2159 23.7002 24.3147 22.0557C25.4135 20.4112 26 18.4778 26 16.5C25.9949 13.8494 24.9397 11.3088 23.0655 9.43455C21.1912 7.56028 18.6506 6.50508 16 6.5ZM19.625 19.0288C19.7694 19.1747 19.8505 19.3717 19.8505 19.5769C19.8505 19.7822 19.7694 19.9792 19.625 20.125C19.478 20.2672 19.2814 20.3466 19.0769 20.3466C18.8724 20.3466 18.6759 20.2672 18.5288 20.125L16 17.5865L13.4712 20.125C13.3241 20.2672 13.1276 20.3466 12.9231 20.3466C12.7186 20.3466 12.522 20.2672 12.375 20.125C12.2306 19.9792 12.1495 19.7822 12.1495 19.5769C12.1495 19.3717 12.2306 19.1747 12.375 19.0288L14.9135 16.5L12.375 13.9712C12.2523 13.8217 12.1897 13.632 12.1991 13.4388C12.2086 13.2457 12.2896 13.063 12.4263 12.9263C12.563 12.7896 12.7457 12.7086 12.9388 12.6991C13.132 12.6896 13.3217 12.7523 13.4712 12.875L16 15.4135L18.5288 12.875C18.6783 12.7523 18.868 12.6896 19.0612 12.6991C19.2543 12.7086 19.437 12.7896 19.5737 12.9263C19.7104 13.063 19.7914 13.2457 19.8009 13.4388C19.8104 13.632 19.7477 13.8217 19.625 13.9712L17.0865 16.5L19.625 19.0288Z"
              fill="#F30C0C"
            />
          </svg>
          <div class="w-full text-left">
            <span class="break-words sm:text-lg">Uh oh!</span>
            <p class="w-full max-w-full whitespace-pre-line break-all text-sm sm:break-normal">
              {{ error }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <SocialProofSection />

    <div class="bg-reviews relative flex min-h-[600px] items-center justify-center bg-fuchsia-300">
      <Testimonials class="container mx-auto my-24" />
    </div>

    <div id="compare">
      <div class="container mx-auto my-10 flex min-h-screen flex-col gap-x-4 py-10">
        <div class="my-2 flex flex-wrap items-end">
          <header class="pb-5">
            <h2 class="text-5xl">Compare plans</h2>
            <p class="font-thin">Choose the perfect plan for your needs</p>
          </header>

          <div class="ml-auto flex items-center gap-4 text-sm text-emerald-600">
            <p v-if="interval === 'year'">You are saving 20% 👌</p>
            <span v-else> Save 20% on a yearly plan! </span>

            <LightSwitch
              background="bg-gradient"
              :options="unwrap.values(intervals)"
              class="bg-white"
              v-model:value="interval"
            />
          </div>
        </div>

        <div ref="tableHeader" class="sticky top-0 z-20 grid grid-cols-4 gap-x-8 bg-[#f9f6feaa] py-8 backdrop-blur">
          <div />
          <div v-for="tier in unwrap.values(plans as Record<TierLevel, Tier>)" :key="tier.key">
            <TierCard :compact="isSticky" :tier="tier" class="hidden xl:flex" minimal />
            <div class="flex flex-col items-center gap-2 xl:hidden">
              <h3 class="p-0 text-xl">{{ tier.title }}</h3>
              <span
                v-if="tier.highlighted"
                class="whitespace-nowrap rounded-full bg-emerald-600 px-2 py-0.5 text-sm text-white"
              >
                Most Popular
              </span>
            </div>
          </div>
        </div>

        <div
          v-for="category in (features as FeaturesTable).categories"
          :key="category.name"
          class="grid grid-cols-4 gap-x-8"
        >
          <div
            :style="{ top: tableHeaderHeight - 1 + 'px' }"
            class="sticky z-10 col-span-4 bg-zinc-100 p-4 text-lg font-bold text-indigo-950"
          >
            {{ category.name }}
          </div>

          <template v-for="feature in category.features" :key="feature.name">
            <div class="col-span-4 flex items-center gap-2 p-4 text-indigo-950 md:col-span-1">
              {{ feature.name }}

              <div
                v-if="feature.tooltip"
                class="tooltip relative h-6 w-6 before:z-10 after:z-10"
                :data-tip="feature.tooltip"
              >
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12C21 7.03125 16.9688 3 12 3ZM11.7188 17.25C11.5333 17.25 11.3521 17.195 11.1979 17.092C11.0437 16.989 10.9236 16.8426 10.8526 16.6713C10.7817 16.5 10.7631 16.3115 10.7993 16.1296C10.8354 15.9477 10.9247 15.7807 11.0558 15.6496C11.1869 15.5185 11.354 15.4292 11.5359 15.393C11.7177 15.3568 11.9062 15.3754 12.0775 15.4464C12.2488 15.5173 12.3952 15.6375 12.4983 15.7917C12.6013 15.9458 12.6562 16.1271 12.6562 16.3125C12.6562 16.5611 12.5575 16.7996 12.3817 16.9754C12.2058 17.1512 11.9674 17.25 11.7188 17.25ZM13.2863 12.4688C12.5264 12.9788 12.4219 13.4461 12.4219 13.875C12.4219 14.049 12.3527 14.216 12.2297 14.339C12.1066 14.4621 11.9397 14.5312 11.7656 14.5312C11.5916 14.5312 11.4247 14.4621 11.3016 14.339C11.1785 14.216 11.1094 14.049 11.1094 13.875C11.1094 12.848 11.5819 12.0314 12.5541 11.3784C13.4578 10.7719 13.9688 10.3875 13.9688 9.54234C13.9688 8.96766 13.6406 8.53125 12.9614 8.20828C12.8016 8.13234 12.4458 8.05828 12.008 8.06344C11.4586 8.07047 11.032 8.20172 10.7034 8.46609C10.0837 8.96484 10.0312 9.50766 10.0312 9.51562C10.0271 9.60181 10.006 9.68632 9.96919 9.76435C9.93237 9.84238 9.88054 9.9124 9.81667 9.9704C9.75279 10.0284 9.67811 10.0732 9.5969 10.1024C9.51569 10.1315 9.42954 10.1444 9.34336 10.1402C9.25718 10.1361 9.17266 10.115 9.09463 10.0782C9.0166 10.0414 8.94659 9.98953 8.88859 9.92565C8.83059 9.86177 8.78574 9.7871 8.7566 9.70589C8.72745 9.62468 8.71459 9.53852 8.71875 9.45234C8.72391 9.33844 8.80313 8.31234 9.87984 7.44609C10.4381 6.99703 11.1483 6.76359 11.9892 6.75328C12.5845 6.74625 13.1437 6.84703 13.523 7.02609C14.6578 7.56281 15.2812 8.45766 15.2812 9.54234C15.2812 11.1281 14.2214 11.8402 13.2863 12.4688Z"
                    fill="#CECECE"
                  />
                </svg>
              </div>

              <div
                v-if="feature.status === 'planned'"
                class="inline-block whitespace-nowrap rounded-full bg-emerald-600 px-2 py-1 text-xs"
              >
                <div class="uppercase text-white">Coming soon!</div>
              </div>
            </div>

            <div class="md:hidden" />

            <div v-for="(value, level) in feature.values" :key="level" class="flex items-center justify-center p-4">
              <div v-if="typeof value === 'string'" class="text-zinc-500">{{ value }}</div>
              <svg
                v-else-if="value"
                class="h-5 w-5 rounded-full bg-emerald-600 p-0.5"
                fill="none"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M13.9705 3.93474L6.03384 13.0052L2.02808 8.99942L3.00035 8.02714L5.96686 10.9937L12.9357 3.0293L13.9705 3.93474Z"
                  fill="white"
                  fill-rule="evenodd"
                />
              </svg>
              <svg v-else fill="none" height="3" viewBox="0 0 9 3" width="9" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.51989 0.460227V3H0.661932V0.460227H8.51989Z" fill="#71717A" />
              </svg>
            </div>

            <div class="col-span-4 h-px bg-zinc-200" />
          </template>
        </div>
      </div>
    </div>

    <FooterApplication />
  </div>
</template>

<style lang="scss" scoped>
.bg-reviews {
  background: url('../assets/backdrops/waves-top.svg') top/100vw no-repeat,
    url('../assets/backdrops/waves-bottom.svg') center calc(100% + 1px) / 100vw no-repeat,
    url('../assets/backdrops/vectors.svg') center/min(1280px, 120vw) no-repeat, #fba1fc;
}
</style>
