<template>
  <button class="btn-twitch btn w-full rounded-none" v-bind="$attrs">
    <twitch-icon></twitch-icon>
    <slot></slot>
  </button>
</template>

<script>
import TwitchIcon from '../Icons/SocialMedia/TwitchIcon.vue'
export default {
  components: { TwitchIcon },
}
</script>

<style scoped lang="scss">
button.btn-twitch {
  --twitch-color: rgb(145, 71, 255);
  background-color: var(--twitch-color);
  border: none;
  color: white;

  &:hover {
    background-color: darken(rgb(145, 71, 255), 10%);
  }

  > svg {
    height: 1.5em;
    width: auto;
    margin-right: 1em;
  }

  line-height: 2em;
}
</style>
