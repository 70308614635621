<script setup lang="ts">
import { ref, provide, defineAsyncComponent } from 'vue'
import CustomLayoutsPlayBar from '@/modules/CustomLayouts/CustomLayoutsPlayBar.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import type { Layout, LayoutsStore } from '@/store/entity-system/useLayoutsStore'
import type { Crop, CropsStore } from '@/store/entity-system/useCropsStore'
import { newCrop } from '@/store/entity-system/useCropsStore'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverPortal } from 'radix-vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import useLogin from '@/Hooks/useLogin'
import { canGuardWithPopup, useGuard } from '@/Hooks/useGuard'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import CustomLayoutsGrid from '@/modules/CustomLayouts/CustomLayoutsGrid.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { copyRef } from '@/store/entity-system/_copyRef'
import { selectText } from '@/modules/CustomLayouts/helper/selectText'
import IconSaxEdit2 from '@/components/Icons/iconsax/IconSaxEdit2.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import { snapshotLayoutById } from '@/store/entity-system/actions/snapshotLayoutById'

const props = defineProps<{
  id: string
  layoutsStore: LayoutsStore
  cropsStore: CropsStore
}>()

// eslint-disable-next-line vue/no-setup-props-destructure
const { layoutsStore, cropsStore } = props

const emit = defineEmits<{
  (event: 'save', layout: Layout, crops: Crop[]): void
  (event: 'close'): void
}>()

const editorVideoStore = useEditorVideoStore()

provide('layoutsStore', layoutsStore)
provide('cropsStore', cropsStore)

const layout = layoutsStore.selectById(props.id)
const layoutName = layoutsStore.selectNameById(props.id)

const crops = cropsStore.idsWhereLayoutIdIs(props.id)

function addCrop() {

  if (!editorVideoStore.videoSize) throw new Error('Video size is not set')

  const crop = newCrop(props.id, editorVideoStore.videoSize, crops.value.length)
  cropsStore.createById(crop.id, crop)

  const editorFocusStore = useEditorFocusStore()
  editorFocusStore.setFocus('crop', crop.id)
}

const root = ref<HTMLElement | null>(null)

const userInfoStore = useUserInfoStore()
const { openLoginModal } = useLogin()

const customLayoutsUnlocked = useGuard('custom-layouts')

async function commit() {

  if (!userInfoStore.isLoggedIn) {
    await openLoginModal()
  }

  if (!userInfoStore.isLoggedIn) {
    return
  }

  if (!canGuardWithPopup('custom-layouts')) {
    return
  }

  const snapshot = snapshotLayoutById(layout.value.id, layoutsStore, cropsStore)
  emit('save', snapshot.layout, snapshot.crops)
}

function onKeyDown(event: KeyboardEvent) {
  if (event.key === 'Enter' || event.key === 'Esc') {
    event.preventDefault()
    updateName(event)
    const label = event.target as HTMLLabelElement
    label.blur()
  }
}

function updateName(event: Event) {
  const label = event.target as HTMLLabelElement
  layoutName.value = label.textContent?.trim() ?? 'Custom Layout'
  label.textContent = layout.value.name
}

const EmojiPicker = defineAsyncComponent(() => import('vue3-emoji-picker'))
const emojiPickerOpen = ref(false)

function selectEmoji(emoji: { i: string }) {
  layoutsStore.updateEmojiById(props.id, emoji.i)
  emojiPickerOpen.value = false
}

const label = ref<HTMLSpanElement | null>(null)
const enableSnapping = ref(true)
</script>

<template>
  <article class="modal modal-open z-[0]" data-retain-focus>
    <div class="layer-1 h-screen w-screen max-w-screen max-h-screen modal-box flex md:h-auto md:w-[90vw] md:max-h-[90vh] md:max-w-screen-2xl flex-col gap-6" ref="root">
      <section class="flex flex-col gap-2 relative">
        <header class="text-3xl flex flex-row">
          <PopoverRoot v-model:open="emojiPickerOpen">
            <PopoverTrigger>
              <span class="mr-2 inline-block">
                {{ layout.emoji }}
              </span>
            </PopoverTrigger>

            <PopoverPortal>
              <PopoverContent side="right" :side-offset="2">
                <EmojiPicker :native="true" @select="selectEmoji" />
              </PopoverContent>
            </PopoverPortal>
          </PopoverRoot>
          <span ref="label" contenteditable="true" @blur="updateName" @keydown="onKeyDown">
            {{ layout.name }}
          </span>

          <button @click="selectText(label)" class="hover:bg-zinc-200 p-2 rounded-lg transition-[background-color,transform] active:scale-90 w-8 h-8 flex items-center justify-center">
            <IconSaxEdit2 class="cursor-pointer w-4 h-4" />
          </button>
        </header>

        <p class="max-w-[50ch] font-light opacity-75">
          Click 'Add New Crop' to manually define and refine areas within your clip for a tailored vertical mobile viewing
          viewing experience.
        </p>

        <button @click="emit('close')" class="w-8 h-8 hover:bg-zinc-200 active:scale-90 transition-[background-color,transform] rounded-full flex items-center justify-center absolute top-0 right-0">
          <IconSaxCloseCircle />
        </button>
      </section>

      <CustomLayoutsGrid :id="layout.id" :enable-snapping="enableSnapping" />

      <div class="flex items-center gap-6 justify-center">
        <button
          :class="{ 'pointer-events-none select-none !bg-zinc-200 !text-zinc-600': crops.length >= 10 }"
          class="inline-flex items-center gap-2 rounded bg-primary px-6 py-2 text-white transition-all hover:bg-primary-focus active:scale-90"
          @click.stop="addCrop"
        >
          <IconSaxAdd class="[&>path]:stroke-2" />
          Add new crop
        </button>
        <label class="flex items-center gap-2 cursor-pointer">
          <input type="checkbox" class="toggle toggle-primary" v-model="enableSnapping" />
          <span class="ml-2">Snap to grid</span>
        </label>
      </div>

      <div class="flex items-center gap-4 flex-wrap md:flex-nowrap">
        <CustomLayoutsPlayBar />
        <button class="btn-primary btn-lg btn rounded gap-2 flex-1 whitespace-nowrap flex-nowrap" @click="commit">
          <GoldPlanButton small v-if="!customLayoutsUnlocked" />
          Continue to edit clip
          <IconSaxArrowRight />
        </button>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss"></style>
