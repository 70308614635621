<script lang="ts" setup>
import { ref, onMounted, computed, onUnmounted, inject } from 'vue'
import CropElementToolbar from '@/modules/CustomLayouts/Crop/CropToolbar/CropToolbar.vue'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import { shapes } from '@/modules/CustomLayouts/@data/shapes'
import { type CropsStore } from '@/store/entity-system/useCropsStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { copyRef } from '@/store/entity-system/_copyRef'
import type { Directions } from '@/modules/SLMovable/@types/Movable'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { resizeAspectLockedFromCenter } from '@/modules/SLMovable/helpers/resize/resizeAspectLockedFromCenter'
import { watchPausable } from '@vueuse/core'
import { uniq } from 'lodash-es'
import { selectText } from '@/modules/CustomLayouts/helper/selectText'
import IconSaxEdit2 from '@/components/Icons/iconsax/IconSaxEdit2.vue'
import { useCloneCrop } from '@/modules/CustomLayouts/Crop/useCloneCrop'

const props = defineProps<{
  id: string
  enableSnapping: boolean
}>()

const cropsStore = inject<CropsStore>('cropsStore')!
const crop = cropsStore.selectById(props.id)

const source = ref(copyRef(crop))

const { pause, resume } = watchPausable(
  crop,
  () => (source.value = copyRef(crop)),
  { immediate: true, deep: true })

function start() {
  pause()
  setFocus()
}

function commit() {
  source.value = copyRef(crop)
  resume()
}

const isEditing = ref(false)
function onKeyDown(event: KeyboardEvent) {
  if (event.key === 'Enter' || event.key === 'Esc') {
    event.preventDefault()
    updateLabel()
    event.stopPropagation()
  }
}

const cropLabel = cropsStore.selectLabelById(props.id)
const updateLabel = () => {
  if (!label.value) return
  const newLabel = label.value.textContent?.trim() ?? 'New Crop'
  cropLabel.value = newLabel
  label.value.textContent = newLabel
  isEditing.value = false
}

const videoStore = useEditorVideoStore()

function resize(area: { x: number; y: number; width: number; height: number }, directions: Directions) {

  cropsStore.updateCropAreaById(props.id, area)

  const { videoWidth, videoHeight } = videoStore.videoElement!
  const inputSize = { width: videoWidth, height: videoHeight }
  const outputSize = { width: 1080, height: 1920 }

  const input = {
    width: area.width * inputSize.width,
    height: area.height * inputSize.height,
    x: area.x * inputSize.width,
    y: area.y * inputSize.height,
  }

  const output = {
    width: outputSize.width,
    height: source.value.feedData.height * outputSize.height,
    x: 0,
    y: source.value.feedData.y * outputSize.height,
  }

  const newSize = resizeAspectLockedFromCenter(
    output,
    { x: output.x + output.width, y: output.y + output.height },
    directions,
    { top: 0, left: 0, right: outputSize.width, bottom: outputSize.height },
    input,
    null)

  cropsStore.updateCropFeedDataById(props.id, {
    x: newSize.x / outputSize.width,
    y: newSize.y / outputSize.height,
    width: newSize.width / outputSize.width,
    height: newSize.height / outputSize.height,
  })
}

function moveStart() {
  start()
  cloneCrop.start()
}

function move(area: { x: number; y: number }) {
  cropsStore.updateCropAreaById(props.id, area)
}

function moveEnd() {
  commit()
  cloneCrop.end()
}

const cloneCrop = useCloneCrop(props.id, source, cropsStore)

const shape = computed(() => shapes.find((s) => s.value === crop.value.input.shape))

const editorFocusStore = useEditorFocusStore()
const focused = computed(() => {
  return editorFocusStore.focus && editorFocusStore.focus.type === 'crop' && editorFocusStore.focus.key === props.id
})

function setFocus() {
  editorFocusStore.setFocus('crop', props.id)
}

function handleKeyPress(event: KeyboardEvent) {

  if (!focused.value) return

  if (event.ctrlKey && event.key === 'd') {
    cropsStore.duplicateCropById(props.id)

    event.preventDefault()
    return false
  }

  if (event.ctrlKey || event.metaKey) {
    if (event.key === '}') {
      cropsStore.moveToForeground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === '{') {
      cropsStore.moveToBackground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === ']') {
      cropsStore.shift(props.id, +1)

      event.preventDefault()
      return false
    } else if (event.key === '[') {
      cropsStore.shift(props.id, -1)

      event.preventDefault()
      return false
    }
  }

  if (event.key === 'Backspace' || event.key === 'Delete') {

    if (crops.value.length > 1) {
      cropsStore.removeById(props.id)
      setTimeout(() => {
        const otherCrop = cropsStore.ids.find((id) => id !== props.id)
        if (otherCrop) {
          editorFocusStore.setFocus('crop', otherCrop)
        }
      }, 0)

      event.preventDefault()
      return false
    }
  }

  if (event.key === 'Escape') {
    editorFocusStore.unFocus()

    event.preventDefault()
    return false
  }
}

const crops = cropsStore.whereLayoutIdIs(crop.value.layoutId)

const otherCropsX = computed(() => {
  return crops.value
    .filter(c => c.id !== crop.value.id)
     .flatMap(crop => [crop.x, crop.x + crop.width])
})

const otherCropsY = computed(() => {
  return crops.value
    .filter(c => c.id !== crop.value.id)
    .flatMap(crop => [crop.y, crop.y + crop.height])
})

const snapGuidelines = computed(() => {
  return {
    x: uniq([0.5, ...otherCropsX.value]),
    y: uniq([0.5, ...otherCropsY.value]),
  }
})

onMounted(() => {
  window.addEventListener('keypress', handleKeyPress)
  window.addEventListener('keydown', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keypress', handleKeyPress)
  window.removeEventListener('keydown', handleKeyPress)
})

const label = ref<HTMLSpanElement | null>(null)
function edit() {
  isEditing.value = true
  selectText(label.value)
}
</script>

<template>
  <div
    :class="{
      'rounded-[100%]': crop.input.shape === 'circle',
      'z-[250] opacity-100': focused,
      'opacity-50 hover:opacity-75': !focused,
    }"
    :style="{
      left: crop.x * 100 + '%',
      top: crop.y * 100 + '%',
      width: crop.width * 100 + '%',
      height: crop.height * 100 + '%',
    }"
    class="absolute transition-opacity will-change-[opacity,contents] group"
    @mousedown.stop="setFocus"
    @click.stop
  >
    <MovableElement
      move
      resize
      :local="crop"
      :source="source"
      :shape="crop.input.shape === 'circle' ? 'circle' : 'rectangle'"
      :aspectLock="shape?.aspectLock"
      :bounds="{ top: 0, right: 1, bottom: 1, left: 0 }"
      :snap="enableSnapping ? snapGuidelines : null"
      :min-size="50"
      @resize-start="start"
      @resize="resize"
      @resize-end="commit"
      @move-start="moveStart"
      @move="move"
      @move-end="moveEnd"
    >
      <template #move>
        <div
          class="absolute inset-0 border-2"
          :class="{ 'rounded-full': crop.input.shape === 'circle' }"
          :style="{ borderColor: crop.input.color }"
        />
      </template>
      <template #resize-direction>
        <div :style="{ backgroundColor: focused ? crop.input.color : 'transparent' }" class="h-3 w-3" />
      </template>
    </MovableElement>

    <div class="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 flex flex-nowrap rounded"
      :style="{ backgroundColor: crop.input.color }"
    >
      <span
        ref="label"
        class="select-all whitespace-nowrap px-2 py-1 text-xs text-black/80"
        :contenteditable="isEditing"
        @dblclick="isEditing = true"
        @blur="updateLabel"
        @keydown.stop="onKeyDown"
      >
        {{ cropLabel }}
      </span>

      <button
        @click.stop="edit"
        class="hover:bg-zinc-900/20 -ml-1 p-1 rounded-r transition-[color,transform] active:scale-90 w-6 h-6  items-center justify-center"
        :class="{
          'flex': focused,
          'hidden group-hover:flex': !focused,
        }"
      >
        <IconSaxEdit2 class="cursor-pointer w-3.5 h-3.5" />
      </button>
    </div>

    <CropElementToolbar :id="id" v-if="focused" />
  </div>
</template>

<style scoped lang="scss"></style>
