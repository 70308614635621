<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useEventListener } from '@vueuse/core'
import { useGesture } from '@vueuse/gesture'
import type { FullGestureState } from '@vueuse/gesture'
import { clamp } from 'lodash-es'
import compatabilityChecker from '@/services/compatabilityChecker'

const {
  containerEl,
  containerWidth,
  pxToMs,
  triggerSeek,
  dragging: timelineDragging,
  durationMs,
  _autoScroll,
} = useTimelineStore()

const isMobile = compatabilityChecker.isMobile()

const handleGesture = (gestureState: FullGestureState<'drag'> & FullGestureState<'scroll'>) => {
  const {
    scrolling,
    xy: [x],
    tap,
    dragging,
  } = gestureState

  if (tap) {
    _autoScroll.value = 'idle'
    if (timelineDragging.value) timelineDragging.value = undefined
    triggerSeek(clamp(pxToMs(x + containerEl.value?.scrollLeft), 0, durationMs.value))
    return
  }

  if (scrolling) {
    if (_autoScroll.value == 'seeking') {
      _autoScroll.value = 'idle'
    } else {
      _autoScroll.value = 'disabled'
    }
  }

  if (dragging) {
    _autoScroll.value = 'disabled'
    if (!(timelineDragging.value?.type === 'node') && !isMobile) {
      triggerSeek(clamp(pxToMs(x + containerEl.value?.scrollLeft), 0, durationMs.value), true)
      timelineDragging.value = {
        type: 'seeker',
      }
    }
  } else {
    if (timelineDragging.value?.type === 'seeker' && !isMobile) {
      timelineDragging.value = undefined
      triggerSeek(clamp(pxToMs(x + containerEl.value?.scrollLeft), 0, durationMs.value))
    }
  }
}

useGesture(
  {
    onDrag: handleGesture,
    onScroll: handleGesture,
  },
  {
    // Global options such as `domTarget`
    domTarget: containerEl,
    // Gesture specific options
    drag: {},
    // wheel: wheelOptions,
    // pinch: pinchOptions,
    // scroll: scrollOptions,
    // wheel: wheelOptions,
    // hover: hoverOptions,
  }
)

// useEventListener(
//   document,
//   'touchmove',
//   (evt) => {
//     if (timelineDragging.value) {
//       evt.preventDefault()
//     }
//   },
//   {
//     passive: false,
//   }
// )
</script>

<template>
  <div
    ref="containerEl"
    class="timeline-container relative overflow-auto"
    :style="{
      touchAction: timelineDragging?.type === 'node' ? 'none' : undefined,
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.timeline-container::-webkit-scrollbar {
  height: 7px !important;
}

.timeline-container::-webkit-scrollbar-track {
  background: rgba(70, 21, 128, 0.15);
  border-radius: 12px;
}

.timeline-container::-webkit-scrollbar-thumb {
  background: #461580;
  border-radius: 12px;
}
</style>
