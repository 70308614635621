<template>
  <div
    @click.self="close"
    :class="{
      'modal-open': show,
    }"
    class="modal"
  >
    <div class="modal-box flex flex-col items-center gap-2 sm:w-auto">
      <FlatPicker
        v-model="date"
        :config="{
          altInput: true,
          altInputClass: 'hidden',
          inline: true,
          static: true,
          enableTime: false,
          dateFormat: 'Y-m-d',
          time_24hr: true,
          minDate: 'today',
          maxDate: addMonths(new Date(), 2),
          locale: {
            firstDayOfWeek: 1,
          },
        }"
        class="form-control"
        placeholder="Select date"
        name="date"
      >
      </FlatPicker>
      <hr class="bg-black" />
      <div class="flex flex-col items-center gap-2">
        <span>Quick action</span>
        <div class="flex flex-row justify-around gap-4">
          <button @click="selectRandomTime(7, 12, 'morning')" class="flex flex-col items-center gap-1">
            <MorningBold v-if="DayTime === 'morning'" class="h-8 w-8" />
            <MorningLine v-else class="h-8 w-8" />
            <span>Morning</span>
          </button>
          <button @click="selectRandomTime(12, 16, 'noon')" class="flex flex-col items-center gap-1">
            <SunBold v-if="DayTime === 'noon'" class="h-8 w-8" />
            <SunLine v-else class="h-8 w-8" />
            <span>Noon</span>
          </button>
          <button @click="selectRandomTime(16, 19, 'afternoon')" class="flex flex-col items-center gap-1">
            <AfternoonBold v-if="DayTime === 'afternoon'" class="h-8 w-8" />
            <AfternoonLine v-else class="h-8 w-8" />
            <span>Afternoon</span>
          </button>
          <button @click="selectRandomTime(19, 23, 'evening')" class="flex flex-col items-center gap-1">
            <MoonBold v-if="DayTime === 'evening'" class="m-1 h-6 w-6" />
            <MoonLine v-else class="m-1 h-6 w-6" />
            <span>Evening</span>
          </button>
        </div>
      </div>
      <hr />
      <div class="form-control flex flex-row justify-center gap-2">
        Time
        <input v-model="time" @change="DayTime = undefined" type="time" class="input-primary pl-2" />
      </div>
      <div class="modal-action">
        <button @click="close" class="btn-outline btn-primary btn">Cancel</button>
        <button @click="pickDateTime" class="btn-primary btn">Pick time</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import 'flatpickr/dist/flatpickr.css'
import { addMonths, format } from 'date-fns'
import MorningLine from '@/components/Icons/Normalized/DaytimeIndicators/MorningLine.vue'
import SunLine from '@/components/Icons/Normalized/DaytimeIndicators/SunLine.vue'
import MoonLine from '@/components/Icons/Normalized/DaytimeIndicators/MoonLine.vue'
import AfternoonLine from '@/components/Icons/Normalized/DaytimeIndicators/AfternoonLine.vue'
import MorningBold from '@/components/Icons/Normalized/DaytimeIndicators/MorningBold.vue'
import SunBold from '@/components/Icons/Normalized/DaytimeIndicators/SunBold.vue'
import AfternoonBold from '@/components/Icons/Normalized/DaytimeIndicators/AfternoonBold.vue'
import MoonBold from '@/components/Icons/Normalized/DaytimeIndicators/MoonBold.vue'

const FlatPicker = defineAsyncComponent(() => import('vue-flatpickr-component'))

const props = defineProps<{
  modelValue?: Date
  show: boolean
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: Date | undefined): void
  (e: 'close'): void
}>()

const date = ref<string | null>(null)
const time = ref<string>()
const DayTime = ref<string>()

const dateTime = computed(() => {
  if (!date.value) return undefined
  return new Date(`${date.value}T${time.value}`)
})

watch(
  () => props.show,
  (value, oldValue) => {
    if (value && !oldValue) {
      if (!props.modelValue) {
        date.value = format(new Date(), 'yyyy-MM-dd')
        time.value = format(new Date(), 'HH:mm')
        return
      }
      date.value = format(props.modelValue, 'yyyy-MM-dd')
      time.value = format(props.modelValue, 'HH:mm')
    }
  }
)

const selectRandomTime = (from: number, to: number, zone: string) => {
  DayTime.value = zone
  const hours = Math.floor(Math.random() * (to - from + 1) + from)
  const minutes = Math.floor(Math.random() * 60)
  time.value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

const pickDateTime = () => {
  emit('update:modelValue', dateTime.value)
  emit('close')
}

const close = () => {
  emit('close')
}
</script>
