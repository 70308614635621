<template>
  <section class="flex flex-col gap-4">
    <section class="flex h-full min-h-0 flex-col gap-4">
      <div class="layer-2 flex h-full flex-col rounded-lg border-2 border-gray-100 bg-white">
        <div v-if="canCustomText" class="p-4">
          <p>
            <strong>Custom Text</strong>
          </p>
          <p>Add text to your clips and capture your audience's attention.</p>
        </div>
        <div v-else class="p-4">
          <p>
            <strong>Custom Text</strong>
          </p>
          <p>Upgrade to add text to your clips and capture your audience's attention.</p>
        </div>

        <template v-if="!loadingBrandkit">
          <div
            class="grid min-h-0 gap-2 overflow-auto p-4"
            :class="hasThreeColumnLayout ? 'grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))]' : 'grid-cols-2'"
          >
            <Tile @click="openBrandKitModal" feature="brandkit">
              <IconSquareRoundedPlus />
              <span>Custom style</span>
            </Tile>

            <brand-kit-style-wrapper
              v-for="sticker in brandKitStyles"
              :key="sticker.id"
              ref="stickerWrappers"
              :sticker="sticker"
              :editable="false"
              :display-actions="true"
              :is-text-sticker="true"
              :has-editable-text="true"
              :should-preview-in-clip="true"
            />

            <sticker-wrapper
              v-for="sticker in textLibrary"
              :key="sticker.key"
              ref="stickerWrappers"
              :sticker="sticker"
              :is-new="isNewText(sticker)"
              :has-editable-text="true"
              :editable="false"
              :is-text-sticker="true"
              @stickerPicked="onStickerPicked(sticker)"
            />
          </div>
        </template>
        <div v-else class="flex flex-col items-center p-4">
          <lottie-animation class="w-1/4 grayscale" url="/lottie/hour-glass-loading.json" :auto-play="true" />
          <p>Loading stickers & styles...</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import StickerWrapper from './StickerWrapper.vue'
import textLibrary from './textLibrary'
import EventBus from '../../eventBus'
import brandKitEvents from '../../events/brandKitEvents'
import brandkitStyleTypes from '../../enums/brandkitStyleTypes'
import BrandKitStyleWrapper from '../Brandkit/BrandKitStyleWrapper.vue'
import { mapState } from 'pinia'
import LottieAnimation from '../LottieAnimation.vue'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { useGuard } from '@/Hooks/useGuard'
import { IconSquareRoundedPlus } from '@tabler/icons-vue'
import Tile from '@/components/Tile.vue'
import { isNewText, markTextAsUsed, newestTextFirst } from '@/helpers/isNewOverlayElement'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'

export default {
  components: {
    Tile,
    IconSquareRoundedPlus,
    BrandKitStyleWrapper,
    StickerWrapper,
    LottieAnimation,
  },
  data() {
    return {
      stickerText: '',
      textLibrary: textLibrary.sort(newestTextFirst),
    }
  },
  setup() {
    const canCustomText = useGuard('text')
    return {
      canCustomText,
      hasThreeColumnLayout: useFeatureFlagVariantEnabled('three-column-layout', 'wide'),
    }
  },
  watch: {
    stickerText(newValue) {
      if (newValue.length > 0) {
        this.rescaleStickers()
      }
    },
  },
  async created() {},
  computed: {
    ...mapState(useUserBrandkitStore, ['brandKitStyles', 'loadingBrandkit']),
  },
  methods: {
    isNewText,
    openBrandKitModal() {
      EventBus.$emit(brandKitEvents.OPEN_BRAND_KIT_EVENT, {
        brandKitType: brandkitStyleTypes.STICKERS,
      })
    },
    rescaleStickers() {
      if (!this.$refs.stickerWrappers) {
        return
      }
      this.$refs.stickerWrappers.forEach((stickerWrapper) => {
        stickerWrapper.scaleSticker()
      })
    },
    onStickerPicked(sticker) {
      this.$emit('stickerPicked')
      markTextAsUsed(sticker)
    },
  },
}
</script>

<style lang="scss" scoped>
.change-sticker-text-button {
  cursor: pointer;
  user-select: none;

  svg {
    transform: rotate(90deg);
  }

  &.open {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.sticker-filter {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  .filter {
    font-size: 0.8em;
    padding: 0.3em 0.8em;
    border: 2px solid rgb(71, 8, 158, 0.2);
    border-radius: 40px;
    color: rgb(71, 8, 158, 0.6);

    &.active {
      background-color: #33cff2;
      color: white;
      border-color: #33cff2;
    }
  }
}
</style>
