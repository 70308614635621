<template>
  <component
    v-for="dialogData in DialogsStore"
    :is="dialogData.dialog"
    v-bind="dialogData.props"
    @confirm="dialogData.confirm"
    @cancel="dialogData.cancel"
    :key="dialogData.id"
  />
</template>

<script lang="ts" setup>
import { useDialogStore } from '@/components-v2/actions/dialog/useDialogStore'

const { DialogsStore } = useDialogStore()
</script>
