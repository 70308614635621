<template>
  <div
    class="preview__phone"
    :style="{
      '--scale': scale,
    }"
  >
    <div class="preview__screen-wrapper">
      <div class="preview__screen">
        <div class="preview__content">
          <slot></slot>
        </div>
        <div class="preview__indicator preview__indicator--time">
          {{ time }}
        </div>
        <div class="preview__indicator preview__indicator--signal">
          <i class="fa fa-signal">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.17em" height="1em" viewBox="0 0 1792 1536">
              <path
                fill="currentColor"
                d="M256 1312v192q0 14-9 23t-23 9H32q-14 0-23-9t-9-23v-192q0-14 9-23t23-9h192q14 0 23 9t9 23zm384-128v320q0 14-9 23t-23 9H416q-14 0-23-9t-9-23v-320q0-14 9-23t23-9h192q14 0 23 9t9 23zm384-256v576q0 14-9 23t-23 9H800q-14 0-23-9t-9-23V928q0-14 9-23t23-9h192q14 0 23 9t9 23zm384-384v960q0 14-9 23t-23 9h-192q-14 0-23-9t-9-23V544q0-14 9-23t23-9h192q14 0 23 9t9 23zm384-512v1472q0 14-9 23t-23 9h-192q-14 0-23-9t-9-23V32q0-14 9-23t23-9h192q14 0 23 9t9 23z"
              />
            </svg>
          </i>
        </div>
        <div class="preview__indicator preview__indicator--battery">
          <i class="fa fa-battery">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1em" viewBox="0 0 2304 1280">
              <path
                fill="currentColor"
                d="M1920 256v768H256V256h1664zm128 576h128V448h-128V160q0-14-9-23t-23-9H160q-14 0-23 9t-9 23v960q0 14 9 23t23 9h1856q14 0 23-9t9-23V832zm256-384v384q0 53-37.5 90.5T2176 960v160q0 66-47 113t-113 47H160q-66 0-113-47T0 1120V160Q0 94 47 47T160 0h1856q66 0 113 47t47 113v160q53 0 90.5 37.5T2304 448z"
              />
            </svg>
          </i>
        </div>
      </div>
      <div class="preview__notch"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customScale: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: null,
    },
    innerWidth: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      time: '00:00',
      timer: null,
    }
  },
  computed: {
    scale() {
      if (this.customScale) return undefined
      if (this.width !== null) return (1125 + 48 * 2) / this.width
      if (this.innerWidth !== null) return 1125 / this.innerWidth
      return 4
    },
  },
  methods: {
    setDateTime() {
      const date = new Date()
      const hours = date.getHours()
      const minutes = date.getMinutes().toString().padStart(2, '0')
      this.time = `${hours}:${minutes}`
    },
  },
  beforeMount() {
    this.timer = setInterval(this.setDateTime, 30 * 1000)
    this.setDateTime()
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.preview__phone {
  --resX: 1125px;
  --resY: 2264px;
  --notch-height: 90px;
  --notch-width: 627px;
  --notch-border-radius: 60px;
  --bezels: 48px;
  --screen-border-radius: 120px;
  --phone-border-radius: 160px;
  --scale: 4;
  --indicator-top-margin: 10px;
  --margins: 36px;

  --iphone-color: #120d30;
  --notch-color: var(--iphone-color);
  --screen-background: #4e2c60;
  --indicators-color: white;
  --background: #feca45;

  position: relative;

  width: calc((var(--resX) + var(--bezels) * 2) / var(--scale));
  height: calc((var(--resY) + var(--bezels) * 2) / var(--scale));
  padding-top: 1px;

  background-color: var(--iphone-color);
  border-radius: calc(var(--phone-border-radius) / var(--scale));
}

.preview__notch {
  position: absolute;
  top: 0;
  left: calc(((var(--resX) - var(--notch-width)) / 2) / var(--scale));

  height: calc(var(--notch-height) / var(--scale));
  width: calc(var(--notch-width) / var(--scale));

  background-color: var(--notch-color);

  border-radius: 0 0 calc(var(--notch-border-radius) / var(--scale)) calc(var(--notch-border-radius) / var(--scale));
}

.preview__screen {
  position: relative;

  height: calc(var(--resY) / var(--scale));
  width: calc(var(--resX) / var(--scale));

  padding-top: calc(100px / var(--scale));

  background: var(--screen-background);

  border-radius: calc(var(--screen-border-radius) / var(--scale));
  user-select: none;
  perspective: 700px;
}

.preview__content {
  position: absolute;
  height: calc(var(--resY) / var(--scale));
  width: calc(var(--resX) / var(--scale));
}

.preview__screen-wrapper {
  position: relative;

  height: calc(var(--resY) / var(--scale));
  width: calc(var(--resX) / var(--scale));
  margin-top: calc(var(--bezels) / var(--scale) - 1px);
  margin-left: calc(var(--bezels) / var(--scale) - 1px);
  overflow: hidden;

  border-radius: calc(var(--screen-border-radius) / var(--scale));
}

.preview__indicator {
  position: absolute;
  top: calc(var(--indicator-top-margin) / var(--scale));
  font-size: calc(54px / var(--scale));
  color: var(--indicators-color);
}

.preview__indicator--time {
  left: calc(75px / var(--scale));
}

.preview__indicator--signal {
  right: calc(180px / var(--scale));
}

.preview__indicator--battery {
  font-size: calc(57px / var(--scale));
  right: calc(66px / var(--scale));
}
</style>
