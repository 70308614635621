<script setup lang="ts">
import { useLayoutsStore, type Layout } from '@/store/entity-system/useLayoutsStore'
import { PopoverRoot, PopoverTrigger, PopoverContent } from 'radix-vue'
import { IconTrash, IconDotsVertical, IconEdit } from '@tabler/icons-vue'
import { ref } from 'vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import EditCustomLayoutsModal from '@/modules/CustomLayouts/EditCustomLayoutsModal.vue'
import NewCustomLayoutsModal from '@/modules/CustomLayouts/NewCustomLayoutsModal.vue'
import type { Crop } from '@/store/entity-system/useCropsStore'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { updateCropsAndLayout } from '@/store/entity-system/actions/updateCropsAndLayout'

const editorVideoStore = useEditorVideoStore()

const layoutsStore = useLayoutsStore()
const customLayouts = layoutsStore.selectCustomLayouts()

const editDialogId = ref<string | null>(null)
function editLayout(layout: Layout) {
  editDialogId.value = layout.id
}

function deleteLayout(layout: Layout) {
  if (confirm('Are you sure you want to delete this layout?')) {
    layoutsStore.removeById(layout.id)
  }
}

const newCustomLayoutModalOpen = ref(false)

function saveNewTemplate(layout: Layout, crops: Crop[]) {
  updateCropsAndLayout(layout, crops)
  newCustomLayoutModalOpen.value = false
  setTimeout(() => emit('save', layout.id), 0)
}

function updateExistingTemplate(layout: Layout, crops: Crop[]) {
  updateCropsAndLayout(layout, crops)
  editDialogId.value = null
  setTimeout(() => emit('save', layout.id), 0)
}

const props = defineProps<{
  selected: string | null | undefined
}>()

const emit = defineEmits<{
  (event: 'select', id: string): void
  (event: 'save', id: string): void
}>()
</script>

<template>
  <button
    :disabled="!editorVideoStore.videoElement"
    @click="newCustomLayoutModalOpen = true"
    class="aspect-[202/373] relative flex h-full w-full cursor-pointer flex-col items-center justify-center gap-1 rounded-xl border-2 border-[#f9f6fe] p-4 transition-[border-color,_transform] hover:border-company-secondary-200 active:scale-90"
  >
    <span class="grid h-6 w-6 place-items-center rounded-full bg-primary text-white">
      <IconSaxAdd :size="16" class="[&>path]:stroke-[1]" />
    </span>
    <span class="text-sm font-semibold">Custom Layout</span>
    <GoldPlanButton class="absolute top-2 right-2" small />
  </button>
  <article
    v-for="layout in [...customLayouts].reverse()"
    :key="layout.id"
    class="relative h-full w-full cursor-pointer rounded-xl border-2 p-4"
    :class="{
      'border-2 border-[#6523c2]': layout.id === props.selected,
      'border-transparent hover:border-company-secondary-200': layout.id !== props.selected,
    }"
    :style="{ backgroundColor: layout.color }"
    @click="emit('select', layout.id)"
  >
    <h3 class="text-2xl font-bold text-gray-950">
      <span class="block text-4xl">{{ layout.emoji }}</span>
      {{ layout.name }}
    </h3>
    <div class="absolute bottom-2 right-2" @click.stop>
      <PopoverRoot>
        <PopoverTrigger>
          <button class="btn-ghost btn-square btn grid h-8 min-h-0 w-8 place-items-center rounded text-gray-950">
            <IconDotsVertical :size="20" />
          </button>
        </PopoverTrigger>
        <PopoverContent>
          <Transition appear name="fade">
            <ol class="layer-2 flex flex-col rounded-xl shadow-lg transition-all" tabindex="0">
              <li>
                <button
                  @click="editLayout(layout)"
                  class="flex w-full justify-start gap-2 whitespace-nowrap rounded-t-xl px-4 py-2 text-left hover:bg-zinc-200 dark:hover:bg-zinc-900"
                >
                  <IconEdit /> Edit
                </button>
              </li>
              <li>
                <button
                  class="flex w-full items-center justify-start gap-2 whitespace-nowrap rounded-b-xl px-4 py-2 text-left text-rose-500 hover:bg-rose-500 hover:text-white"
                  @click="deleteLayout(layout)"
                >
                  <IconTrash /> Delete
                </button>
              </li>
            </ol>
          </Transition>
        </PopoverContent>
      </PopoverRoot>
    </div>
  </article>

  <Teleport to="body">
    <EditCustomLayoutsModal v-if="editDialogId" :id="editDialogId" @close="editDialogId = null" @save="updateExistingTemplate" />
    <NewCustomLayoutsModal v-if="newCustomLayoutModalOpen" @close="newCustomLayoutModalOpen = false" @save="saveNewTemplate" />
  </Teleport>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}

.fade-enter-from,
.fade-leave {
  opacity: 0;
}
</style>
