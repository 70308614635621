<template>
  <div class="w-full">
    <iphone14 class="mx-auto" :width="width" :style="{ '--resY': '2090px' }">
      <slot></slot>
    </iphone14>
  </div>
</template>

<script>
import Iphone14 from '@/components/Phones/iphone14.vue'
import { observe, unobserve } from '@/helpers/ObserverHelper'
import { clamp } from '@/components/Editor/Timeline/helpers'

export default {
  components: { Iphone14 },
  data() {
    return {
      width: 300,
    }
  },
  mounted() {
    this.width = this.$el.clientWidth
    observe(this.$el, () => {
      this.width = clamp(this.$el.clientWidth, 100, 380)
    })
  },
  beforeUnmount() {
    unobserve(this.$el)
  },
}
</script>

<style lang="scss" scoped></style>
