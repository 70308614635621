import type { Ref } from 'vue'
import { ref, watch } from 'vue'
import Decoder from '@/modules/AudioAnalyser/decoder'
import { extractRegionsByRMS2 } from '@/components/Editor/RemoveParts/useWaveFormAnalysis'

export const useAudioAnalyzerForVideoElement = (
  src: Ref<string>,
  options?: {
    decodedData?: AudioBuffer
  }
) => {
  const blob = ref<Blob>()
  const decodedData = ref<AudioBuffer | undefined>(options?.decodedData)
  const isLoading = ref(false)
  const loadingProgress = ref(0)

  const loadAudio = async () => {
    if (decodedData.value) return

    isLoading.value = true
    // Fetch the entire audio as a blob if pre-decoded data is not provided
    if (!blob.value) {
      const onProgress = (percentage: number) => {
        loadingProgress.value = percentage
      }
      blob.value = await fetch(src.value).then((response) => {
        if (!response.ok) throw new Error(`Failed to fetch audio file: ${response.status} ${response.statusText}`)
        watchProgress(response.clone(), onProgress)
        return response.blob()
      })
    }

    if (!blob.value) return

    const audioElement = document.createElement('audio')
    const url = URL.createObjectURL(blob.value)

    await new Promise((resolve) => {
      audioElement.addEventListener('loadedmetadata', () => {
        resolve(audioElement.duration)
      })
      audioElement.src = url
      audioElement.load()
    })
    const arrayBuffer = await blob.value.arrayBuffer()
    decodedData.value = await Decoder.decode(arrayBuffer, 8000)
    isLoading.value = false
  }

  const settings = {
    threshold: 0.01,
    sliceLengthInSeconds: 0.5,
    walkSize: 0.2,
    minSilenceDuration: 1,
    padding: 0.5,
  }

  const getSilentParts = () => {
    if (!decodedData.value) return []
    const regions = extractRegionsByRMS2(
      decodedData.value,
      settings.sliceLengthInSeconds,
      settings.walkSize,
      settings.threshold,
      settings.padding,
      settings.minSilenceDuration
    )
    return regions.map((region) => {
      return {
        start: region.start * 1000,
        end: region.end * 1000,
      }
    })
  }

  return {
    loadAudio,
    getSilentParts,
    decodedData,
    isLoading,
    loadingProgress,
  }
}

async function watchProgress(response: Response, progressCallback: (percentage: number) => void) {
  if (!response.body || !response.headers) return
  const reader = response.body.getReader()

  const contentLength = Number(response.headers.get('Content-Length')) || 0
  let receivedLength = 0

  // Process the data
  const processChunk = async (value: Uint8Array | undefined) => {
    // Add to the received length
    receivedLength += value?.length || 0
    const percentage = Math.round((receivedLength / contentLength) * 100)
    progressCallback(percentage)
  }

  const read = async () => {
    let data
    try {
      data = await reader.read()
    } catch {
      // Ignore errors because we can only handle the main response
      return
    }

    // Continue reading data until done
    if (!data.done) {
      processChunk(data.value)
      await read()
    }
  }

  read()
}
