import { ref, type Ref } from 'vue'
import { useMagicKeys } from '@vueuse/core'
import type { Crop, CropsStore } from '@/store/entity-system/useCropsStore'

export function useCloneCrop(id: string, source: Ref<Crop>, cropsStore: CropsStore) {

  const crop = cropsStore.selectById(id)
  const cropLabel = cropsStore.selectLabelById(id)

  const duplicateId = ref<string | null>(null)
  const originalLabel = ref<string | null>(null)

  function useDuplicate(event: KeyboardEvent) {

    if (event.key !== 'Alt' || duplicateId.value || !source.value) return

    duplicateId.value = cropsStore.duplicateCropById(id, source.value, source.value.feedData)
    setTimeout(() => cropsStore.moveToForeground(id), 0)
    originalLabel.value = cropLabel.value

    if (!duplicateId.value) return
    const duplicateLabel = cropsStore.selectLabelById(duplicateId.value!)
    const copyLabel = duplicateLabel.value
    duplicateLabel.value = cropLabel.value
    cropLabel.value = copyLabel

    return false
  }

  function destroyDuplicate(event: KeyboardEvent) {
    if (event.key !== 'Alt' || !duplicateId.value || !source.value) return

    if (!duplicateId.value || !originalLabel.value) return
    const duplicate = cropsStore.selectById(duplicateId.value).value
    cropLabel.value = originalLabel.value
    originalLabel.value = null

    cropsStore.removeById(duplicateId.value)
    cropsStore.shift(id, duplicate.z - crop.value.z)

    duplicateId.value = null
    return false
  }

  const { alt } = useMagicKeys()
  function start() {

    if (alt.value) {
      duplicateId.value = cropsStore.duplicateCropById(id, source.value, source.value.feedData)
      cropsStore.moveToForeground(id)
    }

    window.addEventListener('keydown', useDuplicate)
    window.addEventListener('keyup', destroyDuplicate)
  }

  function end() {
    duplicateId.value = null
    window.removeEventListener('keydown', useDuplicate)
    window.removeEventListener('keyup', destroyDuplicate)
  }

  return { start, end }
}
