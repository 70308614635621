<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  isNew: boolean
  tags?: string[] | null
}>()

const seasonEmoji = computed(() => {
  const seasonalOptions = {
    halloween: '🎃',
    christmas: '🎄',
  }

  if (!props.tags?.includes('seasonal')) return null

  for (const tag of props.tags) {
    if (tag in seasonalOptions) {
      return seasonalOptions[tag as keyof typeof seasonalOptions]
    }
  }

  return null
})
</script>

<template>
  <span v-if="isNew || seasonEmoji" class="absolute left-2 top-2 flex gap-2">
    <span v-if="isNew" class="badge-success badge text-xs font-bold uppercase">New</span>
    <span v-if="seasonEmoji" class="font-bold uppercase drop-shadow">{{ seasonEmoji }}</span>
  </span>
</template>

<style scoped lang="scss"></style>
