<script setup lang="ts">
import type { Tier, TierLevel } from '@/data/plans'
import { useUserInfoStore } from '@/store/user/userInfo'
import TierPrice from '@/components/Tiers/TierPrice.vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import plans from '@/data/plans'
import unwrap from '@/helpers/unwrap'
import EventBus from '@/eventBus'
import upgradeEvents from '@/events/upgradeEvents'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { useDiscountCode } from '@/Hooks/useDiscountCode'
import { useCurrentCampaign } from '@/data/campaigns'
import { computed } from 'vue'
import { useConfirmDialog } from '@/components-v2/actions/dialog/useConfirmDialog'
import { posthog } from 'posthog-js'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'

const props = defineProps<{ tier: Tier; minimal?: boolean; compact?: boolean; reason: string }>()

const userInfoStore = useUserInfoStore()
const { interval } = useSubscriptionIntervals()

const selectLowerPlan = <T extends TierLevel>(plan: T) => {
  const planKeys = unwrap.keys(plans)

  const index = planKeys.indexOf(plan) - 1
  if (index >= planKeys.length || index < 0) {
    return null
  }

  return plans[planKeys[index]]
}

const campaign = useCurrentCampaign()
const urlDiscountCode = useDiscountCode()

const discountCode = computed(() => {
  if (urlDiscountCode) return urlDiscountCode
  if (campaign.value?.discountCode) return campaign.value.discountCode
  return undefined
})

const { reveal } = useConfirmDialog()
const { isLoading, upgrade: _upgrade } = usePlanUpgrade()

const upgrade = async () => {
  if (userInfoStore.tier >= props.tier.tier) {
    return
  }

  if (userInfoStore.tier !== 0) {
    const confirm = await reveal({
      message: `Are you sure you want to upgrade to ${props.tier.title}?\n You will be charged immediately.`,
      title: `Upgrade to ${props.tier.title}`,
    })
    if (!confirm) return
  }

  if (props.tier.tier > userInfoStore.tier) {
    const eventMeta = {
      tier: props.tier.key,
      monthlyOrYearly: interval,
      currentPlan: userInfoStore.plan,
      isCurrentlyInTrial: userInfoStore.trialStatus === 'active',
    }

    posthog.capture('SubscriptionUpgrade Clicked', eventMeta)
  }

  return _upgrade(props.tier, props.reason, discountCode.value)
}
</script>

<template>
  <article
    :class="{
      'border-[#33CFF2] bg-[#F5FDFEEB] shadow-blue-300/30 hover:shadow-blue-300/50': tier.highlighted,
      'border-gray-300 bg-white/95 ': !tier.highlighted,
      'cursor-default': tier.tier <= userInfoStore.tier,
      'cursor-pointer hover:border-[#33CFF2] hover:shadow-xl': tier.tier > userInfoStore.tier,
      'gap-3 px-6 py-4': compact,
      'gap-6 p-6 pb-4 2xl:p-8': !compact,
    }"
    class="group relative flex h-full flex-col rounded-lg border text-left shadow backdrop-blur transition-all"
    @click="upgrade"
  >
    <span
      v-if="tier.highlighted"
      class="absolute right-3 top-3 rounded-full bg-[#6080FE] px-3 py-1.5 text-sm font-light text-white"
    >
      Most Popular
    </span>

    <header class="flex flex-col gap-2">
      <h3 class="p-0 text-2xl">{{ tier.title }}</h3>
      <p
        class="overflow-hidden text-sm font-thin text-[#78767B] transition-all xl:text-xs 2xl:text-sm"
        :class="{ '-mt-2 max-h-[0] opacity-0': compact, 'max-h-[3em]': !compact }"
      >
        {{ minimal ? tier.tagline : tier.subtitle }}
      </p>
    </header>

    <TierPrice
      :class="{
        'group-hover:text-[#78767B]': tier.tier > userInfoStore.tier,
      }"
      :interval="interval"
      :tier="tier.key"
      class="transition-colors"
    />

    <div
      v-if="isLoading === tier.key"
      :class="!tier.highlighted ? 'btn-outline border-2 bg-white' : ''"
      class="btn-disabled loading btn-lg btn whitespace-nowrap font-bold xl:h-10 xl:min-h-[2.5rem] 2xl:h-12 2xl:min-h-[3rem]"
    >
      Upgrading to {{ tier.title }}...
    </div>
    <div
      v-else-if="tier.tier > userInfoStore.tier && !isLoading"
      :class="{ 'shadow-xl': tier.highlighted }"
      class="group-hover:shadow-gradient relative h-12 rounded-full transition-all before:transition-all xl:h-10 2xl:h-12"
    >
      <div class="bg-gradient absolute w-full rounded-full">
        <button
          :class="
            tier.highlighted
              ? 'bg-black text-white'
              : 'btn-outline border-2 bg-white text-black group-hover:border-black group-hover:bg-black group-hover:text-white'
          "
          class="btn-lg btn w-full flex-nowrap gap-2 whitespace-nowrap text-lg font-bold uppercase mix-blend-lighten xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
        >
          GET {{ tier.title }} NOW

          <svg
            class="transition group-hover:translate-x-2"
            fill="currentColor"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.43 18.8191C14.24 18.8191 14.05 18.7491 13.9 18.5991C13.61 18.3091 13.61 17.8291 13.9 17.5391L19.44 11.9991L13.9 6.45914C13.61 6.16914 13.61 5.68914 13.9 5.39914C14.19 5.10914 14.67 5.10914 14.96 5.39914L21.03 11.4691C21.32 11.7591 21.32 12.2391 21.03 12.5291L14.96 18.5991C14.81 18.7491 14.62 18.8191 14.43 18.8191Z"
            />
            <path
              d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div v-else-if="tier.tier === userInfoStore.tier" class="btn-outline btn-disabled btn-lg btn border-2 bg-white">
      Current plan
    </div>

    <div class="mt-auto" v-if="!minimal">
      <p v-if="selectLowerPlan(tier.key)" class="font-bold">
        All {{ selectLowerPlan(tier.key).title }} features, plus:
      </p>
      <ol class="mt-6 flex flex-col gap-2 text-sm font-thin text-[#78767B]">
        <li v-for="feature in tier.features.slice(0, 3)" v-bind:key="feature" class="flex gap-2">
          <svg
            class="min-w-6 xl:min-w-4 2xl:min-w-6 -mt-0.5 w-6 flex-shrink-0 flex-grow-0 text-[#6080FE] xl:w-4 2xl:w-6"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M20.9557 5.90309L9.05079 19.5087L3.04214 13.5001L4.50055 12.0417L8.95032 16.4915L19.4035 4.54492L20.9557 5.90309Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>

          {{ feature }}
        </li>
      </ol>
    </div>
    <a
      @click.stop
      class="link-hover link text-center text-sm font-bold text-[#2F184E]"
      :class="isLoading && 'pointer-events-none'"
      href="/upgrade#compare"
      target="_blank"
      v-if="!minimal"
    >
      View all {{ tier.title }} features
    </a>
  </article>
</template>
