export const shapes = [
  { label: 'Freeform', value: 'freeform', aspectLock: null },
  { label: 'Rectangle 9:16 (vertical)', value: '9:16', aspectLock: { width: 9, height: 16 } },
  { label: 'Rectangle 16:9 (horizontal)', value: '16:9', aspectLock: { width: 16, height: 9 } },
  { label: 'Rectangle 4:5 (vertical)', value: '4:5', aspectLock: { width: 4, height: 5 } },
  { label: 'Rectangle 5:4 (horizontal)', value: '5:4', aspectLock: { width: 5, height: 4 } },
  { label: 'Square', value: '1:1', aspectLock: { width: 1, height: 1 } },
  { label: 'Circle', value: 'circle', aspectLock: { width: 1, height: 1 } },
] as const

export type Shape = (typeof shapes)[number]['value']
