<script setup lang="ts">
import { getDisplayPrice, useProductsStore } from '@/store/products'
import { computed } from 'vue'
import type { TierLevel } from '@/data/plans'
import { posthog } from 'posthog-js'
import { round } from 'lodash-es'

const props = defineProps<{
  tier: TierLevel
  interval: 'year' | 'month'
}>()

const productsStore = useProductsStore()

const plan = computed(() => {
  if (productsStore.isLoadingPrices) {
    return null
  }

  const plans = productsStore.productData[props.tier].plans
  return plans.find((p) => p.key === props.interval)
})

const difference = computed(() => {
  if (productsStore.isLoadingPrices) {
    return null
  }

  const plans = productsStore.productData[props.tier].plans
  const plan = plans.find((p) => p.key === props.interval)

  const lowerPlan = plans.find((p) => p.key === 'month')
  if (!lowerPlan || !lowerPlan.paddleData) {
    return null
  }

  const rawNumber = (lowerPlan.paddleData.yearlyPricePerMonthRaw - plan.paddleData?.yearlyPricePerMonthRaw) * 12
  return rawNumber.toLocaleString('en-US', {
    style: 'currency',
    currency: lowerPlan.paddleData?.currency,
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  })
})

const currency = computed(() => {
  return productsStore.products?.find((p) => p.currency)?.currency
})

const displayPrice = computed(() => {
  if (plan.value?.paddleData) {
    return plan.value.paddleData.displayPrice
  }

  if (currency.value) {
    return getDisplayPrice(0, currency.value)
  }

  return undefined
})
</script>

<template>
  <div class="flex w-full flex-col items-start gap-1">
    <p class="relative inline-block h-3 w-20" v-if="productsStore.isLoadingPrices">
      <span class="dot dot-1 absolute left-2 top-0 h-3 w-3 rounded-full bg-current opacity-75" />
      <span class="dot dot-2 absolute left-2 top-0 h-3 w-3 rounded-full bg-current opacity-75" />
      <span class="dot dot-3 absolute left-8 top-0 h-3 w-3 rounded-full bg-current opacity-75" />
      <span class="dot dot-4 absolute left-14 top-0 h-3 w-3 rounded-full bg-current opacity-75" />
    </p>
    <p class="flex items-center gap-1">
      <span class="text-3xl font-bold">{{ displayPrice }}</span>
      <span class="mt-1 block text-lg">/</span>
      <span class="text-sm font-medium">
        <span class="text-gray-500">month</span>
        <span class="whitespace-nowrap text-sm font-medium text-gray-500/50" v-if="interval === 'year'">
          (billed yearly)
        </span>
      </span>
    </p>
    <div
      v-if="interval === 'year' && difference > 0"
      class="items-center justify-center gap-1 rounded-full bg-emerald-600 px-3 py-1.5"
    >
      <p class="text-xs leading-tight text-purple-100/90">Saving {{ difference }}!</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dot {
  animation-duration: 500ms;
  animation-iteration-count: infinite;
}

.dot-1 {
  animation-name: dot-1;
}

.dot-2 {
  animation-name: dot-2;
}

.dot-3 {
  animation-name: dot-2;
}

.dot-4 {
  animation-name: dot-3;
}

@keyframes dot-1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 0.75;
    transform: scale(1);
  }
}

@keyframes dot-2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes dot-3 {
  0% {
    opacity: 0.75;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
