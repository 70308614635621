<template>
  <div class="app-bg flex min-h-screen w-full flex-col">
    <NavbarApplication />
    <div class="px-3 pb-8">
      <div
        class="container mx-auto grid max-w-5xl grid-flow-dense gap-x-4 gap-y-4 md:grid-cols-[300px,1fr] md:gap-x-11 md:gap-y-6 lg:mt-20"
      >
        <IphoneContainer>
          <video v-if="videoData" ref="resultVideo" :src="videoData.resultUrl" class="w-full" controls />
          <div v-else class="flex flex-col gap-2 p-4 pt-20">
            <button class="btn-outline btn-accent btn-block btn" @click="showClipSelectionDialog = true">
              Select Clip
            </button>
            <button class="btn-outline btn-accent btn-block btn" @click="showUploadClipDialog = true">
              Upload file
            </button>
          </div>
        </IphoneContainer>

        <div class="row-span-3">
          <!-- skeleton -->
          <div class="relative rounded-xl bg-white p-6 md:p-10">
            <ScheduleClipSectionSkeleton v-if="showLoader" />
            <ScheduleClipSection
              v-if="showScheduler"
              :key="videoId"
              :clip-name="clipName"
              :clip-url="clipUrl"
              :default-values="defaultValues"
              :on-save="publishClip"
            />
            <div v-if="showSuccess" class="flex flex-col items-center gap-4 p-10 md:p-0">
              <ScheduleClipSuccesHeader :is-posted-to-tik-tok="isPostedToTikTok" :is-scheduled="isScheduled" />
              <div class="flex flex-col items-center justify-center gap-2">
                <router-link
                  v-if="isScheduled"
                  :to="{ name: dashboardRouteNames.contentPublisher }"
                  class="btn-primary btn-lg btn gap-2"
                >
                  Schedule another post
                  <calendar-icon />
                </router-link>
                <router-link
                  v-else
                  :to="{ name: dashboardRouteNames.contentPublisher }"
                  class="btn-primary btn-lg btn gap-2"
                >
                  Back to calendar
                  <calendar-icon />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <select-clip-dialog v-model="showClipSelectionDialog" @clipSelected="selectClip" />
    <upload-clip-dialog v-model="showUploadClipDialog" @uploaded="clipUploaded" />
  </div>
</template>

<script lang="ts" setup>
import logging from '@/logging'

import NavbarApplication from '@/components-v2/navigation/NavbarApplication.vue'
import IphoneContainer from '@/components/Phones/IphoneContainer.vue'
import { computed, ref } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import ScheduleClipSection from '@/components-v2/modules/Scheduler/ScheduleClipSection.vue'
import CalendarIcon from '@/components/Icons/CalendarIcon.vue'
import { useRoute } from 'vue-router'
import SelectClipDialog from '@/components/Dialog/Scheduler/SelectClipDialog.vue'
import UploadClipDialog from '@/components/Dialog/Scheduler/UploadClipDialog.vue'
import { useSocialMediaPost } from '@/components-v2/modules/Scheduler/useSocialMediaPost'
import ScheduleClipSectionSkeleton from '@/components-v2/modules/Scheduler/ScheduleClipSectionSkeleton.vue'
import ScheduleClipSuccesHeader from '@/components-v2/modules/Scheduler/ScheduleClipSuccesHeader.vue'
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import type { PostDto, TargetDto } from '@/apis/streamladder-publisher/model'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'

const userInfo = useUserInfoStore()
const route = useRoute()

const queryVideoId = computed(() => route.query.videoId as string | undefined)
const queryPostDate = computed(() => route.query.postDate as string | undefined)

const showUploadClipDialog = ref(false)

const clipUploaded = (videoId: string) => {
  selectedVideoId.value = videoId
}

const showClipSelectionDialog = ref(false)
const selectedVideoId = ref<string>()
const selectClip = (clip: { id: string }) => {
  selectedVideoId.value = clip.id
  showClipSelectionDialog.value = false
}

const isAuthenticated = computed(() => userInfo.isAuthenticated && userInfo.hasLoadedUserInfo)

const videoId = computed(() => selectedVideoId.value || queryVideoId.value)
const { data: videos } = useGetApiVideos({
  query: {
    enabled: isAuthenticated,
  },
})

const videoData = computed(() => {
  return videos.value?.find((video) => video.id === videoId.value)
})

const clipName = computed(() => videoData.value?.title)
const clipUrl = computed(() => videoData.value?.resultUrl)

const defaultValues = computed(() => {
  const queryString = queryPostDate.value
  const postDate = queryString ? new Date(queryString) : undefined
  return {
    dateTime: postDate,
  }
})

// Scheduler display logic
const { newPost, isScheduled, post: scheduleClip, isPostedToTikTok } = useSocialMediaPost()

const showScheduler = computed(() => userInfo.isAuthenticated && userInfo.hasLoadedUserInfo && !newPost.value)

const publishClip = async (publishAt: Date | null, targets: TargetDto[]): Promise<PostDto> => {
  if (!selectedVideoId.value) {
    throw new Error('No video selected')
  }

  const post = await scheduleClip({
    data: {
      contentUrl: clipUrl.value,
      publishAt: publishAt?.toISOString(),
      targets: targets,
    },
  })

  logging.trackEvent('SocialMediaPost Published', {
    isScheduled: isScheduled.value,
    publishDate: publishAt?.toISOString() ?? Date.now(),
    page: 'ScheduleVideoPage',
    targets: targets.map((target) => target.socialMedia),
  })

  return post
}

const showSuccess = computed(() => newPost.value)

const showLoader = computed(() => !showScheduler.value && !showSuccess.value)
</script>
