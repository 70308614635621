<template>
  <div class="app-bg flex min-h-screen w-full flex-col">
    <div
      class="container flex w-full max-w-5xl flex-grow flex-col items-center justify-items-center space-y-2 self-center justify-self-center px-4 pb-20 pt-6 md:flex-row md:space-y-0"
    >
      <div class="rounded bg-white shadow">
        <div class="modal modal-open absolute z-30 bg-transparent">
          <div class="modal-box">
            <div class="flex flex-col items-center">
              <h2 class="text-xl font-bold text-primary">
                <strong>Login in to get the most out of StreamLadder</strong>
              </h2>
            </div>
            <div class="mx-auto flex max-w-sm flex-col gap-2">
              <twitch-button @click="login(loginProviders.TWITCH)" class=""> Login with Twitch</twitch-button>
              <google-login-button @click="login(loginProviders.GOOGLE)" class=""></google-login-button>
              <test-login />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwitchButton from '@/components/Buttons/TwitchButton.vue'
import GoogleLoginButton from '@/components/Buttons/GoogleLoginButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import useLogin from '@/Hooks/useLogin'
import { loginProviders } from '@/enums/loginProviders'
import TestLogin from '@/components/Account/TestLogin.vue'

export default {
  components: {
    TwitchButton,
    GoogleLoginButton,
    TestLogin
  },
  head() {
    return {
      title: 'Login',
    }
  },
  computed: {
    loginProviders() {
      return loginProviders
    },
  },
  methods: {
    async sync() {
      const userInfoStore = useUserInfoStore()
      // await userInfoStore.updateUserInfo()
      if (userInfoStore.isAuthenticated) {
        this.$router.push(this.$route.query.redirect || '/')
      }
    },
    async login(provider) {
      const { login } = useLogin()
      await login(provider)
      await this.sync()
    },
  },
  async mounted() {
    await this.sync()
  },
}
</script>
