<template>
  <div
    class="flex w-full flex-col space-y-4 rounded border bg-company-primary-light px-4 py-4 lg:flex-row lg:space-y-0"
  >
    <div class="flex items-center justify-center">
      <div
        :class="socialMediaPostTarget.status === 'success' ? 'h-6 w-6 bg-green-500 text-white' : 'h-8 w-8 text-red-600'"
        class="flex items-center justify-center rounded-full p-1"
      >
        <check-mark-icon v-if="socialMediaPostTarget.status === 'success'" class="h-8 w-8 fill-current" />
        <cross-icon v-else class="h-8 w-8 fill-current" />
      </div>
    </div>
    <div class="ml-4 flex flex-1 flex-col items-center justify-center text-gray-800 lg:items-start">
      <p class="mb-0" v-if="socialMediaPostTarget.status === 'success' && platform === connectionTypes.TIKTOK">
        Succesfully posted on TikTok
      </p>
      <p class="mb-0" v-else-if="socialMediaPostTarget.status === 'success' && platform === connectionTypes.YOUTUBE">
        Succesfully posted on YouTube
      </p>
      <div
        class="mb-0 flex items-center justify-between"
        v-else-if="socialMediaPostTarget.status !== 'success' && platform === connectionTypes.TIKTOK"
      >
        <p class="mb-0 text-xs">
          Failed posting to TikTok.
          <span class="font-normal"
            >You can use this ID:
            <span @click="copy(socialMediaPostTarget.id)" class="cursor-pointer text-blue-400 hover:text-blue-600"
              >#{{ socialMediaPostTarget.id }}</span
            >
            in our Discord support chat.</span
          >
        </p>
        <button
          @click="reschedule"
          class="rounded bg-company-primary-300 px-4 py-2 text-white hover:bg-company-primary"
        >
          Reschedule
        </button>
      </div>
      <div
        class="mb-0 flex items-center justify-between"
        v-else-if="socialMediaPostTarget.status !== 'success' && platform === connectionTypes.YOUTUBE"
      >
        <p class="mb-0 text-xs">
          Failed posting to YouTube. You can use this ID:
          <span @click="copy(socialMediaPostTarget.id)" class="cursor-pointer text-blue-400 hover:text-blue-600"
            >#{{ socialMediaPostTarget.id }}</span
          >
          in our Discord support chat.
        </p>
        <button
          @click="reschedule"
          class="rounded bg-company-primary-300 px-4 py-2 text-white hover:bg-company-primary"
        >
          Reschedule
        </button>
      </div>
      <p class="mb-0 text-xs text-gray-400" v-if="socialMediaPostTarget.status === 'success'">
        Your video was succesfully posted on {{ platform }}.
      </p>
      <p class="mb-0 text-gray-400" v-else-if="socialMediaPostTarget.statusMessage">
        {{ socialMediaPostTarget.statusMessage }}.
      </p>
    </div>
    <div class="ml-2 flex flex-col items-center justify-center">
      <div
        @click.prevent="redirectTikTok"
        v-if="socialMediaPostTarget.status === 'success' && platform === connectionTypes.TIKTOK"
        class="button-width cursor-default cursor-pointer items-center justify-center rounded bg-black px-4 py-2 text-sm text-white"
      >
        <TikTokIcon class="mr-1 h-4 w-4" /> View on TikTok
      </div>
      <button
        @click.prevent="redirectYoutube"
        v-if="socialMediaPostTarget.status === 'success' && platform === connectionTypes.YOUTUBE"
        class="button-width cursor-pointer items-center justify-center rounded bg-youtube-red px-4 py-2 text-sm text-white"
      >
        <YoutubeIcon class="mr-1 h-4 w-4" /> View on YouTube
      </button>
    </div>
  </div>
</template>
<script>
import CrossIcon from '../../Icons/CrossIcon.vue'
import TikTokIcon from '../../Icons/SocialMedia/TikTokIcon.vue'
import YoutubeIcon from '../../Icons/SocialMedia/YoutubeIcon.vue'
import CheckMarkIcon from '../../Icons/CheckMarkIcon.vue'
import connectionTypes, { mapApiToConnectionType, mapNewApiToConnectionType } from "../../../enums/connectionTypes";
import { useUserInfoStore } from '@/store/user/userInfo'

export default {
  components: { CrossIcon, YoutubeIcon, TikTokIcon, CheckMarkIcon },
  props: {
    socialMediaPostTarget: { type: Object, required: true },
  },
  computed: {
    platform() {
      return mapNewApiToConnectionType(this.socialMediaPostTarget.socialMedia)
    },
  },
  data() {
    return {
      connectionTypes,
    }
  },
  methods: {
    copy(id) {
      if (navigator.clipboard) {
        // Check if the permissions for clipboard are set. For some browsers this is not by default.
        navigator.permissions.query({ name: 'clipboard-write' }).then((permissionStatus) => {
          if (permissionStatus.state === 'granted') navigator.clipboard.writeText(id)
        })
      }
    },
    reschedule() {
      this.$emit('reschedule', this.socialMediaPostTarget)
    },
    redirectYoutube() {
      window.open('https://youtube.com/watch?v=' + this.socialMediaPostTarget.externalContentId, '_blank')
    },
    redirectTikTok() {
      const userInfoStore = useUserInfoStore()
      const user = userInfoStore.tikTokAccounts.find(
        (tikTokAccount) => tikTokAccount.id === this.socialMediaPostTarget.externalAccountId
      )
      window.open(
        'https://tiktok.com/@' + user.displayName + '/video/' + this.socialMediaPostTarget.externalContentId,
        '_blank'
      )
    },
  },
}
</script>
<style scoped>
.button-width {
  width: 200px;
}
</style>
