import { useQueries } from '@tanstack/vue-query'
import { getApiTiktokV2AccountsId } from '@/apis/streamladder-accounts/tik-tok-controller-v2/tik-tok-controller-v2'
import { type Ref, computed } from 'vue'
import { PrivacyLevel } from '@/apis/streamladder-publisher/model'
import { createCache } from '@/queries/cache'
import { useUserInfoStore } from "@/store/user/userInfo";

export function useAvailableTiktokOptionsToUser(selectedTikTokAccountIds: Ref<string[]>) {
  const results = useQueries({
    queries: selectedTikTokAccountIds.value.map((id) => ({
      queryKey: ['api', 'tiktok', 'v2', 'accounts', id],
      queryFn: async () => {
        return await getTikTokAccountById(id)
      },
    })),
  })

  const accounts = computed(() => results.map((r) => r.data))

  const privacyLevels = computed(() => {
    const levels = []
    for (const level of Object.values(PrivacyLevel)) {
      if (accounts.value && accounts.value.every((a) => a?.privacyLevelOptions.includes(level) ?? false)) {
        levels.push(level)
      }
    }
    return levels
  })

  const comments = computed(() => accounts.value && accounts.value.every((a) => (a?.commentDisabled ?? true) === false))
  const duets = computed(() => accounts.value && accounts.value.every((a) => (a?.duetDisabled ?? true) === false))
  const stitch = computed(() => accounts.value && accounts.value.every((a) => (a?.stitchDisabled ?? true) === false))

  return computed(() => ({
    privacyLevels: privacyLevels.value,
    comments: comments.value,
    duets: duets.value,
    stitch: stitch.value,
    accounts: accounts.value,
  }))
}

const accountsCache = createCache<TikTokAccount>('tiktok_accounts', { expiryInMs: 60 * 1000 })

// Note: This response type has been explicitly defined here because Orval failed
// to generate an appropriate one.
async function getTikTokAccountById(id: string) {
  const cachedAccount = accountsCache.getById(id)
  if (cachedAccount) {
    return cachedAccount
  }

  const userInfoStore = useUserInfoStore()
  const social = userInfoStore.tikTokAccounts.find((acc) => acc.id === id)
  if (!social?.scopes?.includes('video.publish')) {
    return {
      commentDisabled: true,
      duetDisabled: true,
      stitchDisabled: true,
      creatorAvatarUrl: social?.profileImageUrl ?? '',
      creatorNickname: social?.displayName ?? '',
      creatorUsername: social?.displayName ?? '',
      maxVideoPostDurationSec: 60,
      privacyLevelOptions: [
        'PUBLIC_TO_EVERYONE',
      ] as PrivacyLevel[],
    } as const satisfies TikTokAccount
  }

  const { value: account } = await getApiTiktokV2AccountsId<TikTokAccount>(id)
  accountsCache.set(id, account)
  return account
}

type TikTokAccount = {
  creatorAvatarUrl: string
  creatorUsername: string
  creatorNickname: string
  privacyLevelOptions: PrivacyLevel[]
  commentDisabled: boolean
  duetDisabled: boolean
  stitchDisabled: boolean
  maxVideoPostDurationSec: number
}
