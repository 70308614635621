<script setup lang="ts">
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { streamLadderAxiosInstance, publicAxios } from '@/services/axios'
import logging from '@/logging'
import { useEditorMainStore } from '@/store/editor/editorMain'
import localForage from 'localforage'
import { MD5 } from 'jscrypto/es6/MD5'
import { Word32Array } from 'jscrypto/es6/Word32Array'
import uploadService from '@/services/uploadService'
import Pusher from 'pusher-js'
import { useMutation } from '@tanstack/vue-query'
import VideoToAudioService from '@/services/videoToAudioService'
import type { Sentence, Word } from '@/components/Captions/captionTypes'
import { ref, onMounted } from 'vue'
import ErrorIcon from '@/components/Dialog/Icons/ErrorIcon.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import MegaphoneIcon from '@/components/Icons/MegaphoneIcon.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import * as Sentry from '@sentry/vue'
import { retryAsync } from '@/helpers/retry'

const emit = defineEmits<{
  (event: 'done'): void
  (event: 'captionsFileContent', captions: { sentences: Sentence[]; words: Word[] }): void
}>()

const editorClipInfoStore = useEditorClipInfoStore()
const editorMainStore = useEditorMainStore()
const editorCaptionsStore = useEditorCaptionsStore()

const supportedLocales = [
  { code: 'en_us', label: 'English' },
  { code: 'es', label: 'Spanish' },
  { code: 'fr', label: 'French' },
  { code: 'de', label: 'German' },
  { code: 'it', label: 'Italian' },
  { code: 'pt', label: 'Portuguese' },
  { code: 'nl', label: 'Dutch' },
]

const captionsLanguageCode = ref<(typeof supportedLocales)[number]['code']>('en_us')

const mutationFn = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let fileUrl = editorClipInfoStore.mp4Url

    try {
      if (editorClipInfoStore.isLocalFile) {
        fileUrl = await extractAndUploadAudioFile()
      }

      editorCaptionsStore.selectedLanguage = captionsLanguageCode.value
      editorCaptionsStore.resetPositionScale()

      let retry = 0
      const result = await retryAsync(async () => {
        const timeout = 30 * Math.max(1000, editorMainStore.trimmedDurationMs / 60) * Math.pow(2, retry)

        const abortController = new AbortController()
        const timeoutId = setTimeout(() => {
          abortController.abort('Captions generation timed out')
          throw new Error('Captions generation timed out')
        }, timeout)

        console.log(`Request will timeout in ${timeout / 1000}s`)

        retry++

        try {
          const result = await streamLadderAxiosInstance<{ fileName: string; fullFilePath?: string; taskId: string }>(
            {
              url: `/api/Captions/GenerateCaptions`,
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              data: { fileUrl, languageCode: captionsLanguageCode.value },
            },
            { signal: abortController.signal }
          )

          clearTimeout(timeoutId)
          return result
        } catch (e) {
          clearTimeout(timeoutId)
          throw e
        }
      })

      if (!result) return

      logging.trackEvent('Editor Captions Added', {
        language: captionsLanguageCode.value,
        localFile: editorClipInfoStore.isLocalFile,
        style: editorCaptionsStore.captionStyle,
        tags: editorCaptionsStore.captionStyleSettings.tags,
      })

      if (result.fullFilePath) {
        await emitCaptionsFileContent(result.fullFilePath)
        emit('done')
      } else {
        await getCaptionsFileByPusherTask(result.taskId)
      }
    } catch (e) {
      Sentry.captureException(e)
      reject(e)
    }
  })
}

const uploadFilePercentage = ref(0)
async function extractAndUploadAudioFile() {
  const file = await localForage.getItem<File>('localFile')
  if (!file) throw new Error('No file found')

  try {
    const audioFile = await VideoToAudioService(
      file,
      'mp3',
      editorMainStore.trimmedStartTime / 1000,
      editorMainStore.trimmedDurationMs / 1000
    ).catch((e: ProgressEvent) => {
      const target = e.target as FileReader
      if (target?.error?.message) {
        throw new Error(target.error.message)
      } else {
        throw e
      }
    })

    const buff = await new Response(audioFile).arrayBuffer()
    const md5Hash = MD5.hash(new Word32Array(buff)).toString()

    const result = await uploadService.getClipAudioUploadSignedUrl(md5Hash)

    // Check if we need to upload the file. Otherwise, the file already exists
    if (result.signedUrl) {
      await uploadService.uploadFileS3(
        result.signedUrl,
        audioFile,
        (p) => (uploadFilePercentage.value = p),
        'audio/mp3'
      )
    }

    return result.resultUrl
  } catch (e) {
    Sentry.captureException(e)
    throw e
  }
}

async function getCaptionsFileByPusherTask(taskId: string) {
  const pusherClient = new Pusher('ef0a10b651ed4adf46eb', {
    cluster: 'us3',
  })

  const channelName = `task-status-${taskId}`
  const channel = pusherClient.subscribe(channelName)

  return new Promise<void>((resolve, reject) => {
    channel.bind('finished', async (data: { FullFilePath: string }) => {
      await emitCaptionsFileContent(data.FullFilePath)
      emit('done')
      pusherClient.disconnect()
      resolve()
    })
    channel.bind('error', (data: unknown) => {
      console.error(data)
      pusherClient.disconnect()
      reject(new Error(`Generating captions failed: ${data}`))
    })
  })
}

async function emitCaptionsFileContent(url: string) {
  const editorMainStore = useEditorMainStore()
  const editorClipInfoStore = useEditorClipInfoStore()

  const result = await publicAxios.get(url)

  if (editorClipInfoStore.isLocalFile) {
    // If it was a local-file, and these can be trimmed before we send them to AssemblyAI, we need to add an extra offset to all data before we return it
    const addOffset = (e: { start: number; end: number }) => {
      e.start += editorMainStore.trimmedStartTime
      e.end += editorMainStore.trimmedStartTime
    }

    result.data.sentences.forEach((sentence: Sentence) => {
      addOffset(sentence)
      sentence.words.forEach(addOffset)
    })

    result.data.words.forEach(addOffset)
  }

  emit('captionsFileContent', result.data)
}

const {
  mutateAsync: generateCaptions,
  isLoading,
  error,
} = useMutation({
  mutationFn,
})

const captionStore = useEditorCaptionsStore()
function guessLanguage() {
  // Attempt to get the language from the ClipInfo store
  const clipLanguage = editorClipInfoStore.languageCode?.toLowerCase() ?? ''
  const lang: (typeof supportedLocales)[number]['code'] = clipLanguage === 'en' ? 'en_us' : clipLanguage

  if (supportedLocales.find((l) => l.code === lang)) {
    return lang
  } else {
    // If not supported, try browser-language. Use english as fallback
    const lang = navigator.language.toLowerCase().split('-')[0]
    return supportedLocales.find((l) => l.code === lang) ? lang : captionStore.selectedLanguage
  }
}

onMounted(() => {
  captionsLanguageCode.value = guessLanguage()
})
</script>

<template>
  <div v-if="error" class="content">
    <ErrorIcon />
    <p>
      <strong>Error while generating captions 😔</strong>
    </p>
    <p class="mb-0 mt-2">Unfortunately an error has occurred</p>

    <p class="mb-0 mt-2">
      Please report this issue in our support channel in discord together with your video/twitch-clip and the following
      message:
    </p>

    <code class="mb-0 mt-2 block rounded bg-zinc-100 p-4 text-sm">{{ error.message }}</code>
    <div class="pt-2">
      <button
        class="mb-2 flex w-full justify-center space-x-2 rounded bg-gray-100 p-2 hover:bg-gray-300"
        @click="emit('done')"
      >
        Close
      </button>
    </div>
  </div>

  <div v-else class="content">
    <div v-if="!isLoading" class="text-left">
      <h5 class="text-lg font-bold">What language is being spoken in the clip?</h5>
      <div class="mt-4 flex items-center gap-2">
        <p>Spoken language:</p>

        <SelectDropdown
          v-model="captionsLanguageCode"
          :options="supportedLocales.map(({ code, label }) => ({ value: code, label }))"
          fixed
        />
      </div>

      <div v-if="captionsLanguageCode !== 'en_us'" class="flex justify-center pt-4">
        <div class="flex items-center space-x-2 rounded-lg bg-blue-100 px-4 py-2 text-sm text-company-primary">
          <MegaphoneIcon height="17" width="16" />

          <span>With this language, long clip captions may run out of sync with the audio</span>
        </div>
      </div>

      <div class="flex justify-center gap-2 pt-10">
        <button class="btn-outline btn-primary btn" @click="emit('done')">Back</button>
        <button class="btn-primary btn" @click="generateCaptions">Generate</button>
      </div>
    </div>

    <div v-else class="text-center">
      <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
      <p>
        <strong>Making magic</strong>
      </p>
      <p class="mb-0 mt-2">
        This process might take up to<br />
        20 seconds depending on the<br />
        length of your clip
      </p>
      <LottieAnimation height="100px" url="/lottie/purple-spinner.json" />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
