import {
  createInjectionState,
  useMouseInElement,
  useMousePressed,
  onKeyDown,
  onKeyUp,
  useElementBounding,
} from '@vueuse/core'
import { type Ref, watch } from 'vue'
import { computed, ref } from 'vue'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import { keepAreaInsideBoundsX, keepAreaInsideBoundsY, keepAreaInsideBounds } from '@/modules/SLMovable/helpers/area'
import type { Area, Bounds, Directions } from '@/modules/SLMovable/@types/Movable'
import { v4 as uuid } from 'uuid'
import { useMovableFocus } from '@/modules/SLMovable/useMovableFocus'

export const [createMovableElementContext, useMovableElementContext] = createInjectionState(
  (config: MovableElementConfig) => {

    const context = useMovableContext()
    const id = uuid()

    const { focus, setFocus } = useMovableFocus()
    const hasFocus = computed(() => focus.value === id)

    function pullFocus() {
      setFocus(id)
    }

    pullFocus()

    const localArea = ref(config.local.value)
    watch(() => config.local, () => {
      localArea.value = config.local.value
    }, { deep: true })

    const { elementX: x, elementY: y } = useMouseInElement(context!.container)
    const { pressed } = useMousePressed({ drag: true })
    const mouse = computed(() => ({
      x: x.value,
      y: y.value,
      pressed: pressed.value
    }))

    const isResizing = computed(() => {
      return config.resize.value && config.resizingFrom.value !== null
    })

    const isResizingNorth = computed(() => isResizing.value && config.resizingFrom.value!.includes('n'))
    const isResizingEast = computed(() => isResizing.value && config.resizingFrom.value!.includes('e'))
    const isResizingSouth = computed(() => isResizing.value && config.resizingFrom.value!.includes('s'))
    const isResizingWest = computed(() => isResizing.value && config.resizingFrom.value!.includes('w'))

    const isResizingY = computed(() => isResizingNorth.value || isResizingSouth.value)
    const isResizingX = computed(() => isResizingEast.value || isResizingWest.value)

    const isMoving = computed(() => {
      return config.move.value && config.movingFrom.value !== null
    })

    const scaleX = computed(() => {
      const width = context!.width.value
      return (value: number) => value / width
    })

    const scaleY = computed(() => {
      const height = context!.height.value
      return (value: number) => value / height
    })

    const _keepInsideBoundsX = computed(() => {
      const bounds = config.bounds.value
      return (area: Area) => {
        if (bounds === null) {
          return area
        } else {
          return keepAreaInsideBoundsX(area, bounds)
        }
      }
    })

    const _keepInsideBoundsY = computed(() => {
      const bounds = config.bounds.value
      return (area: Area) => {
        if (bounds === null) {
          return area
        } else {
          return keepAreaInsideBoundsY(area, bounds)
        }
      }
    })

    const _keepInsideBounds = computed(() => {
      const bounds = config.bounds.value
      return (area: Area) => {
        if (bounds === null) {
          return area
        } else {
          return keepAreaInsideBounds(area, bounds)
        }
      }
    })

    const { x: bodyX, y: bodyY } = useElementBounding(document.body)

    return {
      id: id,

      container: context!.container,
      containerWidth: context!.width,
      containerHeight: context!.height,

      bodyX: bodyX,
      bodyY: bodyY,

      snapGridId: context!.snapGridId,

      source: config.source,
      localArea: localArea,

      bounds: config.bounds,
      minSize: config.minSize,

      move: config.move,
      resize: config.resize,
      snap: config.snap,

      focused: hasFocus,
      pullFocus: pullFocus,

      resizingFrom: config.resizingFrom,
      movingFrom: config.movingFrom,

      isResizing: isResizing,
      isMoving: isMoving,

      isResizingNorth: isResizingNorth,
      isResizingEast: isResizingEast,
      isResizingSouth: isResizingSouth,
      isResizingWest: isResizingWest,

      isResizingY: isResizingY,
      isResizingX: isResizingX,

      aspectRatio: config.aspectRatio,
      _aspectRatio: computed(() => {
        return (config.local.value.width * (context?.width.value ?? 0))
          / (config.local.value.height * (context?.height.value ?? 0))
      }),

      mouse: mouse,

      scaleX: scaleX,
      scaleY: scaleY,

      keepInsideBoundsX: _keepInsideBoundsX,
      keepInsideBoundsY: _keepInsideBoundsY,
      keepInsideBounds: _keepInsideBounds,
    }
  }
)

type MovableElementConfig = {

  local: Ref<{ x: number; y: number; width: number; height: number }>
  source: Ref<{ x: number; y: number; width: number; height: number }>

  snap: Ref<{ x?: number[]; y?: number[] } | null>
  bounds: Ref<Bounds>
  move: Ref<boolean>
  resize: Ref<boolean>

  resizingFrom: Ref<Directions | null>
  movingFrom: Ref<{ x: number; y: number } | null>

  minSize: Ref<number>
  aspectRatio: Ref<{ width: number; height: number } | null>
}
