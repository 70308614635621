<script lang="ts" setup>
import { useSLVideoRenderer } from '@/modules/SLVideoplayer/hooks/useSLVideoRenderer'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useResizeObserver, debouncedWatch } from '@vueuse/core'
import MovableRoot from '@/modules/SLMovable/MovableRoot.vue'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import FeedElement from '@/modules/CustomLayouts/Feed/FeedElement.vue'
import { inject, onMounted, watch } from 'vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'

const editorVideoStore = useEditorVideoStore()

const props = defineProps<{ id: string, enableSnapping: boolean }>()
const cropsStore = inject<CropsStore>('cropsStore')!
const crops = cropsStore.idsWhereLayoutIdIs(props.id)
const videoFragments = cropsStore.selectVideoFragmentsByLayoutId(props.id)

const { renderTarget, error, renderer } = useSLVideoRenderer(editorVideoStore.videoElement, videoFragments, {
  initialBackground: editorVideoStore.videoElement,
  initialHeight: 1280,
  initialWidth: 720,
  backgroundColor: '#170a1e',
})

useResizeObserver(renderTarget, () => {
  if (renderer.value && renderTarget.value) {
    renderer.value.resizeTo(renderTarget.value)
  }
})

debouncedWatch(
  () => videoFragments.value,
  () => {
    if (renderer.value) {
      renderer.value.app.resize()
      renderer.value.app.stage.sortChildren()
    }
  },
  { debounce: 100 }
)

watch(() => videoFragments.value, (newFragments, oldFragments) => {

  if (newFragments.length === oldFragments.length || !renderer.value) {
    return
  }

  for (const fragment of oldFragments) {
    if (!newFragments.map(f => f.key).includes(fragment.key)) {
      renderer.value.removeVideo(fragment.key)
    }
  }
})

const editorFocusStore = useEditorFocusStore()
onMounted(() => {
  const lastCrop = crops.value[crops.value.length - 1]
  if (lastCrop && editorFocusStore.focus?.type !== 'crop') {
    editorFocusStore.setFocus('crop', lastCrop)
  }
})
</script>

<template>
  <MovableRoot>
    <div ref="renderTarget" class="h-full w-full bg-company-primary-800">
      <div v-if="error" class="absolute inset-0 flex items-center justify-center p-6 text-center">
        <span class="font-bold text-white" style="mix-blend-mode: exclusion">{{ error }}</span>
      </div>
    </div>

    <template v-for="crop in crops" :key="crop">
      <FeedElement :id="crop" :enable-snapping="enableSnapping" />
    </template>
  </MovableRoot>
</template>

<style lang="scss" scoped>

</style>
