<template>
  <div class="relative z-0">
    <router-view />

    <has-upgraded-dialog />
    <upgrade-dialog />
    <login-dialog />
    <tw-error-dialog />
    <DialogWrapper />
    <debug-menu v-if="debug" />
  </div>
</template>

<script>
import UpgradeDialog from './components/Dialog/Gold/UpgradeDialog.vue'
import LoginDialog from './components/Dialog/Auth/LoginDialog.vue'
import TwErrorDialog from './components/Dialog/TwErrorDialog.vue'
import DebugMenu from '@/components/Debug/DebugMenu.vue'
import DialogWrapper from '@/components-v2/actions/dialog/DialogWrapper.vue'
import HasUpgradedDialog from '@/components/Dialog/Gold/HasUpgradedDialog.vue'

export default {
  components: {
    DialogWrapper,
    DebugMenu,
    TwErrorDialog,
    LoginDialog,
    UpgradeDialog,
    HasUpgradedDialog,
  },
  head() {
    return {
      title: 'App',
      titleTemplate: '%s | StreamLadder',
    }
  },
  data() {
    const dos = localStorage.getItem('dos') === 'true' || false
    return {
      debug: (import.meta.env.DEV || dos) && !['StickerRender', 'VideoRender'].includes(this.$route.name),
    }
  }
}
</script>
