<script setup lang="ts">
import { ref } from 'vue'
import ConvertInBackgroundAlert from '@/components/Clip2TikTok/ConvertInBackgroundAlert.vue'

function random<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)]
}

const feature = random([
  {
    title: 'Stickers',
    subtitle:
      "Pump up your presence on the socials. Let's get your audience hitting follow faster than a retweet on a viral meme",
    href: 'https://streamladder.com/features/add-stickers',
    video: {
      src: 'videos/spotlight/stickers.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'SmartCut',
    subtitle: 'Remove the quiet and inactive moments from your clip automatically with AI.',
    // href: 'https://www.streamladder.com/features/smartcut',
    video: {
      src: 'videos/spotlight/smartcut.mp4',
      class: 'aspect-[1720/1080]',
      width: 1720,
      height: 1080,
    },
  },
  {
    title: 'Content Publisher',
    subtitle:
      'Upload, schedule and post your videos to both TikTok and YouTube Shorts in advance with our scheduler tool.',
    href: 'https://streamladder.com/features/content-planner',
    video: {
      src: 'videos/spotlight/content-publisher.mp4',
      class: 'aspect-[1720/1080]',
      width: 1720,
      height: 1080,
    },
  },
  {
    title: 'Save Templates',
    subtitle: 'Save 80% of your time by creating templates and create clips super fast.',
    // href: 'https://www.streamladder.com/features/save-templates',
    video: {
      src: 'videos/spotlight/save-templates.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'AI Automated Captions',
    subtitle:
      'Generate captions for your video with just the click of a button. Our AI will generate the words and add ' +
      'highlights and emojis, which you can edit however you want.',
    href: 'https://streamladder.com/features/automated-captions',
    video: {
      src: 'videos/spotlight/captions.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'Custom Timed Text',
    subtitle:
      'Make a statement, add a call to action or just let people know what’s up by adding custom text to your videos.',
    href: 'https://www.streamladder.com/features/add-custom-text',
    video: {
      src: 'videos/spotlight/text.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'Render in the Background',
    subtitle:
      'No more unnecessary waiting. Just leave the tab and let us render your video whilst you catch up on other stuff.',
    href: 'https://www.streamladder.com/features/render-in-background',
    video: {
      src: 'videos/spotlight/render-in-background.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'EmoteMaker',
    subtitle: 'Bring your chat to life with your own animated emotes created from your Twitch clips.',
    href: 'https://emotes.streamladder.com/',
    video: {
      src: 'videos/spotlight/emote-maker.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
])

const gradient = random([
  'bg-gradient-to-br from-red-400 to-purple-500',
  'bg-gradient-to-br from-red-400 to-amber-400',
  'bg-gradient-to-br from-blue-600 to-emerald-300',
  'bg-gradient-to-br from-violet-300 to-blue-700',
  'bg-gradient-to-br from-cyan-300 to-purple-600',
  'bg-gradient-to-br from-emerald-700 to-lime-300',
  'bg-gradient-to-br from-pink-400 to-yellow-400',
])

const loaded = ref(false)
</script>

<template>
  <article class="grid h-full min-h-screen grid-cols-10" :class="gradient">
    <div class="col-span-10 h-full bg-white p-16 md:p-8 lg:col-span-4 xl:col-span-3 2xl:px-20 2xl:pb-20">
      <div class="flex h-full flex-col justify-center gap-8">
        <slot />

        <ConvertInBackgroundAlert />
      </div>
    </div>
    <div class="col-span-10 p-6 md:p-12 lg:col-span-6 xl:col-span-7 2xl:p-36">
      <div class="flex h-full flex-col items-center justify-center gap-16">
        <header class="flex flex-col items-center justify-start gap-6">
          <div
            class="rounded-full bg-opacity-20 bg-gradient-to-br from-stone-600/20 via-stone-500/20 to-stone-600/20 px-6 py-3"
          >
            <span class="text-lg font-medium leading-none text-white">Feature spotlight</span>
          </div>
          <h1 class="text-center text-6xl font-bold text-white">{{ feature.title }}</h1>
          <p class="text-center text-xl font-normal leading-snug text-white" style="text-wrap: balance">
            {{ feature.subtitle }}
          </p>
          <a
            v-if="'href' in feature"
            target="_blank"
            :href="feature.href"
            class="btn-xl btn-outline btn-accent btn uppercase"
          >
            Discover more
          </a>
        </header>

        <div class="grid max-h-[35vh] max-w-[calc(35vh*calc(16/9))] place-items-center">
          <div :class="feature.video.class" class="relative w-full">
            <div
              :class="[feature.video.class, { 'opacity-0': loaded }]"
              class="skeleton !absolute inset-0 rounded-lg bg-white/50 backdrop-blur transition-opacity"
            />
            <video
              class="relative max-w-full rounded-lg shadow-2xl transition-opacity"
              :class="{ 'opacity-0': !loaded }"
              @canplay.once="loaded = true"
              :src="feature.video.src"
              :width="feature.video.width"
              :height="feature.video.height"
              autoplay
              loop
              muted
              playsinline
              disablePictureInPicture
              disableRemotePlayback
            />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss">
// Stolen from DaisyUI 4.x.x
.skeleton {
  --tw-bg-opacity: 1;
  will-change: background-position;
  animation: skeleton 1.8s ease-in-out infinite;
  background-image: linear-gradient(
    105deg,
    transparent 0%,
    transparent 40%,
    #f2f2f233,
    transparent 60%,
    transparent 100%
  );
  background-size: 200% auto;
  background-repeat: no-repeat;
  background-position-x: -50%;
}

@media (prefers-reduced-motion) {
  .skeleton {
    animation-duration: 15s;
  }
}

@keyframes skeleton {
  0% {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}
</style>
