<script setup lang="ts">
import TimelineRoot from '@/modules/SLTimeline/TimelineRoot.vue'
import TimelineContainer from '@/modules/SLTimeline/TimelineContainer.vue'
import Timeline from '@/modules/SLTimeline/Timeline.vue'
import { useProvideTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { computed, nextTick, ref, watch } from 'vue'
import TimeLinePlayHead from '@/modules/SLTimeline/TimeLinePlayHead.vue'
import { useRafFn, useThrottleFn, watchDeep } from '@vueuse/core'
import TimelineHeader from '@/components/Editor/Timeline/TimelineHeader.vue'
import TimeLineDialsTrack from '@/modules/SLTimeline/TimeLineDialsTrack.vue'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import IconSaxSubtitle from '@/components/Icons/iconsax/IconSaxSubtitle.vue'
import IconSaxElement2 from '@/components/Icons/iconsax/IconSaxElement2.vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { onKeyStroke } from '@vueuse/core/index'
import TimelineFooter from '@/components/Editor/Timeline/TimelineFooter.vue'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import { isInputTarget } from '@/components/Editor/Timeline/helpers'
import { useGuard } from '@/Hooks/useGuard'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import TimeLineAddNode from '@/components/Editor/Timeline/TimeLineAddNode.vue'
import IconSaxSearchZoomIn from '@/components/Icons/iconsax/IconSaxSearchZoomIn.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'

const props = defineProps<{
  step: 'layout' | 'crop' | 'editor'
  defaultOpen?: boolean
}>()

const editorVideoStore = useEditorVideoStore()
const editorFeedStore = useEditorFeedDataStore()
const editorClipInfoStore = useEditorClipInfoStore()
const editorFocusStore = useEditorFocusStore()
const editorCaptionStore = useEditorCaptionsStore()

const flagZoomFeature = useFeatureFlagEnabled('ZoomFeature')

const totalDurationMs = computed(() => editorVideoStore._duration * 1000)

const updateFunction = () => {
  updateWordsTimer(editorVideoStore.getExactTime())
}

const { pause, resume } = useRafFn(updateFunction, { immediate: false })

const resumeVideoAndTimeline = () => {
  resume()
  editorVideoStore.playing = true
}

const pauseVideoAndTimeline = () => {
  pause()
  editorVideoStore.playing = false
}

watchDeep(
  () => editorFocusStore.focus,
  (value) => {
    nextTick(() => updateWordsTimer(editorVideoStore.getExactTime() + 0.001))
  }
)

const commitSeekThrottle = useThrottleFn(
  (ms: number) => {
    editorVideoStore._currentTime = ms / 1000
  },
  200,
  true
)

let wasPlaying = false
const commitSeek = (ms: number, scrubbing = false) => {
  editorVideoStore.scrubbing = scrubbing
  if (scrubbing && editorVideoStore.playing) {
    wasPlaying = true
    editorVideoStore.playing = false
  }
  if (!scrubbing && wasPlaying) {
    editorVideoStore.playing = true
    wasPlaying = false
  }
  currentTimeMs.value = ms
  commitSeekThrottle(ms)
}

const {
  pxToMs,
  zoomLevel,
  autoScroll,
  dragging,
  _autoScroll,
  open: timelineOpen,
} = useProvideTimelineStore(totalDurationMs, {
  minVisibleMs: 500,
  onSeek: commitSeek,
  openDefault: props.defaultOpen,
})

watch(
  () => editorVideoStore.playing,
  (value) => {
    value && !editorVideoStore.scrubbing && !editorVideoStore.preservedPaused
      ? resumeVideoAndTimeline()
      : pauseVideoAndTimeline()
  }
)

const canSegment = useGuard('video-segmentation')
onKeyStroke(['Backspace', 'Delete', 'Del'], (e) => {
  if (isInputTarget(e)) return
  editorFocusStore.deleteFocusModel()
})

onKeyStroke(['s'], async (e) => {
  if (isInputTarget(e)) return
  if (!canSegment.value) {
    upgradeDialog.open('video-segmentation')
    return
  }
  editorFeedStore.splitSegment(currentTimeMs.value)
})

onKeyStroke([' '], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  e.preventDefault()
  editorVideoStore.preservedPaused = !editorVideoStore.preservedPaused
  editorVideoStore.playing = !editorVideoStore.playing
})

onKeyStroke(['m'], (e) => {
  if (isInputTarget(e)) return
  editorVideoStore.muted = !editorVideoStore.muted
})

const currentTimeMs = ref(0)

const updateWordsTimer = (currentTime: number) => {
  if (editorVideoStore.seeking) return
  if (currentTime !== currentTimeMs.value) {
    currentTimeMs.value = currentTime
    if (!editorVideoStore.scrubbing) {
      autoScroll(currentTimeMs.value)
    }
  }
}

const captionsWithInSegment = computed(() => {
  return editorCaptionStore.captions.map((caption) => {
    const segment = editorFeedStore.segments.find((segment) => {
      return caption.end >= segment.start && caption.start <= segment.end
    })
    return {
      ...caption,
      text: caption.words.map((word) => word.text).join(' '),
      segmentId: segment?.id,
    }
  })
})

const dialStepSize = computed(() => {
  return zoomLevel.value < 0.5 ? 500 : 1000
})

const dialNumberInterval = computed(() => {
  return zoomLevel.value < 0.5 ? 10 : 5
})

const noEffects = computed(() => {
  return editorFeedStore.zooms.length === 0
})

const showZoomTrack = computed(() => {
  return flagZoomFeature.value && props.step === 'editor'
})
</script>

<template>
  <TimelineRoot class="flex transform flex-col border-gray-100 bg-white transition-[height]">
    <div
      v-if="!timelineOpen"
      class="relative flex h-1.5 origin-bottom transform border-transparent transition hover:scale-y-150"
    >
      <div class="absolute inset-0 bg-gray-100"></div>
      <div
        class="absolute inset-y-0 left-0 bg-company-primary-500"
        :style="{
          width: `${(currentTimeMs / (editorVideoStore._duration * 1000)) * 100}%`,
        }"
      ></div>
    </div>
    <TimelineHeader class="px-4 lg:container lg:mx-auto lg:px-0" />
    <Transition>
      <TimelineContainer
        v-if="timelineOpen"
        class="min-h-16 relative flex h-full w-full flex-col overflow-y-hidden border-t-2 border-gray-50"
      >
        <Timeline class="relative flex h-full flex-grow flex-col gap-3 pb-2">
          <TimeLineDialsTrack
            class="h-8 w-full pt-2 text-xs font-normal text-gray-300"
            :duration="editorVideoStore._duration"
            :stepSize="dialStepSize"
            :number-interval="dialNumberInterval"
          ></TimeLineDialsTrack>
          <TimeLineTrack v-if="showZoomTrack" class="group relative h-10 w-full">
            <TimeLineAddNode></TimeLineAddNode>
            <div
              v-if="noEffects"
              class="absolute inset-0 flex items-center justify-center gap-2 rounded-lg border border-blue-100 bg-blue-100/50 text-blue-600 group-hover:hidden peer-[]:hidden"
            >
              <IconSaxElement2 class="stroke- h-4 w-4" />
              Click to add a effect
            </div>
            <TimeLineTrackNode
              class="zoom-node cursor-grab rounded-[10px] border-none bg-company-secondary-600 outline-white ring-company-secondary-700 ring-offset-2 hover:bg-company-secondary-500"
              v-for="(zoom, i) in editorFeedStore.zooms"
              :key="zoom.id"
              :start-ms="zoom.start"
              :end-ms="zoom.end"
              :min-start-ms="editorFeedStore.zooms[i - 1] ? editorFeedStore.zooms[i - 1].end : 0"
              :max-end-ms="
                editorFeedStore.zooms[i + 1] ? editorFeedStore.zooms[i + 1].start : editorVideoStore._duration * 1000
              "
              :height="40"
              :active="editorFocusStore.focus?.type === 'zoom' && editorFocusStore.focus?.key === zoom.id"
              @resizeStart="() => editorFocusStore.setFocus('zoom', zoom.id)"
              @resizeStop="
                (start, end) => {
                  editorFeedStore.updateZoom(zoom.id, { start, end })
                }
              "
              @dragStart="() => editorFocusStore.setFocus('zoom', zoom.id)"
              @dragStop="
                (start, end) => {
                  editorFeedStore.updateZoom(zoom.id, { start, end })
                }
              "
              @activated="
                () => {
                  editorFocusStore.setFocus('zoom', zoom.id)
                }
              "
            >
              <div
                class="flex h-full w-full items-center overflow-hidden rounded-[10px] text-sm font-normal text-white"
              >
                <span
                  class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-1 text-center align-middle font-normal"
                >
                  <IconSaxSearchZoomIn class="h-4 w-4" />
                  Zoom
                </span>
              </div>
              <template #handles>
                <div class="flex h-full flex-row gap-0.5 px-1.5 py-2">
                  <div class="h-full w-1 rounded bg-company-secondary-700"></div>
                  <div class="h-full w-1 rounded bg-company-secondary-700"></div>
                </div>
              </template>
            </TimeLineTrackNode>
          </TimeLineTrack>
          <TimeLineTrack v-if="editorCaptionStore.captions.length > 0" class="relative h-10 w-full">
            <TimeLineTrackNode
              class="group cursor-grab rounded-[10px] border-solid outline-white ring-offset-2"
              :class="{
                'border-gray-100 bg-gray-100 ring-gray-200 hover:bg-gray-200': !caption.segmentId,
                'border-yellow-500 bg-yellow-500 ring-yellow-600 hover:border-yellow-400 hover:bg-yellow-400 ':
                  !!caption.segmentId,
              }"
              v-for="(caption, i) in captionsWithInSegment"
              :key="caption.id"
              :start-ms="caption.start"
              :end-ms="caption.end"
              :min-start-ms="editorCaptionStore.captions[i - 1] ? editorCaptionStore.captions[i - 1].end : 0"
              :max-end-ms="
                editorCaptionStore.captions[i + 1]
                  ? editorCaptionStore.captions[i + 1].start
                  : editorVideoStore._duration * 1000
              "
              :height="40"
              seek-behavior="seek"
              :active="editorFocusStore.focus?.type === 'caption' && editorFocusStore.focus?.key === caption.id"
              @resizeStop="(start, end) => editorCaptionStore.updateCaption(caption.id, { start, end })"
              @dragStop="(start, end) => editorCaptionStore.updateCaption(caption.id, { start, end })"
              @activated="
                () => {
                  editorFocusStore.setFocus('caption', caption.id)
                  editorVideoStore._currentTime = caption.start / 1000 + 0.01
                }
              "
            >
              <div class="flex h-full w-full items-center overflow-hidden rounded-[10px] text-sm text-gray-950">
                <span
                  class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-1 text-center align-middle"
                  :class="{
                    'hidden group-hover:inline': !caption.segmentId,
                  }"
                >
                  <IconSaxSubtitle class="h-4 w-4" />
                  {{ caption.text }}
                </span>
              </div>
              <template #handles>
                <div class="flex h-full flex-row gap-0.5 px-1.5 py-2">
                  <div
                    class="h-full w-1 rounded"
                    :class="{
                      'bg-gray-600': !caption.segmentId,
                      'bg-yellow-600 ': !!caption.segmentId,
                    }"
                  ></div>
                  <div
                    class="h-full w-1 rounded"
                    :class="{
                      'bg-gray-600': !caption.segmentId,
                      'bg-yellow-600 ': !!caption.segmentId,
                    }"
                  ></div>
                </div>
              </template>
            </TimeLineTrackNode>
          </TimeLineTrack>
          <TimeLineTrack class="group relative h-10 w-full">
            <div class="absolute inset-x-0 inset-y-2 rounded-lg border border-red-100 bg-red-100/50" />
            <TimeLineTrackNode
              class="cursor-grab rounded-[10px] border-none bg-purple-500 outline-white ring-purple-600 ring-offset-2 hover:bg-purple-400"
              v-for="(segment, i) in editorFeedStore.segments"
              :key="segment.id"
              :start-ms="segment.start"
              :end-ms="segment.end"
              :min-start-ms="editorFeedStore.segments[i - 1] ? editorFeedStore.segments[i - 1].end : 0"
              :max-end-ms="
                editorFeedStore.segments[i + 1]
                  ? editorFeedStore.segments[i + 1].start
                  : editorVideoStore._duration * 1000
              "
              :height="40"
              :active="
                (editorCaptionStore.captions.length === 0 &&
                  editorFeedStore.zooms.length === 0 &&
                  editorFeedStore.segments.length === 1) ||
                (editorFocusStore.focus?.type === 'segment' && editorFocusStore.focus?.key === segment.id)
              "
              seek-behavior="seek"
              @resizeStart="() => editorFocusStore.setFocus('segment', segment.id)"
              @resizeStop="
                (start, end) => {
                  if (start > currentTimeMs || end < currentTimeMs) commitSeek(start)
                  editorFeedStore.updateSegment(segment.id, { start, end })
                }
              "
              @dragStart="() => editorFocusStore.setFocus('segment', segment.id)"
              @dragStop="
                (start, end) => {
                  if (start > currentTimeMs || end < currentTimeMs) commitSeek(start)
                  editorFeedStore.updateSegment(segment.id, { start, end })
                }
              "
              @activated="
                () => {
                  editorFocusStore.setFocus('segment', segment.id)
                }
              "
            >
              <div
                class="flex h-full w-full items-center overflow-hidden rounded-[10px] text-sm font-normal text-white"
              >
                <span
                  class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-1 text-center align-middle font-normal"
                >
                  <IconSaxElement2 class="stroke- h-4 w-4" />
                  {{ editorClipInfoStore.title }}
                </span>
              </div>
              <template #handles>
                <div class="flex h-full flex-row gap-0.5 px-1.5 py-2">
                  <div class="h-full w-1 rounded bg-purple-700"></div>
                  <div class="h-full w-1 rounded bg-purple-700"></div>
                </div>
              </template>
            </TimeLineTrackNode>
          </TimeLineTrack>
          <div class="w-full md:h-8"></div>

          <TimeLinePlayHead
            :current-time="currentTimeMs"
            class="no-drag pointer-events-none absolute inset-y-0 z-20 flex h-full -translate-x-1/2 transform cursor-col-resize px-2"
          >
            <div class="h-full w-[4px] bg-gray-950" />
          </TimeLinePlayHead>
          <!--        <TimeLineSeeker class="no-drag pointer-events-none absolute inset-y-0 flex h-full -translate-x-1/2 transform">-->
          <!--          <div class="h-full w-[4px] bg-gray-500" />-->
          <!--        </TimeLineSeeker>-->
        </Timeline>
      </TimelineContainer>
    </Transition>
    <TimelineFooter v-if="timelineOpen" class="md:hidden" />
  </TimelineRoot>
</template>

<style scoped lang="scss"></style>
