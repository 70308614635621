<script setup lang="ts">
import {
  ToolbarButton,
  ToolbarRoot,
  ToolbarSeparator,
  TooltipRoot,
  TooltipContent,
  TooltipPortal,
  TooltipProvider, TooltipTrigger, TooltipArrow,
} from 'radix-vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import ShapeSelect from '@/modules/CustomLayouts/Crop/CropToolbar/ShapeSelect.vue'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import { inject, computed } from 'vue'
import { useElementBounding } from '@vueuse/core'
import IconSaxDocumentCopy from '@/components/Icons/iconsax/IconSaxDocumentCopy.vue'
import IconBringToFront from '@/components/Icons/IconBringToFront.vue'

const props = defineProps<{ id: string }>()
const cropsStore = inject<CropsStore>('cropsStore')!

const crop = cropsStore.selectById(props.id)

const canvas = inject('canvas') as HTMLCanvasElement
const canvasBounding = useElementBounding(canvas)

const fixedPosition = computed(() => {
  const positionOnCanvas = {
    top: (crop.value.y + crop.value.height) * canvasBounding.height.value,
    left: (crop.value.x + 0.5 * crop.value.width) * canvasBounding.width.value,
  }

  return {
    top: positionOnCanvas.top + canvasBounding.top.value + 'px',
    left: positionOnCanvas.left + canvasBounding.left.value + 'px',
  }
})

const currentCrops = cropsStore.idsWhereLayoutIdIs(crop.value.layoutId)
</script>

<template>
  <Teleport to="body">
    <ToolbarRoot
      :style="fixedPosition"
      class="layer-1 !fixed mt-2 flex !min-w-max max-w-full -translate-x-1/2 animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards] items-center gap-1 rounded-md p-1 shadow-lg"
      @click.stop
    >
      <ShapeSelect :id="id" />

      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <TooltipProvider :delay-duration="0">
          <TooltipRoot>
            <TooltipTrigger class="">
              <div>
                <button
                  class="flex items-center gap-2 rounded p-2 text-sm transition-all hover:bg-zinc-100 hover:text-indigo-400 active:scale-90 active:text-indigo-500"
                  @click.stop="cropsStore.moveToForeground(id)"
                >
                  <IconBringToFront class="stoke-[1] h-4 w-4" />
                </button>
              </div>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent
                class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade z-20 select-none rounded-[4px] layer-1 p-2 text-sm leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
                :side-offset="5"
                :avoid-collisions="true"
                :collision-padding="5"
              >
                <span>Bring to front</span>
                <TooltipArrow class="fill-white" size="8" />
              </TooltipContent>
            </TooltipPortal>
          </TooltipRoot>
        </TooltipProvider>
      </ToolbarButton>

      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <TooltipProvider :delay-duration="0">
          <TooltipRoot>
            <TooltipTrigger class="">
              <div>
                <button
                  :disabled="currentCrops.length >= 10"
                  :class="{ 'pointer-events-none opacity-50': currentCrops.length >= 10 }"
                  class="flex items-center gap-2 rounded p-2 text-sm transition-all hover:bg-zinc-100 hover:text-indigo-400 active:scale-90 active:text-indigo-500"
                  @click.stop="cropsStore.duplicateCropById(id)"
                >
                  <IconSaxDocumentCopy class="stoke-[1] h-4 w-4" />
                </button>
              </div>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent
                class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade z-20 select-none rounded-[4px] layer-1 p-2 text-sm leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
                :side-offset="5"
                :avoid-collisions="true"
                :collision-padding="5"
              >
                <span v-if="currentCrops.length < 10">Duplicate crop</span>
                <span v-else>Cannot add more than 10 crops</span>
                <TooltipArrow class="fill-white" size="8" />
              </TooltipContent>
            </TooltipPortal>
          </TooltipRoot>
        </TooltipProvider>
      </ToolbarButton>

      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <TooltipProvider :delay-duration="0">
          <TooltipRoot>
            <TooltipTrigger as-child class="">
              <div>
                <button
                  :disabled="currentCrops.length === 1"
                  :class="{ 'pointer-events-none opacity-50': currentCrops.length === 1 }"
                  class="flex cursor-pointer items-center gap-2 rounded p-2 text-sm transition-all hover:bg-zinc-100 hover:text-rose-400 active:scale-90 active:text-rose-500"
                  @click.stop="cropsStore.removeById(id)"
                >
                  <IconSaxTrash class="stoke-[1] h-4 w-4" />
                </button>
              </div>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent
                class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade z-20 select-none rounded-[4px] layer-1 p-2 text-sm leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
                :side-offset="5"
                :avoid-collisions="true"
                :collision-padding="5"
              >
                <span v-if="currentCrops.length > 1">Remove crop</span>
                <span v-else>At least one crop is required</span>
                <TooltipArrow class="fill-white" size="8" />
              </TooltipContent>
            </TooltipPortal>
          </TooltipRoot>
        </TooltipProvider>
      </ToolbarButton>
    </ToolbarRoot>
  </Teleport>
</template>

<style lang="scss">
@keyframes slideUpAndFade {
  0% {
    transform: translate(var(--tw-translate-x), 0.25rem) scale(0.95);
    opacity: 0;
  }

  100% {
    transform: translate(var(--tw-translate-x), 0) scale(1);
    opacity: 1;
  }
}
</style>
