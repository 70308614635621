<template>
  <div class="app-bg canary-survey flex min-h-screen w-full flex-col">
    <NavbarApplication />
    <div class="px-3 pb-8">
      <h2 class="mb-6 mt-6 text-center align-baseline text-3xl font-bold text-white">
        <CheckMarkRoundFill class="relative bottom-0.5" />
        Ta-da! Your clip has been converted!
      </h2>

      <div
        class="container mx-auto mt-8 grid max-w-5xl grid-flow-dense gap-4 md:grid-cols-[300px,1fr] md:gap-x-11 md:gap-y-6"
      >
        <div class="box-content aspect-[9/16] rounded-2xl border-10 border-purple-950 bg-purple-950">
          <video ref="resultVideo" :src="resultUrl" class="h-full w-full rounded-xl" controls />
        </div>
        <div class="col-start-1 row-start-3 flex flex-col gap-3 md:row-start-auto">
          <button class="btn-accent btn-block btn-lg btn gap-2" @click="convertAnotherClip">
            <span>Convert another clip</span>
            <AddSquare />
          </button>
          <a
            :download="`streamladder-${clipName}.mp4`"
            :href="resultUrl"
            id="video-result-page-download-link"
            class="btn-outline btn-accent btn-block btn-lg btn gap-2"
            @click="onDownloadClicked"
          >
            <span>Download your clip</span>
            <DownloadIcon />
          </a>
          <button class="btn-outline btn-accent btn-block btn-lg btn gap-2" @click="openQrCodeModal">
            <span>Send to your mobile</span>
            <QrIcon />
            <SilverPlanButton :small="true" feature="qr-code-download" />
          </button>
        </div>

        <div class="md:row-span-3">
          <!-- skeleton -->
          <div class="relative rounded-xl bg-white p-6 md:p-10">
            <ScheduleClipSectionSkeleton v-if="showLoader" />
            <div v-if="showLogin" class="modal modal-open absolute z-30 bg-transparent">
              <div class="modal-box">
                <div class="flex flex-col items-center">
                  <h2 class="text-xl font-bold text-primary">
                    <strong>Connect and Share your clip!</strong>
                  </h2>
                  <p class="text-sm">Login to StreamLadder and share your clips with the world.</p>
                </div>
                <div class="mx-auto flex max-w-sm flex-col gap-2">
                  <twitch-button @click="login(loginProviders.TWITCH)"> Login with Twitch</twitch-button>
                  <google-login-button @click="login(loginProviders.GOOGLE)"></google-login-button>
                </div>
              </div>
            </div>
            <div
              v-if="showProfileCompletion"
              class="modal modal-open relative z-30 bg-transparent py-2 md:absolute md:p-0"
            >
              <div class="modal-box bg-white">
                <CompleteProfileSection @continue="socialsAdded" />
              </div>
            </div>
            <ScheduleClipSection
              v-if="showScheduler"
              :clip-name="clipName"
              :clip-url="resultUrl"
              :on-save="publishClip"
            />
            <div v-if="showSuccess" class="flex flex-col items-center gap-4 p-10 md:p-0">
              <ScheduleClipSuccesHeader :is-posted-to-tik-tok="isPostedToTikTok" :is-scheduled="newPostIsScheduled" />
              <div class="flex flex-col items-center justify-center gap-2">
                <button class="btn-primary btn-lg btn gap-2" @click="convertAnotherClip">
                  <span>Convert another clip</span>
                  <AddSquare />
                </button>
                <router-link
                  v-if="newPostIsScheduled"
                  :to="{ name: dashboardRouteNames.contentPublisher }"
                  class="btn-outline btn-primary btn-lg btn gap-2"
                >
                  View your schedule
                  <calendar-icon />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <get-qr-code ref="QrCodeModal" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavbarApplication from '@/components-v2/navigation/NavbarApplication.vue'
import { computed, onMounted, ref, watch } from 'vue'
import AddSquare from '@/components/Icons/Normalized/AddSquare.vue'
import QrIcon from '@/components/Icons/QrIcon.vue'
import DownloadIcon from '@/components/Icons/DownloadIcon.vue'
import CheckMarkRoundFill from '@/components/Icons/Normalized/CheckMarkRoundFill.vue'
import GetQrCode from '@/components/ResultPage/GetQrCode.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import CompleteProfileSection from '@/components-v2/modules/Account/CompleteProfileSection.vue'
import useLogin from '@/Hooks/useLogin'
import ScheduleClipSection from '@/components-v2/modules/Scheduler/ScheduleClipSection.vue'
import CalendarIcon from '@/components/Icons/CalendarIcon.vue'
import localForage from 'localforage'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { useSocialMediaPost } from '@/components-v2/modules/Scheduler/useSocialMediaPost'
import ScheduleClipSuccesHeader from '@/components-v2/modules/Scheduler/ScheduleClipSuccesHeader.vue'
import ScheduleClipSectionSkeleton from '@/components-v2/modules/Scheduler/ScheduleClipSectionSkeleton.vue'
import { loginProviders } from '@/enums/loginProviders'
import GoogleLoginButton from '@/components/Buttons/GoogleLoginButton.vue'
import TwitchButton from '@/components/Buttons/TwitchButton.vue'
import { storeToRefs } from 'pinia'
import logging from '@/logging'
import type { TargetDto } from '@/apis/streamladder-publisher/model'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useHead } from '@unhead/vue'

const QrCodeModal = ref<GetQrCode>('QrCodeModal')

const userInfo = useUserInfoStore()
const { hasLoadedUserInfo, isAuthenticated } = storeToRefs(userInfo)

const { login, openLoginModal } = useLogin()

const rout = useRoute()
const resultUrlFromUrl = computed(() => rout.query.resultUrl as string)
const clipName = computed(() => (rout.query.clipName || 'clip') as string)

useHead({
  title: `Video Result | ${clipName.value}`,
  meta: [
    {
      name: 'description',
      content: 'Your video result',
    },
  ],
})

const isLocalFile = computed(() => resultUrlFromUrl.value.indexOf('blob:') > -1)
const localFileUrl = ref<string>('')

const resultUrl = computed(() => {
  if (isLocalFile.value) {
    return localFileUrl.value
  }
  return resultUrlFromUrl.value
})

onMounted(async () => {
  if (isLocalFile.value) {
    const fileData = await localForage.getItem('resultBlob')
    if (fileData == null) return
    localFileUrl.value = URL.createObjectURL(fileData)
  }

  logging.trackEvent('VideoResultPage Viewed', {})
})

const showLogin = computed(() => !userInfo.isAuthenticated && userInfo.hasLoadedUserInfo)

// Profile completion display logic
const isProfileComplete = computed(() => userInfo.allSocials.length > 0)
const showProfileCompletion = ref(false)

watch(hasLoadedUserInfo, (newUserLoaded, oldUserLoaded) => {
  if (newUserLoaded && !oldUserLoaded && userInfo.isAuthenticated) {
    showProfileCompletion.value = !isProfileComplete.value
  }
})
watch(isAuthenticated, (newUserAuthenticated, oldUserAuthenticated) => {
  if (newUserAuthenticated && !oldUserAuthenticated && userInfo.hasLoadedUserInfo) {
    showProfileCompletion.value = !isProfileComplete.value
  }
})
onMounted(() => {
  if (userInfo.hasLoadedUserInfo && userInfo.isAuthenticated) {
    showProfileCompletion.value = !isProfileComplete.value
  }
})

// Scheduler display logic
const showScheduler = computed(
  () => userInfo.isAuthenticated && userInfo.hasLoadedUserInfo && !showProfileCompletion.value && !newPost.value
)

const showSuccess = computed(() => newPost.value)

const showLoader = computed(() => !showScheduler.value && !showSuccess.value)

const router = useRouter()

const { newPost, post: publishClipAsync, isScheduled: newPostIsScheduled, isPostedToTikTok } = useSocialMediaPost()

const publishClip = async (publishAt: Date | null, targets: TargetDto[]) => {
  if (isLocalFile.value) throw new Error('Cannot publish local file')
  const url = resultUrl.value
  const post = await publishClipAsync({
    data: {
      contentUrl: url,
      publishAt: publishAt?.toISOString() ?? null,
      targets: targets,
    },
  })

  logging.trackEvent('SocialMediaPost Published', {
    isScheduled: newPostIsScheduled.value,
    publishDate: publishAt?.toISOString() ?? new Date(),
    page: 'VideoResultPage',
    targets: targets.map((target) => target.socialMedia),
  })

  return post
}

const socialsAdded = () => {
  showProfileCompletion.value = false
}

const openQrCodeModal = async () => {
  if (!canGuardWithPopup('qr-code-download')) return
  const url = resultUrl.value
  QrCodeModal.value?.publish(url)
  logging.trackEvent('ResultVideo QrCode Generated', {
    Location: 'Video Result Page',
    Destination: 'QrCode',
    IsPublic: false,
  })
}

// Redirect and reset the page
const resetEditor = useResetEditor()
const convertAnotherClip = () => {
  resetEditor()
  router.push({ name: dashboardRouteNames.editor })
}

function onDownloadClicked() {
  if (!userInfo.isAuthenticated) {
    openLoginModal()
    return
  }

  logging.trackEvent('Video Result Downloaded', {
    location: 'Video Result Page',
  })
}
</script>
