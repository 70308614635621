<template>
  <div class="app-bg flex min-h-screen w-full flex-col">
    <NavbarApplication />
    <div class="px-3 pb-8">
      <div
        class="container mx-auto grid max-w-5xl grid-flow-dense gap-4 md:grid-cols-[300px,1fr] md:gap-x-11 md:gap-y-6 lg:mt-20"
      >
        <IphoneContainer>
          <video v-if="videoData" ref="resultVideo" :src="videoData.resultUrl" class="w-full" controls />
        </IphoneContainer>

        <div class="col-start-1 row-start-3 flex flex-col gap-3 md:row-start-auto">
          <a
            :download="`streamladder-${videoData?.title}.mp4`"
            id="video-detail-page-download-link"
            :href="videoData?.resultUrl"
            class="btn-outline btn-accent btn-block btn-lg btn gap-2"
            @click="onDownloadClicked"
          >
            <span>Download your clip</span>
            <DownloadIcon />
          </a>
          <button class="btn-outline btn-accent btn-block btn-lg btn gap-2" @click="openQrModal">
            <span>Send to your mobile</span>
            <QrIcon />
            <SilverPlanButton :small="true" feature="qr-code-download" />
          </button>
          <router-link
            @click="logging.trackEvent('Clicked Edit as Montage')"
            :to="{ name: dashboardRouteNames.montageMaker }"
            class="btn-outline btn-accent btn-lg btn gap-2"
          >
            <span>Edit as Montage</span>
            <IconSaxVideoVertical class="h4" />
            <DynamicPlanButton size="4" small feature="montage-maker" />
          </router-link>
        </div>

        <div class="md:row-span-3">
          <!-- skeleton -->
          <div class="relative rounded-xl bg-white p-6 md:p-10">
            <ScheduleClipSectionSkeleton v-if="showLoader" />
            <div
              v-if="showProfileCompletion"
              class="modal modal-open relative z-30 bg-transparent py-2 md:absolute md:p-0"
            >
              <div class="modal-box bg-white">
                <CompleteProfileSection @continue="socialsAdded" />
              </div>
            </div>
            <ScheduleClipSection
              v-if="showScheduler"
              :clip-name="clipName"
              :clip-url="clipUrl"
              :on-save="publishClip"
            />
            <div v-if="showSuccess" class="flex flex-col items-center gap-4 p-10 md:p-0">
              <ScheduleClipSuccesHeader :is-posted-to-tik-tok="isPostedToTikTok" :is-scheduled="isScheduled" />
              <div class="flex flex-col items-center justify-center gap-2">
                <button class="btn-primary btn-lg btn gap-2" @click="convertAnotherClip">
                  <span>Convert another clip</span>
                  <AddSquare />
                </button>
                <router-link
                  v-if="isScheduled"
                  :to="{ name: dashboardRouteNames.contentPublisher }"
                  class="btn-outline btn-primary btn-lg btn gap-2"
                >
                  View your schedule
                  <calendar-icon />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <get-qr-code ref="QrCodeModal" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavbarApplication from '@/components-v2/navigation/NavbarApplication.vue'
import IphoneContainer from '@/components/Phones/IphoneContainer.vue'
import { computed, onMounted, ref, watch } from 'vue'
import AddSquare from '@/components/Icons/Normalized/AddSquare.vue'
import QrIcon from '@/components/Icons/QrIcon.vue'
import DownloadIcon from '@/components/Icons/DownloadIcon.vue'
import GetQrCode from '@/components/ResultPage/GetQrCode.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import CompleteProfileSection from '@/components-v2/modules/Account/CompleteProfileSection.vue'
import useLogin from '@/Hooks/useLogin'
import ScheduleClipSection from '@/components-v2/modules/Scheduler/ScheduleClipSection.vue'
import CalendarIcon from '@/components/Icons/CalendarIcon.vue'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import { useSocialMediaPost } from '@/components-v2/modules/Scheduler/useSocialMediaPost'
import ScheduleClipSectionSkeleton from '@/components-v2/modules/Scheduler/ScheduleClipSectionSkeleton.vue'
import ScheduleClipSuccesHeader from '@/components-v2/modules/Scheduler/ScheduleClipSuccesHeader.vue'
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import type { TargetDto } from '@/apis/streamladder-publisher/model'
import logging from '@/logging'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRouter } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue'
import IconSaxVideoVertical from '@/components/Icons/iconsax/IconSaxVideoVertical.vue'

const userInfo = useUserInfoStore()
const hasLoadedUserInfo = computed(() => userInfo.hasLoadedUserInfo)

const { openLoginModal } = useLogin()

const props = defineProps<{
  videoId: string
}>()

const { data } = useGetApiVideos()
const videoData = computed(() => data.value?.find((video) => video.id === props.videoId))
const clipName = computed(() => videoData.value?.title)
const clipUrl = computed(() => videoData.value?.resultUrl)

// Profile completion display logic
const isProfileComplete = computed(() => userInfo.allSocials.length > 0)
const showProfileCompletion = ref(false)

watch(hasLoadedUserInfo, (newUserLoaded, oldUserLoaded) => {
  if (newUserLoaded && !oldUserLoaded && userInfo.isAuthenticated) {
    showProfileCompletion.value = !isProfileComplete.value
  }
})
onMounted(() => {
  if (userInfo.hasLoadedUserInfo && userInfo.isAuthenticated) {
    showProfileCompletion.value = !isProfileComplete.value
  }
})

// QR code modal
const QrCodeModal = ref()
const openQrModal = () => {
  if (!canGuardWithPopup('qr-code-download')) return
  QrCodeModal.value.publish(videoData.value?.resultUrl)
  logging.trackEvent('ResultVideo QrCode Generated', {
    Location: 'Video Detail Page',
    Destination: 'QrCode',
    IsPublic: false,
  })
}

const { newPost, isScheduled, post: scheduleClip, isPostedToTikTok } = useSocialMediaPost()

// Scheduler display logic
const showScheduler = computed(
  () =>
    userInfo.isAuthenticated &&
    userInfo.hasLoadedUserInfo &&
    !showProfileCompletion.value &&
    !newPost.value &&
    videoData &&
    clipName.value &&
    clipUrl.value
)

const publishClip = async (publishAt: Date | null, targets: TargetDto[]) => {
  const post = scheduleClip({
    data: {
      contentUrl: clipUrl.value,
      publishAt: publishAt?.toISOString(),
      targets: targets,
    },
  })

  logging.trackEvent('SocialMediaPost Published', {
    isScheduled: isScheduled.value,
    publishDate: publishAt?.toISOString() ?? Date.now(),
    page: 'VideoDetailPage',
    targets: targets.map((target) => target.socialMedia),
  })

  return post
}

const showSuccess = computed(() => newPost.value)

const showLoader = computed(() => !showScheduler.value && !showSuccess.value)

const socialsAdded = () => {
  showProfileCompletion.value = false
}

// Redirect and reset the page
const resetEditor = useResetEditor()
const router = useRouter()
const convertAnotherClip = () => {
  resetEditor()
  router.push({ name: dashboardRouteNames.editor })
}

function onDownloadClicked() {
  if (!userInfo.isAuthenticated) {
    openLoginModal()
    return
  }

  logging.trackEvent('Video Result Downloaded', {
    location: 'Video Detail Page',
  })
}
</script>
