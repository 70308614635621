<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useMouseInElement, useParentElement } from '@vueuse/core'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { PopoverContent, PopoverPortal, PopoverRoot, PopoverTrigger } from 'radix-vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { findClosestFittingLocation } from '@/modules/SLVideoplayer/helpers'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'

const editorFocusStore = useEditorFocusStore()
const editorFeedStore = useEditorFeedDataStore()

const { pxToMs, msToPx, durationMs, dragging } = useTimelineStore()

const parentEl = useParentElement()
const { isOutside, elementX } = useMouseInElement(parentEl)

const width = ref(52)
const popupOpen = ref(false)
let storedLastPosition: { point: number; distance: number } | undefined

const positionOnTimeline = computed(() => {
  if (popupOpen.value && storedLastPosition != undefined) {
    return storedLastPosition
  }

  const closestFittingLocation = findClosestFittingLocation(
    elementX.value,
    width.value,
    0,
    msToPx(durationMs.value),
    editorFeedStore.zooms.map((zoom) => ({
      start: msToPx(zoom.start, true),
      end: msToPx(zoom.end, true),
    }))
  )

  if (popupOpen.value && storedLastPosition == undefined) {
    storedLastPosition = closestFittingLocation
    return storedLastPosition
  }

  // reset storedLastPosition if the popover is closed
  storedLastPosition = undefined

  return (
    closestFittingLocation || {
      point: elementX.value,
      distance: 0,
    }
  )
})

const left = computed(() => positionOnTimeline.value.point)

async function addZoom() {
  const canAdd = canGuardWithPopup('zoom-segments')
  if (!canAdd) return
  const targetMs = pxToMs(left.value, true)
  const duration = pxToMs(width.value, true)

  const id = editorFeedStore.addZoom({
    start: targetMs - duration / 2,
    end: targetMs + duration / 2,
  })
  await nextTick()
  editorFocusStore.setFocus('zoom', id)
  popupOpen.value = false
}
</script>

<template>
  <PopoverRoot v-model:open="popupOpen">
    <PopoverTrigger
      ref="node"
      class="peer absolute rounded-[10px] border-none bg-purple-700 p-2 px-3 text-white"
      v-if="popupOpen || (!isOutside && positionOnTimeline.distance <= 0 && dragging?.type !== 'node')"
      :style="{
        left: `${left}px`,
        transform: `translateX(-50%)`,
        width: `${width}px`,
      }"
    >
      <IconSaxAdd size="1.5rem" />
    </PopoverTrigger>
    <PopoverPortal>
      <PopoverContent
        side="right"
        data-retain-focus
        class="text black z-50 flex flex-col items-start items-stretch overflow-hidden rounded-xl bg-white shadow-2xl"
      >
        <button class="btn-accent btn justify-start gap-2 rounded-none py-0.5 pt-1" @click="addZoom">
          <IconSaxAdd />
          Add Zoom
          <span class="badge badge-success text-white">New!</span>
        </button>
        <button class="btn-accent btn justify-start gap-2 rounded-none py-0.5 opacity-40">
          <IconSaxAdd />
          Add Sticker
          <span class="badge text-white">Coming soon</span>
        </button>
        <button class="btn-accent btn justify-start gap-2 rounded-none py-0.5 pb-1 opacity-40">
          <IconSaxAdd />
          Add Text
          <span class="badge text-white">Coming soon</span>
        </button>
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>

<style scoped lang="scss"></style>
