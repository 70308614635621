<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { postSupportForm } from '@/queries/n8n'

const description = ref('')
const router = useRouter()

const proceed = async () => {
  await postSupportForm(description.value, 'TechnicalDifficultiesFeedback')
  await router.push({ name: 'OfferHelp' })
}
</script>

<template>
  <div class="panel relative min-h-[70vh] items-center justify-center">
    <div class="mx-auto flex w-full flex-col gap-8 lg:w-1/2">
      <div>
        <h1 class="text-3xl font-bold text-company-primary">What issue were you experiencing?</h1>
        <p>Sharing more can help us improve for you and other streamers.</p>
      </div>

      <textarea
        v-model="description"
        class="placeholder-text-gray-700 rounded bg-gray-100 p-4"
        placeholder="Could you describe in more detail what you experienced?"
        rows="4"
        type="text"
      />

      <div class="flex w-full justify-between">
        <router-link
          :to="{ name: 'CancelSubscription' }"
          class="cursor-pointer rounded-full px-4 py-2 text-gray-700 hover:bg-gray-200"
        >
          Back
        </router-link>
        <button class="btn-primary btn" @click="proceed">Continue</button>
      </div>
    </div>
  </div>
</template>
