import { useEditorStickersStore } from '@/store/editor/editorStickers'
import useLogin from '@/Hooks/useLogin'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import { getLayout } from '@/data/layoutData'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import localForage from 'localforage'
import { useRoute, useRouter } from 'vue-router'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { postApiRenders, useGetApiRenders } from '@/apis/streamladder-api/renders/renders'
import { getSegmentsFromStores } from '@/components/Clip2TikTok/renderingHelpers'
import { computed } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import type { RenderDto } from '@/apis/streamladder-api/model'
import { isBefore, subMinutes } from 'date-fns'
import { useDebounceFn } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import logging from '@/logging'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { setLayoutArguments } from '@/services/layoutHelper'

export const useStreamladderRender = () => {
  const { awaitUserLogin } = useLogin()
  const editorStickersStore = useEditorStickersStore()
  const editorCaptionsStore = useEditorCaptionsStore()
  const editorClipInfoStore = useEditorClipInfoStore()
  const editorFeedDataStore = useEditorFeedDataStore()
  const editorMainStore = useEditorMainStore()
  const editorVideoStore = useEditorVideoStore()
  const router = useRouter()
  const route = useRoute()

  const userInfoStore = useUserInfoStore()
  const {
    data: renderData,
    refetch,
    isLoading,
  } = useGetApiRenders(undefined, {
    query: {
      enabled: computed(() => userInfoStore.isAuthenticated),
    },
  })
  const renders = computed(() => renderData.value?.value?.items ?? [])

  const fetchRenders = async (): Promise<RenderDto[]> => {
    return await refetch().then((res) => res.data?.value?.items ?? [])
  }

  // date-fns function to check if a render is older than 10 minutes using date-fns library

  const isRendering = computed(() => {
    return renders.value.some(
      (render) =>
        render.status === 'rendering' && !isBefore(new Date(render.createdAt || ''), subMinutes(new Date(), 10))
    )
  })

  const preprocess = async () => {
    // Make sure the user is authenticated
    // Wait for the user to authenticate
    const userIsAuthenticated = await awaitUserLogin('Please login to render your video')
    if (!userIsAuthenticated)
      return {
        type: 'error',
        message: 'User is not authenticated',
      } as const

    const hasStickers = editorStickersStore.hasStickers
    if (hasStickers && !canGuardWithPopup('stickers')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to stickers',
      } as const
    }

    const hasCaptions = editorCaptionsStore.hasCaptions
    if (hasCaptions && !canGuardWithPopup('captions')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to captions',
      } as const
    }

    const hasPremiumLayout = getLayout(editorMainStore.layoutName)?.premium || false
    if (hasPremiumLayout && !canGuardWithPopup('layouts')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to layouts',
      } as const
    }

    const canServerSideRender = canGuard('server-side-rendering')

    // the user cannot server side queue as they are free so preload the required data and send user to preprocessing
    if (!canServerSideRender) {
      // prepare ffmpeg data
      // redirect to preprocess where the state data will be reduced to a command string
      // and redirected to the render page for cors purposes
      setLayoutArguments(route.params.layout)
      const hasFfmpegString = editorMainStore.ffmpegStringArray.length > 0 || editorClipInfoStore.mp4Url
      if (!hasFfmpegString) {
        return {
          type: 'error',
          message: 'No ffmpeg string data',
        } as const
      }

      const query: Record<string, string> = {
        clipName: editorClipInfoStore.title,
        command: btoa(JSON.stringify(editorMainStore.ffmpegStringArray)),
        lf: (editorClipInfoStore.isLocalFile ?? false).toString(),
        trimmedDurationMs: editorMainStore.trimmedDurationMs?.toString(),
        trimmedStartTime: editorMainStore.trimmedStartTime?.toString(),
      }

      if (!editorClipInfoStore.isLocalFile) {
        query.clipUrl = editorClipInfoStore.mp4Url
      }

      return {
        type: 'local',
        generateUrl: `${location.origin}/generate?${new URLSearchParams(query).toString()}`,
      } as const
    }

    if (canServerSideRender) {
      // make sure the user has their file uploaded if they use server side queueing
      const hasLocalFile = editorClipInfoStore.isLocalFile
      const isFileSuccessful = editorClipInfoStore.uploadedLocalFileBackgroundSuccess
      const isFileUploading = editorClipInfoStore.isUploadingLocalFileBackground
      if (hasLocalFile && !isFileSuccessful) {
        try {
          if (!isFileUploading) {
            const fileData = await localForage.getItem('localFile')
            await editorClipInfoStore.uploadClipInBackground(fileData)
          }
          const uploadSuccess = await editorClipInfoStore.waitForUpload()
          if (!uploadSuccess) throw new Error('Failed to upload file')
        } catch (e) {
          return {
            type: 'error',
            message: 'Failed to upload file',
          } as const
        }
      }

      const canQueRenders = canGuard('server-side-queueing')
      if (!canQueRenders) {
        return {
          type: 'server',
          status: 'wait',
        } as const
      }

      // redirect to preprocess where the client will collect data and send it to the server
      return {
        type: 'server',
        status: 'ready',
      } as const
    }

    return {
      type: 'error',
      message: 'No render type',
    } as const
  }

  const trackClipCreation = (options: Awaited<ReturnType<typeof preprocess>>) => {
    const eventProperties = {
      Layout: editorMainStore.layoutName,
      Source: route.meta.clipSource,
      ...editorStickersStore.getLoggingData(),
      ...editorCaptionsStore.getLoggingData(),
      ...editorFeedDataStore.getLoggingData(),
      SelectedResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      SegmentCount: editorVideoStore.segments.length,
    }

    if (options.type === 'interrupted') {
      logging.trackEvent('Clip Creation Interrupted', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    if (options.type === 'error') {
      logging.trackEvent('Clip Creation Error', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    logging.trackEvent('Clip Created', {
      ...eventProperties,
      renderer: options.type,
      Queued: options.type === 'server' && options.status === 'ready',
    })
  }

  const renderVideoOnServer = async () => {
    const editorStickersStore = useEditorStickersStore()
    const editorCaptionsStore = useEditorCaptionsStore()
    const editorClipInfoStore = useEditorClipInfoStore()
    const editorMainStore = useEditorMainStore()
    // strip out the visible and component properties
    const stickerData = editorStickersStore.selectedStickers.map(({ visible, component, ...properties }) => properties)

    const hasOverlay = editorStickersStore.hasStickers || editorCaptionsStore.hasCaptions

    const isLocalFile = editorClipInfoStore.isLocalFile
    const contentUrl = isLocalFile ? editorClipInfoStore.uploadedLocalFileResultUrl : editorClipInfoStore.mp4Url

    const res = await postApiRenders({
      contentUrl: contentUrl,
      title: editorClipInfoStore.title,
      segments: getSegmentsFromStores(),
      outputFPS: editorMainStore.outputWidth === 1080 ? 60 : 30,
      outputResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      overlay: hasOverlay
        ? {
            Stickers: stickerData,
            Captions: editorCaptionsStore.captions,
            CaptionsWrapper: editorCaptionsStore.captionsWrapper,
            DurationMs: editorMainStore.videoDuration,
            captionsObject: {
              CaptionStyleSettings: editorCaptionsStore.captionStyleSettings,
            },
          }
        : null,
    }).catch((e) => {
      return null
    })
    if (res == null)
      return {
        type: 'error',
        message: 'No response',
      } as const

    const taskId = res.value?.id
    if (!taskId)
      return {
        type: 'error',
        message: 'No taskId',
      } as const
    return {
      type: 'server',
      task: res.value,
    } as const
  }

  const requestVideoRender = useDebounceFn(async () => {
    try {
      // Validate the current editor state
      // make sure the user is authenticated
      // make sure the user has a subscription
      // make sure the users does not use features that are not allowed
      // return the type of rendering required
      const preprocessResult = await preprocess()
      trackClipCreation(preprocessResult)
      if (preprocessResult.type === 'error') return preprocessResult
      if (preprocessResult.type === 'local') {
        // user is on the client, rendering the video.
        // The url contains all relevant data to render the video
        // for cors reasons we need hard redirect to the url
        console.log('redirecting to', preprocessResult.generateUrl)
        window.location.href = preprocessResult.generateUrl
        return preprocessResult
      }
      if (preprocessResult.type === 'interrupted') {
        // user is not allowed to render
        return preprocessResult
      }

      if (preprocessResult.type === 'server') {
        // The rendering needs to be done on the server
        if (preprocessResult.status === 'wait') {
          // make sure a silver user does not have more than one render
          // wait for latest render information
          // if the user is rendering, show a popup
          await fetchRenders()
          if (isRendering.value) {
            const canContinue = canGuardWithPopup('server-side-queueing', {
              title: 'One at a Time, Please!',
              subtitle:
                'Your Silver Membership allows one active render. Choose to wait, or upgrade to Gold for multiple renders.',
            })
            if (!canContinue) return { type: 'error', message: 'There is already a render running' }
          }
        }
        // user is ready to render on the server

        const renderResult = await renderVideoOnServer()
        if (renderResult.type === 'error') return renderResult
        if (renderResult.type === 'server') {
          // trigger a refresh of the render list
          // this wil trigger a poll
          return renderResult
        }
      }
    } catch (e) {
      Sentry.captureException(e)
      return {
        type: 'error',
        message: 'Error',
      }
    }
    return {
      type: 'error',
      message: 'Error',
    }
  }, 1000)

  return {
    preprocess,
    renderVideoOnServer,
    requestVideoRender,
  }
}
