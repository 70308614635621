<script lang="ts" setup>
import { range } from 'lodash-es'
import { useTwitchClips, type Clip } from '@/Hooks/useTwitchClips'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import { useQueries } from '@tanstack/vue-query'
import { getGetApiTwitchClipsClipInfoQueryOptions } from '@/apis/streamladder-api/twitch-clips/twitch-clips'
import { computed, ref } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'

const userInfoStore = useUserInfoStore()

const sampleClips = [
  'CrispyHeartlessAdminThisIsSparta-SYsnQDmG57Yr3Dyq',
  'ExuberantToughAnteaterTakeNRG-FDURk-g0tD3ue2Kq',
  'AnimatedMoralWalletMingLee-ODj2KlFT8ICSSGJs',
]

function toClipQuery(url) {
  const options = getGetApiTwitchClipsClipInfoQueryOptions({ clipId: url })
  return {
    ...options,
    queryFn: async (...args) => ({ ...(await options.queryFn(...args)), id: url }),
  }
}

const queries = useQueries({
  queries: sampleClips.map(toClipQuery),
})

const recentClips = useTwitchClips({ filter: 'recency' })

const isLoading = computed(() => {
  return userInfoStore.isAuthenticated ? recentClips.isLoading.value : queries.some((q) => q.isLoading)
})

const clips = computed(() => {
  return userInfoStore.isAuthenticated ? recentClips.clips.value : queries.map((q) => q.data ?? [])
})

const emit = defineEmits<{ (name: 'select', clip: Clip): void }>()

function select(clip: Clip) {
  emit('select', clip)
}

const previewId = ref<string | null>(null)
function preview(clip: Clip) {
  previewId.value = clip.id
}
</script>

<template>
  <h4 v-if="userInfoStore.isAuthenticated" class="m-0 p-0 text-lg font-normal lg:text-xl">Your most recent clips</h4>
  <h4 v-else class="m-0 p-0 text-lg font-normal lg:text-xl">Try out the editor with a sample clip</h4>

  <ol class="grid-single-row -m-4 grid grid-cols-1 gap-x-2 p-4 pb-0 md:grid-cols-3 md:gap-x-4">
    <li v-for="i in range(3)" :key="'sample-clip-' + i">
      <ClipPreviewCard
        :clip="clips[i]"
        :disable-preview="!userInfoStore.isAuthenticated"
        :is-loading="isLoading || !userInfoStore.hasLoadedUserInfo"
        overlay-scaling="scale-75"
        @select="select"
        @preview="preview"
      />
    </li>
  </ol>

  <teleport v-if="userInfoStore.isAuthenticated && clips" to="body">
    <TwitchClipPreviewDialog v-model="previewId" :clips="clips" @confirm="select" />
  </teleport>
</template>

<style lang="scss" scoped></style>
