<template>
  <TwBaseDialog :background="'layer-1'" :close-event="events.close" :open-event="events.open" styles="max-w-lg">
    <template v-slot:header="{ close }">
      <div class="absolute right-2 top-2">
        <div class="cursor-pointer p-2 text-gray-300 hover:text-gray-700" @click="close()">
          <cross-icon class="h-6 w-6 fill-current" />
        </div>
      </div>
    </template>
    <div class="flex w-full flex-col justify-center gap-4 p-4 lg:p-8 xl:p-12">
      <div v-if="!isAuthenticated">
        <div class="flex flex-col items-center gap-8">
          <h4 class="flex items-center gap-4">
            <img alt="StreamLadder branding" class="h-12 w-12" height="192" src="/images/logo.png" width="192" />
            <span class="text-2xl font-semibold leading-[1]">StreamLadder</span>
          </h4>
          <h5 class="text-2xl font-semibold">{{ title || 'Please continue by logging in' }}</h5>
        </div>

        <twitch-button class="w-100 mt-4" @click="login(loginProviders.TWITCH)"> Login with Twitch</twitch-button>
        <google-login-button class="w-100 mt-2" @click="login(loginProviders.GOOGLE)"></google-login-button>
        <test-login />
      </div>
      <div v-else class="flex flex-col justify-center text-center">
        <success-icon />
        <h5><strong>Success</strong></h5>
        <p>
          <slot> Successfully logged in. You can now close this dialog.</slot>
        </p>
        <button class="w-full rounded bg-green-600 p-2 text-white hover:bg-green-800" @click="close">
          Close dialog
        </button>
      </div>
    </div>
  </TwBaseDialog>
</template>

<script>
import { mapState } from 'pinia'
import SuccessIcon from '../Icons/SuccessIcon.vue'
import TwitchButton from '../../Buttons/TwitchButton.vue'
import GoogleLoginButton from '../../Buttons/GoogleLoginButton.vue'
import TwBaseDialog from '../TwBaseDialog.vue'
import authEvents from '../../../events/authEvents'
import EventBus from '../../../eventBus'
import popupService from '../../../services/popupService'
import CrossIcon from '../../Icons/CrossIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { loginProviders } from '@/enums/loginProviders'
import useLogin from '@/Hooks/useLogin'
import TestLogin from '@/components/Account/TestLogin.vue'
import { defineComponent } from 'vue'
import { noop } from 'lodash-es'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import { tiers } from '@/enums/tiers'

export default defineComponent({
  components: {
    SilverPlanButton,
    GoldPlanButton,
    CrossIcon,
    TwBaseDialog,
    TwitchButton,
    SuccessIcon,
    GoogleLoginButton,
    TestLogin,
  },
  mixins: [popupService],
  data() {
    return {
      events: {
        close: authEvents.CLOSE_LOGIN_DIALOG,
        open: authEvents.OPEN_LOGIN_DIALOG,
      },
      callback: noop,
      loginPopup: null,
      title: '',
    }
  },
  computed: {
    tiers() {
      return tiers
    },
    ...mapState(useUserInfoStore, ['isAuthenticated']),
    loginProviders() {
      return loginProviders
    },
  },
  methods: {
    close() {
      EventBus.$emit(authEvents.CLOSE_LOGIN_DIALOG)
      if (this.callback) this.callback()
    },
    async sync() {
      const userInfoStore = useUserInfoStore()
      await userInfoStore.updateUserInfo()
      if (userInfoStore.isAuthenticated) {
        this.loginSuccess()
      }
    },
    async receiveMessage(event) {
      const { data } = event
      if (data === 'login-success') {
        await this.sync()
        this.loginPopup?.close()
      }
    },
    loginSuccess() {
      this.close()
    },
    async login(provider) {
      await this.$trackEvent('Log in Clicked', {
        provider,
      })
      const { login } = useLogin()
      await login(provider)
    },
  },
  mounted() {
    EventBus.$on(authEvents.OPEN_LOGIN_DIALOG, (data) => {
      if (typeof data === 'object' && data.callback) {
        this.callback = data.callback
        if (data.title) this.title = data.title
      }
    })

    EventBus.$on(this.events.close, () => this.callback())
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage)
    EventBus.$off(authEvents.OPEN_LOGIN_DIALOG)
    EventBus.$off(authEvents.CLOSE_LOGIN_DIALOG)
  },
})
</script>

<style lang="scss" scoped>
.streamladder-logo {
  background: linear-gradient(80.58deg, #21d2fe -4.97%, #a236fe 167.75%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
