<template>
  <button class="btn-google btn w-full rounded-none bg-white transition-all hover:text-white" v-bind="$attrs">
    <google-icon></google-icon>
    <slot>Login with Google</slot>
  </button>
</template>

<script>
import GoogleIcon from "../Icons/GoogleIcon.vue";

export default {
  components: {
    GoogleIcon
  }
};
</script>

<style lang="scss">
button.btn-google {
  --google-blue: #4285f4;
  border: 1px solid var(--google-blue);
  line-height: 2em;

  > svg.google-icon {
    height: 1.5em;
    width: auto;
    margin-right: 1em;
  }

  &:hover {
    border-color: var(--google-blue);
    background-color: var(--google-blue);
  }

  color: gray;
}
</style>
