<template>
  <video
    class="rounded-lg"
    ref="videoElement"
    :autoplay="autoPlay"
    :hidden="hidden"
    :controls="controls"
    playsinline
    :src="mp4Url"
    :width="width"
    :height="height"
    loop
    muted
    preload="auto"
    crossorigin="anonymous"
    @canplay.once="videoLoaded"
    @pause="videoStore.preservedPaused = true"
    @play="videoStore.preservedPaused = false"
    disableRemotePlayback
    disablePictureInPicture
  />
</template>

<script lang="ts" setup>
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { storeToRefs } from 'pinia'
import { onBeforeUnmount, ref } from 'vue'

defineProps({
  updateVideoData: {
    type: Boolean,
    default: true,
  },
  allowUnmute: {
    type: Boolean,
    default: true,
  },
  autoPlay: {
    type: Boolean,
    default: false,
  },
  hidden: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['loaded'])
const isLoaded = ref(false)

const clipInfoStore = useEditorClipInfoStore()
const { mp4Url } = storeToRefs(clipInfoStore)

const videoStore = useEditorVideoStore()

const videoElement = ref<HTMLVideoElement | null>(null)
const width = ref<number>()
const height = ref<number>()

const videoLoaded = () => {
  if (videoElement.value) {
    videoStore.loadVideo(videoElement.value, mp4Url.value)
    width.value = videoElement.value.videoWidth
    height.value = videoElement.value.videoHeight

    isLoaded.value = true
    emit('loaded', videoElement.value)
  } else {
    if (videoStore._duration !== 0) return
    setTimeout(videoLoaded, 200)
  }
}

onBeforeUnmount(() => {
  videoStore.unmountElement()
})
</script>
