<script lang="ts" setup>
import Stepper from '../../components/Editor/Stepper/Stepper.vue'
import AppPage from '../../components/AppPage.vue'
import { feedTypes } from '@/modules/SLVideoplayer/types'
import FacecamIcon from '../../components/Icons/FacecamIcon.vue'
import GamefeedIcon from '../../components/Icons/GamefeedIcon.vue'
import BreadCrumbSeperator from '../../components/Sources/BreadCrumb/BreadCrumbSeperator.vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import WarningIcon from '../../components/Icons/WarningIcon.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import {getLayout, LayoutKeys, type LayoutKey, LayoutData, getLayoutNameByKey} from '@/data/layoutData'
import SplitViewSplitser from '@/components/Layouts/SplitViewSplitser.vue'
import { maxOutputDuration } from '@/data/outputDuration'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import logging from '@/logging'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import Timeline from '@/components/Editor/Timeline/Timeline.vue'
import Cropper from '@/components/Editor/Cropper.vue'
import VideoPlayerContainer from '@/components/VideoPlayerContainer.vue'

const editorMainStore = useEditorMainStore()
const editorFeedDataStore = useEditorFeedDataStore()
const editorVideoStore = useEditorVideoStore()

const step = ref(0)
const isEditorLoaded = ref(false)
const layout = ref(null)
const editor = ref<HTMLVideoElement | null>(null)

const displayClipTooLongWarning = computed(() => {
  // Check if the clip is longer than the maximum allowed duration of a TikTok video, if true then display a warning
  return isEditorLoaded.value && editorVideoStore.duration > maxOutputDuration
})

const fragment = computed(() => {
  return isEditorLoaded.value ? editorFeedDataStore.fragmentArray[step.value] : null
})

const router = useRouter()
const route = useRoute()

const layoutName = computed(() => {
  return getLayoutNameByKey(route.params.layout as string)
})

const isPremium = computed(() => {
  return layout.value ? getLayout(layout.value)?.premium : false
})

const content = computed(() => {
  const titles = {
    facecam: 'Select the facecam',
    gamefeed: 'Select the gamefeed',
    gameui: 'Select the game UI',
    free: 'Select the content',
    custom: 'Select the content',
  }
  const nextStepTexts = {
    facecam: 'Select facecam',
    gamefeed: 'Select gamefeed',
    gameui: 'Select game UI',
    free: 'Select content',
    custom: 'Select next content',
  }

  const title = `${step.value + 3}. ${titles[fragment.value?.feedOptions.type] || titles['free']}`

  const nextFragment = editorFeedDataStore.fragmentArray[step.value + 1]
  const nextStepText = nextFragment ? nextStepTexts[nextFragment.feedOptions.type] : 'Edit video'
  return {
    title,
    nextStepText,
  }
})

const editorLoaded = async (video: HTMLVideoElement) => {
  const editorFeedStore = useEditorFeedDataStore()
  editorFeedStore.initSegments(video.duration * 1000)

  if (!video) throw new Error('Video not loaded')

  editor.value = video

  if (editorFeedDataStore.segments.length > 0) {
    const editorFocusStore = useEditorFocusStore()
    editorFocusStore.setFocus(FocusTypes.FEED, editorFeedDataStore.segments[0].id)
  }

  // If we have no fragments yet, initialize it to default for the new selected layout
  if (Object.keys(editorFeedDataStore.fragments).length === 0) {
    if (layoutName.value === 'Custom') {
      editorFeedDataStore.initializeForCustomLayout(route.params.layout as string)
    } else {
      editorFeedDataStore.initializeForCrop(layoutName.value, video.clientWidth, video.clientHeight)
    }
  }

  isEditorLoaded.value = true

  video.oncanplaythrough = () => {
    isVideoLoaded.value = true
  }
}

const isVideoLoaded = ref(false)

const changeSplitRatio = (ratio: string) => {
  router.replace({ name: route.name, params: { layout: `Split${ratio}` } })
  editorFeedDataStore.initializeForCrop(`Split${ratio}`, editor.value.clientWidth, editor.value.clientHeight)
}

const nextStep = () => {
  window.scrollTo(0, 0)
  editorFeedDataStore.storeCropData(fragment.value.key)

  // for free layouts, we need to calculate the aspect ratio of the gamefeed
  if (fragment.value.key === 'splitfree_fc') {
    editorFeedDataStore.fragments['splitfree_gf'].cropOptions.aspectRatio =
      9 / (16 - (9 / (fragment.value.cropData.w * 16)) * (fragment.value.cropData.h * 9))
    editorFeedDataStore.fragments['splitfree_gf'].cropOptions.fixedRatio = true
  }

  step.value = step.value + 1
  if (step.value === editorFeedDataStore.fragmentArray.length) {
    // Const set Layout in the store
    editorMainStore.layoutName = layoutName.value

    // Move to the next page
    router.push({
      name: `editor/main`,
    })
  }
}

onMounted(async () => {
  await logging.trackEvent('Layout Selected', {
    Layout: route.params.layout,
    SavedTemplate: !!route.query.savedTemplate,
    IsPremiumLayout: isPremium.value,
    IsCustomLayout: false,
  })
})

const isReady = computed(() => isEditorLoaded.value && isVideoLoaded.value)
</script>

<template>
  <app-page header-size="medium" show-breadcrumb alert-on-leave-site>
    <template v-slot:sub-nav>
      <stepper />
    </template>
    <template v-slot:content>
      <div class="z-10 w-full">
        <h2 class="text-white">{{ content.title }}</h2>
        <div class="flex flex-col gap-6">
          <div class="flex flex-col gap-6 rounded-xl border-2 border-gray-100 bg-white p-4 lg:flex-row">
            <div class="relative aspect-[16/9] max-w-full basis-2/3 lg:max-w-[66%]">
              <div v-if="!isReady" class="loader absolute inset-0 grid place-items-center">
                <div class="spinner-border h-32 w-32 text-[#2fc1fe]" role="status" />
              </div>
              <VideoPlayerContainer @loaded="editorLoaded" :is-preview="false" update-video-data auto-play>
                <Cropper v-if="isReady && fragment" :key="fragment.key" :fragment="fragment" />
              </VideoPlayerContainer>
            </div>
            <div class="row flex basis-1/3 flex-col justify-between" style="min-height: 100%">
              <div class="w-full" style="margin-top: 2em">
                <!-- Free cropper text -->
                <div v-if="fragment?.feedOptions?.type === feedTypes.Free || layoutName === 'Custom'" class="crop-explanation">
                  <div class="head flex gap-2">
                    <facecam-icon />
                    <div class="font-bold">Content</div>
                  </div>
                  <h3>Select area</h3>
                  <p>Drag and resize the box to wherever you want the video to be cropped.</p>
                </div>

                <!-- cropper is facecam -->
                <div v-if="fragment?.feedOptions?.type === feedTypes.FaceCam" class="crop-explanation">
                  <div class="head flex gap-2">
                    <facecam-icon />
                    <div class="font-bold">Facecam</div>
                  </div>
                  <h3>Select area where your facecam is</h3>
                  <p>Drag and resize the box to wherever your facecam is.</p>
                </div>

                <!-- cropper for gameplay -->
                <div v-if="fragment?.feedOptions?.type === feedTypes.GameFeed" class="crop-explanation">
                  <div class="head flex gap-2">
                    <gamefeed-icon />
                    <div class="font-bold">Gamefeed</div>
                  </div>
                  <h3>Select where your gamefeed is</h3>
                  <p>
                    Drag and resize the box to where your gameplay is. This is the gameplay which you'll see below your
                    facecam in the final video. Usually, it's the center part and you don't have to change it.
                  </p>
                </div>

                <!-- cropper for game ui -->
                <div v-if="fragment?.feedOptions?.type === feedTypes.GameUi" class="crop-explanation">
                  <div class="head flex gap-2">
                    <gamefeed-icon />
                    <strong>Game ui</strong>
                  </div>
                  <h3>Select where your game ui is</h3>
                  <p>Drag and resize the box to where your game ui is.</p>
                </div>
              </div>

              <SplitViewSplitser
                v-if="
                  fragment?.feedOptions?.type === feedTypes.FaceCam &&
                  [LayoutKeys.Split16x9, LayoutKeys.Split4x3, LayoutKeys.SplitFree].includes(layoutName)
                "
                @cropperRatioChanged="changeSplitRatio"
              />

              <div>
                <div
                  v-show="displayClipTooLongWarning"
                  class="flex w-full items-center justify-center rounded bg-company-primary-300 bg-opacity-10 p-2 text-sm text-company-primary"
                >
                  <div class="flex flex-col items-center justify-center pl-2 pr-4 text-company-primary-300">
                    <warning-icon class="h-5 w-5 fill-current" />
                  </div>
                  <p class="mb-0">
                    If you make a video longer than {{ maxOutputDuration.inMinutes() }} minutes, you won't be able to
                    upload your video to TikTok and Youtube through StreamLadder
                  </p>
                </div>
              </div>
              <!-- used for justify-content css, so dont delete -->
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex w-full flex-col">
        <div class="container mx-auto">
          <div class="relative w-full">
            <button
              class="floating-footer-button btn-primary btn absolute bottom-full right-0 mb-6 gap-2 sm:btn-xl"
              :disabled="!isEditorLoaded"
              @click="nextStep"
            >
              {{ content.nextStepText }}
              <bread-crumb-seperator />
            </button>
          </div>
        </div>
        <Timeline class="mx-0 w-full border-0" step="crop" :default-open="true" />
      </div>
    </template>
  </app-page>
</template>
