<template>
  <div class="relative flex h-12 w-20 flex-col justify-center">
    <button
      @click="onClickHandler"
      class="flex h-12 w-12 origin-center transform cursor-pointer items-center justify-center rounded-full border border-company-primary-300 text-company-primary-300 transition hover:bg-company-primary-300 hover:text-white active:scale-95"
      title="Add new social account"
    >
      <plus-icon class="fill-current" width="12" height="12" />
    </button>
  </div>
</template>
<script>
import PlusIcon from '../../Icons/PlusIcon.vue'
import EventBus from '../../../eventBus'
import accountEvents from '../../../events/accountEvents'

export default {
  components: { PlusIcon },
  methods: {
    onClickHandler() {
      EventBus.$emit(accountEvents.OPEN_CONNECT_SOCIAL_MEDIA_DIALOG)
    },
  },
}
</script>
