import type { Tier, TierLevel } from '@/data/plans'
import { ref } from 'vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import { useUserInfoStore } from '@/store/user/userInfo'
import CanUpgradeAccounts from '@/mixins/canUpgradeAccounts.js'

const isLoading = ref<TierLevel | null>(null)
const error = ref<'configuration-unknown' | 'checkout-failed' | null>(null)

const { interval } = useSubscriptionIntervals()

const upgrade = async (tier: Tier, reason: string, discountCode?: string | null) => {
  if (isLoading.value === null) {
    error.value = null

    const userInfoStore = useUserInfoStore()
    if (userInfoStore.trialStatus !== 'active' && tier.tier <= userInfoStore.tier) {
      return
    }

    isLoading.value = tier.key

    const plans = [...tier.plans]
    const plan = plans.find((p) => p.key === interval.value)

    if (plan === undefined) {
      error.value = 'configuration-unknown'
      return
    }

    const meta = {
      fromTier: userInfoStore.tier,
      toTier: tier.tier,
      interval: interval.value,
    }

    try {
      await CanUpgradeAccounts.methods.attemptCheckout(plan.paddle, discountCode, reason, meta)
    } catch (e) {
      error.value = 'checkout-failed'
    }

    isLoading.value = null
  }
}

export const usePlanUpgrade = () => {
  return { isLoading, error, upgrade }
}
