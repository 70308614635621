<script setup lang="ts">
import ArrowRightIcon from '../../Icons/ArrowRightIcon.vue'
import { useRoute } from 'vue-router'
import Step from '@/components/Editor/Stepper/Step.vue'
import { computed } from 'vue'

const route = useRoute()

const hasSource = computed(() => route.params.source !== undefined)
const hasClipId = computed(() => route.params.clipId !== undefined)
</script>

<template>
  <div class="sl-breadcrumb flex">
    <div class="flex flex-row gap-2">
      <step
        step-name="layout"
        full-text="Layout"
        mobile-text="1."
        :route-name="'editor'"
        :is-active="route.name === 'editor'"
        :is-link="hasSource && route.name !== 'editor'"
      />

      <span>
        <arrow-right-icon />
      </span>

      <step
        step-name="crop"
        full-text="Crop"
        mobile-text="2."
        :route-name="'editor/cropping'"
        :is-active="route.name === 'editor/cropping'"
        :is-link="hasSource && route.name !== 'editor/cropping' && route.name !== 'editor'"
      />

      <span>
        <arrow-right-icon />
      </span>

      <step
        step-name="edit"
        full-text="Edit"
        mobile-text="3."
        :route-name="'editor/main'"
        :is-active="route.name === 'editor/main'"
        :is-link="
          hasSource && route.name !== 'editor/main' && route.name !== 'editor/cropping' && route.name !== 'editor'
        "
      />

      <span>
        <arrow-right-icon />
      </span>

      <span
        class="text-white opacity-50"
        :class="{
          active:
            hasSource && route.name !== 'editor/main' && route.name !== 'editor/cropping' && route.name !== 'editor',
        }"
        >Export</span
      >
    </div>
  </div>
</template>

<style lang="scss">
.sl-breadcrumb {
  .mobile-breadcrumb {
    display: none;
    width: 100%;
    flex-basis: 100%;
    font-size: 1em;
  }

  .full-breadcrumb {
    display: inline;
  }

  svg {
    opacity: 0.5;
  }
}
</style>
