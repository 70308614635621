import type { Area, Point, Directions, Bounds, Size } from '@/modules/SLMovable/@types/Movable'
import { clamp } from 'lodash-es'
import { keepPointInsideBoundsY, keepPointInsideBoundsX } from '@/modules/SLMovable/helpers/point'
import { contain, cover } from '@/modules/SLMovable/helpers/fit'

export function resizeAspectLocked(
  from: Area,
  to: Point,
  directions: Directions,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null
): Area {

  const isResizingX = directions.includes('e') || directions.includes('w')
  const isResizingY = directions.includes('n') || directions.includes('s')

  if (isResizingX && isResizingY) {
    return resizeBothAspectLocked(from, to, directions, bounds, aspectLock, minSize)
  } else if (isResizingX) {
    return resizeXAspectLocked(from, to, directions, bounds, aspectLock, minSize)
  } else if (isResizingY) {
    return resizeYAspectLocked(from, to, directions, bounds, aspectLock, minSize)
  }

  throw new Error('At least one direction must be provided')
}

function resizeXAspectLocked(
  from: Area,
  to: Point,
  directions: Directions,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null
): Area {

  const anchorX = directions.includes('w')
    ? from.x + from.width
    : from.x

  const anchorY = from.y + 0.5 * from.height

  const clampedX = keepPointInsideBoundsX(to.x, bounds)
  const deltaX = clampedX - anchorX

  const width = minSize
    ? Math.max(minSize.width, Math.abs(deltaX))
    : deltaX

  const height = width * (aspectLock.height / aspectLock.width)

  const top = anchorY - 0.5 * height
  const bottom = top + height

  const clampedTop = -1 * top + keepPointInsideBoundsY(top, bounds)
  const clampedBottom = bottom - keepPointInsideBoundsY(bottom, bounds)

  const newHeight = height - 2 * Math.max(clampedTop, clampedBottom)
  const newWidth = newHeight * (aspectLock.width / aspectLock.height) * Math.sign(deltaX)

  const isCrossingLeftBound = bounds !== null && bounds.left !== null && anchorX + newWidth < bounds.left
  const isCrossingRightBound = bounds !== null && bounds.right !== null && anchorX + newWidth > bounds.right

  let newX = Math.min(anchorX, anchorX + newWidth)
  if (isCrossingLeftBound) {
    newX = anchorX
  } else if (isCrossingRightBound) {
    newX = anchorX - newWidth
  }

  return {
    x: newX,
    y: anchorY - 0.5 * newHeight,
    width: Math.abs(newWidth),
    height: Math.abs(newHeight),
  }
}

function resizeYAspectLocked(
  from: Area,
  to: Point,
  directions: Directions,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null
): Area {

  const anchorY = directions.includes('n')
    ? from.y + from.height
    : from.y

  const anchorX = from.x + 0.5 * from.width

  const clampedY = keepPointInsideBoundsY(to.y, bounds)
  const deltaY = clampedY - anchorY

  const height = minSize
    ? Math.max(minSize.height, Math.abs(deltaY))
    : deltaY

  const width = height * (aspectLock.width / aspectLock.height)

  const left = anchorX - 0.5 * width
  const right = left + width

  const clampedLeft = -1 * left + keepPointInsideBoundsX(left, bounds)
  const clampedRight = right - keepPointInsideBoundsX(right, bounds)

  const newWidth = width - 2 * Math.max(clampedLeft, clampedRight)
  const newHeight = newWidth * (aspectLock.height / aspectLock.width) * Math.sign(deltaY)

  const isCrossingTopBound = bounds !== null && bounds.top !== null && anchorY + newHeight < bounds.top
  const isCrossingBottomBound = bounds !== null && bounds.bottom !== null && anchorY + newHeight > bounds.bottom

  let newY = Math.min(anchorY, anchorY + newHeight)
  if (isCrossingTopBound) {
    newY = anchorY
  } else if (isCrossingBottomBound) {
    newY = anchorY - newHeight
  }

  return {
    x: anchorX - 0.5 * Math.abs(newWidth),
    y: newY,
    width: Math.abs(newWidth),
    height: Math.abs(newHeight),
  }
}

function resizeBothAspectLocked(
  from: Area,
  to: Point,
  directions: Directions,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null,
): Area {

  const anchorX = directions.includes('w')
    ? from.x + from.width
    : from.x
  const anchorY = directions.includes('n')
    ? from.y + from.height
    : from.y

  const clampedX = keepPointInsideBoundsX(to.x, bounds)
  const clampedY = keepPointInsideBoundsY(to.y, bounds)

  const deltaX = clampedX - anchorX
  const deltaY = clampedY - anchorY

  const newWidth = minSize
    ? Math.max(Math.abs(deltaX), minSize.width)
    : Math.abs(deltaX)

  const newHeight = minSize
    ? Math.max(Math.abs(deltaY), minSize.height)
    : Math.abs(deltaY)

  let size = cover(aspectLock, {
    width: newWidth,
    height: newHeight,
  })

  if (bounds) {
    size = contain(size, {
      width: clamp(newWidth, Math.abs(bounds.left! - deltaX), Math.abs(bounds.right! - deltaX)),
      height: clamp(newHeight, Math.abs(bounds.top! - deltaY), Math.abs(bounds.bottom! - deltaY)),
    })
  }

  const isCrossingLeftBound = bounds !== null && bounds.left !== null && anchorX + size.width * Math.sign(deltaX) < bounds.left
  const isCrossingRightBound = bounds !== null && bounds.right !== null && anchorX + size.width * Math.sign(deltaX) > bounds.right
  const isCrossingTopBound = bounds !== null && bounds.top !== null && anchorY + size.height * Math.sign(deltaY) < bounds.top
  const isCrossingBottomBound = bounds !== null && bounds.bottom !== null && anchorY + size.height * Math.sign(deltaY) > bounds.bottom

  let newX = Math.min(anchorX, anchorX + (size.width * Math.sign(deltaX)))
  if (isCrossingLeftBound) {
    newX = anchorX
  } else if (isCrossingRightBound) {
    newX = anchorX - size.width
  }

  let newY = Math.min(anchorY, anchorY + (size.height * Math.sign(deltaY)))
  if (isCrossingTopBound) {
    newY = anchorY
  } else if (isCrossingBottomBound) {
    newY = anchorY - size.height
  }

  return {
    x: newX,
    y: newY,
    width: size.width,
    height: size.height,
  }
}
