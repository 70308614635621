<script setup lang="ts">
import LottieAnimation from '@/components/LottieAnimation.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import socialProof from '@/components-v2/modules/SocialProof/social-proof.json'
import type { User } from '@sentry/vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'

const userInfoStore = useUserInfoStore()

const emit = defineEmits<{
  (event: 'openUpgradeDialog'): void
}>()

const users = socialProof.users as User[]
</script>

<template>
  <div
    class="sticky -mb-[1px] flex w-full items-center justify-center bg-[#059669] p-2"
    v-if="userInfoStore.tier !== tiers.GOLD"
  >
    <div class="container flex flex-col items-center justify-between sm:flex-row">
      <div class="flex items-center">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="mr-2 hidden w-12 sm:block" />
        <div class="cursor-default p-2">
          <p class="md:text-md text-center text-sm font-extrabold text-white sm:text-left lg:text-lg">
            {{ `Get More with StreamLadder ${userInfoStore.tier === tiers.SILVER ? 'Gold' : 'Silver or Gold'}` }}
          </p>
          <p class="hidden text-sm font-light text-white/90 md:block">
            You're currently on the <span class="capitalize">{{ tiers.toString(userInfoStore.tier) }}</span> version -
            upgrade to join 50k+ streamers enjoying advanced features and growth
          </p>
        </div>
      </div>
      <div class="flex h-full items-center justify-center pl-4">
        <button
          class="btn-success btn-sm btn h-6 rounded-md border-none bg-white px-2 text-[11px] uppercase text-[#059669] drop-shadow-md hover:bg-white/90 md:h-10 md:px-4 md:text-[13px]"
          @click="emit('openUpgradeDialog')"
        >
          Upgrade Now <IconSaxArrowRight class="ml-1 h-4 w-4" />
        </button>

        <div class="ml-4 hidden md:block">
          <ol class="flex flex-row justify-center">
            <li
              v-for="user in users.slice(0, 5)"
              :key="user.handle"
              class="relative -mx-0.5 flex h-7 w-7 flex-col items-center justify-center"
            >
              <img
                draggable="false"
                class="absolute h-full w-full rounded-full border-2 border-stone-50 bg-stone-50 object-contain"
                :title="user.handle"
                :src="user.avatar"
                :alt="`${user.handle}'s profile picture`"
              />
              <div
                class="absolute bottom-0 right-0 z-10 flex h-3 w-3 items-center justify-center rounded-full bg-stone-50"
              >
                <TwitchIcon v-if="user.platform === 'twitch'" class="h-2 w-2 text-[#6441a5]" />
                <YoutubeIcon v-if="user.platform === 'youtube'" class="h-2 w-2 text-[#F00]" />
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
