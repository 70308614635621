<script lang="ts" setup>
import TierComparison from '@/components/Tiers/TierComparison.vue'
import { computed, onMounted, onUnmounted, watch } from 'vue'
import upgradeEvents from '@/events/upgradeEvents'
import type { Feature } from '@/data/features'
import { useFeature } from '@/data/features'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import unwrap from '@/helpers/unwrap'
import plans from '@/data/plans'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import compatabilityChecker from '@/services/compatabilityChecker'
import logging from '@/logging'
import { useModal } from '@/Hooks/useModal'
import CrossIcon from '@/components/Icons/CrossIcon.vue'
import eventBus from '@/eventBus'
import SocialProof from '@/components-v2/modules/SocialProof/SocialProof.vue'
import { useUserInfoStore, onUserInfoReadyAsync } from '@/store/user/userInfo'
import { canGuard } from '@/Hooks/useGuard'
import settings from '@/data/settings'

type Payload = {
  feature?: Feature
  reason?: string
  title?: string
  subtitle?: string
}

const { isLoading, error } = usePlanUpgrade()

const { isShowing, payload, close, open } = useModal<Payload>({
  closeEvent: upgradeEvents.CLOSE_UPGRADE_DIALOG,
})

const userInfoStore = useUserInfoStore()

async function onOpen(payload: Payload = {}) {
  await userInfoStore.ensureUserIsLoggedIn()
  await onUserInfoReadyAsync()

  if (payload.feature && canGuard(payload.feature)) return

  open(payload)
}

onMounted(() => {
  eventBus.$on(upgradeEvents.OPEN_UPGRADE_DIALOG, onOpen)
  eventBus.$on(upgradeEvents.UPGRADE_SUCCESS, close)
})

onUnmounted(() => {
  eventBus.$off(upgradeEvents.OPEN_UPGRADE_DIALOG, onOpen)
  eventBus.$off(upgradeEvents.UPGRADE_SUCCESS, close)
})

const featureString = computed(() => payload.value?.feature)
const feature = useFeature(featureString)
const dialog = computed(() => {
  return {
    title: payload.value?.title || feature.value?.dialog?.title,
    subtitle: payload.value?.subtitle || feature.value?.dialog?.subtitle,
  }
})

const editorStickersStore = useEditorStickersStore()

watch(isShowing, (value) => {
  if (value) {
    logging.trackEvent('Upgrade SubscriptionDialog Opened', {
      // Why the dialog was opened
      ReasonOpened: payload.value?.reason,
      Payload: payload.value,

      // Properties for user action/choices
      HasStickers: editorStickersStore.hasStickers,
      HasPremiumTemplate: false,

      // Properties of the client
      IsMobile: compatabilityChecker.isMobile(),
      Is32Bit: compatabilityChecker.is32Bit(),
    })
  }
})

const title = computed(() => dialog.value?.title ?? 'Choose a plan')

const tier = computed(() => {
  return unwrap.values(plans).find((p) => p.tier === feature.value?.tier)
})

const subtitle = computed(() => {
  const htmlContent = dialog.value?.subtitle ?? 'Choose the perfect plan for your needs'
  return htmlContent.replace(/\{\{ tier }}/gi, tier.value?.title ?? 'Gold')
})

const handleClose = (payload: Payload) => {
  if (!isLoading.value) {
    close(payload)
  }
}
</script>

<template>
  <Teleport to="body">
    <div
      @click.self="handleClose"
      class="modal h-screen w-screen p-0 md:p-0"
      :class="{
        'modal-open': isShowing,
      }"
      v-if="isShowing"
    >
      <div
        class="modal-box max-h-full w-full max-w-full gap-12 overflow-y-auto rounded-none pb-0 md:rounded-lg min-[1175px]:max-w-[1175px] xl:flex xl:items-stretch xl:overflow-hidden"
      >
        <div
          v-if="!isLoading"
          class="absolute right-2 top-2 z-10 cursor-pointer p-2 text-company-primary hover:text-company-primary xl:text-gray-300"
          @click="close"
        >
          <cross-icon class="h-6 w-6 fill-current" />
        </div>
        <div class="mt-4 flex-1 justify-center gap-8 pb-8 md:p-0 xl:min-h-0 xl:min-w-[620px] xl:p-11 xl:pt-3">
          <div class="flex max-h-full flex-col gap-4 overflow-y-auto xl:gap-2">
            <div class="flex flex-col items-center">
              <SocialProof variant="dense" />
              <p class="text-center text-black">Trusted by 200k happy users</p>
            </div>
            <header class="flex min-h-[110px] flex-col items-center justify-center text-center">
              <h3 class="pb-2 pt-0 text-center text-4xl leading-[1.2] text-[#2F184E] lg:text-4xl" v-html="title" />
              <p class="text-center text-lg font-thin text-[#2F184E] md:text-base 2xl:text-lg" v-html="subtitle" />
            </header>

            <div class="flex flex-col items-center gap-2 pb-6">
              <TierComparison
                :reason="payload?.reason ?? 'unknown'"
                :tiers="unwrap.values(plans)"
                variant="transparent"
              />
            </div>

            <div v-if="error" class="alert alert-error">
              <div class="flex w-full flex-row gap-4">
                <svg
                  class="flex-shrink-0"
                  fill="none"
                  height="33"
                  viewBox="0 0 32 33"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16" cy="16.5" fill="#FDCECE" r="16" />
                  <path
                    d="M16 6.5C14.0222 6.5 12.0888 7.08649 10.4443 8.1853C8.79981 9.28412 7.51809 10.8459 6.76121 12.6732C6.00433 14.5004 5.8063 16.5111 6.19215 18.4509C6.578 20.3907 7.53041 22.1725 8.92894 23.5711C10.3275 24.9696 12.1093 25.922 14.0491 26.3078C15.9889 26.6937 17.9996 26.4957 19.8268 25.7388C21.6541 24.9819 23.2159 23.7002 24.3147 22.0557C25.4135 20.4112 26 18.4778 26 16.5C25.9949 13.8494 24.9397 11.3088 23.0655 9.43455C21.1912 7.56028 18.6506 6.50508 16 6.5ZM19.625 19.0288C19.7694 19.1747 19.8505 19.3717 19.8505 19.5769C19.8505 19.7822 19.7694 19.9792 19.625 20.125C19.478 20.2672 19.2814 20.3466 19.0769 20.3466C18.8724 20.3466 18.6759 20.2672 18.5288 20.125L16 17.5865L13.4712 20.125C13.3241 20.2672 13.1276 20.3466 12.9231 20.3466C12.7186 20.3466 12.522 20.2672 12.375 20.125C12.2306 19.9792 12.1495 19.7822 12.1495 19.5769C12.1495 19.3717 12.2306 19.1747 12.375 19.0288L14.9135 16.5L12.375 13.9712C12.2523 13.8217 12.1897 13.632 12.1991 13.4388C12.2086 13.2457 12.2896 13.063 12.4263 12.9263C12.563 12.7896 12.7457 12.7086 12.9388 12.6991C13.132 12.6896 13.3217 12.7523 13.4712 12.875L16 15.4135L18.5288 12.875C18.6783 12.7523 18.868 12.6896 19.0612 12.6991C19.2543 12.7086 19.437 12.7896 19.5737 12.9263C19.7104 13.063 19.7914 13.2457 19.8009 13.4388C19.8104 13.632 19.7477 13.8217 19.625 13.9712L17.0865 16.5L19.625 19.0288Z"
                    fill="#F30C0C"
                  />
                </svg>
                <div class="w-full text-left">
                  <span class="break-words sm:text-lg">Uh oh!</span>
                  <p class="w-full max-w-full whitespace-pre-line break-all text-sm sm:break-normal">
                    <template v-if="error === 'configuration-unknown'">
                      This plan configuration could not be found. Please contact our support team through our&nbsp;
                      <a :href="settings.discordInviteUrl" target="_blank" class="link text-indigo-500 hover:no-underline"
                        >Discord</a
                      >.
                    </template>
                    <template v-if="error === 'checkout-failed'">
                      Something went wrong while updating your subscription. Please ensure you are logged in. If the
                      problem persists, please don't hesitate to contact us through our&nbsp;
                      <a :href="settings.discordInviteUrl" target="_blank" class="link text-indigo-500 hover:no-underline"
                        >Discord</a
                      >.
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss"></style>
