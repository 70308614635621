<template>
  <tw-base-dialog styles="max-w-3xl" :modelValue="modelValue" @update:modelValue="(e) => $emit('update:modelValue', e)">
    <template v-slot:header="{ close }">
      <div class="absolute right-4 top-4">
        <div class="cursor-pointer p-2 text-gray-300 hover:text-company-primary" @click="close()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current" viewBox="0 0 512 512">
            <path
              d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
            />
          </svg>
        </div>
      </div>
    </template>
    <div class="flex w-full flex-col flex-col-reverse lg:flex-row">
      <div class="flex w-full flex-col px-4 py-16 lg:p-12">
        <div v-if="!success" class="main-content flex-1">
          <h2 class="mb-0 text-4xl font-bold text-company-primary">Upload video</h2>
          <p class="opacity-70">Add custom videos and schedule them via our scheduler</p>

          <div
            v-if="!isUploading"
            @dragover.prevent="isDraggingFile = true"
            @dragleave.prevent="isDraggingFile = false"
            @drop.prevent="handleOnFileDrop"
            @click="onDropzoneClick"
            :class="{
              'border-dashed border-company-primary': !isDraggingFile && !uploadedFile,
              'border-solid border-green-600': isDraggingFile && !uploadedFile,
              'cursor-pointer': !uploadedFile,
            }"
            class="dropzone my-8 flex flex-col items-center justify-center rounded-xl border-2 bg-company-primary bg-opacity-10 py-8"
          >
            <div v-if="!uploadedFile" class="flex w-full flex-col items-center justify-center">
              <lottie-animation class="w-1/4" url="/lottie/upload-to-server.json" :auto-play="true" />
              <p class="mb-0 text-xl" v-if="!isDraggingFile">Drop files</p>
              <p class="mb-0 text-xl text-green-600" v-else>Drop the file in here!</p>
              <p class="text-xs text-gray-700">(or click here!)</p>
              <p class="text-xs text-gray-700">Supported formats: {{ validExtensions.join(', ') }}</p>

              <input
                type="file"
                class="hidden"
                ref="fileInput"
                @change="fileUploaded"
                :accept="hiddenFileUploadAccept"
              />
            </div>
            <div v-else class="flex w-full flex-col items-center justify-center">
              <div
                class="video-container video-wrapper relative flex cursor-pointer select-none flex-col items-center rounded-xl"
                @mouseover="hoveringOverVideo = true"
                @mouseleave="hoveringOverVideo = false"
              >
                <video
                  :src="uploadedFileSource"
                  class="shadow-xl"
                  ref="video"
                  autoplay="autoplay"
                  loop="loop"
                  disableRemotePlayback
                  disablePictureInPicture
                  muted
                />
                <div
                  v-if="hoveringOverVideo"
                  @click.stop="reset"
                  class="bg-trans-white absolute flex flex-col items-center justify-center text-gray-700"
                  style="width: 100%; height: 100%"
                >
                  <cross-icon class="h-8 w-8 fill-current" />
                  <span class="text-xs">Remove this video</span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="uploadedFile !== null" class="mb-8 flex w-full flex-col">
            <p v-if="isUploading">
              Uploading your file<span class="dots"><span>.</span><span>.</span><span>.</span></span>
            </p>
            <stream-ladder-progression-input
              :errors="fileError"
              :progression-percentage="uploadPercentage"
              placeholder="filename"
              label="Filename"
              v-model="fileName"
            />
          </div>

          <div class="flex w-full justify-between">
            <button class="btn-outline btn-primary btn" @click="close">Back</button>
            <button
              class="btn-primary btn"
              :disabled="uploadedFile === null"
              :class="{ loading: isUploading }"
              @click="uploadFile"
            >
              <span>Upload</span>
            </button>
          </div>
        </div>
        <div v-else class="flex-1">
          <lottie-feedback-component title="Upload complete!">
            <template #footer>
              <p class="mb-0">You can now use this clip for scheduling!</p>
              <p>This pop-up will close automatically in {{ countdown.timer }}</p>
            </template>
          </lottie-feedback-component>
        </div>
      </div>
    </div>
  </tw-base-dialog>
</template>
<script>
import axios from 'axios'
import TwBaseDialog from '../TwBaseDialog.vue'
import LottieAnimation from '../../LottieAnimation.vue'
import uploadService from '../../../services/uploadService'
import LottieFeedbackComponent from '../Components/LottieFeedbackComponent.vue'
import StreamLadderProgressionInput from '../../Form/StreamLadderProgressionInput.vue'
import CrossIcon from '../../Icons/CrossIcon.vue'
import schedulerTrackingEvents from '../../../logging/events/scheduler'
import { getGetApiVideosQueryKey, postApiVideos } from '@/apis/streamladder-api/videos/videos'
import { queryClient } from '@/services/QueryClient'

export default {
  components: {
    CrossIcon,
    TwBaseDialog,
    LottieAnimation,
    LottieFeedbackComponent,
    StreamLadderProgressionInput,
  },
  props: {
    modelValue: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      cancelSource: null,
      success: null,
      isDraggingFile: false,
      isUploading: false,
      uploadedFile: null,
      uploadedFileSource: null,
      toUpload: false,
      hoveringOverVideo: false,
      uploadPercentage: 0,
      fileName: null,
      validExtensions: ['MP4', 'MOV'],
      fileError: '',
      countdown: {
        interval: null,
        timer: 3,
      },
    }
  },
  computed: {
    hiddenFileUploadAccept() {
      return this.validExtensions.map((ext) => `.${ext.toLowerCase()}`).join(',')
    },
  },
  methods: {
    handleOnFileDrop(e) {
      e.preventDefault()

      if (e.dataTransfer.items) {
        ;[...e.dataTransfer.items].forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            this.uploadedFile = item.getAsFile()
          }
        })
      } else {
        // Use DataTransfer interface to access the file(s)
        ;[...e.dataTransfer.files].forEach((file) => {
          this.uploadedFile = file
        })
      }

      this.isDraggingFile = false
    },
    async uploadFile() {
      if (!this.validate() || this.isUploading) return

      this.isUploading = true

      //Creating a cancellation token
      this.cancelSource = axios.CancelToken.source()

      // Start uploading the file.
      const result = await uploadService.getUploadResultSignedUrl()
      const res = await uploadService.uploadFileS3(
        result.signedUrl,
        this.uploadedFile,
        (p) => (this.uploadPercentage = p),
        'video/mp4',
        '',
        { cancelToken: this.cancelSource.token }
      )

      // Only post a video when the result is 200 (uploaded).
      if (res.status === 200) {
        const videoResponse = await postApiVideos({
          videoUrl: result.resultUrl,
          title: this.fileName,
        })
        await queryClient.invalidateQueries({
          queryKey: getGetApiVideosQueryKey(),
        })

        this.$emit('uploaded', videoResponse.value.id)

        this.$trackEvent(schedulerTrackingEvents.USER_UPLOAD_OWN_CLIP, {})

        this.success = true
        this.fileError = ''
      }
      this.isUploading = false

      this.startAutoClosing()
    },
    startAutoClosing() {
      this.countdown.timer = 3
      this.countdown.interval = setInterval(() => {
        if (this.countdown.timer <= 1) {
          clearInterval(this.countdown.interval)
          this.close()
        }
        this.countdown.timer -= 1
      }, 1000)
    },
    validate() {
      if (!this.validateExtension()) {
        this.fileError = 'The extension you are trying to upload is not allowed!'
      }

      return this.fileError === ''
    },
    validateExtension() {
      const ext = this.uploadedFile.name.split('.').at(-1)

      return this.validExtensions.includes(ext.toUpperCase())
    },
    reset() {
      this.uploadedFile = null
    },
    close() {
      this.$emit('update:modelValue', false)
    },
    onDropzoneClick() {
      if (this.uploadedFile) return
      this.$refs.fileInput.click()
    },
    fileUploaded(e) {
      this.uploadedFile = e.target.files[0]
    },
    async documentKeyDown(e) {
      if (e.code === 'Enter' && this.uploadedFile !== null) {
        await this.uploadFile()
      }
    },
  },
  watch: {
    uploadedFile(curr) {
      // Delete previous versions of the uploadedFileSource
      if (this.uploadedFileSource) {
        URL.revokeObjectURL(this.uploadedFileSource)
        this.uploadedFileSource = null
      }

      if (curr !== null) {
        this.fileName = curr.name.split('.').at(0)
        this.uploadedFileSource = URL.createObjectURL(this.uploadedFile)
      }
    },
    value(curr) {
      if (!curr) {
        if (this.cancelSource !== null) {
          try {
            this.cancelSource.cancel('Upload cancelled')
            this.cancelSource = null
          } catch (e) {
            //
          }
        }
        this.uploadedFile = null
        this.uploadPercentage = 0
        this.isUploading = false
        this.success = null
        this.fileError = ''
        this.cancelSource = null
        window.removeEventListener('keypress', this.documentKeyDown)
      } else {
        window.addEventListener('keypress', this.documentKeyDown)
      }
    },
  },
}
</script>
<style scoped lang="scss">
@keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}

@keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}

@-webkit-keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}

@-webkit-keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}

.bg-trans-white {
  background-color: rgba(255, 255, 255, 0.5);
}

.dots span {
  animation: dots-1 1s infinite steps(1);
  -webkit-animation: dots-1 1s infinite steps(1);
}

.dots span:first-child + span {
  animation-name: dots-2;
  -webkit-animation-name: dots-2;
}

.dots span:first-child + span + span {
  animation-name: dots-3;
  -webkit-animation-name: dots-3;
}

.video-container {
  width: 33%;

  video {
    max-width: 100%;
    border-radius: 15px;
  }
}
</style>
