<script setup lang="ts">
import { computed } from 'vue'
const props = defineProps<{
  points: [x: number, y: number][]
  containerWidth: number
}>()

const stroke = 2
const scaleX = computed(() => Math.round(props.containerWidth))
const scaleY = computed(() => Math.round((scaleX.value / 9) * 16))
const points = computed(() => {
  return props.points.map(([x, y]) => `${Math.round(x * scaleX.value)} ${Math.round(y * scaleY.value)}`).join(', ')
})
</script>

<template>
  <svg
    :width="scaleX"
    :height="scaleY"
    :viewBox="`${stroke} ${stroke} ${Math.max(scaleX - 2 * stroke, 1)} ${Math.max(scaleY - 2 * stroke, 1)}`"
    class="absolute inset-0 stroke-2 grid-cols-1 grid-rows-1 place-items-center z-[40] pointer-events-none"
  >
    <polyline
      :points="points"
      :stroke-width="stroke"
      stroke-dasharray="6 7"
      stroke-linecap="square"
      stroke-linejoin="bevel"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
