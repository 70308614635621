<template>
  <div class="flex w-full flex-col items-center" ref="container">
    <div class="color-picker" @click.stop="" :style="{ maxWidth }">
      <div
        v-for="(color, className) in options"
        :key="className"
        class="dot"
        :class="{ selected: modelValue === className }"
        :style="{ background: color }"
        @click.stop="$emit('update:modelValue', className)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core'
import { computed, ref } from 'vue'

// Expects an object in {className: #hexColorWhichIsDisplayed}. the className is used as v-model
const props = defineProps<{ options: Record<string, string>; modelValue: string }>()

const container = ref<HTMLDivElement>()
const { width } = useElementSize(container)

const itemsPerRow = (width: number) => Math.floor((width - 11) / 25)
const itemsInLastRow = (width: number) => itemCount.value % itemsPerRow(width)

const itemCount = computed(() => Object.keys(props.options).length)
const maxWidth = computed(() => {
  let newWidth = width.value
  while (itemsInLastRow(newWidth) === 1 && itemsPerRow(newWidth) > 2) {
    newWidth -= 10
  }
  return newWidth + 'px'
})
</script>

<style lang="scss" scoped>
.color-picker {
  padding: 8px; // Together with @click.stop this prevents misclicks for firing a sticker-add event
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-wrap: wrap;

  background-color: white;
}
.dot {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  cursor: pointer;

  opacity: 0.5;
  border: 1px solid white;
  box-shadow: 0 0 0 1px rgb(70, 8, 158, 0.5);

  &:hover,
  &.selected {
    opacity: 1;
    border: 1px solid white;
    box-shadow: 0 0 0 1px #46089e;
  }
}
</style>
