<script setup lang="ts">
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useCampaignComponent } from '@/data/campaigns'
import { useHead } from '@unhead/vue'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import {
  getGetApiRendersQueryKey,
  putApiRendersIdRead,
  useGetApiRendersId,
} from '@/apis/streamladder-api/renders/renders'
import { useRouter } from 'vue-router'
import FeatureShowcase from '@/components/Clip2TikTok/FeatureShowcase.vue'
import AsyncComponent from '@/components/Campaigns/AsyncComponent.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import StatusRow from '@/components/Clip2TikTok/StatusRow.vue'
import { useRouteQuery } from '@vueuse/router'
import { isArray } from 'lodash-es'
import { useUserInfoStore } from '@/store/user/userInfo'
import type { RenderDto } from '@/apis/streamladder-api/model'
import { queryClient } from '@/services/QueryClient'
import { useLiveRenderStatus } from '@/Hooks/useLiveRenderStatus'

const editorMainStore = useEditorMainStore()
const editorClipInfoStore = useEditorClipInfoStore()
const editorCaptionsStore = useEditorCaptionsStore()
const LoaderComponent = useCampaignComponent('generate-loader')
const userInfoStore = useUserInfoStore()

const router = useRouter()

useHead({
  title: 'Generating video...',
  bodyAttrs: {
    class: 'bg-base-100',
  },
})

type PageStatus = 'idle' | 'rendering' | 'waiting' | 'error' | 'success'
const pageStatus = ref<PageStatus>('idle')

type Status = 'idle' | 'in-progress' | 'success' | 'error' | 'in-endless-progress'

const setupStatus = ref<Status>('in-endless-progress')

const videoHasErrors = ref(false)
const taskId = useRouteQuery('task')

const cleanTaskId = computed(() => {
  return (isArray(taskId.value) ? taskId.value[0] : taskId.value) || ''
})

const { data, refetch } = useGetApiRendersId(cleanTaskId, {
  query: {
    enabled: computed(() => userInfoStore.isAuthenticated),
    refetchInterval: 8000,
    refetchIntervalInBackground: true,
  },
})

const { overlayStatus, overlayPercentage, uploadStatus, renderStatus, renderPercentage, isFinished } =
  useLiveRenderStatus(cleanTaskId)

watch(isFinished, (isFinished) => {
  if (isFinished) {
    refetch()
  }
})

const checkForSuccess = async (renderTask: RenderDto) => {
  if (renderTask?.status === 'success') {
    renderStatus.value = 'success'
    pageStatus.value = 'success'
    putApiRendersIdRead(renderTask.id)
    queryClient.invalidateQueries(getGetApiRendersQueryKey())
    router.replace({
      name: 'Result',
      query: { resultUrl: renderTask.resultUrl, clipName: editorClipInfoStore.title },
    })
  }

  if (renderTask?.status === 'failed') {
    renderStatus.value = 'error'
    pageStatus.value = 'error'
    videoHasErrors.value = true
  }
}
watch(data, (response) => {
  if (response && response.value) checkForSuccess(response.value)
})

const setupState = async () => {
  setupStatus.value = 'in-endless-progress'
  if (taskId.value) {
    setupStatus.value = 'success'
    pageStatus.value = 'rendering'
    return
  }
  setupStatus.value = 'error'
  return
}

onMounted(() => {
  nextTick(() => {
    setupState()
  })
})
</script>

<template>
  <FeatureShowcase>
    <div class="flex w-full items-center justify-center">
      <AsyncComponent :is="LoaderComponent || 'div'" class-name="w-3/5 md:w-1/3 lg:w-1/2">
        <lottie-animation url="./lottie/walking-chick.json" :loop="true" :autoPlay="true" />
      </AsyncComponent>
    </div>

    <div v-if="pageStatus === 'waiting'" class="flex w-full flex-col text-center">
      <h1 class="text-2xl"><strong>Waiting on server</strong></h1>
      <p class="text-company-primary text-opacity-75">
        You already have a video rendering on our servers. We will start rendering your video as soon as the other is
        done
      </p>
    </div>
    <div v-else class="flex w-full flex-col text-center">
      <h1 class="text-2xl"><strong>Rendering your video</strong></h1>
      <p class="text-company-primary text-opacity-75">
        We are rendering your video in the background and will notify you when it’s ready!
      </p>
    </div>

    <div class="mx-auto flex flex-col space-y-2">
      <status-row status-text="Loading resources" :status="uploadStatus" />
      <status-row
        status-text="Applying stickers & captions"
        :status="overlayStatus"
        :progress-percentage="overlayPercentage"
      />
      <status-row status-text="Rendering video" :status="renderStatus" :progress-percentage="renderPercentage" />
    </div>

    <div v-if="videoHasErrors" class="self-center rounded-xl border border-red-700 bg-red-100 p-4">
      <h5>Error</h5>
      <p>We couldn't process your video.</p>
      <a href="/video-support" class="btn-primary btn">Find out why</a>
    </div>
  </FeatureShowcase>
</template>
