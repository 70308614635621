<script setup lang="ts">
import { ref, toRefs, computed } from 'vue'
import { createMovableElementContext } from '@/modules/SLMovable/useMovableElementContext'
import MovableElementMoveHandle from '@/modules/SLMovable/MovableElementMoveHandle.vue'
import MovableElementResizeHandles from '@/modules/SLMovable/MovableElementResizeHandles.vue'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import { useHead } from '@unhead/vue'
import { isEqual } from 'lodash-es'
import type { Directions } from '@/modules/SLMovable/@types/Movable'
import { watchImmediate } from '@vueuse/core'
import unwrap from '@/helpers/unwrap'

const props = withDefaults(
  defineProps<{
    local: { x: number; y: number; width: number; height: number }
    source: { x: number; y: number; width: number; height: number }

    resize?: boolean
    snap?: { x?: number[]; y?: number[] } | null
    move?: boolean

    shape: 'rectangle' | 'circle'

    minSize: number

    aspectLock?: { width: number; height: number } | null
    bounds?: {
      top: number
      right: number
      bottom: number
      left: number
    } | null
  }>(),
  { snap: null, aspectLock: null, bounds: null, resize: false, move: false }
)

const { maskId } = useMovableContext()!

const movingFrom = ref<{ x: number; y: number } | null>(null)
const resizingFrom = ref<Directions | null>(null)

const { source, local, resize, move, snap, aspectLock, bounds, minSize } = toRefs(props)

const { localArea, pullFocus } = createMovableElementContext({

  local: local,
  source: source,

  resize: resize,
  snap: snap,
  move: move,

  minSize: minSize,

  aspectRatio: aspectLock,

  bounds: bounds,

  movingFrom: movingFrom,
  resizingFrom: resizingFrom,
})

const emit = defineEmits<{
  (event: 'resizeStart'): void
  (event: 'resize', area: { x: number; y: number; width: number; height: number }, directions: Directions): void
  (event: 'resizeEnd'): void
  (event: 'moveStart'): void
  (event: 'move', area: { x: number; y: number }): void
  (event: 'moveEnd'): void
}>()

function startMove(point: { x: number; y: number }) {
  document.body.classList.add('block-interaction', 'cursor-grabbing')
  movingFrom.value = point
  emit('moveStart')
}

function onMove(area: { x: number; y: number }) {
  emit('move', area)
}

function endMove() {
  document.body.classList.remove('block-interaction', 'cursor-grabbing')
  movingFrom.value = null
  emit('moveEnd')
}

const resizeCursorValidators = {
  'cursor-n-resize': () => isEqual(resizingFrom.value, ['n']),
  'cursor-ne-resize': () => isEqual(resizingFrom.value, ['n', 'e']),
  'cursor-e-resize': () => isEqual(resizingFrom.value, ['e']),
  'cursor-se-resize': () => isEqual(resizingFrom.value, ['s', 'e']),
  'cursor-s-resize': () => isEqual(resizingFrom.value, ['s']),
  'cursor-sw-resize': () => isEqual(resizingFrom.value, ['s', 'w']),
  'cursor-w-resize': () => isEqual(resizingFrom.value, ['w']),
  'cursor-nw-resize': () => isEqual(resizingFrom.value, ['n', 'w']),
}

const resizeCursors = unwrap.keys(resizeCursorValidators)

function findResizeCursor() {
  return resizeCursors.find(key => {
    const isCurrentCursor = resizeCursorValidators[key]
    return isCurrentCursor()
  })
}

function startResize(directions: Directions) {
  const resizeCursor = findResizeCursor()
  if (resizeCursor) {
    document.body.classList.add('block-interaction', resizeCursor)
  } else {
    document.body.classList.add('block-interaction')
  }

  resizingFrom.value = directions
  emit('resizeStart')
}

function onResize(area: { x: number; y: number; width: number; height: number }, directions: Directions) {
  emit('resize', area, directions)
}

function endResize() {
  document.body.classList.remove('block-interaction', ...resizeCursors)

  resizingFrom.value = null
  emit('resizeEnd')
}

const blackout = ref(false)
watchImmediate(maskId, () => {
  setTimeout(() => {
    blackout.value = maskId.value !== null
  }, 0)
})
</script>

<template>
  <div @click.stop="pullFocus">
    <MovableElementMoveHandle v-if="move" @move-start="startMove" @move="onMove" @move-end="endMove">
      <slot name="move" />
    </MovableElementMoveHandle>

    <MovableElementResizeHandles v-if="resize" @resize-start="startResize" @resize="onResize" @resize-end="endResize">
      <template #direction="{ angle }">
        <slot name="resize-direction" :angle="angle" />
      </template>
    </MovableElementResizeHandles>

    <Teleport :to="`#${maskId}`" v-if="blackout">
      <ellipse
        v-if="shape === 'circle'"
        :cx="(localArea.x + 0.5 * localArea.width) * 100 + '%'"
        :cy="(localArea.y + 0.5 * localArea.height) * 100 + '%'"
        :rx="0.5 * localArea.width * 100 + '%'"
        :ry="0.5 * localArea.height * 100 + '%'"
        fill="black"
      />
      <rect
        v-else
        :x="localArea.x * 100 + '%'"
        :y="localArea.y * 100 + '%'"
        :width="localArea.width * 100 + '%'"
        :height="localArea.height * 100 + '%'"
        fill="black"
      />
    </Teleport>
  </div>
</template>

<style lang="scss">
.block-interaction,
.block-interaction::before,
.block-interaction::after,
.block-interaction .modal-open .modal-box,
.block-interaction .modal-open .modal-box::before,
.block-interaction .modal-open .modal-box::after {
  overflow: hidden !important;
  touch-action: none !important;
  user-select: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
