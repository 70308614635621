<template>
  <tw-base-dialog :close-event="events.close" styles="max-w-lg" :open-event="events.open">
    <div class="flex w-full flex-col items-center p-12 pt-4 text-center">
      <h3 class="mb-4 mt-2 py-0 text-4xl font-bold text-company-primary">Something went wrong</h3>
      <lottie-animation
        class="flex -mb-16 -mt-16 h-64 w-64 flex-shrink-0 items-center justify-center"
        url="/lottie/error-cross.json"
        :loop="false"
        :autoPlay="true"
      />
      <p v-if="message" v-html="message" class="py-3" />
      <div class="flex space-x-4">
        <button
          class="rounded-full bg-company-primary-300 px-4 py-2 text-white hover:bg-company-primary"
          @click="close"
        >
          Ok
        </button>
      </div>
    </div>
  </tw-base-dialog>
</template>
<script>
import TwBaseDialog from './TwBaseDialog.vue'
import mainEvents from '../../events/mainEvents'
import EventBus from '../../eventBus'
import LottieAnimation from '@/components/LottieAnimation.vue'

export default {
  components: { LottieAnimation, TwBaseDialog },
  data() {
    return {
      message: null,
      events: {
        open: mainEvents.ERROR,
        close: mainEvents.CLOSE_ERROR,
      },
    }
  },
  methods: {
    close() {
      EventBus.$emit(this.events.close)
    },
    setMessage(message) {
      this.message = message
    },
  },
  mounted() {
    EventBus.$on(this.events.open, this.setMessage)
  },
  beforeUnmount() {
    EventBus.$off(this.events.open, this.setMessage)
  },
}
</script>
