<template>
  <label :for="label" class="toggle-label">
    <input type="checkbox" :id="label" :name="label" v-model="selectedValue" v-on:change="onChange" />
    <div class="toggle-control" :class="{ checked: modelValue }">
      <i class="off">
        <times-icon width="9" height="9" fill="#FFF" />
      </i>
      <i class="on">
        <check-mark-icon width="10" height="10" />
      </i>
    </div>
    <span class="ml-1 inline-block">{{ label }}</span>
  </label>
</template>

<script>
import CheckMarkIcon from '../Icons/CheckMarkIcon.vue'
import TimesIcon from '../Icons/TimesIcon.vue'

export default {
  components: { TimesIcon, CheckMarkIcon },
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedValue: true,
    }
  },
  mounted() {
    this.selectedValue = this.modelValue
  },
  methods: {
    onChange(e) {
      if (this.disabled) {
        e.preventDefault()
        return
      }

      this.selectedValue !== this.selectedValue
      this.$emit('update:modelValue', this.selectedValue)
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  opacity: 0;
  width: 0;
  height: 0;
}

label {
  user-select: none;
  font-weight: 500;
  font-size: 0.9em;
  color: #47089e;
  cursor: pointer;
}

.toggle-control {
  display: inline-flex;
  border: 2px solid #f9f6fe;
  background-color: #fff;
  border-radius: 40px;

  i {
    border-radius: 50%;
    margin: 0.2em;
    width: 1em;
    height: 1em;
    align-items: center;
    justify-content: center;

    svg {
      width: 50%;
      height: 50%;
    }

    &.on {
      display: none;
      background-color: white;
      color: #8833ff;
      margin-left: 1.5em;
    }

    &.off {
      display: flex;
      background-color: #8833ff;
      margin-right: 1.5em;
      color: white;
    }
  }
}

.toggle-control.checked {
  background-color: #8833ff;
  border-color: #8833ff;

  i.off {
    display: none;
  }
  i.on {
    display: flex;
  }
}
</style>
