<script lang="ts" setup>
import { onMounted, computed, ref, inject } from 'vue'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import { copyRef } from '@/store/entity-system/_copyRef'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { watchPausable } from '@vueuse/core'
import { uniq } from 'lodash-es'
import { useCloneCrop } from '@/modules/CustomLayouts/Crop/useCloneCrop'

const props = defineProps<{
  id: string
  enableSnapping: boolean
}>()

const emit = defineEmits(['focus'])

const cropsStore = inject<CropsStore>('cropsStore')!
const crop = cropsStore.selectById(props.id)
const source = ref(copyRef(crop))

const editorVideoStore = useEditorVideoStore()

const feedData = computed(() => crop.value.feedData)

function start() {
  pause()
  setFocus()
}

function resize(area: { x: number; y: number; width: number; height: number }) {
  cropsStore.updateCropFeedDataById(props.id, area)
}

const cloneCrop = useCloneCrop(props.id, source, cropsStore)

function moveStart() {
  start()
  cloneCrop.start()
}

function move(area: { x: number; y: number }) {
  cropsStore.updateCropFeedDataById(props.id, area)
}

function moveEnd() {
  commit()
  cloneCrop.end()
}

const { pause, resume } = watchPausable(() => crop.value.feedData, () => {
  source.value = copyRef(crop)
}, { immediate: true, deep: true })

function commit() {
  source.value = copyRef(crop)
  resume()
}

onMounted(() => {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => emit('focus'), 0)
})

const aspectLock = computed(() => {
  const sourceSize = { width: crop.value.width, height: crop.value.height }
  const { videoWidth, videoHeight } = editorVideoStore.videoElement!
  return {
    width: sourceSize.width * videoWidth,
    height: sourceSize.height * videoHeight,
  }
})

const editorFocusStore = useEditorFocusStore()
const focused = computed(() => {
  return editorFocusStore.focus && editorFocusStore.focus.type === 'crop' && editorFocusStore.focus.key === props.id
})

function setFocus() {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => editorFocusStore.setFocus('crop', props.id), 0)
}

const crops = cropsStore.whereLayoutIdIs(crop.value.layoutId)

const otherCropsX = computed(() => {
  return crops.value
    .filter(c => c.id !== crop.value.id)
    .flatMap(crop => [crop.feedData.x, crop.feedData.x + crop.feedData.width])
})

const otherCropsY = computed(() => {
  return crops.value
    .filter(c => c.id !== crop.value.id)
    .flatMap(crop => [crop.feedData.y, crop.feedData.y + crop.feedData.height])
})

const snapGuidelines = computed(() => {
  return {
    x: uniq([0.5, ...otherCropsX.value]),
    y: uniq([0.5, ...otherCropsY.value]),
  }
})
</script>

<template>
  <div
    :class="{
      'rounded-[100%]': crop.input.shape === 'circle',
      'z-[250]': focused,
    }"
    :style="{
      left: feedData.x * 100 + '%',
      top: feedData.y * 100 + '%',
      width: feedData.width * 100 + '%',
      height: feedData.height * 100 + '%',
    }"
    class="absolute transition-opacity group"
    @click.stop
    @mousedown.stop="setFocus"
  >
    <MovableElement
      move
      resize
      :local="feedData"
      :source="source.feedData"
      :shape="crop.input.shape === 'circle' ? 'circle' : 'rectangle'"
      :aspectLock="aspectLock"
      :bounds="{ top: 0, right: 1, bottom: 1, left: 0 }"
      :snap="enableSnapping ? snapGuidelines : null"
      :min-size="50"
      @resize-start="start"
      @resize="resize"
      @resize-end="commit"
      @move-start="moveStart"
      @move="move"
      @move-end="moveEnd"
    >
      <template #move>
        <div
          class="absolute inset-0 border-2 transition-[opacity] border-current"
          :class="{
            'rounded-full': crop.input.shape === 'circle',
            'opacity-100': focused,
            'opacity-0 group-hover:opacity-75': !focused,
          }"
          :style="{ color: crop.input.color }"
        />
      </template>
      <template #resize-direction>
        <div
          :style="{ backgroundColor: focused ? crop.input.color : 'transparent' }"
          class="h-3 w-3 transition-[background-color]"
        />
      </template>
    </MovableElement>
  </div>
</template>

<style scoped lang="scss"></style>
