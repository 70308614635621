// a simple wrapper for ResizeObserver
// make is easier to use withiing vue 2 components
// usage:
// import { observe, unobserve } from '@/helpers/ObserverHelper'
// observe(this.$refs.myElement, (width, height) => {
//   // do something with width and height
// })
// unobserve(this.$refs.myElement)
//
// note: only one callback per element is supported

const ObservedElements = new Map()

const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const callback = ObservedElements.get(entry.target)
    if (callback) {
      callback(entry.contentRect.width, entry.contentRect.height)
    }
  }
})

export const observe = (element: HTMLElement, callback: (width: number, height: number) => void) => {
  if (ObservedElements.has(element)) {
    return
  }
  resizeObserver.observe(element)
  ObservedElements.set(element, callback)
}

export const unobserve = (element: HTMLElement) => {
  if (!ObservedElements.has(element)) {
    return
  }
  resizeObserver.unobserve(element)
  ObservedElements.delete(element)
}
