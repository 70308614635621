<template>
  <teleport to="body">
    <base-dialog
      ref="dialog"
      :closeable="false"
      :showDismissButton="false"
      box-class="!overflow-visible"
      class="dialog"
      size="medium"
    >
      <div v-if="isLocalFile && trimmedDurationMs > maxOutputDuration" class="content-500 pb-4 pt-5 text-center">
        <LottieAnimation :loop="false" class="mx-auto w-24" url="/lottie/error-alt.json" />

        <h3>Oops! Video too long 🎥</h3>
        <p>
          Your clip exceeds the 3-minute limit for caption generation. Please upload a shorter video or trim your
          current one to fit within the time frame.
        </p>

        <div class="mt-8 flex w-full flex-col items-stretch justify-center gap-4 md:flex-row md:items-center">
          <div class="hover:shadow-gradient group flex-1 rounded-full transition-all before:transition-all">
            <div class="bg-gradient w-full rounded-full">
              <button
                class="btn-outline btn-lg btn w-full gap-2 border-2 bg-[#f8f4fd] text-lg font-bold text-black mix-blend-lighten group-hover:border-black group-hover:bg-black group-hover:text-white"
                @click="close"
              >
                Cancel
              </button>
            </div>
          </div>
          <div class="hover:shadow-gradient group flex-1 rounded-full shadow-xl transition-all before:transition-all">
            <div class="bg-gradient w-full rounded-full">
              <button
                @click="close"
                class="btn-lg btn w-full flex-nowrap gap-2 bg-black text-lg font-bold text-white mix-blend-lighten"
              >
                Trim video now
                <svg
                  class="transition group-hover:translate-x-2"
                  fill="currentColor"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.43 18.8191C14.24 18.8191 14.05 18.7491 13.9 18.5991C13.61 18.3091 13.61 17.8291 13.9 17.5391L19.44 11.9991L13.9 6.45914C13.61 6.16914 13.61 5.68914 13.9 5.39914C14.19 5.10914 14.67 5.10914 14.96 5.39914L21.03 11.4691C21.32 11.7591 21.32 12.2391 21.03 12.5291L14.96 18.5991C14.81 18.7491 14.62 18.8191 14.43 18.8191Z"
                  />
                  <path
                    d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <CaptionGenerator
        @done="close"
        @captions-file-content="(content) => $emit('captionsFileContent', content)"
        v-else
      />
    </base-dialog>
  </teleport>
</template>

<script lang="ts" setup>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { storeToRefs } from 'pinia'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { maxOutputDuration } from '@/data/outputDuration'
import CaptionGenerator from '@/components/Captions/CaptionGenerator.vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { ref } from 'vue'
import LottieAnimation from '@/components/LottieAnimation.vue'

// Component brings up a dialog, allows language selection and emits a Transcription File URL when generation is complete
const dialog = ref<InstanceType<BaseDialog> | null>(null)

const { isLocalFile } = storeToRefs(useEditorClipInfoStore())
const { trimmedDurationMs } = storeToRefs(useEditorMainStore())

function open() {
  dialog.value?.open()
}

function close() {
  dialog.value?.close()
}

defineExpose({ open, close })

defineEmits(['captionsFileContent'])
</script>

<style lang="scss" scoped>
.content,
.content-500 {
  max-width: 400px;
  margin: 20px auto;
}

.content-500 {
  max-width: 500px !important;
}
</style>
