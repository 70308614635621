<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { createMovableContext } from '@/modules/SLMovable/useMovableContext'
import { v4 as uuid } from 'uuid'
import MovableMask from '@/modules/SLMovable/MovableMask.vue'

const props = defineProps<{ blackout?: boolean, maskClass?: string }>()

const cropper = ref<HTMLElement | null>(null)

const uniqueId = uuid()
const maskId = computed(() => (props.blackout ? `movable-mask-${uniqueId}` : null))

const snapGridId = `snap-grid-${uniqueId}`

createMovableContext(cropper, { blackout: maskId, snapGridId })

// Cheeky hack to ensure teleport targets are mounted before rendering any crops.
const initialized = ref(false)
onMounted(() => {
  initialized.value = true
})
</script>

<template>
  <div ref="cropper">
    <MovableMask class="absolute inset-0" :class="maskClass" v-if="blackout" :mask-id="maskId" />
    <div :id="snapGridId" class="absolute inset-0" />
    <slot v-if="initialized" />
  </div>
</template>

<style scoped lang="scss">
.mask {
  mask-image: var(--mask-id);
}
</style>
