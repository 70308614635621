<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row gap-5">
      <img
        src="/images/svgs/SocialMediaCircle.svg"
        alt="Social media icons"
        class="hidden aspect-square h-full md:block"
      />
      <div class="flex w-full flex-col gap-2">
        <h3 class="m-0 p-0 text-xl">Complete your profile</h3>
        <p class="m-0 text-lg font-medium">Connect your social media to post and plan clips</p>
        <progress class="progress progress-secondary h-3 w-full" :value="percentage" max="100"></progress>
      </div>
    </div>
    <div class="flex flex-col gap-4 md:px-16">
      <div
        class="flex flex-row items-center justify-between rounded-xl p-4"
        :class="{
          'bg-gray-100': tiktokConnected,
        }"
      >
        <div class="flex flex-row items-center gap-2 rounded">
          <connection-type-icon connection-type="tiktok" />
          <p class="m-0 p-0 md:text-lg">TikTok</p>
        </div>
        <button
          v-if="!tiktokConnected"
          @click="connectSocialMedia(ConnectionTypes.TIKTOK)"
          class="btn-primary btn-sm btn gap-2 md:btn-md"
        >
          <span>Connect</span>
        </button>
        <button v-else class="btn-success btn-sm btn gap-2 text-white md:btn-md">
          <CheckMarkIcon class="w-4 fill-current" />
          <span>Connected</span>
        </button>
      </div>
      <hr />
      <div
        class="flex flex-row items-center justify-between rounded-xl p-4"
        :class="{
          'bg-gray-100': youtubeConnected,
        }"
      >
        <div class="flex flex-row items-center gap-2 rounded">
          <connection-type-icon connection-type="youtube" />
          <p class="m-0 p-0 md:text-lg">Youtube</p>
        </div>
        <button
          v-if="!youtubeConnected"
          @click="connectSocialMedia(ConnectionTypes.YOUTUBE)"
          class="btn-primary btn-sm btn gap-2 md:btn-md"
        >
          <span>Connect</span>
        </button>
        <button v-else class="btn-success btn-sm btn gap-2 text-white md:btn-md">
          <CheckMarkIcon class="w-4 fill-current" />
          <span>Connected</span>
        </button>
      </div>
      <hr v-if="instagramEnabled" />
      <div v-if="instagramEnabled" class="flex flex-row items-center justify-between rounded-xl p-4">
        <div class="flex flex-row items-center gap-2 rounded">
          <connection-type-icon connection-type="instagram" />
          <p class="m-0 p-0 md:text-lg">Instagram</p>
        </div>
        <button
          v-if="!instagramConnected"
          @click="connectSocialMedia(ConnectionTypes.INSTAGRAM)"
          class="btn-primary btn-sm btn gap-2 md:btn-md"
        >
          <span>Connect</span>
        </button>
        <button v-else class="btn-success btn-sm btn gap-2 text-white md:btn-md">
          <CheckMarkIcon class="w-4 fill-current" />
          <span>Connected</span>
        </button>
      </div>
    </div>
    <AlertBox v-if="connectionError" use-icon title="Connection failed" variant="error" :message="connectionError" />
    <button class="btn-primary btn-lg btn mx-auto gap-2 px-4" @click="emit('continue')" :disabled="!canStartSharing">
      Start sharing
      <caret-right-icon class="fill-current" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import ConnectionTypeIcon from '@/components/Account/Connections/List/ConnectionTypeIcon.vue'
import CaretRightIcon from '@/components/Icons/CaretRightIcon.vue'
import CheckMarkIcon from '@/components/Icons/CheckMarkIcon.vue'
import useCreateSocialConnection from '@/Hooks/useCreateSocialConnection'
import ConnectionTypes, { type ConnectionType } from '@/enums/connectionTypes'
import { useProfileCompletion } from '@/components-v2/modules/Account/useProfileCompletion'
import { ref } from 'vue'
import AlertBox from '@/components-v2/data-display/AlertBox.vue'

const emit = defineEmits<{
  (event: 'continue'): void
}>()

const { connect } = useCreateSocialConnection()
const connectionError = ref<string | null>(null)
function connectSocialMedia(connectionType: ConnectionType) {
  connectionError.value = null
  connect(connectionType).catch((error) => {
    connectionError.value = error
  })
}

const instagramEnabled =
  location.hostname.includes('local.streamladder.com') || location.hostname.includes('acc.streamladder.com')

const {
  tiktokConnected,
  youtubeConnected,
  instagramConnected,
  percentageComplete: percentage,
  canStartSharing,
} = useProfileCompletion()
</script>
