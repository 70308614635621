<template>
  <div class="fixed left-1/2 top-2 min-w-1/4 -translate-x-1/2 transform space-y-2" style="z-index: 999999">
    <transition-group
      class="flex flex-col space-y-2"
      enter-active-class="animate__animated animate__fadeInDown"
      leave-active-class="animate__animated animate__fadeOutUp"
      name="list"
      tag="span"
    >
      <toast-message
        v-for="message in messages"
        :id="message.id"
        :key="message.id"
        :status="message.status"
        :subtitle="message.subtitle"
        :title="message.title"
        @click="removeMessage"
      />
    </transition-group>
  </div>
</template>
<script>
import ToastMessage from './ToastMessage.vue'
import EventBus from '../../eventBus'
import ToastEvents from '../../events/toastEvents'

export default {
  components: { ToastMessage },
  data() {
    return {
      messages: [],
      nextId: 0,
    }
  },
  methods: {
    removeMessage(e) {
      this.messages = this.messages.filter((m) => m.id !== e)
    },
    addErrorMessage(data) {
      const count = this.nextId++

      this.messages.push({
        id: count,
        status: 'error',
        title: data.title,
        subtitle: data.subtitle,
      })

      setTimeout(() => this.removeMessage(count), data.timeout ?? 4000)
    },
    addSuccessMessage(data) {
      const count = this.nextId++

      this.messages.push({
        id: count,
        status: 'success',
        title: data.title,
        subtitle: data.subtitle,
      })

      setTimeout(() => this.removeMessage(count), data.timeout ?? 4000)
    },
    addMessage(data) {
      const count = this.nextId++

      this.messages.push({
        id: count,
        status: data.status,
        title: data.title,
        subtitle: data.subtitle,
      })

      setTimeout(() => this.removeMessage(count), data.timeout ?? 4000)
    },
  },
  mounted() {
    EventBus.$on(ToastEvents.TOAST_ERROR, this.addErrorMessage)
    EventBus.$on(ToastEvents.TOAST_SUCCESS, this.addSuccessMessage)
    EventBus.$on(ToastEvents.TOAST, this.addMessage)
  },
  beforeUnmount() {
    EventBus.$off(ToastEvents.TOAST_ERROR, this.addErrorMessage)
    EventBus.$off(ToastEvents.TOAST_SUCCESS, this.addSuccessMessage)
    EventBus.$off(ToastEvents.TOAST, this.addMessage)
  },
}
</script>

<style scoped>
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s !important;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
</style>
