<template>
  <div
    class="flex flex-row flex-wrap rounded font-semibold leading-tight text-gray-800 hover:border-gray-200 hover:bg-gray-200 hover:shadow-xl"
    :class="{
      'selected !bg-blue-200': active && editorMainStore.correctionState !== 'text',
      'focus-within:!bg-green-700 focus-within:text-white': editorMainStore.correctionState === 'text',
    }"
    @click="focus"
  >
    <div
      ref="box"
      class="editor-line flex flex-row flex-wrap items-center rounded bg-transparent p-0.5"
      :class="{
        '!bg-transparent !text-inherit': editorMainStore.correctionState === 'text',
      }"
    >
      <VDropdown
        placement="top"
        v-for="word in caption.words"
        ref="inputs"
        :key="word.text"
        :disabled="editorMainStore.correctionState === 'text'"
      >
        <template #popper>
          <EmojiPicker v-if="selectEmoji" :native="true" @select="onSelectEmoji" />
          <div class="flex w-full items-center justify-center p-1">
            <template v-if="!captionStore.captionStyleSettings.disableHighlight">
              <button
                v-for="color in captionStore.captionStyleSettings.colors"
                @click="onColorChange(color, word)"
                :key="color"
                class="flex h-7 w-7 items-center justify-center rounded-full hover:border-2 hover:border-[#277DFF]"
                :class="{
                  'border-2 border-[#277DFF]': color === word.color || (color === caption.color && !word.color),
                }"
              >
                <span
                  class="h-5 w-5 rounded-full"
                  :class="{
                    'border border-gray-400': tinycolor(color).getLuminance() > 0.95,
                  }"
                  :style="{
                    background: captionStore.captionStyleSettings.gradients?.[color] ?? color,
                  }"
                ></span>
              </button>
              <div class="mx-1 mt-2 h-5 w-px border-l border-gray-100" />
            </template>
            <button
              v-if="caption.emojis?.length === 0 || caption.emojis === undefined"
              class="m-1 flex w-6 items-center justify-center text-gray-400 hover:text-red-400"
              @click="selectEmoji = !selectEmoji"
            >
              <EmojiIcon class="fill-current" />
            </button>
            <button
              class="m-1 flex w-6 items-center justify-center text-gray-400 hover:text-red-400"
              @click="deleteWord(word)"
            >
              <TrashcanIcon class="fill-current" />
            </button>
          </div>
        </template>
        <div
          :contenteditable="editorMainStore.correctionState === 'text'"
          placeholder="Type here!"
          class="word-input min-h-[24px] min-w-[10px] cursor-text border-2 border-transparent focus-within:rounded focus-within:bg-purple-400"
          :style="{
            borderBottomColor: word.color || 'transparent',
          }"
          :class="{
            'outline-0 hover:outline-0': captionStore.baseOptions.grouping !== 'group',
            'opacity-20': disabled,
          }"
          @dblclick="doubleClick"
          v-on-long-press="doubleClick"
          @blur="(e) => onInput(e, word)"
          @keydown.enter="(e) => onInput(e, word)"
        >
          {{ word.text }}
        </div>
      </VDropdown>
      <VDropdown
        v-if="!!caption.emojis?.[0]"
        :disabled="editorMainStore.correctionState === 'text'"
        placement="top"
        @open:popper="focus"
      >
        <template #popper>
          <EmojiPicker :native="true" @select="onSelectEmoji" />
          <div class="flex w-full justify-center">
            <button class="mb-2 flex h-6 justify-center gap-1 text-gray-400 hover:text-red-400" @click="deleteEmoji">
              <TrashcanIcon class="w-6 fill-current" />
              Delete emoji
            </button>
          </div>
        </template>
        <button
          class="word-input"
          :class="{
            'opacity-20': disabled,
          }"
        >
          {{ caption.emojis[0] }}
        </button>
      </VDropdown>
    </div>
  </div>
</template>

<style>
.v-popper--shown:not(:only-child) .word-input {
  background: rgb(192 132 252);
  border-radius: 3px;
}

.word-input:hover {
  outline: -webkit-focus-ring-color auto 1px;
}

.word-input:only-child:empty {
  width: 84px;
}

.word-input:only-child:empty:before {
  content: attr(placeholder);
  color: lightgray;
  display: block;
  position: absolute;
  font-weight: 400;
  font-family: 'Campton', sans-serif;
}

.\!bg-blue-400 .word-input:only-child:empty:before,
.selected .word-input:only-child:empty:before,
.editor-line:focus-within .word-input:only-child:empty:before,
.editor-line:hover .word-input:only-child:empty:before {
  color: white;
}

.v-popper__inner .v3-emoji-picker {
  box-shadow: none;
}
</style>

<script setup lang="ts">
import type { StoredCaption, StoredWord } from '@/components/Captions/captionTypes'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import Popper from 'vue3-popper'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useTimeLineAnimation } from '@/components-v2/modules/VideoPlayer/useVideoManager'
import { nextTick, onMounted, ref, defineAsyncComponent } from 'vue'
import TrashcanIcon from '@/components/Icons/TrashcanIcon.vue'
import tinycolor from 'tinycolor2'
import { onClickOutside } from '@vueuse/core'
import 'vue3-emoji-picker/css'
import EmojiIcon from '@/components/Icons/Normalized/EmojiIcon.vue'
import { vOnLongPress } from '@vueuse/components'
import { useEditorVideoStore } from '@/store/editor/editorVideo'

const EmojiPicker = defineAsyncComponent(() => import('vue3-emoji-picker'))

const props = defineProps<{
  caption: StoredCaption
  active?: boolean
  disabled?: boolean
}>()

const inputs = ref<(typeof Popper)[]>([])

const captionStore = useEditorCaptionsStore()
const onInput = (e: InputEvent, word: StoredWord) => {
  const target = e.target as HTMLElement
  word.text = target.innerText
}

function selectElementContents(el: Element) {
  const range = document.createRange()
  range.selectNodeContents(el)
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
}

const doubleClick = async (e: MouseEvent) => {
  const target = e.target as HTMLElement
  selectElementContents(target)
  editorMainStore.correctionState = 'text'
  await nextTick()
  target.focus()
}

const focusStore = useEditorFocusStore()
const editorMainStore = useEditorMainStore()
const editorVideoStore = useEditorVideoStore()

onMounted(() => {
  if (focusStore.focus?.type === FocusTypes.CAPTION && focusStore.focus?.key === props.caption.id) {
    editorMainStore.correctionState = 'text'
    nextTick(() => {
      const popper = inputs.value?.[0]
      const input = popper?.$el.querySelector('.word-input')
      input?.focus()
      input?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    })
  }
})

const focus = () => {
  focusStore.setFocus(FocusTypes.CAPTION, props.caption.id)
  editorVideoStore._currentTime = props.caption.start / 1000
  editorVideoStore.playing = false
  editorVideoStore.preservedPaused = true
  // EventBus.$emit('editor/seeking', props.caption.start + 3)
}

const box = ref<HTMLElement>()

onClickOutside(box, (event) => {
  if (editorMainStore.correctionState !== 'text') return false
  const isContentEditable = event.target?.isContentEditable
  if (isContentEditable) return false
  editorMainStore.correctionState = 'none'
})

useTimeLineAnimation([box], (timeline) => {
  if (!box.value) return
  const animationDuration = 0
  timeline
    .set(box.value, { clearProps: 'all' }, 0)
    .fromTo(
      box.value,
      {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      {
        backgroundColor: 'rgba(136, 51, 255, 1)',
        color: 'white',
        borderColor: 'rgba(136, 51, 255, 1)',
        duration: 0,
      },
      props.caption.start / 1000
    )
    .fromTo(
      box.value,
      {
        backgroundColor: 'rgba(136, 51, 255, 1)',
        borderColor: 'rgba(136, 51, 255, 1)',
      },
      {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'inherit',
        duration: 0,
      },
      props.caption.end / 1000 - animationDuration
    )
    .set(box.value, { clearProps: 'all' }, props.caption.end + 2)
})

const deleteEmoji = () => {
  captionStore.updateCaption(props.caption.id, {
    emojis: [],
  })
}

const selectEmoji = ref(false)
const onSelectEmoji = (emoji: any) => {
  selectEmoji.value = false
  captionStore.updateCaption(props.caption.id, {
    emojis: [emoji.i],
  })
}

const deleteWord = (word: StoredWord) => {
  if (props.caption.words.length === 1) {
    deleteCaption()
    return
  }
  captionStore.updateCaption(props.caption.id, {
    words: props.caption.words.filter((w) => w.text !== word.text),
  })
}

const deleteCaption = () => {
  captionStore.deleteCaption(props.caption.id)
}

const onColorChange = (color: string, word: StoredWord) => {
  captionStore.updateCaption(props.caption.id, {
    words: props.caption.words.map((w) => {
      if (w.text === word.text) {
        w.color = color
      }
      return w
    }),
  })
}
</script>
