// Extend JS
import './services/extensionService'

// Initialize basic Vue app
import { createApp } from 'vue'
import App from './App.vue'
import { MotionPlugin } from '@vueuse/motion'

const app = createApp(App)

// Pinia state management
import { createPinia } from 'pinia'

const pinia = createPinia()
app.use(pinia)

// Vue Router
import { router } from '@/router'

app.use(router)

// Sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

if (!import.meta.env.DEV) {
  // Disable Sentry for dev
  Sentry.init({
    app,
    dsn: 'https://1b812231cf154e4f92832b08f236275a@o661190.ingest.sentry.io/5764616',
    tunnel: '/tunnel/',
    ignoreErrors: [
      'ResizeObserver loop',
      'play()',
      'NetworkError',
      'TypeError: Failed to fetch dynamically imported module',
      'UnknownError: Internal error opening backing store for indexedDB.open',
    ],
    environment: import.meta.env.VITE_DEPLOYMENT_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          'local.streamladder.com',
          'api.streamladder.com',
          'api-acc.streamladder.com',
          'accounts.streamladder.com',
        ],
      }),
    ],
    tracesSampleRate: 0.05,
  })
}

// Add user/event tracking
import logging from './logging'

app.config.globalProperties.$trackEvent = logging.trackEvent
logging.initialize(app, router)

import { VueQueryPlugin } from '@tanstack/vue-query'
import { queryClient } from '@/services/QueryClient'

app.use(VueQueryPlugin, {
  queryClient,
})

// Add vue use-head
import { createHead, VueHeadMixin } from '@vueuse/head'

const head = createHead()
app.mixin(VueHeadMixin)
app.use(head)

// Add vue2-touch-events
import Vue3TouchEvents from 'vue3-touch-events'

app.use(Vue3TouchEvents)

app.use(MotionPlugin)

// Add vue-focus. you can add "v-focus" on an element to focus it on render
app.directive('focus', {
  mounted: (el) => {
    // Focus the element
    el.focus()
  },
})

// Add v-click-outside, working with vue3
import vClickOutside from 'v-click-outside'

const { bind, unbind } = vClickOutside.directive

app.directive('click-outside', {
  mounted(el, bindling) {
    bind(el, { value: bindling.value })
  },
  beforeUnmount(el) {
    unbind(el)
  },
})

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

app.use(FloatingVue)

// add auto-animate
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

app.use(autoAnimatePlugin)

// Add Paddle
// TODO move to App.vue?
import { useProductsStore } from '@/store/products'

if (!['/sticker-render', '/video-render', '/generate', '/login-success'].includes(window.location.pathname)) {
  const productsStore = useProductsStore()
  productsStore.getPrices()

  import('https://cdn.paddle.com/paddle/paddle.js')
    .then(() => Paddle.Setup({ vendor: 136619 }))
    .catch((e) => console.error(e))
}

window.jasper = () => {
  localStorage.setItem('dos', 'true')
  window.location.reload()
}

// Further setup vue app
import './assets/app.css'

app.mount('#app')

addEventListener('vite:preloadError', (e) => {
  // if (!['/sticker-render/', 'generate'].includes(location.pathname)) {
  //   Sentry.captureException(e.payload.stack)
  //   location.reload()
  // }
})
