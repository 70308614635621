<template>
  <app-page
    content-direction="row"
    fixed-header
    header-size="small"
    show-breadcrumb
    :content-container-class="hasThreeColumnLayout ? 'lg:max-2xl:!max-w-full lg:min-h-0' : ''"
  >
    <template v-slot:sub-nav>
      <stepper />
      <div class="lg:hidden" v-if="!hasThreeColumnLayout">
        <dropdown-panel button-class="button mobile-settings" button-text="Settings">
          <div>
            <div class="preview-controls">
              <toggle-control v-model="enableSnapping" label="Enable snapping" />

              <div class="my-[1em] flex flex-col">
                <strong class="flex items-center justify-start gap-2">
                  Show safe zone
                  <span
                    class="tooltip font-thin before:left-[calc(50%_-_5rem)!important] before:w-48"
                    data-tip="Toggle to show areas to avoid for key content."
                  >
                    <svg
                      class="block align-baseline"
                      fill="none"
                      height="16"
                      viewBox="0 0 16 16"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00004 1.3335C4.32671 1.3335 1.33337 4.32683 1.33337 8.00016C1.33337 11.6735 4.32671 14.6668 8.00004 14.6668C11.6734 14.6668 14.6667 11.6735 14.6667 8.00016C14.6667 4.32683 11.6734 1.3335 8.00004 1.3335ZM7.50004 5.3335C7.50004 5.06016 7.72671 4.8335 8.00004 4.8335C8.27337 4.8335 8.50004 5.06016 8.50004 5.3335V8.66683C8.50004 8.94016 8.27337 9.16683 8.00004 9.16683C7.72671 9.16683 7.50004 8.94016 7.50004 8.66683V5.3335ZM8.61337 10.9202C8.58004 11.0068 8.53337 11.0735 8.47337 11.1402C8.40671 11.2002 8.33337 11.2468 8.25337 11.2802C8.17337 11.3135 8.08671 11.3335 8.00004 11.3335C7.91337 11.3335 7.82671 11.3135 7.74671 11.2802C7.66671 11.2468 7.59337 11.2002 7.52671 11.1402C7.46671 11.0735 7.42004 11.0068 7.38671 10.9202C7.35337 10.8402 7.33337 10.7535 7.33337 10.6668C7.33337 10.5802 7.35337 10.4935 7.38671 10.4135C7.42004 10.3335 7.46671 10.2602 7.52671 10.1935C7.59337 10.1335 7.66671 10.0868 7.74671 10.0535C7.90671 9.98683 8.09337 9.98683 8.25337 10.0535C8.33337 10.0868 8.40671 10.1335 8.47337 10.1935C8.53337 10.2602 8.58004 10.3335 8.61337 10.4135C8.64671 10.4935 8.66671 10.5802 8.66671 10.6668C8.66671 10.7535 8.64671 10.8402 8.61337 10.9202Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </strong>
                <div class="mt-[1em] flex flex-col space-y-1">
                  <button
                    :class="{ '!bg-primary !text-[#F9F6FE]': showAllSafeZones }"
                    class="btn-primary btn-sm btn self-start !border-transparent bg-[#F9F6FE] font-thin text-primary shadow-none hover:bg-primary/75 hover:text-[#F9F6FE] active:bg-primary active:text-[#F9F6FE]"
                    @click="toggleAllSafeZones"
                  >
                    Show all
                  </button>
                  <button
                    v-for="safeZone in safeZones"
                    :key="safeZone.key"
                    :class="{
                      '!bg-primary !text-[#F9F6FE]': safeZone.show,
                    }"
                    class="btn-primary btn-sm btn self-start !border-transparent bg-[#F9F6FE] font-thin text-primary shadow-none hover:bg-primary/75 hover:text-[#F9F6FE] active:bg-primary active:text-[#F9F6FE]"
                    @click="safeZone.show = !safeZone.show"
                  >
                    {{ safeZone.label }}
                  </button>
                </div>
              </div>
            </div>

            <slot name="layout-options"></slot>
          </div>
        </dropdown-panel>
      </div>
    </template>
    <template v-slot:content>
      <div
        :id="editorMainStore.layoutId ? 'custom-layout' : null"
        class="grid w-full grid-cols-1 gap-6 lg:min-h-full"
        :class="
          hasThreeColumnLayout
            ? 'editor-grid self-stretch lg:grid-cols-[200px_1fr_400px] lg:gap-2 xl:grid-cols-[200px_1fr_500px] 2xl:grid-cols-[200px_1fr_600px]'
            : 'h-full lg:h-auto lg:grid-cols-[1fr_2fr_2fr_1fr] xl:grid-cols-[230px_1fr_auto_200px]'
        "
      >
        <!-- Desktop menu -->
        <div
          class="steps-panel hidden flex-col gap-6 lg:sticky lg:top-20 lg:flex lg:h-auto lg:self-start"
          data-retain-focus
        >
          <ul class="flex flex-col overflow-auto rounded-lg border-2 border-gray-100 bg-white p-4">
            <li>
              <button
                @click="goToCrop"
                class="flex w-full cursor-pointer items-center justify-between rounded-lg px-4 py-2 font-semibold opacity-50 hover:bg-company-primary-100 hover:text-white"
              >
                Crop
              </button>
            </li>
            <li v-for="step in steps" :key="step.key">
              <button
                :class="{
                  'opacity-50': activeStep !== step.key,
                  'pointer-events-none bg-[#8833FF] text-white opacity-100': activeStep === step.key,
                }"
                class="flex w-full cursor-pointer items-center justify-between rounded-lg px-4 py-2 font-semibold hover:bg-company-primary-100 hover:text-white"
                @click="activeStep = step.key"
              >
                {{ step.title }}
                <i v-show="activeStep === step.key">
                  <arrow-right-icon />
                </i>
              </button>
            </li>
          </ul>
          <!--          <RemovePartsPicker />-->
        </div>

        <!-- Editor Panel -->
        <div class="flex min-w-0 flex-col overflow-x-hidden lg:overflow-visible">
          <!-- Mobile Menu header-->
          <ul class="mobile-steps flex lg:hidden">
            <li>
              <router-link :to="{ name: $route.meta.parentRouteName }" class="step block">
                Crop

                <i>
                  <arrow-right-icon />
                </i>
              </router-link>
            </li>
            <li
              v-for="step in previousAndCurrentSteps"
              :key="step.key"
              :class="{ active: activeStep === step.key }"
              class="step"
              @click="activeStep = step.key"
            >
              {{ step.title }}
              <i v-if="activeStep !== step.key">
                <arrow-right-icon />
              </i>
            </li>
          </ul>

          <!-- Text Step-->
          <text-picker v-if="activeStep === 'text'" data-retain-focus />

          <!-- Sticker Step-->
          <div v-if="activeStep === 'stickers'" class="flex flex-col gap-4">
            <div class="flex flex-col" data-retain-focus>
              <div class="">
                <tab-radio-group
                  v-model="activeStickerPanel"
                  :options="[
                    { label: 'Library', value: 'library' },
                    { label: 'Upload image', value: 'custom', icon: UploadIcon() },
                  ]"
                />
              </div>
              <div class="flex max-h-full flex-col rounded-b-lg border-2 border-t-0 border-gray-100 bg-white">
                <sticker-picker
                  v-if="activeStickerPanel === 'library'"
                  :sticker-text="stickerText"
                  @uploadSticker="() => (activeStickerPanel = 'custom')"
                />
                <sticker-custom-upload v-if="activeStickerPanel === 'custom'" />
              </div>
            </div>
          </div>

          <!-- Caption Step-->
          <keep-alive>
            <!--            <caption-editor v-if="activeStep === 'captions'" ref="captionEditor" />-->
          </keep-alive>

          <section v-if="activeStep === 'captions'" class="flex flex-col gap-4">
            <CaptionsPanel />
          </section>

          <!-- Output Step-->
          <section v-if="activeStep === 'output-settings'" class="flex h-full flex-col gap-4">
            <ExportStep
              ref="exportStep"
              :save-template="openSaveTemplateDialog"
              :startRender="preprocessing"
              data-retain-focus
            />
          </section>

          <!-- Mobile menu footer-->
          <ul class="mobile-steps block lg:hidden">
            <li
              v-for="step in nextSteps"
              :key="step.key"
              :class="{ active: activeStep == step.key }"
              class="step"
              @click="activeStep = step.key"
            >
              {{ step.title }}
              <i v-if="activeStep !== step.key">
                <arrow-right-icon />
              </i>
            </li>
          </ul>
        </div>

        <!-- Preview -->
        <div
          class="row-start-1 overflow-hidden lg:sticky lg:top-20 lg:row-start-auto lg:mx-0"
          :class="hasThreeColumnLayout ? 'preview-container flex-auto lg:flex lg:flex-col' : 'mx-auto lg:h-auto'"
        >
          <preview-container
            :enable-snapping="enableSnapping"
            :safe-zones="safeZones"
            @advancedSettings="advancedSettings = true"
          />
        </div>

        <!-- Options -->
        <div
          v-if="!hasThreeColumnLayout"
          class="hidden flex-col gap-6 lg:sticky lg:top-20 lg:flex lg:h-auto lg:self-start"
          data-retain-focus
        >
          <div class="flex flex-col overflow-visible rounded-lg border-2 border-gray-100 bg-white p-4">
            <div class="panel-content">
              <div class="preview-controls">
                <toggle-control v-model="enableSnapping" label="Enable snapping" />

                <div class="mt-[1em] flex flex-col">
                  <strong class="flex items-center justify-start gap-2">
                    Show safe zone
                    <span
                      class="tooltip font-thin before:left-[calc(50%_-_5rem)!important] before:w-48"
                      data-tip="Toggle to show areas to avoid for key content."
                    >
                      <svg
                        class="block align-baseline"
                        fill="none"
                        height="16"
                        viewBox="0 0 16 16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 1.3335C4.32671 1.3335 1.33337 4.32683 1.33337 8.00016C1.33337 11.6735 4.32671 14.6668 8.00004 14.6668C11.6734 14.6668 14.6667 11.6735 14.6667 8.00016C14.6667 4.32683 11.6734 1.3335 8.00004 1.3335ZM7.50004 5.3335C7.50004 5.06016 7.72671 4.8335 8.00004 4.8335C8.27337 4.8335 8.50004 5.06016 8.50004 5.3335V8.66683C8.50004 8.94016 8.27337 9.16683 8.00004 9.16683C7.72671 9.16683 7.50004 8.94016 7.50004 8.66683V5.3335ZM8.61337 10.9202C8.58004 11.0068 8.53337 11.0735 8.47337 11.1402C8.40671 11.2002 8.33337 11.2468 8.25337 11.2802C8.17337 11.3135 8.08671 11.3335 8.00004 11.3335C7.91337 11.3335 7.82671 11.3135 7.74671 11.2802C7.66671 11.2468 7.59337 11.2002 7.52671 11.1402C7.46671 11.0735 7.42004 11.0068 7.38671 10.9202C7.35337 10.8402 7.33337 10.7535 7.33337 10.6668C7.33337 10.5802 7.35337 10.4935 7.38671 10.4135C7.42004 10.3335 7.46671 10.2602 7.52671 10.1935C7.59337 10.1335 7.66671 10.0868 7.74671 10.0535C7.90671 9.98683 8.09337 9.98683 8.25337 10.0535C8.33337 10.0868 8.40671 10.1335 8.47337 10.1935C8.53337 10.2602 8.58004 10.3335 8.61337 10.4135C8.64671 10.4935 8.66671 10.5802 8.66671 10.6668C8.66671 10.7535 8.64671 10.8402 8.61337 10.9202Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </strong>
                  <div class="mt-[1em] flex flex-col space-y-1">
                    <button
                      :class="{ '!bg-primary !text-[#F9F6FE]': showAllSafeZones }"
                      class="btn-primary btn-sm btn self-start !border-transparent bg-[#F9F6FE] font-thin text-primary shadow-none hover:bg-primary/75 hover:text-[#F9F6FE] active:bg-primary active:text-[#F9F6FE]"
                      @click="toggleAllSafeZones"
                    >
                      Show all
                    </button>
                    <button
                      v-for="safeZone in safeZones"
                      :key="safeZone.key"
                      :class="{
                        '!bg-primary !text-[#F9F6FE]': safeZone.show,
                      }"
                      class="btn-primary btn-sm btn self-start !border-transparent bg-[#F9F6FE] font-thin text-primary shadow-none hover:bg-primary/75 hover:text-[#F9F6FE] active:bg-primary active:text-[#F9F6FE]"
                      @click="safeZone.show = !safeZone.show"
                    >
                      {{ safeZone.label }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PreviewOptions />
        </div>
      </div>

      <save-template-dialog ref="saveTemplateDialog"></save-template-dialog>
      <create-brand-kit-dialog />
      <Teleport to="body" v-if="hasThreeColumnLayout">
        <div
          class="modal-sm modal"
          :class="{ 'modal-open': advancedSettings }"
          @click="advancedSettings = false"
          @keydown="
            (e) => {
              if (e.key === 'Escape') {
                advancedSettings = false
              }
            }
          "
        >
          <div class="modal-box" @click.stop>
            <header class="flex flex-row items-center justify-between gap-4 pb-4">
              <h3 class="p-0">Advanced Settings</h3>
              <button class="btn-ghost btn-sm btn h-auto !rounded-lg p-1.5" @click="advancedSettings = false">
                <IconSaxCloseSquare />
              </button>
            </header>

            <div class="flex w-full flex-col gap-2">
              <div class="flex flex-col overflow-visible rounded-lg border-2 border-gray-100 bg-white p-4">
                <div class="panel-content">
                  <div class="preview-controls">
                    <toggle-control v-model="enableSnapping" label="Enable snapping" />

                    <div class="mt-[1em] flex flex-col">
                      <strong class="flex items-center justify-start gap-2">
                        Show safe zone
                        <span
                          class="tooltip font-thin before:left-[calc(50%_-_5rem)!important] before:w-48"
                          data-tip="Toggle to show areas to avoid for key content."
                        >
                          <svg
                            class="block align-baseline"
                            fill="none"
                            height="16"
                            viewBox="0 0 16 16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00004 1.3335C4.32671 1.3335 1.33337 4.32683 1.33337 8.00016C1.33337 11.6735 4.32671 14.6668 8.00004 14.6668C11.6734 14.6668 14.6667 11.6735 14.6667 8.00016C14.6667 4.32683 11.6734 1.3335 8.00004 1.3335ZM7.50004 5.3335C7.50004 5.06016 7.72671 4.8335 8.00004 4.8335C8.27337 4.8335 8.50004 5.06016 8.50004 5.3335V8.66683C8.50004 8.94016 8.27337 9.16683 8.00004 9.16683C7.72671 9.16683 7.50004 8.94016 7.50004 8.66683V5.3335ZM8.61337 10.9202C8.58004 11.0068 8.53337 11.0735 8.47337 11.1402C8.40671 11.2002 8.33337 11.2468 8.25337 11.2802C8.17337 11.3135 8.08671 11.3335 8.00004 11.3335C7.91337 11.3335 7.82671 11.3135 7.74671 11.2802C7.66671 11.2468 7.59337 11.2002 7.52671 11.1402C7.46671 11.0735 7.42004 11.0068 7.38671 10.9202C7.35337 10.8402 7.33337 10.7535 7.33337 10.6668C7.33337 10.5802 7.35337 10.4935 7.38671 10.4135C7.42004 10.3335 7.46671 10.2602 7.52671 10.1935C7.59337 10.1335 7.66671 10.0868 7.74671 10.0535C7.90671 9.98683 8.09337 9.98683 8.25337 10.0535C8.33337 10.0868 8.40671 10.1335 8.47337 10.1935C8.53337 10.2602 8.58004 10.3335 8.61337 10.4135C8.64671 10.4935 8.66671 10.5802 8.66671 10.6668C8.66671 10.7535 8.64671 10.8402 8.61337 10.9202Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </strong>
                      <div class="mt-[1em] flex flex-col space-y-1">
                        <button
                          :class="{ '!bg-primary !text-[#F9F6FE]': showAllSafeZones }"
                          class="btn-primary btn-sm btn self-start !border-transparent bg-[#F9F6FE] font-thin text-primary shadow-none hover:bg-primary/75 hover:text-[#F9F6FE] active:bg-primary active:text-[#F9F6FE]"
                          @click="toggleAllSafeZones"
                        >
                          Show all
                        </button>
                        <button
                          v-for="safeZone in safeZones"
                          :key="safeZone.key"
                          :class="{
                            '!bg-primary !text-[#F9F6FE]': safeZone.show,
                          }"
                          class="btn-primary btn-sm btn self-start !border-transparent bg-[#F9F6FE] font-thin text-primary shadow-none hover:bg-primary/75 hover:text-[#F9F6FE] active:bg-primary active:text-[#F9F6FE]"
                          @click="safeZone.show = !safeZone.show"
                        >
                          {{ safeZone.label }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <PreviewOptions />
            </div>
          </div>
        </div>
      </Teleport>
      <Teleport to="body" v-if="customLayoutsModal">
        <EditCustomLayoutsModal :id="editorMainStore.layoutId" @close="customLayoutsModal = false" @save="updateCustomLayout" />
      </Teleport>
      <toast />
    </template>
    <template v-slot:footer>
      <div class="flex w-full flex-col" data-retain-focus>
        <div class="container mx-auto">
          <div class="relative w-full">
            <button
              :class="{
                'btn-primary': activeStep !== 'captions' || editorCaptionsStore.hasCaptions,
                'btn-outline bg-white': activeStep === 'captions' && !editorCaptionsStore.hasCaptions,
              }"
              :disabled="preprocessing || popupOpen"
              class="floating-footer-button btn-primary btn absolute bottom-full right-0 mb-6 gap-2 sm:btn-xl"
              @click="(nextSteps.length === 0 && hasVideoOutputQualityPopup) ? (popupOpen = !popupOpen) : nextStep()"
            >
              {{ (nextSteps.length === 0 && hasVideoOutputQualityPopup) ? 'Export clip' : nextButtonText }}

              <Spinner v-if="preprocessing" class="h-6 w-6 animate-spin" />
              <bread-crumb-seperator class="transition-all" v-else :class="{'rotate-90 h-3 w-3': (nextSteps.length === 0 && hasVideoOutputQualityPopup && !popupOpen), 'rotate-[270deg] h-3 w-3': (nextSteps.length === 0 && hasVideoOutputQualityPopup && popupOpen)}" />
            </button>
            <Transition name="fade" key="qualityPopup">
              <ExportQualityPicker v-if="hasVideoOutputQualityPopup" :nextStep="nextStep" :popupOpen="popupOpen" :closePopup="() => popupOpen = false" />
            </Transition>
          </div>
        </div>
        <Timeline step="editor" class="mx-0 w-full border-0" :default-open="timelineDefaultOpen" />
      </div>
    </template>
  </app-page>
</template>

<script>
import StickerPicker from '../../components/Stickers/StickerPicker.vue'
import TextPicker from '../../components/Stickers/TextPicker.vue'
import AppPage from '../../components/AppPage.vue'
import SaveTemplateDialog from '../../components/Dialog/SaveTemplateDialog.vue'
import ToggleControl from '../../components/Controls/ToggleControl.vue'
import Stepper from '../../components/Editor/Stepper/Stepper.vue'
import stickerService from '../../services/stickerService'
import PreviewContainer from '../../components/Editor/PreviewContainer.vue'
import DropdownPanel from '../../components/Buttons/DropdownPanel.vue'
import StickerCustomUpload from '../../components/Stickers/StickerCustomUpload.vue'
import EventBus from '../../eventBus'
import canFeature from '../../mixins/canFeature'
import CreateBrandKitDialog from '../../components/Dialog/BrandKit/CreateBrandKitDialog.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { useFontsStore } from '@/store/fonts'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorMainStore } from '@/store/editor/editorMain'
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon.vue'
import BreadCrumbSeperator from '../../components/Sources/BreadCrumb/BreadCrumbSeperator.vue'
import PreviewOptions from '@/components/Layouts/PreviewOptions.vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import CaptionsPanel from '@/components/Captions/CaptionsPanel.vue'
import TabRadioGroup from '@/components-v2/data-input/TabRadioGroup.vue'
import UploadIcon from '@/components/Icons/UploadIcon.vue'
import ExportStep from '@/pages/editor/ExportStep.vue'
import Toast from '@/components/Toast/Toast.vue'
import { useUserTemplatesStore } from '@/store/user/userTemplates'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import Timeline from '@/components/Editor/Timeline/Timeline.vue'
import { useStreamladderRender } from '@/Hooks/useStreamladderRender'
import { useDebugActionRenderOverlay } from '@/components/Debug/debugActions'
import mainEvents from '@/events/mainEvents'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import Spinner from '@/components/Icons/Spinner.vue'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import IconSaxCloseSquare from '@/components/Icons/iconsax/IconSaxCloseSquare.vue'
import compatabilityChecker from '@/services/compatabilityChecker.js'
import EditCustomLayoutsModal from '@/modules/CustomLayouts/EditCustomLayoutsModal.vue'
import { updateCropsAndLayout } from '@/store/entity-system/actions/updateCropsAndLayout'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import ExportQualityPicker from '@/pages/editor/ExportQualityPicker.vue'

export default {
  components: {
    ExportQualityPicker,
    EditCustomLayoutsModal,
    IconSaxCloseSquare,
    Spinner,
    Timeline,
    Toast,
    ExportStep,
    TabRadioGroup,
    CaptionsPanel,
    PreviewOptions,
    BreadCrumbSeperator,
    ArrowRightIcon,
    CreateBrandKitDialog,
    ToggleControl,
    Stepper,
    DropdownPanel,
    AppPage,
    StickerPicker,
    TextPicker,
    PreviewContainer,
    StickerCustomUpload,
    SaveTemplateDialog,
  },
  mixins: [canFeature],
  setup() {
    const editorMainStore = useEditorMainStore()

    const editorCaptionsStore = useEditorCaptionsStore()
    editorCaptionsStore.resetPositionScale()
    const editorFocusStore = useEditorFocusStore()
    const userBrandkitStore = useUserBrandkitStore()
    const userInfoStore = useUserInfoStore()
    const { requestVideoRender } = useStreamladderRender()

    const userTemplatesStore = useUserTemplatesStore()

    useDebugActionRenderOverlay()
    const hasThreeColumnLayout = useFeatureFlagVariantEnabled('three-column-layout', 'wide')

    const hasVideoOutputQualityPopup = useFeatureFlagVariantEnabled('video-output-quality-popup', 'popup')

    return {
      editorMainStore,
      editorCaptionsStore,
      editorFocusStore,
      userBrandkitStore,
      userInfoStore,
      userTemplatesStore,
      requestVideoRender,
      hasThreeColumnLayout,
      hasVideoOutputQualityPopup
    }
  },
  computed: {
    stepKeys() {
      return this.steps.map((x) => x.key)
    },
    previousAndCurrentSteps() {
      const currentIndex = this.stepKeys.indexOf(this.activeStep)
      return this.steps.filter((x, i) => i <= currentIndex)
    },
    nextSteps() {
      const currentIndex = this.stepKeys.indexOf(this.activeStep)
      return this.steps.filter((x, i) => i > currentIndex)
    },
    nextButtonText() {
      if (this.activeStep === 'captions' && !this.editorCaptionsStore.hasCaptions) {
        return 'Skip Captions'
      }

      if (this.nextSteps.length > 0) {
        return this.nextSteps[0].title
      } else {
        return 'Render clip'
      }
    },
    showAllSafeZones() {
      return this.safeZones.every((s) => s.show)
    },
    showTimeline() {
      return true
    },
    timelineDefaultOpen() {
      return compatabilityChecker.isMobile() ? false : true
    },
  },
  data() {
    return {
      enableSnapping: true,
      safeZones: [
        { key: 'tiktok', label: 'Tiktok', show: false },
        { key: 'youtube', label: 'YouTube Shorts', show: false },
      ],
      stickerText: '',
      showPreview: true,
      showTimelineStickerTrack: import.meta.env.VITE_FEATURE_TIMELINE_STICKER_TRACK === '1',
      advancedSettings: false,
      steps: [
        {
          key: 'text',
          title: 'Text',
        },
        {
          key: 'stickers',
          title: 'Stickers',
        },
        {
          key: 'captions',
          title: 'Captions',
        },
        {
          key: 'output-settings',
          title: 'Export',
        },
      ],
      activeStep: 'text',
      activeStickerPanel: 'library',
      preprocessing: false,
      customLayoutsModal: false,
      popupOpen: false,
      activeQuality: '720p 30fps',
    }
  },
  async mounted() {
    if (this.userCan('brandkit')) {
      await this.userBrandkitStore.list()
    }

    const fontsStore = useFontsStore()
    await fontsStore.initialize()

    const clipSource = this.$route.params.source

    if (this.stickerText === '') {
      this.stickerText = stickerService.getInitialStickerText(clipSource)
    }
    if (this.$route.query.savedTemplate) {
      this.activeStep = this.steps[this.steps.length - 2].key
    }
    await this.$nextTick()
    this.showPreview = true

    const layoutsStore = useLayoutsStore()
    if (this.editorMainStore.layoutId && layoutsStore.selectById(this.editorMainStore.layoutId).value) {
      await this.$trackEvent('Layout Selected', {
        Layout: this.$route.meta.layout,
        SavedTemplate: !!this.$route.query.savedTemplate,
        IsPremiumLayout: true,
        IsCustomLayout: true,
      })
    }
  },
  methods: {
    updateCustomLayout(layout, crops) {
      updateCropsAndLayout(layout, crops)
      this.customLayoutsModal = false
      const editorFeedDataStore = useEditorFeedDataStore()
      editorFeedDataStore.initializeForCustomLayout(layout.id)
    },
    async goToCrop() {
      if (this.editorMainStore.layoutId) {
        this.customLayoutsModal = true
      } else {
        await this.$router.push({ name: 'editor/cropping' })
      }
    },
    UploadIcon() {
      return UploadIcon
    },
    async nextStep() {
      const index = this.stepKeys.indexOf(this.activeStep)
      if (index < this.stepKeys.length - 1) {
        this.activeStep = this.stepKeys[index + 1]
      } else {
        // finish
        this.preprocessing = true
        await this.renderVideo()
        this.preprocessing = false
      }
    },
    async renderVideo() {
      const result = await this.requestVideoRender()

      if (!result || (result.type === 'error' && result.message !== 'User is not authenticated')) {
        EventBus.$emit(
          mainEvents.ERROR,
          `Something went wrong with rendering <strong>${result.message}</strong>.
          <br/><br/>
          ${result.taskId ? 'Please open a Ticket on Discord with TaskId:<br/> ' + result.taskId : ''}`
        )
      }

      if (result.type === 'server' && result.task) {
        this.$router.push({
          name: 'GenerateServerSideQueue',
          query: {
            task: result.task.id,
          },
        })
      }
    },
    openSaveTemplateDialog() {
      if (!this.userInfoStore.isAuthenticated || !this.userCan('save-template')) {
        upgradeDialog.open('save-template')
        return
      }

      this.$refs.saveTemplateDialog.open()
    },
    toggleAllSafeZones() {
      const show = !this.showAllSafeZones
      for (const safeZone of this.safeZones) {
        safeZone.show = show
      }
    },
  },
  watch: {
    'editorFocusStore.focus'(to) {
      if (to?.type === FocusTypes.CAPTION) {
        this.activeStep = 'captions'
      }
    },
    'userInfoStore.isAuthenticated'() {
      if (this.userCan('brandkit')) {
        this.userBrandkitStore.list()
      }
    },
    'userTemplatesStore.savedTemplates'() {
      if (this.$route.query.savedTemplate) {
        const template = this.userTemplatesStore.getTemplateById(this.$route.query.savedTemplate)

        if (!template) return
        const stickerStore = useEditorStickersStore()
        stickerStore.selectedStickers = template.stickers
        template.stickers.forEach((sticker) => {
          if (sticker.component == undefined) {
            sticker.component = stickerLibrary.find((x) => x.key === sticker.componentName).component
          }
          sticker.savedSticker = true
          EventBus.$emit('editor/stickers/added', sticker)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

ul.mobile-steps {
  list-style: none;
  margin: 0;
  padding-left: 0;
  flex-direction: column;

  .step {
    padding: 0.3em 1em;
    font-size: 17px;
    cursor: pointer;
    opacity: 0.5;

    i {
      float: right;
    }

    &.active {
      opacity: 1;
    }
  }
}

.display-options {
  display: flex;
  flex-direction: column;
  gap: 1em;

  strong {
    color: #47089e;
  }

  color: #8833ff;
}

.editor-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.options-panel {
  background: white;
  border: 2px solid #eff2f9;
  border-radius: 10px;
  padding: 1em;
}

.editor-container {
  justify-content: center;
  height: 100%;
}

.preview-controls {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 1em;
}

.preview-container {
  min-height: 400px;
  max-height: calc(100vh - calc(var(--footer-offset)));
}
</style>
