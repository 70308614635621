<script setup lang="ts">
import { useMovableElementContext } from '@/modules/SLMovable/useMovableElementContext'
import { useRafFn, onKeyDown, onKeyUp, useMagicKeys } from '@vueuse/core'
import { moveWithBoundingAndSnapping } from '@/modules/SLMovable/helpers/move/move'
import { ref, computed, onMounted, onUnmounted } from 'vue'

const emit = defineEmits<{
  (event: 'moveStart', point: { x: number; y: number }): void
  (event: 'move', area: { x: number; y: number; width: number; height: number }): void
  (event: 'moveEnd'): void
}>()

const {
  focused,
  isMoving,
  isResizing,
  movingFrom,
  source,
  localArea,
  scaleX,
  scaleY,
  bounds,
  snap,
  snapGridId,
  containerHeight,
  containerWidth,
  container,
} = useMovableElementContext()!

const snapLines = ref<{ x: number[]; y: number[] }>({ x: [], y: [] })

onKeyDown(['Shift'], () => {
  if (movingFrom.value) {
    snapLines.value = {
      x: [localArea.value.x + 0.5 * localArea.value.width],
      y: [localArea.value.y + 0.5 * localArea.value.height],
    }
  }
})

onKeyUp(['Shift'], () => {
  if (isMoving.value) {
    snapLines.value = { x: [], y: [] }
  }
})

const snapTo = computed(() => ({
  x: [...(snap.value?.x ?? []), ...snapLines.value.x],
  y: [...(snap.value?.y ?? []), ...snapLines.value.y],
}))


function determineSnapping(event: MouseEvent | TouchEvent) {

  if (!snap.value || event.ctrlKey) {
    return null
  }

  return {
    x: snap.value.x ?? null,
    y: snap.value.y ?? null,
  }
}

function determineMoveTarget(event: MouseEvent | TouchEvent) {

  const point = determineTouchPoint(event)
  const deltaX = scaleX.value(point.x - movingFrom.value!.x)
  const deltaY = scaleY.value(point.y - movingFrom.value!.y)

  if (!event.shiftKey) {
    return {
      x: source.value.x + deltaX,
      y: source.value.y + deltaY,
    }
  }

  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    const snapY = snapLines.value.y[0] ?? localArea.value.y + 0.5 * localArea.value.height
    return {
      x: source.value.x + deltaX,
      y: snapY - 0.5 * source.value.height,
    }
  } else {
    const snapX = snapLines.value.x[0] ?? localArea.value.x + 0.5 * localArea.value.width
    return {
      x: snapX - 0.5 * localArea.value.width,
      y: source.value.y + deltaY,
    }
  }
}

const snapThreshold = computed(() => ({
  x: scaleX.value(5),
  y: scaleY.value(5),
}))

function move(event: MouseEvent | TouchEvent) {

  if (!movingFrom.value) return

  localArea.value = moveWithBoundingAndSnapping(
    localArea.value,
    determineMoveTarget(event),
    bounds.value,
    determineSnapping(event),
    snapThreshold.value,
  )

  emit('move', localArea.value)
}

function determineTouchPoint(event: MouseEvent | TouchEvent) {

  const { pageX, pageY } = 'pageX' in event ? event : event.touches[0]

  const containerRect = container.value!.getBoundingClientRect()

  return {
    x: pageX - containerRect.x - window.scrollX,
    y: pageY - containerRect.y - window.scrollY,
  }
}

function moveStart(event: MouseEvent | TouchEvent) {

  emit('moveStart', determineTouchPoint(event))

  window.addEventListener('mousemove', move)
  window.addEventListener('touchmove', move)

  window.addEventListener('mouseup', moveEnd)
  window.addEventListener('touchend', moveEnd)
}

function moveEnd() {

  snapLines.value = { x: [], y: [] }
  emit('moveEnd')

  window.removeEventListener('mousemove', move)
  window.removeEventListener('touchmove', move)

  window.removeEventListener('mouseup', moveEnd)
  window.removeEventListener('touchend', moveEnd)
}

const snapLinesToDisplay = computed(() => {

  function pointAt(relative: number) {
    return {
      x: localArea.value.x + relative * localArea.value.width,
      y: localArea.value.y + relative * localArea.value.height,
    }
  }

  return {
    x: snapTo.value.x.filter((x) => [0, 0.5, 1].some(v => pointAt(v).x === x)),
    y: snapTo.value.y.filter((y) => [0, 0.5, 1].some(v => pointAt(v).y === y)),
  }
})

const boundaryLinesToDisplay = computed(() => {

  const boundariesX = [bounds.value?.top, bounds.value?.bottom].filter((x) => x !== null) as number[]
  const boundariesY = [bounds.value?.left, bounds.value?.right].filter((x) => x !== null) as number[]

  return {
    x: boundariesX.filter((x) => x === localArea.value.x || x === localArea.value.x + localArea.value.width),
    y: boundariesY.filter((y) => y === localArea.value.y || y === localArea.value.y + localArea.value.height),
  }
})

const { shift, arrowUp, arrowLeft, arrowDown, arrowRight } = useMagicKeys()

const { pause: pauseKeyboardHandling, resume: resumeKeyboardHandling } = useRafFn(() => {

  if (!shift.value && !arrowUp.value && !arrowLeft.value && !arrowDown.value && !arrowRight.value) {
    return
  }

  const delta = {
    x: arrowLeft.value ? -1 : arrowRight.value ? 1 : 0,
    y: arrowUp.value ? -1 : arrowDown.value ? 1 : 0,
  }

  if (shift.value) {
    delta.x *= 10
    delta.y *= 10
  }

  delta.x /= containerWidth.value
  delta.y /= containerHeight.value

  delta.x += localArea.value.x
  delta.y += localArea.value.y

  localArea.value = moveWithBoundingAndSnapping(
    localArea.value,
    delta,
    bounds.value,
    null)

  emit('move', localArea.value)

}, { immediate: false })

function onArrowKeyDown() {
  if (focused.value) {
    if (arrowUp.value || arrowLeft.value || arrowDown.value || arrowRight.value) {
      resumeKeyboardHandling()
      return false
    }
  }
}

function onArrowKeyUp() {
  if (!arrowUp.value  && !arrowLeft.value  && !arrowDown.value  && !arrowRight.value) {
    setTimeout(() => {
      pauseKeyboardHandling()
      emit('moveEnd')
    }, 0)
  }
}

onMounted(() => {
  window.addEventListener('keydown', onArrowKeyDown)
  window.addEventListener('keyup', onArrowKeyUp)
})

onUnmounted(() => {
  window.removeEventListener('keydown', onArrowKeyDown)
  window.removeEventListener('keyup', onArrowKeyUp)
})
</script>

<template>
  <div
    class="absolute inset-0"
    :class="{
      'cursor-grab': !isResizing && !isMoving,
      'cursor-grabbing': isMoving,
    }"
    @mousedown="moveStart"
    @touchstart="moveStart"
  >
    <slot />
  </div>

  <Teleport :to="`#${snapGridId}`">
    <template v-if="isMoving">
      <template v-if="bounds">
        <div
          v-for="x in boundaryLinesToDisplay.x"
          :key="`bound-${x}`"
          class="absolute w-px bg-red-500"
          :style="{
            top: (bounds.top ?? 0) * containerHeight + 'px',
            height: (1 + ((bounds.bottom ?? 0) - 1) - (bounds.top ?? 0)) * containerHeight + 'px',
            left: x * containerWidth - 1 + 'px',
          }"
        />
      </template>

      <div
        v-for="x in snapLinesToDisplay.x"
        :key="x"
        class="absolute w-px bg-sky-500"
        :style="{
          top: (bounds?.top ?? 0) * containerHeight + 'px',
          height: (1 + ((bounds?.bottom ?? 0) - 1) - (bounds?.top ?? 0)) * containerHeight + 'px',
          left: x * containerWidth - 1 + 'px',
        }"
      />

      <template v-if="bounds">
        <div
          v-for="y in boundaryLinesToDisplay.y"
          :key="`bound-${y}`"
          class="absolute h-px bg-red-500"
          :style="{
            left: (bounds.left ?? 0) * containerWidth + 'px',
            width: (1 + ((bounds.right ?? 0) - 1) - (bounds.left ?? 0)) * containerWidth + 'px',
            top: y * containerHeight - 1 + 'px',
          }"
        />
      </template>

      <div
        v-for="y in snapLinesToDisplay.y"
        :key="y"
        class="absolute h-px bg-sky-500"
        :style="{
          left: (bounds?.left ?? 0) * containerWidth + 'px',
          width: (1 + ((bounds?.right ?? 0) - 1) - (bounds?.left ?? 0)) * containerWidth + 'px',
          top: y * containerHeight - 1 + 'px',
        }"
      />
    </template>
  </Teleport>
</template>

<style scoped lang="scss"></style>
