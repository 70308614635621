import type { EditorFragment, ScaleSet } from '@/modules/SLVideoplayer/types'
import type { CropData, Segment } from '@/apis/streamladder-api/model'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useEditorMainStore } from '@/store/editor/editorMain'

const scalesetToCropData = (
  scaleset: ScaleSet
): {
  x?: number
  y?: number
  width?: number
  height?: number
} => {
  return {
    x: scaleset.x,
    y: scaleset.y,
    width: scaleset.w,
    height: scaleset.h,
  }
}

export const fragmentToCropData = (fragment: EditorFragment): CropData => {
  const roundedEffect = fragment.cropOptions.isRound
    ? ([
        {
          type: 'rounded',
        },
      ] as const)
    : undefined

  const cropData: CropData = {
    ...scalesetToCropData(fragment.cropData),
    feedData: {
      ...scalesetToCropData(fragment.feedData),
      effects: roundedEffect,
    },
  }
  return cropData
}

// convert internal layout to the layout used in the API
const layoutToRenderLayout = (layout: string): 'Fullscreen' | 'Split' | 'Custom' => {
  if (layout === 'Split16x9' || layout === 'Split4x3' || layout === 'SplitFree') return 'Split'
  if (layout === 'FullScreen') return 'Fullscreen'
  return 'Custom'
}

export const getSegmentsFromStores = (): Segment[] => {
  const feedDataStore = useEditorFeedDataStore()
  const renderFragments = feedDataStore.renderFragments
  return renderFragments.map((segment) => {
    const renderLayout = layoutToRenderLayout(segment.layout || '')

    // sort cropData by y position if layout is split
    // this is necessary because the API expects the cropData to be in the correct order
    const cropData = segment.cropData?.sort((a, b) => {
      if (renderLayout === 'Split') return a.feedData?.y || 0 - (b.feedData?.y || 0)
      return 0
    })

    return {
      startMs: segment.start,
      endMs: segment.end,
      layout: renderLayout,
      cropData: cropData,
    }
  })
}
