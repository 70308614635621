<template>
  <div class="hidden animate-pulse flex-col md:flex">
    <div class="skeleton mb-4 h-8 w-1/2"></div>
    <div class="skeleton mb-2 h-8 w-1/2"></div>
    <div class="mb-4 flex gap-2">
      <div class="skeleton h-9 w-9 rounded-full"></div>
      <div class="skeleton h-9 w-9 rounded-full"></div>
      <div class="skeleton h-9 w-9 rounded-full"></div>
    </div>
    <div class="skeleton mb-2 h-8 w-1/2"></div>
    <div class="skeleton mb-6 h-12 w-full"></div>
    <div class="skeleton mb-2 h-6 w-1/2"></div>
    <div class="skeleton mb-2 h-44 w-full"></div>
    <div class="skeleton mb-2 h-12 w-full"></div>
    <div class="skeleton mb-6 h-12 w-full"></div>
    <div class="h-16"></div>
    <div class="flex justify-end gap-2">
      <div class="skeleton h-12 w-32 rounded-full"></div>
      <div class="skeleton h-12 w-32 rounded-full"></div>
    </div>
  </div>
</template>
