<script setup lang="ts">
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import { useGuard } from '@/Hooks/useGuard'
import { ref } from 'vue'
import EventBus from '@/eventBus'
import upgradeEvents from '@/events/upgradeEvents'
import { useEditorMainStore } from '@/store/editor/editorMain'

const props = defineProps<{
  nextStep: () => void,
  popupOpen: boolean,
  closePopup: () => void,
}>();

const canHighQuality = useGuard('resolution-1080');
const activeQuality = ref('720p 30fps');
const editorMainStore = useEditorMainStore();

const standardQualityRef = ref<HTMLInputElement|null>(null);
const highQualityRef = ref<HTMLInputElement|null>(null);
const highestQualityRef = ref<HTMLInputElement|null>(null);

const showUpgradeDialog = () => {

  EventBus.$emit(upgradeEvents.OPEN_UPGRADE_DIALOG, {
    feature: 'resolution-1080',
    reason: 'High quality export',
  });

  if (standardQualityRef.value) {
    standardQualityRef.value.checked = true
    standardQualityRef.value.parentElement?.classList.add('active')
  }

  props.closePopup()
};

const handleExport = () => {
  props.nextStep()
  props.closePopup()
};

const handleQualityChange = (input: Event) => {

  const currentInput = input.target as HTMLInputElement;

  const labels = document.querySelectorAll('label')

  labels.forEach((label) => {
    label.classList.remove('active')
  });

  currentInput?.parentElement?.classList?.add('active');

  if (currentInput.value === '720p30fps') {
    activeQuality.value = '720p 30fps';
    editorMainStore.setVideoOutputDimensions({
      width: 720,
      height: 1280,
    });
  } else if (currentInput.value === '720p60fps') {
    if (canHighQuality.value) {
      activeQuality.value = '720p 60fps';
      editorMainStore.setVideoOutputDimensions({
        width: 720,
        height: 1280,
      });
    } else {
      showUpgradeDialog();
    }
  } else if (currentInput.value === '1080p') {
    if (canHighQuality.value) {
      activeQuality.value = '1080p 60fps';
      editorMainStore.setVideoOutputDimensions({
        width: 1080,
        height: 1920,
      });
    } else {
      showUpgradeDialog();
    }
  }
};
</script>

<template>
  <div v-if="popupOpen" v-click-outside="closePopup" class="absolute sm:-right-[110px] bottom-4 sm:w-[420px] sm:bottom-24 w-full rounded-lg bg-white shadow-2xl">
    <div class="flex flex-col">
      <div class="flex w-full justify-between flex-row p-5 items-center border-b-[1px] border-gray-200">
        <p class="font-semibold text-xl cursor-default">Select your quality</p>
        <IconSaxCloseCircle class="w-5 h-5 text-white bg-gray-400 rounded-full cursor-pointer" v-on:click="closePopup" />
      </div>
      <label class="cursor-pointer p-4 flex gap-4 items-center px-6 hover:bg-blue-50 border-[1px] border-gray-200 transition-all" :class="{'active': !canHighQuality}" for="720p30fps">
        <input
          :ref="standardQualityRef"
          value="720p30fps"
          type="radio"
          name="quality"
          id="720p30fps"
          autocomplete="off"
          v-on:change="handleQualityChange"
          :checked="!canHighQuality"
        />
        <span class="flex flex-col justify-center">
          <span class="font-bold mb-0.5 w-16 text-sm">720p</span>
          <span class="font-extralight text-gray-400 leading-none text-sm">30 fps</span>
        </span>
        <span class="flex flex-col justify-center">
          <span class="font-bold mb-0.5">Standard 720p</span>
          <span class="font-extralight text-gray-400 leading-none text-sm">Content creation starts here</span>
        </span>
      </label>
      <label class="cursor-pointer p-4 gap-4 flex items-center px-6 hover:bg-blue-50 border-[1px] border-gray-200 transition-all" :class="{'active': canHighQuality}" for="720p60fps">
        <input
          :ref="highQualityRef"
          value="720p60fps"
          type="radio"
          name="quality"
          id="720p60fps"
          autocomplete="off"
          v-on:change="handleQualityChange"
          :checked="canHighQuality"
        />
        <span class="flex flex-col justify-center">
          <span class="font-bold mb-0.5 w-16 text-sm">720p</span>
          <span class="font-extralight text-gray-400 leading-none text-sm">60 fps</span>
        </span>
        <span class="flex flex-col justify-center">
          <span class="font-bold mb-0.5">720p High Frame Rate</span>
          <span class="font-extralight text-gray-400 leading-none text-sm">Enhanced smoothness</span>
        </span>
        <DynamicPlanButtonWithTooltip class="w-4 h-4" feature="resolution-1080" />
      </label>
      <label class="cursor-pointer p-4 flex gap-4 items-center px-6 hover:bg-blue-50 border-[1px] border-gray-200 transition-all" for="1080p">
        <input
          :ref="highestQualityRef"
          value="1080p"
          type="radio"
          name="quality"
          id="1080p"
          autocomplete="off"
          v-on:change="handleQualityChange"
        />
        <span class="flex flex-col justify-center">
          <span class="font-bold mb-0.5 w-16 text-sm">1080p</span>
          <span class="font-extralight text-gray-400 leading-none text-sm">60 fps</span>
        </span>
        <span class="flex flex-col justify-center">
          <span class="font-bold mb-0.5">Full HD 1080p</span>
          <span class="font-extralight text-gray-400 leading-none text-sm">Best for creators</span>
        </span>
        <DynamicPlanButtonWithTooltip class="w-4 h-4" feature="resolution-1080" />
        <span class="px-2 py-1 whitespace-nowrap bg-green-600 text-white text-[11px] rounded-lg">Highest quality!</span>
      </label>
      <div class="flex items-center w-full justify-center p-4 border-t-[1px] border-t-gray-200">
        <GradientButton class="w-full text-sm" v-on:click="handleExport">Export in {{ activeQuality }}</GradientButton>
      </div>
    </div>
    <div class="w-4 h-4 rotate-45 absolute -bottom-2 left-[calc(50%-15px)] bg-white hidden sm:block"></div>
  </div>
</template>

<style scoped lang="scss">
.active {
  background-color: rgb(239 246 255);
  border: 1px solid rgb(0 122 255) !important;
}
</style>