<template>
  <span
    class="flex flex-wrap items-center justify-center"
    :class="isKappa ? '-gap-x-[0.5ch] -gap-y-[0.2em]' : 'gap-x-[0.5ch]'"
    :style="{
      fontFamily: captionStyleDefinition.fontFamily,
      lineHeight: captionStyleDefinition.fontSize.lineHeight || 1.2,
      // textTransform: captionStyleDefinition.effects.textTransform,
      '--color': caption.color,
    }"
  >
    <span
      v-if="emojiPosition === 'top'"
      :class="isKappa ? '-gap-x-[0.5ch] -gap-y-[0.2em]' : 'gap-x-[0.5ch]'"
      class="mb-[0.25ch] flex w-full justify-center"
    >
      <span
        v-for="(emoji, index) of caption.emojis"
        :key="index"
        :class="classes"
        :style="getStyles(caption.color, captionStyleDefinition)"
      >
        {{ emoji }}
      </span>
    </span>
    <template v-if="emojiPosition === 'left'">
      <span
        v-for="(emoji, index) of caption.emojis"
        :key="index"
        :class="classes"
        :style="getStyles(caption.color, captionStyleDefinition)"
      >
        {{ emoji }}
      </span>
    </template>
    <span
      v-for="word in words"
      :key="word.id"
      :class="classes"
      :style="getStyles(word.color || caption.color, captionStyleDefinition)"
    >
      {{ word.text }}
    </span>
    <template v-if="emojiPosition === 'right'">
      <span
        v-for="(emoji, index) of caption.emojis"
        :key="index"
        :class="classes"
        :style="getStyles(caption.color, captionStyleDefinition)"
      >
        {{ emoji }}
      </span>
    </template>
    <span
      v-if="emojiPosition === 'bottom'"
      class="mt-[0.25ch] flex w-full justify-center"
      :class="isKappa ? '-gap-x-[0.5ch] -gap-y-[0.2em]' : 'gap-x-[0.5ch]'"
    >
      <span
        v-for="(emoji, index) of caption.emojis"
        :key="index"
        :class="classes"
        :style="getStyles(caption.color, captionStyleDefinition)"
      >
        {{ emoji }}
      </span>
    </span>
  </span>
  <span
    v-if="hasGradient"
    class="absolute inset-0 mx-auto bg-clip-text"
    :style="{
      fontFamily: captionStyleDefinition.fontFamily,
      lineHeight: captionStyleDefinition.fontSize.lineHeight || 1.2,
      backgroundImage: getGradient(caption.color),
    }"
  >
    <span
      class="m-auto flex flex-wrap items-center justify-center"
      :class="isKappa ? '-gap-x-[0.5ch] -gap-y-[0.2em]' : 'gap-x-[0.5ch]'"
    >
      <span
        v-if="emojiPosition === 'top'"
        class="mb-[0.25ch] flex w-full justify-center"
        :class="isKappa ? '-gap-x-[0.5ch] -gap-y-[0.2em]' : 'gap-x-[0.5ch]'"
      >
        <span
          v-for="(emoji, index) of caption.emojis"
          :key="index"
          class="inline-block opacity-0"
          :class="classes"
          :style="getStyles(caption.color, captionStyleDefinition)"
        >
          {{ emoji }}
        </span>
      </span>
      <template v-if="emojiPosition === 'left'">
        <span
          v-for="(emoji, index) of caption.emojis"
          :key="index"
          class="inline-block opacity-0"
          :class="classes"
          :style="getStyles(caption.color, captionStyleDefinition)"
        >
          {{ emoji }}
        </span>
      </template>
      <template v-for="word in words" :key="word.id">
        <span v-if="word.isEmoji" class="inline-block opacity-0">{{ word.text }}</span>
        <span
          v-else-if="word.color"
          class="inline-block bg-clip-text !text-transparent"
          :style="{ backgroundImage: getGradient(word.color) ?? getBase64(word.color) }"
        >
          {{ word.text }}
        </span>
        <span class="inline-block !text-transparent" v-else>{{ word.text }}</span>
      </template>
      <template v-if="emojiPosition === 'right'">
        <span
          v-for="(emoji, index) of caption.emojis"
          :key="index"
          class="inline-block opacity-0"
          :class="classes"
          :style="getStyles(caption.color, captionStyleDefinition)"
        >
          {{ emoji }}
        </span>
      </template>
      <span
        v-if="emojiPosition === 'bottom'"
        class="mt-[0.25ch] flex w-full justify-center"
        :class="isKappa ? '-gap-x-[0.5ch] -gap-y-[0.2em]' : 'gap-x-[0.5ch]'"
      >
        <span
          v-for="(emoji, index) of caption.emojis"
          :key="index"
          class="inline-block opacity-0"
          :class="classes"
          :style="getStyles(caption.color, captionStyleDefinition)"
        >
          {{ emoji }}
        </span>
      </span>
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { CaptionStyleDefinition, StoredCaption } from '@/components/Captions/captionTypes'
import { useMemoize } from '@vueuse/core'
import tinyColor from 'tinycolor2'
import emojiRegexp from 'emoji-regex'

const props = defineProps<{
  caption: StoredCaption
  captionStyleDefinition: CaptionStyleDefinition
}>()

const classes = computed(() => {
  return [
    props.captionStyleDefinition.cssClass,
    props.captionStyleDefinition.style.border && 'text-border',
    props.captionStyleDefinition.style.shadow && 'text-shadow',
    props.captionStyleDefinition.style.dropShadow && 'drop-shadow',
    ...(isKappa.value ? ['mx-[-0.25ch] my-[-0.125ch] px-[0.5ch] py-[0.5ch] leading-[1]'] : []),
  ].filter(Boolean)
})

const getColor = useMemoize((color: 'default' | 'darker' | 'lighter' | string, baseColor: string) => {
  if (color === 'default') return baseColor
  if (color === 'darker') return tinyColor(baseColor).darken(30).toString()
  if (color === 'lighter') return tinyColor(baseColor).lighten(70).toString()
  return color
})

const getStyles = useMemoize((color: string, style: CaptionStyleDefinition) => {
  const styles: Record<string, string> = {
    '--color': getColor(style.style.color, color),
    '--second-color': color === '#FFFFFF' ? '#000000' : '#FFFFFF',
  }
  if (style.style.border) {
    styles['--border-size'] = `${style.style.border.width}px`
    styles['--border-color'] = style.borderColors
      ? style.borderColors[color]
      : getColor(style.style.border.color, color)
  }
  if (style.style.shadow) {
    styles['--shadow-color'] = getColor(style.style.shadow.color, color)
    if (style.style.shadow.width) {
      styles['--shadow-width'] = `${style.style.shadow.width}px`
    }
  }
  if (style.style.dropShadow) {
    const { offset, blur, color: shadowColor, opacity } = style.style.dropShadow
    styles['--drop-shadow'] = [
      `${offset?.x ?? 0}px`,
      `${offset?.y ?? 0}px`,
      `${blur ?? 0}px`,
      `${tinyColor(getColor(shadowColor, color))
        .setAlpha(opacity ?? 1)
        .toRgbString()}`,
    ].join(' ')
  }
  if (style.fontSize.fontWeight) {
    styles['font-weight'] = style.fontSize.fontWeight.toString()
  }
  if (getGradient(props.caption.color) ?? getGradient(color)) {
    styles['--color'] = '#000000'
  }
  return { ...style.styleOverrides, ...styles }
})

const getGradient = useMemoize((color: string | undefined) => {
  return props.captionStyleDefinition.gradients && color ? props.captionStyleDefinition.gradients[color] : undefined
})

const canvas = document.createElement('canvas')
canvas.width = 1
canvas.height = 1

// Appears to result in slightly crisper rendering when combined with text gradients than `color: word.color`
const getBase64 = (color: string) => {
  const ctx = canvas.getContext('2d')
  if (!ctx) return ''
  ctx.fillStyle = color
  ctx.fillRect(0, 0, 1, 1)
  return `url(${canvas.toDataURL()})`
}

const isAnEmoji = (() => {
  const regexp = emojiRegexp()
  return (word: string) => regexp.test(word)
})()

const emojiPosition = computed(() => props.caption.emojis && props.captionStyleDefinition.effects.emoji?.location)

const words = computed(() => {
  const words = []
  for (const entry of props.caption.words) {
    const entryWords = entry.text.split(' ')
    for (const index in entryWords) {
      const word = entryWords[index]?.toString().trim()
      if (word) {
        words.push({ ...entry, text: word.trim(), id: `${entry.id}-${index}`, isEmoji: isAnEmoji(word.trim()) })
      }
    }
  }
  return words
})

const isKappa = computed(() => props.captionStyleDefinition.cssClass.split(' ').some((item) => item === 'kappa'))

const hasGradient = computed(() => {
  return getGradient(props.caption.color) || props.caption.words.some((w) => getGradient(w.color))
})
</script>

<style scoped>
span {
  font-weight: 400;
  color: var(--color);
  --border-size: 3px;
  text-shadow: var(--text-border, 0 0 0 transparent), var(--text-shadow, 0 0 0 transparent);
  filter: drop-shadow(var(--drop-shadow));
}

.text-border {
  font-style: normal;
  --text-border: 0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color),
    0 0 var(--border-size) var(--border-color), 0 0 var(--border-size) var(--border-color);
}

.text-shadow {
  font-style: normal;
  --text-shadow: 0 0 var(--shadow-width, 10px) var(--shadow-color), 0 0 var(--shadow-width, 10px) var(--shadow-color),
    0 0 var(--shadow-width, 10px) var(--shadow-color);
}

.kappa {
  color: var(--second-color);
  font-weight: 700;
  position: relative;
  -webkit-box-decoration-break: clone;
  background-color: var(--color);
  border-radius: 5px;
}

.stroke {
  color: var(--second-color);
}
</style>
