import { type Layout, useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import { type Crop, useCropsStore } from '@/store/entity-system/useCropsStore'

export function updateCropsAndLayout(layout: Layout, crops: Crop[]) {

  console.log('layout', layout)

  const cropsStore = useCropsStore()

  const oldCrops = cropsStore.idsWhereLayoutIdIs(layout.id).value
  const removedCrops = oldCrops.filter((id) => !crops.map((c) => c.id).includes(id))

  for (const id of removedCrops) {
    cropsStore.removeById(id)
  }

  for (const crop of crops) {
    cropsStore.upsertById(crop.id, crop)
  }

  const layoutsStore = useLayoutsStore()
  layoutsStore.upsertById(layout.id, layout)
}
