<template>
  <Tile @click="selectSticker" @resize="onResize" :class="{ 'text-sticker': isTextSticker }" feature="text">
    <component
      ref="sticker"
      :is="sticker.component"
      :html-content="htmlContent"
      :color="selectedColor"
      :variant="sticker.variant"
      :icon="isMultiSticker ? JSON.stringify(activeSocials) : sticker.icon"
      :editable="false"
      @stickerLoaded="() => scaleSticker(containerSize)"
      class="sticker"
      style="break-after: always"
    />

    <ColorPicker class="mt-2" v-if="sticker.colors" v-model="selectedColor" :options="sticker.colors" />

    <OverlayBadges :is-new="isNew" :tags="sticker.tags" />
  </Tile>
</template>

<script>
import ColorPicker from './ColorPicker.vue'
import EventBus from '../../eventBus'
import { mapState } from 'pinia'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import Tile from '@/components/Tile.vue'
import OverlayBadges from '@/components/OverlayBadges.vue'

export default {
  components: {
    OverlayBadges,
    Tile,
    ColorPicker,
  },
  props: {
    htmlContent: {
      type: String,
      required: false,
    },
    // One of the objects in StickerLibrary.js or textLibrary.js
    sticker: {
      type: Object,
      required: true,
    },
    isTextSticker: {
      type: Boolean,
      default: false,
    },
    isMultiSticker: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    hasEditableText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useEditorStickersStore, ['activeSocials']),
  },
  data() {
    return {
      selectedColor: null,
      containerSize: null,
    }
  },
  created() {
    if (this.sticker.colors) {
      const keys = Object.keys(this.sticker.colors)
      this.selectedColor = keys.length > 0 ? keys[0] : null
    }
  },
  methods: {
    onResize(size) {
      this.containerSize = size
      this.scaleSticker(this.containerSize)
    },
    scaleSticker() {
      if (!this.$refs.sticker || !this.containerSize) {
        return
      }
      const stickerElement = this.$refs.sticker.$el
      const targetWidth = this.containerSize.width * (2 / 3)
      if (stickerElement.clientWidth >= targetWidth) {
        const stickerScale = targetWidth / stickerElement.clientWidth
        stickerElement.style.transform = `scale(${stickerScale})`
      } else {
        stickerElement.style.transform = null
      }
    },
    selectSticker() {
      const newSticker = {
        component: this.sticker.component,
        componentName: this.sticker.key,
        color: this.selectedColor,
        variant: this.sticker.variant,
        icon: this.isMultiSticker ? JSON.stringify(this.activeSocials) : this.sticker.icon,
        savedSticker: false,
        x: -1,
        y: -1,
        scale: -1,
        htmlContent: this.htmlContent,
        isTextSticker: this.isTextSticker,
        hasEditableText: this.hasEditableText,
        key: '',
        visible: true,
        animationStyle: 'none',
        animationTime: undefined,
      }

      // scroll to top when a sticker is added (mostly for mobile)
      window.scrollTo(0, 0)
      EventBus.$emit('editor/stickers/added', newSticker)

      if (this.isTextSticker) {
        this.$trackEvent('Editor TextSticker Added', {
          Variant: newSticker.variant,
          Color: newSticker.color,
          Tags: this.sticker.tags,
        })
      } else if (this.isMultiSticker) {
        this.$trackEvent('Editor MultiSticker Added', {
          Color: newSticker.color,
          Component: newSticker.componentName,
          socials: this.activeSocials,
          Tags: this.sticker.tags,
        })
      } else {
        this.$trackEvent('Editor Sticker Added', {
          Color: newSticker.color,
          Component: newSticker.componentName,
          Icon: newSticker.icon,
          Tags: this.sticker.tags,
        })
      }
      this.$emit('stickerPicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.sticker {
  pointer-events: none;
}
</style>
