import { defineAsyncComponent } from 'vue'
import TextSticker from "@/components/Stickers/TextSticker.vue";

export default [
  {
    key: 'caption-stroke',
    variant: 'outline',
    component: TextSticker,
    colors: {
      white: 'white',
      black: 'black',
      red: '#ea4040',
      orange: '#ff923d',
      yellow: '#f2ce46',
      green: '#77c25d',
      lightblue: '#3496ef',
      pink: '#f8d7e8',
    },
  },
  {
    key: 'caption-transparent',
    variant: 'transparent',
    component: TextSticker,
    colors: {
      white: 'white',
      red: '#ea4040',
      orange: '#ff923d',
      yellow: '#f2ce46',
      green: '#77c25d',
      lightblue: '#3496ef',
      pink: '#f8d7e8',
    },
  },
  {
    key: 'caption-glow',
    component: TextSticker,
    tags: ['text'],
    icon: '',
    variant: 'glow',
    title: 'Neon',
    colors: {
      blue: 'rgb(8, 8, 241)',
      red: '#ea4040',
      yellow: 'rgb(255, 255, 0)',
      pink: '#f8d7e8',
    },
  },
  {
    key: 'caption-padded',
    variant: 'padded',
    component: TextSticker,
    colors: {
      white: 'white',
      black: 'black',
      red: '#ea4040',
      orange: '#ff923d',
      yellow: '#f2ce46',
      green: '#77c25d',
      lightblue: '#3496ef',
      pink: '#f8d7e8',
    },
  },
]