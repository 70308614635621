<template>
  <div
    v-if="!captionEditorStore.captionsGenerated"
    class="layer-2 flex max-h-full flex-col rounded-lg border-2 border-gray-100 bg-white"
    data-retain-focus
  >
    <!-- Add Captions Banner-->
    <div class="flex flex-col gap-1 p-4">
      <h2 class="text-1xl text-2xl font-semibold leading-snug">Add AI-Powered animated captions to your clip</h2>
      <p class="text-sm font-thin leading-loose">
        Select a caption style below and customize it to your preferences after adding captions.
      </p>
    </div>
    <CaptionStyleSelector @generate-captions="generateCaptions" />
  </div>

  <div v-else class="selecto-container flex max-h-full flex-col">
    <tab-radio-group
      v-model="selectedTab"
      :options="[
        { label: 'Captions', value: 0, icon: Caption },
        { label: 'Settings', value: 1, icon: SettingsIcon },
      ]"
    />
    <div
      class="layer-2 relative flex max-h-full flex-col gap-4 rounded-lg rounded-t-none border-2 border-t-0 border-gray-100"
      :class="{ 'p-4': selectedTab === 0 }"
    >
      <keep-alive>
        <div
          v-if="selectedTab == 0"
          class="relative flex flex-col gap-2 rounded-xl border-2 p-4"
          ref="captionsContainer"
          :class="{
            'border-green-400': editorMainStore.correctionState === 'text',
            'border-gray-100': editorMainStore.correctionState !== 'text',
          }"
        >
          <div class="-mx-1 flex flex-row flex-wrap gap-2">
            <button
              @click="addCaption"
              class="btn-sm btn gap-1 border-gray-100 bg-gray-100 text-gray-600 hover:border-purple-600 hover:bg-purple-600 hover:text-white"
            >
              <IconSaxAddSquare class="w-5" />
              Add caption
            </button>
          </div>
          <div
            class="relative flex w-full flex-row flex-wrap gap-1 overflow-auto border-t border-gray-200 pt-2"
            :class="{
              'selection-wrapper': editorMainStore.correctionState !== 'text',
            }"
            draggable="false"
          >
            <CaptionEditorLine
              v-for="caption in filteredCaptions"
              :key="caption.id"
              :caption="caption"
              class="selectable"
              :disabled="caption.segmentId == undefined"
              :active="editorFocusStore.selectedCaptions.includes(caption.id)"
              :data-id="caption.id"
            />
          </div>
          <div class="flex pt-8">
            <button
              v-if="editorMainStore.correctionState === 'none'"
              @click="editorMainStore.correctionState = 'text'"
              class="btn-sm btn gap-1 border-gray-100 bg-gray-100 text-gray-600 hover:border-purple-600 hover:bg-purple-600 hover:text-white"
            >
              <EditIcon class="w-4" />
              Edit captions
            </button>
            <button v-else @click="editorMainStore.correctionState = 'none'" class="btn-secondary btn-sm btn gap-1">
              <CheckMarkIcon class="w-4" />
              Finish editing
            </button>
            <div class="flex-grow"></div>
            <button class="btn-ghost btn-sm btn gap-1" @click="resetCaptions">
              <ResetIcon class="w-4" />
            </button>
          </div>
        </div>
      </keep-alive>

      <keep-alive>
        <div v-if="selectedTab == 1" class="flex h-full flex-col gap-4 overflow-auto p-4">
          <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
            <div>
              <h4 class="font-bold">Style</h4>
              <p>Change your caption style</p>
            </div>
            <div
              ref="captionsStylesContainer"
              :style="{
                maxHeight: !isCaptionStylesContainerExpanded ? `${captionStylesContainerWidth}px` : 'none',
              }"
              class="overflow-hidden"
            >
              <CaptionStyleSelector @generate-captions="(type) => (captionEditorStore.captionStyle = type)" />
            </div>
            <div class="mt-2 flex w-full justify-center">
              <button
                class="btn-ghost btn-sm btn mx-auto rounded"
                @click="() => (isCaptionStylesContainerExpanded = !isCaptionStylesContainerExpanded)"
              >
                Show {{ isCaptionStylesContainerExpanded ? 'fewer' : 'more' }} styles
              </button>
            </div>
          </div>
          <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
            <div>
              <h4 class="font-bold">Size</h4>
              <p>Change the base text size</p>
            </div>
            <button-radio-group
              :options="[
                { label: 'Large', value: 'large' },
                { label: 'Medium', value: 'medium' },
                { label: 'Small', value: 'small' },
              ]"
              block
              v-model="captionEditorStore.baseOptions.size"
            />
          </div>
          <div class="flex flex-col gap-2 gap-4 rounded-xl border-2 border-gray-100 p-4">
            <div @click.shift="showDebugColorSelector = true">
              <h4 class="font-bold">Color</h4>
              <p>Select your preferred color</p>
            </div>
            <div v-if="showDebugColorSelector"><input type="color" v-model="baseColor" />{{ baseColor }}</div>
            <div class="flex flex-row flex-wrap gap-2">
              <button
                v-for="color in captionEditorStore.captionStyleSettings.colors"
                @click="captionEditorStore.styleOptions.data.baseColor = color"
                :key="color"
                class="flex h-9 w-9 items-center justify-center rounded-xl"
                :class="{
                  'border-2 border-[#277DFF]': color === captionEditorStore.styleOptions.data?.baseColor,
                }"
              >
                <div
                  class="h-5 w-5 rounded-full"
                  :style="{
                    background: captionEditorStore.captionStyleSettings.gradients?.[color] ?? color,
                  }"
                  :class="{
                    'border border-black': color === '#FFFFFF',
                  }"
                ></div>
              </button>
            </div>
          </div>
          <div
            v-if="!captionEditorStore.captionStyleSettings.disableHighlight"
            class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4"
          >
            <label class="flex cursor-pointer justify-between gap-1">
              <div>
                <h4 class="font-bold">AI Keywords highlighter</h4>
                <p>Quickly identify important keywords in your text</p>
              </div>
              <input
                v-model="captionEditorStore.baseOptions.highlight"
                type="checkbox"
                class="toggle-primary toggle"
                checked
              />
            </label>
            <div class="flex flex-row flex-wrap gap-2">
              <button
                v-for="color in captionEditorStore.captionStyleSettings.colors"
                @click="captionEditorStore.styleOptions.data.highlightColor = color"
                :key="color"
                class="flex h-9 w-9 items-center justify-center rounded-xl"
                :disabled="!captionEditorStore.baseOptions.highlight"
                :class="{
                  'border-2 border-[#277DFF]': color === captionEditorStore.styleOptions.data?.highlightColor,
                  'opacity-40': !captionEditorStore.baseOptions.highlight,
                }"
              >
                <div
                  class="h-5 w-5 rounded-full"
                  :style="{
                    background: captionEditorStore.captionStyleSettings.gradients?.[color] ?? color,
                  }"
                  :class="{
                    'border border-black': color === '#FFFFFF',
                  }"
                ></div>
              </button>
            </div>
          </div>
          <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
            <label class="flex cursor-pointer justify-between gap-1">
              <div>
                <h4 class="font-bold">
                  AI emojis 😎
                  <span v-if="captionEditorStore.selectedLanguage !== 'en_us'" class="badge-primary badge"
                    >English only</span
                  >
                </h4>
                <p>Automatically add relevant emojis to your captions.</p>
              </div>
              <input
                v-model="captionEditorStore.baseOptions.emojis"
                type="checkbox"
                class="toggle-primary toggle"
                checked
              />
            </label>
            <div class="mt-4 text-base font-bold leading-tight text-purple-900">Emoji Placement</div>
            <ButtonRadioGroup
              class="self-start"
              :options="[
                { label: bottomIcon, value: 'bottom' },
                { label: leftIcon, value: 'left' },
                { label: topIcon, value: 'top' },
                { label: rightIcon, value: 'right' },
              ]"
              :variant="'box'"
              v-model="captionEditorStore.baseOptions.emojiLocation"
            />
          </div>
          <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
            <label class="flex cursor-pointer justify-between gap-1">
              <div>
                <h4 class="font-bold">Random rotation</h4>
                <p>captions that rotate randomly at a pre-specified degree.</p>
              </div>
              <input
                v-model="captionEditorStore.baseOptions.rotate"
                type="checkbox"
                class="toggle-primary toggle"
                checked
              />
            </label>
          </div>
          <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
            <div>
              <h4 class="font-bold">Grouping</h4>
              <p>Change how many words are shown per caption</p>
            </div>
            <button-radio-group
              :options="[
                { label: 'Single word', value: 'single' },
                { label: 'Group', value: 'group' },
                // { label: 'Sentence', value: 'sentence' },
              ]"
              block
              v-model="captionEditorStore.baseOptions.grouping"
            />
          </div>
        </div>
      </keep-alive>
    </div>
  </div>

  <CaptionGenerateDialog ref="captionGenerateDialog" @captionsFileContent="setCaptionsFileContent" />
</template>

<style>
.selecto-container > .selecto-selection {
  border: 1px solid transparent;
  background: transparent;
  cursor: text;
}

.selection-wrapper ::selection {
  background: transparent;
}

body .v-popper__popper {
  z-index: 40;
}
</style>

<script lang="ts" setup>
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import CaptionGenerateDialog from '@/components/Captions/CaptionGenerateDialog.vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import type { CaptionsDocument } from '@/components/Captions/captionTypes'
import { markRaw, ref, watchEffect } from 'vue'
import type { Component, CSSProperties } from 'vue'
import TabRadioGroup from '@/components-v2/data-input/TabRadioGroup.vue'
import CaptionEditorLine from '@/components/Captions/CaptionEditorLine.vue'
import ButtonRadioGroup from '@/components-v2/data-input/ButtonRadioGroup.vue'
import CaptionStylePreview from '@/components/Captions/CaptionStylePreview.vue'
import Caption from '@/components/Icons/Normalized/EffectTypes/Caption.vue'
import SettingsIcon from '@/components/Icons/Normalized/SettingsIcon.vue'
import { canGuardWithPopup, useGuard } from '@/Hooks/useGuard'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { computed } from 'vue'
import LeftEmoji from '@/components/Icons/Normalized/EmojiPlacement/LeftEmoji.vue'
import TopEmoji from '@/components/Icons/Normalized/EmojiPlacement/TopEmoji.vue'
import RightEmoji from '@/components/Icons/Normalized/EmojiPlacement/RightEmoji.vue'
import BottomEmoji from '@/components/Icons/Normalized/EmojiPlacement/BottomEmoji.vue'
import {
  useTextSelection,
  watchDebounced,
  useElementSize,
  watchThrottled,
  useMouseInElement,
  useParentElement,
} from '@vueuse/core'
import { useConfirmDialog } from '@/components-v2/actions/dialog/useConfirmDialog'
import { useDeleteCaption } from '@/Hooks/captions/useCaptionTools'
import EditIcon from '@/components/Icons/EditIcon.vue'
import CheckMarkIcon from '@/components/Icons/CheckMarkIcon.vue'
import ResetIcon from '@/components/Icons/ResetIcon.vue'
import { createTooltip, destroyTooltip } from 'floating-vue'
import { markCaptionAsUsed } from '@/helpers/isNewOverlayElement'
import CaptionStyleOption from '@/components/Captions/CaptionStyleOption.vue'
import { captionStyleSettingsArray } from '@/components/Captions/styles/CaptionStyleManager'
import type { CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'
import { findLastIndex } from 'lodash-es'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import IconSaxAddSquare from '@/components/Icons/iconsax/IconSaxAddSquare.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import CaptionStyleSelector from '@/components/Captions/CaptionStyleSelector.vue'

const hasThreeColumnLayout = useFeatureFlagVariantEnabled('three-column-layout', 'wide')

const topIcon: Component = markRaw(TopEmoji)
const bottomIcon: Component = markRaw(BottomEmoji)
const leftIcon: Component = markRaw(LeftEmoji)
const rightIcon: Component = markRaw(RightEmoji)

const editorMainStore = useEditorMainStore()
const captionEditorStore = useEditorCaptionsStore()
const editorFocusStore = useEditorFocusStore()

const showDebugColorSelector = ref(false)
const baseColor = ref('#FFFFFF')
watchThrottled(
  baseColor,
  (color) => {
    captionEditorStore.styleOptions.data.baseColor = color
  },
  { throttle: 700 }
)

const wasPlaying = ref(false)

const captionsContainer = ref<HTMLElement | null>(null)

const captionGenerateDialog = ref<typeof CaptionGenerateDialog | null>(null)
const setCaptionsFileContent = (captionsFileContent: CaptionsDocument) => {
  captionEditorStore.setCaptionsDocument(captionsFileContent)
  editorFocusStore.setFocus(FocusTypes.CAPTION)
}

const deleteCaptions = useDeleteCaption()

const sortedCaptions = captionStyleSettingsArray

window.__vtooltip_deleteCaptions = deleteCaptions

const state = useTextSelection()

const parent = useParentElement()
const { isOutside } = useMouseInElement(parent)

watchEffect(() => {
  const range = state.ranges.value?.[0]
  if (!range || isOutside.value) return
  const startId = range.startContainer.parentElement?.closest('.selectable')?.dataset.id
  const endId = range.endContainer.parentElement?.closest('.selectable')?.dataset.id
  const firstCaption = captionEditorStore.captions.findIndex((caption) => startId === caption.id)
  const lastCaption = findLastIndex(captionEditorStore.captions, (caption) => endId === caption.id)
  editorFocusStore.selectedCaptions = captionEditorStore.captions
    .slice(firstCaption, lastCaption + 1)
    .map((caption) => caption.id)
})

const popupHolder = ref()
watchDebounced(
  [state.ranges],
  () => {
    if (popupHolder.value) {
      destroyTooltip(popupHolder.value)
    }
    const range = state.ranges.value?.[0]
    if (!range) return
    if (editorFocusStore.selectedCaptions.length <= 1) return
    const tooltip = createTooltip(
      range.startContainer.parentElement,
      {
        triggers: [],
        content: `<div class="flex w-full items-center justify-center p-2">
            <button class="flex h-6 justify-center gap-1 text-red-400" onclick="window.__vtooltip_deleteCaptions()">
               <svg class="w-6 fill-current" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.25 3.92583C12.2383 3.92583 12.2208 3.92583 12.2033 3.92583C9.1175 3.61667 6.0375 3.5 2.98666 3.80917L1.79666 3.92583C1.55166 3.94917 1.33583 3.77417 1.3125 3.52917C1.28916 3.28417 1.46416 3.07417 1.70333 3.05083L2.89333 2.93417C5.99666 2.61917 9.14083 2.74167 12.2908 3.05083C12.53 3.07417 12.705 3.29 12.6817 3.52917C12.6642 3.75667 12.4717 3.92583 12.25 3.92583Z"
                  />
                  <path
                    d="M4.95833 3.33666C4.935 3.33666 4.91167 3.33666 4.8825 3.33083C4.64917 3.29 4.48583 3.0625 4.52667 2.82916L4.655 2.065C4.74833 1.505 4.87667 0.729164 6.23583 0.729164H7.76417C9.12917 0.729164 9.2575 1.53416 9.345 2.07083L9.47333 2.82916C9.51417 3.06833 9.35083 3.29583 9.1175 3.33083C8.87833 3.37166 8.65083 3.20833 8.61583 2.975L8.4875 2.21666C8.40583 1.70916 8.38833 1.61 7.77 1.61H6.24167C5.62333 1.61 5.61167 1.69166 5.52417 2.21083L5.39 2.96916C5.355 3.185 5.16833 3.33666 4.95833 3.33666Z"
                  />
                  <path
                    d="M8.8725 13.2708H5.1275C3.09167 13.2708 3.01 12.145 2.94583 11.235L2.56667 5.36083C2.54917 5.12166 2.73583 4.91167 2.975 4.89417C3.22 4.8825 3.42417 5.06333 3.44167 5.3025L3.82083 11.1767C3.885 12.0633 3.90833 12.3958 5.1275 12.3958H8.8725C10.0975 12.3958 10.1208 12.0633 10.1792 11.1767L10.5583 5.3025C10.5758 5.06333 10.7858 4.8825 11.025 4.89417C11.2642 4.91167 11.4508 5.11583 11.4333 5.36083L11.0542 11.235C10.99 12.145 10.9083 13.2708 8.8725 13.2708Z"
                  />
                  <path
                    d="M7.96833 10.0625H6.02583C5.78667 10.0625 5.58833 9.86417 5.58833 9.625C5.58833 9.38583 5.78667 9.1875 6.02583 9.1875H7.96833C8.2075 9.1875 8.40583 9.38583 8.40583 9.625C8.40583 9.86417 8.2075 10.0625 7.96833 10.0625Z"
                  />
                  <path
                    d="M8.45833 7.72916H5.54166C5.3025 7.72916 5.10416 7.53083 5.10416 7.29166C5.10416 7.0525 5.3025 6.85416 5.54166 6.85416H8.45833C8.6975 6.85416 8.89583 7.0525 8.89583 7.29166C8.89583 7.53083 8.6975 7.72916 8.45833 7.72916Z"
                  />
                </svg>
                Delete captions
            </button>
          </div>`,
        html: true,
        theme: 'dropdown',
        placement: 'top',
      },
      undefined
    )
    tooltip.show()
    popupHolder.value = range.startContainer.parentElement
  },
  { debounce: 500 }
)

const generateCaptions = (captionStyle: CaptionStyle) => {
  // if (!canGuard('captions')) return
  if (!canGuardWithPopup('captions')) return
  markCaptionAsUsed(captionStyle)
  editorVideoStore.playing = false
  captionEditorStore.captionStyle = captionStyle
  captionGenerateDialog.value?.open()
}

const { reveal } = useConfirmDialog()

const resetCaptions = async () => {
  const confirmed = await reveal({
    title: 'Reset Captions',
    message: 'Are you sure you want to reset all captions to the originally generated?',
  })
  if (!confirmed) return
  const copy = JSON.parse(JSON.stringify(captionEditorStore.captionsDocument))
  captionEditorStore.setCaptionsDocument(copy)
}

const editorFeedDataStore = useEditorFeedDataStore()

const filteredCaptions = computed(() => {
  return captionEditorStore.captions.map((caption) => {
    const segmentId = editorFeedDataStore.segments.find(
      (segment) => segment.start <= caption.end && segment.end >= caption.start
    )?.id
    // const segmentId = 0
    return {
      ...caption,
      segmentId,
    }
  })
})

const selectedTab = ref(0)

const captionsStylesContainer = ref<HTMLElement | null>(null)
const { width: captionStylesContainerWidth } = useElementSize(captionsStylesContainer)
const isCaptionStylesContainerExpanded = ref(false)

const editorCaptionStore = useEditorCaptionsStore()
const editorVideoStore = useEditorVideoStore()

const addCaption = () => {
  editorCaptionStore.addCaption(editorVideoStore._currentTime * 1000)
}

const deleteCaption = async () => {
  await deleteCaptions()
}
</script>
