<script setup lang="ts">
import ErrorDialog from '@/components/Dialog/ErrorDialog.vue'
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getApiTwitchClipsClipId } from '@/apis/streamladder-api/twitch-clips/twitch-clips'
import { getApiYouTubeClipsClipId } from '@/apis/streamladder-api/you-tube-clips/you-tube-clips'
import { getApiKickClipsClipId } from '@/apis/streamladder-api/kick-clips/kick-clips'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import AppPage from '@/components/AppPage.vue'
import Spinner from '@/components/Icons/Spinner.vue'
import localForage from 'localforage'
import { canGuard } from '@/Hooks/useGuard'
import { last } from 'lodash-es'
import { routes } from '@/areas/dashboard/router'
import LottieAnimation from '@/components/LottieAnimation.vue'

type SupportedSources = 'twitch-clip' | 'youtube-clip' | 'kick-clip' | 'local-file'

const props = defineProps<{
  source: SupportedSources
  clipId: string
}>()

const editorClipInfoStore = useEditorClipInfoStore()

const errorDialog = ref<ErrorDialog | undefined>(undefined)
const clipStatus = reactive({
  status: 'loading' as 'loading' | 'error' | 'success',
  errorButtonText: '',
  errorMessage: '',
})

const emptyResponse = {
  id: null,
  mp4Url: null,
  title: null,
}

const loading = ref(false)

function clipDoesNotExist() {
  clipStatus.status = 'error'
  clipStatus.errorMessage =
    'Unfortunately, your clip could not be found. Please try another clip or use the "From Twitch" button.'
  clipStatus.errorButtonText = 'Change video'
  errorDialog.value?.open()
}

const router = useRouter()

function resetClip() {
  clipStatus.status = 'loading'
  router.push({ name: 'ClipEditor' })
}

async function getClip() {
  try {
    const source = props.source
    loading.value = true

    let response
    if (source === 'twitch-clip') {
      response = await getApiTwitchClipsClipId(props.clipId)
    } else if (source === 'youtube-clip') {
      response = await getApiYouTubeClipsClipId(props.clipId)
    } else if (source === 'kick-clip') {
      response = await getApiKickClipsClipId(props.clipId)
    } else if (source === 'local-file') {
      response = await handleLocalFile(props.clipId)
    }

    if (response.mp4Url == null) {
      editorClipInfoStore.setClip(emptyResponse)
      clipDoesNotExist()
    } else {
      editorClipInfoStore.setClip({
        ...response,
        source: source,
      })
    }
  } catch (e) {
    clipDoesNotExist()
  } finally {
    clipStatus.status = 'success'
    loading.value = false
  }
}

async function handleLocalFile(blobGuid: string) {
  const clipUrl = `blob:${location.origin}/${blobGuid}`
  const fileData = await localForage.getItem('localFile')
  const fileUrlFunctions = await doesFileExist(clipUrl)

  // No file data, so we can't use the file
  if (fileData == null) {
    console.error('No file data found')
    return emptyResponse
  }

  // the file url exists, so we can use it
  if (fileUrlFunctions) {
    // Start uploading the clip if the file comes from local storage.
    await startUpload(fileData)
    return {
      id: blobGuid,
      title: fileData.name,
      mp4Url: clipUrl,
      slug: fileData.name,
      isLocalFile: true,
      source: 'local-file',
    }
  }

  // the file url does not exist, so we can't use it
  // create new blobUrl from localforage data
  const blobUrl = URL.createObjectURL(fileData)
  const _blobGuid = last(blobUrl.split('/')) ?? blobUrl
  await startUpload(fileData)
  return {
    id: _blobGuid,
    title: fileData.name,
    mp4Url: blobUrl,
    slug: fileData.name,
    isLocalFile: true,
    source: 'local-file',
  }
}

async function startUpload(fileData, force = false) {
  // Check if the user is allowed to upload local files.
  if (force || canGuard('server-side-queueing')) {
    editorClipInfoStore.uploadClipInBackground(fileData)
  }
}

async function doesFileExist(objectUrl) {
  try {
    return (await fetch(objectUrl)).ok
  } catch (e) {
    return false
  }
}

onMounted(async () => {
  await getClip()
})
</script>

<template>
  <div>
    <router-view v-if="!loading || source === 'twitch-clip'"></router-view>
    <app-page v-else>
      <template #content>
        <div v-if="props.source === 'youtube-clip'" class="flex w-full flex-col p-8">
          <lottie-animation url="/lottie/search-youtube.json" class="mx-auto w-1/3" :auto-play="true" />
          <p class="mb-0 text-center font-bold text-youtube">One moment</p>
          <p class="text-center text-gray-800">Importing YouTube Clip</p>
        </div>
        <div
          v-else
          class="align-center flex h-full w-full flex-grow cursor-not-allowed items-center justify-center self-center pb-12"
        >
          <spinner class="mr-2 h-20 w-20 animate-spin text-company-primary-50" />
          <span class="sr-only">Loading...</span>
        </div>
      </template>
    </app-page>
    <error-dialog
      ref="errorDialog"
      :button-text="clipStatus.errorButtonText"
      :action="resetClip"
      :title="clipStatus.errorMessage"
    />
  </div>
</template>

<style></style>
